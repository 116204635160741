import React from "react";
import Select from "react-select";

const NewSelect = ({
  name,
  label,
  options,
  placeholder,
  value,
  error,
  ...rest
}) => {
  //this will map to the id and name into value and label
  options = options.map(p => ({ value: p.id, label: p.name }));

  //console.log("value", value);
  //and this is where we check if the value is null to pass the objetc that wants in the format valye and label
  if (value === null) {
    value = { value: "", label: "" };
  } else {
    value = options.find(o => o.value === value);
  }

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Select
        name={name}
        id={name}
        {...rest}
        // className="form-control"
        className="react-select primary"
        classNamePrefix="react-select"
        placeholder={placeholder}
        value={value}
        options={options}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default NewSelect;
