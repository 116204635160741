import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import { Table } from "reactstrap";

const TableAll = ({
  columns,
  sortColumn,
  onSort,
  data,
  uniqueKey1,
  uniqueKey2
}) => {
  return (
    // <table className="table">

    <Table className="align-items-center table-flush" responsive>
      <TableHeader
        // className="text-primary"
        columns={columns}
        sortColumn={sortColumn}
        onSort={onSort}
        align="center"
        className="thead-light"
      />
      <TableBody
        columns={columns}
        data={data}
        align="center"
        uniqueKey1={uniqueKey1}
        uniqueKey2={uniqueKey2}
      />
    </Table>
    // </table>
  );
};

TableAll.defaultProps = {
  uniqueKey1: "id",
  uniqueKey2: "id"
};

export default TableAll;
