import React from "react";
import Form from "../../../components/common/form";
import Joi from "joi-browser";
import { Badge, Col, Row } from "reactstrap";
import { Button } from "../../../components/Ncomponents";

import { electionsAreasGetAll } from "../../../services/listOfValues";
import { fvstationsGetAll } from "../../../services/fakeVstationsService";
import {
  vstationsGetAll,
  vstationsDelete
} from "../../../services/vstationsService";
import LoaderButton from "../../../components/common/loaderButton";
import Pagination from "../../../components/common/pagination";
import SearchBox from "../../../components/common/searchBox";
import { paginate } from "../../../components/utils/paginate";
import swal from "sweetalert";
import _ from "lodash";
import ElectionsVstationsTable from "./electionVstationsTable";
import NewSelect4 from "../../../components/common/newSelect4";

class ElectionsVstationsForm extends Form {
  constructor(props) {
    super();
    this.state = {
      data: {
        id: "",
        name: "",
        order: "",
        // number: "",
        electionLocation: "",
        electionLocationId: ""
      },

      electionVstations: [],
      electionVstationsFiltered: [],
      electionLocations: [],
      vstationsAll: [],
      areasAll: [],
      //the delta between DB and used
      location: "", //{ value: "", label: "", layerId: "" },
      //   party: { value: "", label: "", layerId: "" },
      //   party2: {},
      //The values that will be kept for the drodowns
      errors: {},
      title: "",
      showBodyForm: true,
      showCreateForm: false,
      hideDropDownLocationVstations: false,
      hideDropDownVstations: false,

      //for the pagination
      pageSize: 50,
      currentPage: 1,
      searchQuery: "",
      sortColumn: { path: "order", order: "asc" },
      ////
      isLoading: false
    };
  }

  schema = {
    id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Όνομα"),
    order: Joi.number()
      .required()
      .label("Αριθμός"),
    electionLocation: Joi.string()
      .required()
      .label("Περιοχη"),
    electionLocationId: Joi.string()
      .required()
      .label("Περιοχη Id")
  };

  validateForm() {
    var disabled = true;

    if (this.state.data.name.length > 0 && this.state.data.order !== "") {
      disabled = false;
    }

    if (Object.entries(this.state.errors).length !== 0) {
      disabled = true;
    }
    return disabled;
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  async componentDidMount() {
    const elections = { ...this.props.elections };

    var vstations;
    if (this.props.production === true) vstations = await vstationsGetAll();
    if (this.props.production === false) vstations = fvstationsGetAll();

    //const vstations = await vstationsGetAll();

    const areas = electionsAreasGetAll(); // localy from the file

    this.setState(
      {
        electionVstations: elections.electionVstations,
        electionVstationsFiltered: elections.electionVstations,
        electionLocations: elections.electionLocations,
        vstationsAll: vstations,
        areasAll: areas
      },
      () =>
        console.log(
          "call back on cdm election Vstation Form",
          this.state.electionVstations,
          this.props.elections.electionVstations
        )
    );

    //the first will have the areas that are new( not used ) while the second one should not be updated i need that later on edit to check the layerid
    //this.setState({ electionLocations, electionLocationsAllDb: electionLocations });
  }

  handleSave = () => {
    this.setState({ isLoading: true });

    const data = { ...this.state.data };
    data.order = parseInt(data.order, 10); // to make sure that the order is saved as integer cuase it was saving that as string

    // console.log("save", this.state);
    this.props.onSave(data);

    this.setState({
      showBodyForm: true,
      showCreateForm: false,
      data: null,
      isLoading: false
    });
  };

  initializeStateData() {
    const data = { ...this.state.data };
    data.id = "";
    data.name = "";
    data.order = "";
    data.electionLocation = ""; // i added that for helping us sorting the stations here
    data.electionLocationId = "";

    return data;
  }

  handleShowCreateForm = () => {
    //create the Title
    const title = "Προσθήκη Εκλογικού τμήματος";

    //inititliaze as i save or cancel i make that null and the dropdown vstations complains ...
    const data = this.initializeStateData();

    //Load only the new vstations so to see the already used partis
    this.getNewVstations();

    //Bring the locations from the electionLocations, those will be used to filter my vstations also
    var areasUsed = this.props.elections.electionLocations;

    //if perfieriakes then i need to go on level up my areas so to show the perferies
    // if (this.props.elections.electionType.id === "ELTY003") {
    //   var Periferies = [];
    //   var pen = {};
    //   var periferia = {};
    //   for (var i = 0; i < areasUsed.length; i++) {
    //     pen = this.state.areasAll.find(f => f.id === areasUsed[i].id);
    //     periferia = this.state.areasAll.find(f => f.id === pen.pId);

    //     console.log("topoi ", pen, periferia);
    //     // console.log(periferia, areasUsed[i]);
    //     if (Periferies.indexOf(periferia) === -1) {
    //       Periferies.push(periferia);
    //     }
    //   }

    //   //and finally i make the areasUsed to periferies
    //   areasUsed = Periferies;
    // }

    this.setState(
      {
        data: data,
        title: title,
        showCreateForm: true,
        showBodyForm: false,
        electionLocations: areasUsed,
        location: "", //initialize the drop down of locations
        hideDropDownVstations: true,
        hideDropDownLocationVstations: false
      },
      () => console.log("create form: state", this.state)
    );
  };

  handleCancel = () => {
    this.setState({
      showBodyForm: true,
      showCreateForm: false,
      data: null
    });
  };

  getNewVstations = () => {
    var freshVstations = this.state.vstationsAll;

    // console.log(this.props, freshVstations);

    if (!this.props.elections.electionVstations) {
      console.log("Props were not loaded");
    } else {
      freshVstations = this.state.vstationsAll.filter(
        cdb =>
          !this.props.elections.electionVstations.find(cf => cdb.id === cf.id)
      );

      this.setState({ electionVstations: freshVstations }, () =>
        console.log("Refreshed the vstations", this.state)
      );
    }
  };

  validateCreateButton() {
    if (this.props.elections.electionLocations.length > 0) {
      return false;
    }

    return true;
  }

  handleChangeNewSelect3 = e => input => {
    if (e === "electionLocations") {
      //find all vstations that have in theri location the value of the drop down
      //it work either for periferiakes (level1) or all the others as the party has the value of level1 for periferiakes
      //or the party for dimotikes has all three leveles and it matches with the 3 one

      // const electionVstations = JSON.parse(
      //   JSON.stringify(this.state.electionVstations)
      // );

      let vstations = this.state.electionVstations.filter(p =>
        p.location.find(f => f.id === input.value)
      );
      console.log(this.state.electionVstations);
      console.log("on select of location ", input.value, vstations);

      //sort them
      vstations = vstations.sort((a, b) => (a.name > b.name ? 1 : -1));

      const data = { ...this.state.data };
      data.electionLocation = input.label;
      data.electionLocationId = input.value;

      //make null the other drop down finally i fix that
      data.id = null;

      this.setState({
        data: data,
        electionVstationsFiltered: vstations,
        hideDropDownVstations: false
      });
    }

    if (e === "Vstations") {
      const data = { ...this.state.data };
      console.log("Select Vstations", e, input);
      data.id = input.value; // will add the value to id
      data.name = input.label; // and the lable to name
      this.setState({ data });
    }
  };

  // cleaning = async () => {
  //   const DeleteArray = [
  //     "cd73631e-1229-469a-bad3-615061a61297",
  //     "c14cd377-0dc6-4a32-bb67-1efe90846e53",
  //     "5217daca-29a1-44f2-b786-156c4951bdc4",
  //     "21560f79-2bbd-4fd5-84b2-dffc4feb3807",
  //     "b5d06853-531f-43a9-847b-80fbb62984b0",
  //     "873cb56a-d2f2-418d-b5b9-b734cfaa999f",
  //     "257e715d-245a-436a-a2ad-742ddad327e7",
  //     "06478144-e7ed-421d-9d26-37ede99feccc",
  //     "986feba8-c85e-4b4f-ab0c-4574fe679033",
  //     "461c4b02-cf31-463e-a1e3-7c37d2a226bf",
  //     "a1fb3b0a-19bf-4ee8-af6b-a0aa029367cd",
  //     "1c64ce4d-2a79-40b9-b072-ae85ac906d93",
  //     "5cc4f405-6aac-4357-a5a6-1abb9e9bbd59",
  //     "06ed8bc1-b01a-4eba-b134-b95e09674640",
  //     "5b616de7-d2d0-4d1f-a476-eff45433fe98",
  //     "d1faa930-1b8d-4b8c-bdda-e7f9caab0902",
  //     "b0cd6198-8bec-4198-b294-1d7a411fa073",
  //     "4863e1af-0e8a-43d5-9976-0edf7288b506",
  //     "3b88caeb-e44e-41a3-a580-ac46cc22f296",
  //     "54c46118-7d2e-4c1d-8ab7-ec359f29a44a",
  //     "d0904426-0ead-4597-b947-1183eff8272a",
  //     "2a5a23c8-5726-4b8e-8ec6-b441722d2cc2",
  //     "4f12481d-93df-4a67-bd94-cd3ed0cbb3b9",
  //     "4d1b3b1a-6fc3-483f-80c4-9ba41e9fd46b",
  //     "326d9e72-43c5-4739-88c7-0bab59246a0d",
  //     "3ae66b7a-6122-4593-ab25-4f9f9c98bdcb",
  //     "219f5056-0646-4fb5-8a3a-05c3cc3bca08",
  //     "a238d218-a10f-4bc1-9da3-955dc331eec8",
  //     "c009601c-9889-4d88-a435-65d9e595b1e7",
  //     "ac4b3125-9248-4396-a6e7-b491cc221760",
  //     "7a2beef8-85d7-4bea-89dc-7cf6f2aaa0b7",
  //     "b08ba021-31de-4dff-b42c-cfd96067ded7",
  //     "cad462a9-6a82-40a1-87de-0cd6282f954e",
  //     "31349314-10db-4abb-9dc8-e5d50087fb35",
  //     "15332d62-9f79-4d24-af0b-54c1fa5d51ac",
  //     "d55a822a-99fe-4586-8169-9ac15cb36b2f",
  //     "79c7414f-3f0f-4e6e-bdae-a691b0943d2f",
  //     "2b5bed0e-13cd-45d5-95d9-12f95f040460",
  //     "d14b542e-ae2d-491f-a42c-b4a84765203b",
  //     "f6d848d3-5a48-4dbd-a2b2-2986cbbdf8fa",
  //     "ab562dff-a6b8-4927-b4a8-c85d2de39158",
  //     "fc2fef45-3495-410d-9a11-9d99af3ae6c9",
  //     "0ec12497-06ee-4c64-a07d-f0ac8055e3eb",
  //     "48026082-7989-43de-900c-af9f3b255fd6",
  //     "ab112472-a165-4a0c-8365-7f5a62a6498e",
  //     "930a467a-0b01-4edb-9592-d115503894e7",
  //     "35d5f5d6-b145-4681-a4b3-bd433961c8d8",
  //     "2d89a870-4b38-420a-991a-5097277caef0",
  //     "4a1bd609-f39c-44b3-9a81-18644060bed3",
  //     "f0eb420e-0481-4f1d-aad4-2a41d6acd431",
  //     "81dee829-15d3-4ddf-9a05-033c3381e0bc",
  //     "26cae1c4-079e-4fc8-9dc0-fdcabc1c0b3a",
  //     "cdfd4349-b53a-43ad-8502-06186e00d945",
  //     "348b42ef-e1a7-45f8-9874-7ffa29d6bc7c",
  //     "41d6a015-14f0-46e7-8c6b-e75d4096e536",
  //     "85bd18fe-e6cf-4559-b9d4-e42281f9ded6",
  //     "9114d20b-bc54-4b71-95f5-dda89471bf31",
  //     "4d7c157c-efa1-477d-9e9b-5af12f8e5695",
  //     "8ba022de-c55d-4811-a94d-6fbe150ecfb5",
  //     "0931a06a-8371-470c-ae2a-fe9e58798767",
  //     "643ea1a4-f4aa-49e6-b6b0-4f22989cef04",
  //     "482b4b87-7c1d-4f09-b4f0-e8ea053be2cc",
  //     "45b98016-7d93-4d15-ab10-edb6a1fadecb",
  //     "276e0880-b2a5-4600-9451-df444d8cd914",
  //     "7ce6392c-7147-4d5a-a227-63ab74726bad",
  //     "e2297411-993a-4f98-a153-caa9099db22e",
  //     "55b88315-96dd-4129-bc06-a7a71d7a7731",
  //     "bba13226-5348-4310-9c43-5457b124f201",
  //     "dd0d79ff-64c0-4507-8dbb-8fd2766a16b0",
  //     "a9b640c6-be2a-40b6-af66-a5c8350fde79",
  //     "b699764f-db98-40c5-8604-28b022c6ef9c",
  //     "c1813d21-6fbc-485e-b5c9-21b762873e22",
  //     "4e6a89be-68e1-47f0-a683-f7d75dd8a9a9",
  //     "8d5eb262-73b1-46b2-991e-726279654ca5",
  //     "1d748fb2-7dec-4cef-aa06-8d66f3d02be0",
  //     "a10d27a0-cf84-42d5-b288-15772075e727",
  //     "e3051d60-33d6-4c55-8615-202f53919aef",
  //     "69ddc012-c436-4873-94d7-aff25d1065f1",
  //     "b9147486-aee0-4629-9815-0c7a0ad4d465",
  //     "0046fe58-3626-48ae-a048-8566f85dcc44",
  //     "aada7de5-ee64-4936-97c9-c7353eea9b5f",
  //     "7ea32585-975a-4d18-941e-da7b66096f00",
  //     "a0e20b7b-3287-4647-bbe7-6c1a5885f6eb",
  //     "4d0e3b87-846b-4878-b77a-34fa3215d098",
  //     "89f5c1d0-b28b-48bc-aa9b-ccaff56579cc",
  //     "de6e4f03-9de1-4a53-aa6e-5e8600bc4f94",
  //     "f65b64f5-59cf-4004-9e8a-52645a51d8e4",
  //     "45465dce-b958-437b-850a-fc1d60ad1acd",
  //     "aea119bf-09d0-48e8-b130-9650cb07544e",
  //     "5c9b7e03-7c54-4971-b025-e9d2e45f4185",
  //     "94a62c41-36dc-4ec5-b0c5-d744a39c5e7d",
  //     "1ce93c6b-68b4-4d98-9785-89d3d9081b93",
  //     "d7cbe672-8990-4f1e-b704-ae8343c755fe",
  //     "f1554112-adfd-4556-92ed-221a04a1ce39",
  //     "6a1a146f-561a-4e4b-a43f-90f0c65ccee3",
  //     "69b9fde0-291f-436b-b743-16c842dbd7ec",
  //     "8b05b62f-f604-4118-b43f-8717c7234b0c",
  //     "84e14133-dcda-4b1e-8223-df73e004244d",
  //     "16958352-fa17-4e13-a08f-6ff7947dfbb4",
  //     "98a59618-17d3-428c-b313-60b153efa8a6",
  //     "aea8da93-e373-4d2c-9f37-939223d4a29a",
  //     "77c05f71-22a0-49bd-818a-8a212dd86127",
  //     "1154e059-30ea-4cf8-8019-1cd29fca8ba4",
  //     "e98b6637-381e-4da4-b10b-3a73502d48a9",
  //     "cc763b46-eab5-46ce-b824-aad49ac153d2",
  //     "09ecb813-6f17-40b8-bf73-6ae4f733e8f6",
  //     "53acc94a-ed6b-45be-9611-521ee78c7096",
  //     "57f55dad-dd46-451e-bb28-ef00ea2754c1",
  //     "c0777501-b0fe-40c5-92af-cec7d6e83324",
  //     "738eecf9-c66e-4598-9342-dee714b648e1",
  //     "f7fa0d18-49e4-443a-b0e7-d27a00e86f83",
  //     "4c20a686-5b2b-48a5-8d91-5f08a97ee9ce",
  //     "018c117f-3396-43bf-9840-44269da1f4a7",
  //     "c0af3521-d62c-4570-8633-23014637c966",
  //     "520f285a-e441-4d57-a0d9-4d3a1224abf1",
  //     "ad74117a-42f2-4c25-aede-fc7502d61098",
  //     "2954a843-8a20-491f-8d20-ad9d1a29b652",
  //     "df0d37e3-e4d1-4f8c-a9ea-80b780d43235",
  //     "31a8bf0a-1c0b-4469-b413-fda0bc7ec23a",
  //     "834b80fe-20eb-460b-914d-afb3e1c65723",
  //     "7dc0b91e-41e2-4e26-bebe-8845deb70be3",
  //     "438880d8-74e7-43b1-8624-7b5424ba5686",
  //     "d7ae156e-5348-4033-94fd-18123fb20534",
  //     "96a04d39-5edf-4f94-a8f8-ba20af0e2300",
  //     "a686ba49-75db-4591-818f-a815d4308b65",
  //     "f0dc0bb4-46c1-41fe-bc63-848b4831b394",
  //     "cb8aa4ea-c3eb-40f4-9ff2-dfb09cc9733d",
  //     "bb22b865-71f3-43ad-bff2-928c4bdadcc4",
  //     "3d4bcea9-8f01-4cde-892c-ba10dd62d427"
  //   ];

  //   for (var x = 0; x < DeleteArray.length; x++) {
  //     console.log("deleting", DeleteArray[x]);
  //     await vstationsDelete(DeleteArray[x]);
  //   }
  // };

  handleDelete = electionVstation => {
    //console.log("On Delete within the Areas Forrm", this.props, electionLocations);

    swal({
      title: electionVstation.name,
      text:
        "Εισαι σιγουρος  οτι θελεις να αφαιρεσεις το κομμα απο την εκλογικη αναμετρηση",
      icon: "warning",
      buttons: true
    }).then(async willDelete => {
      if (willDelete)
        if (
          this.state.currentPage > 1 &&
          this.state.currentPage - 1 ===
            (this.props.elections.electionVstations.length - 1) /
              this.state.pageSize //e,g, page 3 , 8 length page size 4=>  (3-1=2) =  ( 8/4= 2) so reduce
        ) {
          const newPage = this.state.currentPage - 1;
          this.setState({ currentPage: newPage });
        }

      this.props.onDelete(electionVstation);
    });
  };

  getPagedDataElectionsVstations = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { electionVstations: AllelectionVstations } = this.props.elections;

    let filtered = AllelectionVstations || []; // in case is undefined
    if (searchQuery)
      filtered = AllelectionVstations.filter(c =>
        c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    // console.log("Input to paginate", sorted, currentPage, pageSize);

    const vstations = paginate(sorted, currentPage, pageSize);

    return { totalCountVstations: filtered.length, vstations: vstations };
  };

  mapToViewModel(electionVstation) {
    return {
      id: electionVstation.id,
      name: electionVstation.name,
      order: electionVstation.order,
      electionLocation: electionVstation.electionLocation,
      electionLocationId: electionVstation.electionLocationId
    };
  }

  handleUpdate = electionVstation => {
    //update the title of the form
    const title = "Ενημέρωση εγγραφής";

    // //as it  may not have all the data eg. order cause some has forgotten to enter
    // //then it will be overide by the data of the
    // this.initializeStateData();

    //find the full details of the party as i want its locations
    const vstationFullDetails = this.state.vstationsAll.find(
      f => f.id === electionVstation.id
    );

    console.log("vstationFullDetails", vstationFullDetails);

    //find the location of the patrty from the table vstations All i can get all t
    var electionsAreas = [];
    var electionArea = {};
    if (this.props.elections.electionType.id === "ELTY003") {
      electionArea = vstationFullDetails.location.find(
        p => p.layerId === "LOLA02"
      );
    } else {
      electionArea = vstationFullDetails.location.find(
        p => p.layerId === "LOLA03"
      );

      console.log("Dimoitkes", electionArea, vstationFullDetails);
    }
    electionsAreas.push(electionArea);

    //load for the vstations dropdonw only the potion omly the one option
    const electionVstations = [];
    electionVstations.push(electionVstation);

    //bring the data to thet state
    var data = { ...this.state.data };
    data = this.mapToViewModel(electionVstation);

    //the location is comin from the dropdown so i will overwite the label
    // console.log("electionArea", electionArea);
    data.electionLocation = electionArea.name;
    data.electionLocationId = electionArea.id;

    //check the order if it is null or undefinde
    // if (data.order === undefined) {
    //   data.order = "";
    // }

    // data.id = electionVstation.id;
    // data.name = electionVstation.name;
    // data.order = ""; //electionVstation.order;

    console.log("electionVstation", electionVstation);

    this.setState(
      {
        data: data, // the value that the drop down has is inside the id
        electionVstations: electionVstations, // for dropdonw of vstations
        title: title,
        showCreateForm: true,
        showBodyForm: false,
        hideDropDownLocationVstations: true,
        location: electionArea.id, // the value of the dropdonw of location
        electionLocations: electionsAreas // the options of the drop donw location
      },
      () => console.log(this.state)
    );
  };

  renderAreasBody() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const {
      totalCountVstations,
      vstations
    } = this.getPagedDataElectionsVstations();

    return (
      <React.Fragment>
        <br />
        <Row>
          <Col xs="6">
            <h3 className="mb-0">
              <Badge color="info">{totalCountVstations}</Badge>
              <span className="m-1" />
              Εκλογικά Τμήματα
            </h3>
          </Col>
          <Col className="text-right" xs="6">
            <button
              onClick={this.handleShowCreateForm}
              className="btn btn-success"
              style={{ marginBottom: 20 }}
              disabled={this.validateCreateButton()} // if there is no record on the parent
            >
              Προσθήκη
            </button>
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12}>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ElectionsVstationsTable
              electionVstations={vstations}
              sortColumn={sortColumn}
              onDelete={this.handleDelete} //{this.props.onDelete}
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <Pagination
              itemsCount={totalCountVstations}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderAreasCreate() {
    console.log("on create", this.state);

    return (
      <React.Fragment>
        <br />

        <form>
          <Row>
            <Col xs="12">
              <h3 className="mb-0">{this.state.title}</h3>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} xs={12}>
              <NewSelect4
                name="electionLocations"
                className="react-select primary"
                classNamePrefix="react-select"
                isSearchable
                placeholder="Επιλογή"
                value={this.state.data.electionLocationId}
                label="Εκλογική Περιοχή"
                options={this.state.electionLocations}
                onChange={this.handleChangeNewSelect3("electionLocations")}
                isDisabled={this.state.hideDropDownLocationVstations}
              />
            </Col>

            <Col md={4} xs={12}>
              <NewSelect4
                name="Vstations"
                className="react-select primary"
                classNamePrefix="react-select"
                isSearchable
                placeholder="Επιλογή"
                value={this.state.data.id} // it wants only the ID wich will automatically convert that to value
                label="Εκλογικό Τμήμα"
                options={this.state.electionVstationsFiltered}
                onChange={this.handleChangeNewSelect3("Vstations")}
                isDisabled={this.state.hideDropDownVstations}
              />
            </Col>

            <Col md={3} xs={12}>
              {this.renderInput("order", "Σειρά Εμφάνισης")}
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <Button
                // leftLabel="now-ui-icons arrows-1_minimal-left"
                // round
                onClick={this.handleCancel} //    {this.props.onCancel}
              >
                Ακύρωση
              </Button>
              {/* <span className="m-2"> </span>
              <Button
                // leftLabel="now-ui-icons arrows-1_minimal-left"
                // round
                onClick={this.cleaning} //    {this.props.onCancel}
              >
                Cleaning
              </Button> */}
              <span className="m-2"> </span>

              <LoaderButton
                color="success"
                // round
                // leftLabel="now-ui-icons ui-1_check"
                onClick={this.handleSave}
                disabled={this.validateForm()}
                isLoading={this.state.isLoading}
                text="Αποθηκευση"
                loadingText="Saving...."
              />
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showBodyForm === true
          ? this.renderAreasBody()
          : this.renderAreasCreate()}
      </React.Fragment>
    );
  }
}

export default ElectionsVstationsForm;
