import { API } from "aws-amplify";

export function electionsSave(election) {
  if (election.id) {
    const body = { ...election };
    delete body.id;
    return API.put("elections", `/elections/${election.id}`, {
      body: body
    });
  }
  return API.post("elections", "/elections", {
    body: election
  });
}
export function electionsGetOne(electionId) {
  return API.get("elections", `/elections/${electionId}`);
}

export function electionsGetAll() {
  return API.get("elections", "/elections");
}

export function electionsDelete(electionId) {
  return API.del("elections", `/elections/${electionId}`);
}
