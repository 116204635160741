import { API } from "aws-amplify";

export function usersSave(user) {
  if (user.id) {
    const body = { ...user };
    delete body.id;
    return API.put("users", `/users/${user.id}`, {
      body: body
    });
  }
  return API.post("users", "/users", {
    body: user
  });
}

export function usersCreate(user) {
  return API.post("users", "/users", {
    body: user
  });
}

export function usersGetOne(userId) {
  return API.get("users", `/users/${userId}`);
}

export function usersGetAll() {
  return API.get("users", "/users");
}

export function usersDelete(userId) {
  return API.del("users", `/users/${userId}`);
}
