import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import moment from "moment";
// Specifc libraries
import { fusersGetOne } from "../../../services/fakeUsersService";
import { usersGetOne, usersSave } from "../../../services/usersService";
import LoaderButton from "../../../components/common/loaderButton";
import Form2 from "../../../components/common/form";

class Users extends Form2 {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: "",
        lastName: "",
        firstName: "",
        email: "",
        mobile: "",
        notes: "",
        imageProfileId: "",
        updatedOn: moment().unix()
      },
      errors: {},
      isLoading: false,
      inputReadOnly: true,
      hideUpdateButtons: true,
      hideChangeButton: false
    };
  }

  async componentDidMount() {
    var user;
    if (this.props.production === true) {
      user = await usersGetOne(this.props.email);
    }
    if (this.props.production === false) {
      user = fusersGetOne("malakasis@gmail.com");
    }

    console.log("USEWRRR", user);

    this.setState({ data: this.mapToViewModel(user) });
  }

  validateForm() {
    return false;
  }

  mapToViewModel(user) {
    return {
      id: user.id,
      lastName: user.lastName || "",
      firstName: user.firstName || "",
      email: user.email || "",
      mobile: user.mobile || "",
      notes: user.notes || "",
      imageProfileId: user.imageProfileId || "",
      updatedOn: user.updatedOn
    };
  }

  handleOnUpdate = () => {
    this.setState({
      inputReadOnly: false,
      hideUpdateButtons: false,
      hideChangeButton: true
    });
  };

  handleChange = event => {
    const { data } = { ...this.state };

    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  handleSubmit = async () => {
    this.setState({ isLoading: true });

    try {
      if (this.props.production === true) await usersSave(this.state.data);
      //   if (this.props.production === false) fusersSave(this.state.data);

      toast.success("Επιτυχία, Ενημέρωσα τα στοιχεια σου ");
      this.setState({
        isLoading: false,
        inputReadOnly: true,
        hideUpdateButtons: true,
        hideChangeButton: false
      });
    } catch (ex) {
      toast.error("Αποτυχία, Δεν μπόρεσα να ενημερώσω τα στοιχεια σου");
      console.log(ex);
      this.setState({ isLoading: false });
    }
  };

  handleCancel = () => {
    this.setState({
      inputReadOnly: true,
      hideUpdateButtons: true,
      hideChangeButton: false
    });
  };

  render() {
    return (
      <>
        <SimpleHeader />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col md="9" sm="9">
                  <h2 className="mb-0">Tο Προφίλ σου</h2>
                </Col>
                <Col md="3" sm="3" className="align-items-right">
                  <h5 className="mb-0">
                    Ενημερώθηκε
                    {" " +
                      moment
                        .unix(this.state.data.updatedOn)
                        .format("DD/MM/YY HH:mm:ss")}
                  </h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!this.state.hideChangeButton && (
                <Row>
                  <Col md="4" sm="12">
                    <Button
                      color="default"
                      type="button"
                      onClick={this.handleOnUpdate}
                    >
                      Αλλαγές
                    </Button>
                  </Col>
                </Row>
              )}
              <br />
              <Row>
                <Col md="4" sm="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="lastName">
                      Επίθετο
                    </label>
                    <Input
                      id="lastName"
                      placeholder="Επίθετο"
                      type="text"
                      onChange={this.handleChange}
                      disabled={this.state.inputReadOnly}
                      value={this.state.data.lastName}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="firstName">
                      Όνομα
                    </label>
                    <Input
                      id="firstName"
                      placeholder="Ονομα"
                      type="text"
                      onChange={this.handleChange}
                      disabled={this.state.inputReadOnly}
                      value={this.state.data.firstName}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="mobile">
                      Κινητό
                    </label>
                    <Input
                      id="mobile"
                      placeholder="Κινητο Τηλέφωνο"
                      type="text"
                      onChange={this.handleChange}
                      disabled={this.state.inputReadOnly}
                      value={this.state.data.mobile}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {/* <Col md="3" sm="6">
                  {this.renderMoment("updatedOn", "Ενημερώθηκε", "Text", true)}

         
                </Col> */}
                <Col md="3" sm="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="email">
                      Email
                    </label>
                    <Input
                      id="email"
                      placeholder="email"
                      type="text"
                      disabled={this.state.inputReadOnly}
                      onChange={this.handleChange}
                      value={this.state.data.email}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="notes">
                      Σημειώσεις
                    </label>
                    <Input
                      id="notes"
                      placeholder="Σημειώσεις"
                      rows="3"
                      type="textarea"
                      disabled={this.state.inputReadOnly}
                      onChange={this.handleChange}
                      value={this.state.data.notes}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {!this.state.hideUpdateButtons && (
                  <Col md={12} xs={2}>
                    <Button onClick={this.handleCancel}>Ακύρωση</Button>
                    <span className="m-2"> </span>
                    <LoaderButton
                      color="success"
                      onClick={this.handleSubmit}
                      disabled={this.validateForm()}
                      isLoading={this.state.isLoading}
                      text="Αποθηκευση"
                      loadingText="Saving...."
                    />
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}
export default Users;
