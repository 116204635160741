import React from "react";
import Joi from "joi-browser";
import moment from "moment";
import { toast } from "react-toastify";

//Specific
import Form from "../../../components/common/form";
import {
  candidatesSave,
  candidatesGetOne
} from "../../../services/candidatesService";
import {
  fcandidatesSave,
  fcandidatesGetOne
} from "../../../services/fakeCandidatesService";
import LoaderButton from "../../../components/common/loaderButton";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Container
} from "reactstrap";
import { Button } from "../../../components/Ncomponents";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class CandidateForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        lastName: "",
        firstName: "",
        email: "",
        telephone: "",
        notes: "",
        job: "",
        updatedOn: moment().unix()
      },
      title: "",
      errors: {},
      isLoading: null
    };
  }

  schema = {
    id: Joi.string(),
    lastName: Joi.string()
      .required()
      .label("Επιθετο"),
    firstName: Joi.string()
      .required()
      .label("Ονομα"),
    job: Joi.string()
      .label("Επάγγελμα")
      .allow(""),
    email: Joi.string()
      .label("email")
      .allow(""),
    telephone: Joi.number()
      .label("Τηλεφωνο")
      .allow(""),
    notes: Joi.string()
      .label("Σημειώσεις")
      .allow("")
  };

  populateCandidate = async () => {
    try {
      const candidateId = this.props.match.params.id;
      if (candidateId === "new") {
        this.setState({ title: "Δημιουργία καινούριου Υποψηφίου" });
        return;
      }
      var candidate;
      if (this.props.production === true)
        candidate = await candidatesGetOne(candidateId);
      if (this.props.production === false)
        candidate = fcandidatesGetOne(candidateId);

      console.log(candidate);
      const title =
        "Ενημέρωση Υποψηφίου " + candidate.lastName + " " + candidate.firstName;
      //const candidate = await candidatesGetOne(candidateId);

      this.setState({ data: this.mapToViewModel(candidate), title: title });
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  };

  //Last Name and First Name is mandatory on the API
  validateForm() {
    const { lastName, firstName } = this.state.data;
    // const { name } = this.state.data;

    var disabled = true;

    if (
      lastName &&
      firstName &&
      Object.entries(this.state.errors).length === 0
    ) {
      disabled = false;
    }

    return disabled;
  }

  componentDidMount() {
    console.log("on can props", this.props);
    this.populateCandidate();
  }

  //i addedd the "" cause the Dynamo DB saves nulls to the attributes that dint have a value on create and
  // then when i am trying to update that i got the warnings !! here so to make sure that it will not be the case
  //if null then "" to my non mandatory fields
  mapToViewModel(candidate) {
    return {
      id: candidate.id,
      lastName: candidate.lastName,
      firstName: candidate.firstName,
      email: candidate.email || "",
      telephone: candidate.telephone || "",
      notes: candidate.notes || "",
      job: candidate.job || "",
      updatedOn: candidate.updatedOn
    };
  }

  handleSubmit = async () => {
    this.setState({ isLoading: true });

    //console.log(this.state.data);

    try {
      if (this.props.production === true) await candidatesSave(this.state.data);
      if (this.props.production === false) fcandidatesSave(this.state.data);

      // await candidatesSave(this.state.data);
      toast.success("Επιτυχία, Ενημέρωσα τον Υποψήφιο");
      this.props.history.push("/ekloges/candidates");
    } catch (ex) {
      toast.error("Αποτυχία, Δεν μπόρεσα να ενημερώσω τον Υποψήφιο");
      console.log(ex);
      this.setState({ isLoading: false });
    }
  };

  handleCancel = () => {
    this.props.history.push("/ekloges/candidates");
  };

  render() {
    const { title } = this.state;

    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col md="9" sm="9">
                  <h2 className="mb-0">{title}</h2>
                </Col>
                <Col md="3" sm="3" className="align-items-right">
                  <h5 className="mb-0">
                    Ενημερώθηκε :
                    {" " +
                      moment
                        .unix(this.state.data.updatedOn)
                        .format("DD/MM/YY HH:mm:ss")}
                  </h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={this.doSubmit}>
                <Row>
                  <Col md={4} xs={12}>
                    {this.renderInput("lastName", "Επίθετο")}
                  </Col>
                  <Col md={4} xs={12}>
                    {this.renderInput("firstName", "Όνομα")}
                  </Col>
                  <Col md={4} xs={12}>
                    {this.renderInput("email", "email")}
                  </Col>
                </Row>
                <Row>
                  <Col md={4} xs={12}>
                    {this.renderInput("telephone", "Τηλέφωνο")}
                  </Col>
                  <Col md={8} xs={12}>
                    {this.renderInput("job", "Επάγγελμα")}
                  </Col>
                </Row>

                <Row>
                  <Col md={12} xs={12}>
                    {this.renderTextArea(
                      "notes",
                      "Σημειώσεις",
                      "Text",
                      false,
                      "4",
                      "40"
                    )}
                  </Col>
                  {/* <Col md={4} xs={6}>
                    {this.renderMoment(
                      "updatedOn",
                      "Ενημερώθηκε",
                      "Text",
                      true
                    )}
                  </Col> */}
                </Row>
                <Row>
                  <h4> </h4>
                </Row>
                <Row>
                  <Col md={12} xs={12}>
                    <Button
                      leftLabel="now-ui-icons arrows-1_minimal-left"
                      round
                      onClick={this.handleCancel}
                    >
                      Ακύρωση
                    </Button>
                    <span className="m-2"> </span>

                    <LoaderButton
                      color="success"
                      onClick={this.handleSubmit}
                      disabled={this.validateForm()}
                      isLoading={this.state.isLoading}
                      text="Αποθήκευση"
                      loadingText="Saving...."
                    />
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default CandidateForm;
