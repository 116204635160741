import React from "react";
import Datetime from "react-datetime";

const DateTimePicker = ({
  name,
  label,
  value,
  placeholder,
  error,
  ...rest
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Datetime
        {...rest}
        name={name}
        timeFormat={false}
        id={name}
        value={value}
        inputProps={{
          placeholder: { placeholder }
        }}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default DateTimePicker;
