import React, { Component } from "react";
import Table from "../../../components/common/table";

class ElectionsPartiesTable extends Component {
  columns = [
    // { path: "id", label: "ID" },
    { path: "name", label: "Ονομα" },
    { path: "order", label: "Σειρα" },
    {
      key: "edit",
      content: electionParty => (
        <button
          onClick={() => this.props.onUpdate(electionParty)}
          className="btn btn-info btn-sm"
        >
          Edit
        </button>
      )
    },
    {
      key: "delete",
      content: electionParty => (
        <button
          onClick={() => this.props.onDelete(electionParty)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      )
    }
  ];

  render() {
    const { electionParties, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={electionParties}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ElectionsPartiesTable;
