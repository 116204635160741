import React from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    initializing: true,
    color: {
      hex: "" // "#0013BF" //"#0013BF"
      // ,
      // r: "241",
      // g: "112",
      // b: "19",
      // a: "1"
    }
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };
  componentWillReceiveProps(props) {
    // if (this.state.initializing) {
    const color = { ...this.state.color };
    color.hex = props.colorHexValue;

    this.setState({ color, initializing: false });
    // console.log("props color:", props.colorHexValue, color);
    // console.log("The updated state from props", this.state);
    // }
  }

  handleChange = color => {
    this.setState({ color: color });
    this.props.onColorChange(color.hex);
    // console.log("changed color", this.state);
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          position: "relative",
          left: "5 em",
          top: "10px",
          width: "36px",
          height: "18px",
          borderRadius: "2px",
          background: `${this.state.color.hex}`

          // `rgba(${this.state.color.r}, ${this.state.color.g}, ${
          //   this.state.color.b
          // }, ${this.state.color.a})`
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer"
        },
        popover: {
          position: "absolute",
          zIndex: "2"
        },

        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px"
        }
      }
    });

    return (
      <div className="form-group">
        <label htmlFor="Akis" />
        <div onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;

// const CPicker = ({ name, label, onChangeComplete, error, ...rest }) => {
//   return (
//     <div className="form-group">
//       <label htmlFor={name}>{label}</label>
//       <MaterialPicker
//         // onChange={onChange}
//         onChangeComplete={onChangeComplete}
//         {...rest}
//         name={name}
//         id={name}
//         className="form-control"
//       />
//     </div>
//   );
// };
