import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
import Form from "../../../components/common/form";
import Joi from "joi-browser";
import NewSelect3 from "../../../components/common/newSelect3";
import {
  fresultsGetOne,
  fresultsSave
} from "../../../services/fakeResultsService";
import { resultsGetOne, resultsSave } from "../../../services/resultsService";

import { vStatusGetAll } from "../../../services/listOfValues";
import { Button } from "../../../components/Ncomponents";
import swal from "sweetalert2";
import moment from "moment";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class ResultsForm extends Form {
  state = {
    data: {
      idElection: "", // not changable
      idVstation: "", // not changable
      //date: "", //not changable
      nameElection: "",
      nameVstation: "",
      dateElection: "",
      number: "", //not changable
      electionLocationId: "",
      electionLocation: "", //not changable
      vstationLocation: [], //not changable

      ////USER INPUT
      registeredVoters: "", //USER "Eγγεγραμμένοι"
      blankVotes: "", //USER "Λευκά"
      invalidVotes: "", //"Άκυρα"
      voted: 0, //Ψήφισαν
      vStatus: {}, //"Status" Drop Down
      votesParties: [],
      votesCandidates: [],
      ///AUTOMATED
      completionRate: "", //AUTOMATED ,"Ενσωμάτωση", by the system after user saves data
      turnout: "", //AUTOMATED, "Προσέλευση" ,
      updatedOn: "" //Τελευταια Ενημερωση"
    },

    voteParties: 123,

    errors: [],
    vStatus: [], //The process of the votting
    hTabs: "ht1",
    vTabs: "vt1",
    vTabsIcons: "vti1",
    pageSubcategories: "ps1"
  };

  schema = {
    id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Όνομα"),
    registeredVoters: Joi.number()
      .min(0)
      .max(1000)
      .label("Εγγεγεραμμένοι"),
    blankVotes: Joi.number()
      .min(0)
      .max(1000)
      .label("Λευκά"),
    invalidVotes: Joi.number()
      .min(0)
      .max(1000)
      .label("Άκυρα"),
    voted: Joi.number()
      .min(0)
      .max(1000)
      .label("Ψήφισαν")
  };

  mapToViewModel(result) {
    return {
      idElection: result.idElection, // not changable
      idVstation: result.idVstation, // not changable
      //date: "", //not changable
      nameElection: result.nameElection,
      nameVstation: result.nameVstation,
      dateElection: result.dateElection,
      number: result.number || "", //not changable
      electionLocationId: result.electionLocationId,
      electionLocation: result.electionLocation, //not changable
      vstationLocation: result.vstationLocation, //not changable

      ////USER INPUT
      registeredVoters: result.registeredVoters || "", //USER "Eγγεγραμμένοι"
      blankVotes: result.blankVotes || "", //USER "Λευκά"
      invalidVotes: result.invalidVotes || "", //"Άκυρα"
      voted: result.voted || "", //Ψήφισαν
      vStatus: result.vStatus, //"Status" Drop Down
      votesParties: result.votesParties || [],
      votesCandidates: result.votesCandidates || [],
      ///AUTOMATED
      completionRate: result.completionRate || "", //AUTOMATED ,"Ενσωμάτωση", by the system after user saves data
      turnout: result.turnout || "", //AUTOMATED, "Προσέλευση" ,
      updatedOn: result.updatedOn
    };
  }

  handleChangeNewSelect3 = e => input => {
    const data = { ...this.state.data };
    data.vStatus.id = input.value;
    data.vStatus.name = input.label;

    this.setState({ data: data });
  };

  async componentDidMount() {
    const vStatus = vStatusGetAll();

    const idElection = this.props.match.params.idElection;
    const idVstation = this.props.match.params.idVstation;
    //console.log("params", idElection, idVstation);

    var result;

    if (this.props.production === true)
      result = await resultsGetOne(idElection, idVstation);
    if (this.props.production === false)
      result = fresultsGetOne(idElection, idVstation);

    // const result = await resultsGetOne(idElection, idVstation);

    // const resultsId = this.props.match.params.id;

    // console.log(resultsId);
    // const result = resultsGetOne(resultsId);
    // console.log(result);

    // result.updatedOn = moment
    //   .unix(result.updatedOn)
    //   .format("DD/MM/YY HH:mm:ss");

    this.setState({ data: this.mapToViewModel(result), vStatus: vStatus }, () =>
      console.log("Call back Results cmd, state is ", this.state)
    );

    //Test of lodash
    // console.log("Lodash", _.get(this.state.data.votesParties, I  )); // false
  }

  handleSave = async () => {
    //we fix the numbers
    const data = { ...this.state.data };

    data.registeredVoters = parseInt(data.registeredVoters, 10) || 0;
    data.blankVotes = parseInt(data.blankVotes, 10) || 0;
    data.invalidVotes = parseInt(data.invalidVotes, 10) || 0;
    data.voted = parseInt(data.voted, 10) || 0;

    console.log("data voted", data.voted);
    if (data.voted > 0) {
      //console.log("dividiing", data.voted, data.registeredVoters);
      data.turnout = Number(data.voted / data.registeredVoters).toFixed(2);
      // console.log("result", data.turnout);
    }

    //ensomatosi
    var totalVotesCaptured; // votes that i have counted
    for (var i = 0; i < data.votesParties.length; i++) {
      totalVotesCaptured =
        parseInt(totalVotesCaptured || 0, 10) +
        parseInt(data.votesParties[i].votes, 10);
    }

    if (data.voted > 0) {
      data.completionRate = Number(totalVotesCaptured / data.voted).toFixed(2);
    }
    console.log("totalVotesRegistered", totalVotesCaptured);

    try {
      if (this.props.production === true) await resultsSave(data);
      if (this.props.production === false) fresultsSave(data);

      // await resultsSave(data);

      swal.fire({
        title: "Επιτυχία",
        type: "success",
        text: "Το αποτέλεσμα καταχωρηθηκε για το εκλογικό τμήμα",
        showConfirmButton: false,
        timer: 1000
      });
      this.props.history.push("/ekloges/results");
    } catch (ex) {
      console.log(ex);
    }

    console.log("On save", this.state);
  };

  handleCancel = () => {
    this.props.history.push("/ekloges/results");
  };

  handleChangeDynamicInput = input => {
    //console.log("Mpika", input.currentTarget.id, input.currentTarget.value);

    // const data = { ...this.state };
    // data.voteParties = input.value;

    // // // data.votesParties[
    // // //   this.state.votesParties.findIndex(p => p["id"] === e)
    // // // ].votes = input;

    const data = { ...this.state.data };

    data.votesParties[
      this.state.data.votesParties.findIndex(
        p => p.id === input.currentTarget.id
      )
    ].votes = input.currentTarget.value;

    this.setState({ data: data });
  };

  handleChangeDynamicInputCandidates = input => {
    console.log("Mpika", input.currentTarget.id, input.currentTarget.value);

    const data = { ...this.state.data };

    data.votesCandidates[
      this.state.data.votesCandidates.findIndex(
        p => p.id === input.currentTarget.id
      )
    ].votes = input.currentTarget.value;

    this.setState({ data: data });
  };

  render() {
    const {
      nameElection,
      electionLocation,
      nameVstation,
      votesParties
    } = this.state.data;

    // console.log(
    //   "attempt 1",
    //   votesParties,
    //   votesParties.findIndex(f => f["id"] === "P2")
    // );

    // console.log(this.state.data);

    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col md="9" sm="9">
                  <h2 className="mb-0">
                    {" "}
                    {nameElection} - {electionLocation} <br />
                    <small className="description">
                      <b>{nameVstation}</b>
                    </small>
                  </h2>
                </Col>
                <Col md="3" sm="3" className="align-items-right">
                  <h4 className="mb-0">
                    Ενημερώθηκε
                    {" " +
                      moment
                        .unix(this.state.data.updatedOn)
                        .format("DD/MM/YY HH:mm:ss")}
                  </h4>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <Nav pills className="nav-pills-primary">
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht1" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht1" })}
                  >
                    Εκλογικό Τμήμα
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht2" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht2" })}
                  >
                    Ψήφοι Κομμάτων
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht3" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht3" })}
                  >
                    Ψήφοι Υποψηφίων
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.hTabs} className="tab-space">
                <TabPane tabId="ht1">
                  <Row>
                    {" "}
                    <p> </p>{" "}
                  </Row>
                  <h3>Πληροφορίες Εκλογικού τμηματος</h3>
                  <Row>
                    {" "}
                    <p> </p>{" "}
                  </Row>
                  <Row>
                    <Col md={3} xs={4}>
                      {this.renderInput(
                        "registeredVoters",
                        "Eγγεγραμμένοι",
                        "Number"
                      )}
                    </Col>
                    <Col md={3} xs={4}>
                      {this.renderPercentage(
                        "completionRate",
                        "Ενσωμάτωση",
                        "Number",
                        true
                      )}
                    </Col>
                    <Col md={3} xs={4}>
                      {this.renderPercentage(
                        "turnout",
                        "Προσέλευση",
                        "Number",
                        true
                      )}
                    </Col>
                    {/* <Col md={3} xs={4}>
                      {this.renderMoment(
                        "updatedOn",
                        "Ενημερώθηκε",
                        "Text",
                        true
                      )}
                    </Col> */}
                  </Row>
                  <br />
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <h3>Παρακαλώ συμπληρώστε τα παρακατω στοιχεια </h3>
                    </Col>
                    <br />
                    <br />
                    <Col md={3} xs={6}>
                      {this.renderInput("voted", "Ψήφισαν", "Number", false)}
                    </Col>

                    <Col md={3} xs={6}>
                      {this.renderInput("blankVotes", "Λευκά", "Number", false)}
                    </Col>
                    <Col md={3} xs={6}>
                      {this.renderInput(
                        "invalidVotes",
                        "Ακυρα",
                        "Number",
                        false
                      )}
                    </Col>

                    <Col md={4} xs={6}>
                      <NewSelect3
                        name="vStatus"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        isSearchable
                        placeholder="Επιλογή"
                        value={this.state.data.vStatus.id} // it wants only the ID wich will automatically convert that to value
                        label="Status Ημέρας"
                        options={this.state.vStatus}
                        onChange={this.handleChangeNewSelect3("vStatus")}
                        isDisabled={this.state.hideDropDownvStatus}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}>
                      <div className="btns-mr-5">
                        <Button
                          // leftLabel="now-ui-icons arrows-1_minimal-left"
                          // round
                          onClick={this.handleCancel}
                        >
                          Ακύρωση
                        </Button>

                        <span className="m-2"> </span>
                        <Button
                          color="success"
                          // round
                          // leftLabel="now-ui-icons ui-1_check"
                          onClick={this.handleSave}
                          // disabled={this.validateForm()}
                        >
                          Αποθήκευση
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="ht2">
                  <br />

                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <h3>
                        {" "}
                        Παρακαλώ συμπληρώστε τα παρακατω στοιχεια για τα κομματα
                      </h3>
                    </Col>
                    <br />

                    {this.state.data.votesParties.map(f => (
                      <React.Fragment key={f.id}>
                        <Col lg={3} md={4} xs={12}>
                          <div className="form-group">
                            <label htmlFor={f.id}>{f.name}</label>
                            <input
                              id={f.id}
                              key={f.id}
                              value={
                                this.state.data.votesParties[
                                  this.state.data.votesParties.findIndex(
                                    p => p.id === f.id
                                  )
                                ].votes
                              }
                              type="number"
                              name={f.id}
                              onChange={this.handleChangeDynamicInput}
                              className="form-control"
                            />
                          </div>
                        </Col>
                        {/* <Row>
                          <h5> </h5>{" "}
                        </Row> */}
                      </React.Fragment>
                    ))}
                  </Row>
                </TabPane>
                <TabPane tabId="ht3">
                  <br />
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <h3>
                        {" "}
                        Παρακαλώ συμπληρώστε τους ψήφους για τους παρακατω
                        Υποψηφίους{" "}
                      </h3>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    {this.state.data.votesCandidates.map(f => (
                      <React.Fragment key={f.id}>
                        {/* <Row>
                          {" "}
                          <h5> </h5>{" "}
                        </Row> */}
                        <Col lg={3} md={4} xs={12}>
                          <div key={f.id} className="form-group">
                            <label htmlFor={f.id}>{f.name}</label>
                            <input
                              id={f.id}
                              key={f.id}
                              value={
                                this.state.data.votesCandidates[
                                  this.state.data.votesCandidates.findIndex(
                                    p => p.id === f.id
                                  )
                                ].votes
                              }
                              type="number"
                              name={f.id}
                              onChange={this.handleChangeDynamicInputCandidates}
                              className="form-control"
                            />
                          </div>
                        </Col>
                        {/* <Row>
                          <h5> </h5>{" "}
                        </Row> */}
                      </React.Fragment>
                    ))}
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default ResultsForm;
