import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Select from "react-select";

import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import Form from "../../../components/common/form";
import LoaderButton from "../../../components/common/loaderButton";
import {
  fvstationsSave,
  fvstationsGetOne
} from "../../../services/fakeVstationsService";
import {
  vstationsSave,
  vstationsGetOne
} from "../../../services/vstationsService";

import { Button } from "../../../components/Ncomponents";
import { electionsAreasGetAll } from "../../../services/listOfValues";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class VstationsForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        number: "",
        active: true,
        address: "",
        registeredVoters: "", // The number of registered voters
        startName: "", //The start name of the elections deprtment
        lastName: "", //The Last Name
        location: [],
        area: "", //eklogiko diamerisma
        notes: "",
        updatedOn: moment().unix()
      },

      //for the Location Dropdowns
      periferies: [], //for Dropdown 1
      periferiakesEnotites: [], //for Dropdown 2
      dimoi: [], //for Dropdown 3
      periferia: { value: "", label: "", layerId: "" },
      periferiakiEnotita: { value: "", label: "", layerId: "" },
      dimos: { value: "", label: "", layerId: "" },
      hideDropdownPeriferia: false,
      hideDropdownPeriferiakiEnotita: false,
      hideDropDownDimos: false,
      electionLocations: [],

      //dropdwons
      // electionLocations: [], //has all elections areas
      // periferiaId: "",
      // periferies: [],
      // periferiakiEnotitaId: "",
      // periferiakiEnotita: "",
      // periferiakesEnotites: [],
      // dimosId: "",
      // dimoi: [],
      // hideDropdownPeriferia: false,
      // hideDropdownPeriferiakiEnotita: true,
      // hideDropdownDimos: true,

      ///
      title: "",
      errors: {},
      isLoading: false
    };
  }

  schema = {
    id: Joi.string(),
    name: Joi.string()
      .required()
      .min(5)
      .label("Όνομα"),
    active: Joi.boolean(),
    number: Joi.string()
      .required()
      .label("Νούμερο Τμήματος"),
    address: Joi.string()
      .label("Διεύθυνση")
      .allow(""),
    registeredVoters: Joi.number()
      .required()
      .label("Εγγεγραμμένοι"),
    startName: Joi.string()
      .label("Αρχικο Όνομα")
      .allow(""),
    lastName: Joi.string()
      .label("Τελικό Όνομα")
      .allow(""),
    notes: Joi.string()
      .label("Σημειώσεις")
      .allow(""),
    area: Joi.string()
      .label("Εκλογικό Διαμέρισμα")
      .allow(""),
    updatedOn: Joi.string().label("Τελευταία Ενημέρωση")
  };

  populateVstation = async () => {
    try {
      var electionLocations = electionsAreasGetAll();

      var periferies = electionLocations.filter(p => p.layerId === "LOLA01");
      var periferiakesEnotites = electionLocations.filter(
        p => p.layerId === "LOLA02"
      );
      var dimoi = electionLocations.filter(p => p.layerId === "LOLA03");

      //map them to format that dropdown wants them with value and label
      periferies = this.mapDropdownIdToValue(periferies);
      periferiakesEnotites = this.mapDropdownIdToValue(periferiakesEnotites);
      dimoi = this.mapDropdownIdToValue(dimoi);

      const vstationId = this.props.match.params.id;

      if (vstationId === "new") {
        const title = "Δημιουργία νέου εκλογικού τμήματος";

        this.setState(
          {
            electionLocations,
            periferies,
            periferiakesEnotites,
            dimoi,
            title
          },
          () => console.log("Update the Drop", this.state)
        );
        return;
      }

      var vstation;
      if (this.props.production === true)
        vstation = await vstationsGetOne(vstationId);
      if (this.props.production === false)
        vstation = fvstationsGetOne(vstationId);

      // const vstation = await vstationsGetOne(vstationId);

      console.log(vstation.location.length);

      var periferia = "";
      var periferiakiEnotita = "";
      var dimos = "";

      for (var i = 0; i < vstation.location.length; i++) {
        switch (i) {
          case 0:
            periferia = {};
            periferia.value = vstation.location[i].id;
            periferia.label = vstation.location[i].name;
            periferia.layerId = vstation.location[i].layerId;

            //periferia = this.mapDropdownIdToValue(party.location[i]);
            break;

          case 1:
            periferiakiEnotita = {};
            // periferiakiEnotita = party.location[i];
            periferiakiEnotita.value = vstation.location[i].id;
            periferiakiEnotita.label = vstation.location[i].name;
            periferiakiEnotita.layerId = vstation.location[i].layerId;

            console.log("periferiakesEnotites", periferiakesEnotites);

            //i will filetr also out the pefiriakes enotites that are not on the perifeira
            periferiakesEnotites = periferiakesEnotites.filter(
              f => f.pId === periferia.value
            );

            console.log("periferiakesEnotites", periferiakesEnotites);

            break;

          case 2:
            // dimos = this.party.location[i];
            dimos = {};
            dimos.value = vstation.location[i].id;
            dimos.label = vstation.location[i].name;
            dimos.layerId = vstation.location[i].layerId;

            console.log("check,", dimos, vstation.location);
            //i will filetr also out the pefiriakes enotites that are not on the perifeira
            dimoi = dimoi.filter(f => f.pId === periferiakiEnotita.value);
            break;
          default:
        }
      }

      const title = "Ενημέρωση εκλογικού τμήματος, " + vstation.name;

      this.setState({
        data: this.mapToViewModel(vstation),
        periferia,
        periferiakiEnotita,
        periferiakesEnotites,
        dimos,
        dimoi,
        title
      });
    } catch (ex) {
      toast.warning("Δυστυχώς, Δεν μπόρεσα να βρω το Εκλογικό Τμήμα");
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  };

  validateForm() {
    var disabled = true;

    const { periferia, periferiakiEnotita, dimos } = this.state;
    const { name, registeredVoters } = this.state.data;

    //console.log(" validation", periferia, periferiakiEnotita, dimos);
    if (
      periferia &&
      periferiakiEnotita &&
      dimos &&
      name.length > 4 &&
      registeredVoters
    ) {
      if (
        periferia.value !== "" &&
        periferiakiEnotita !== "" &&
        dimos.value !== ""
      ) {
        disabled = false;
      }
      // periferiakiEnotita.value === "" &&
      // dimos.value === ""
      //console.log("Mpika sto validation");
    }

    // console.log("registeredVoters", registeredVoters);
    // if (registeredVoters === undefined) {
    //   disabled = true;
    // }

    return disabled;
  }

  populateDropDowns() {
    var electionLocations = electionsAreasGetAll();

    var periferies = electionLocations.filter(p => p.layerId === "LOLA01");
    var periferiakesEnotites = electionLocations.filter(
      p => p.layerId === "LOLA02"
    );
    var dimoi = electionLocations.filter(p => p.layerId === "LOLA03");

    //map them to format that dropdown wants them with value and label
    periferies = this.mapDropdownIdToValue(periferies);
    periferiakesEnotites = this.mapDropdownIdToValue(periferiakesEnotites);
    dimoi = this.mapDropdownIdToValue(dimoi);

    this.setState(
      {
        electionLocations,
        periferies,
        periferiakesEnotites,
        dimoi
      },
      () => console.log("Update the Drop", this.state)
    );
  }

  componentDidMount() {
    this.populateDropDowns();
    this.populateVstation();
  }

  //this is happenning if in the future will add more thing to the API to have a view mdoel
  mapToViewModel(vstation) {
    return {
      id: vstation.id,
      name: vstation.name,
      number: vstation.number || "",
      active: vstation.active,
      address: vstation.address || "",
      registeredVoters: vstation.registeredVoters || "",
      startName: vstation.startName || "",
      lastName: vstation.lastName || "",
      area: vstation.area || "", //eklogiko diamerisma
      location: vstation.location,
      notes: vstation.notes || "",
      updatedOn: vstation.updatedOn
    };
  }

  handleMChange = name => event => {
    const { data } = { ...this.state };

    data[name] = event.target.checked;
    this.setState({ data });
  };

  mapDropdownIdToValue(options) {
    options = options.map(p => ({
      value: p.id,
      label: p.name,
      layerId: p.layerId,
      pId: p.pId
    }));

    return options;
  }

  handleChangeNewSelect3 = e => input => {
    if (e === "periferia") {
      //update the input tables of the other two drop downs
      var periferiakesEnotites = this.state.electionLocations.filter(
        pe => pe.layerId === "LOLA02" && pe.pId === input.value
      );
      var dimoi = this.state.electionLocations.filter(
        d => d.layerId === "LOLA03"
      );

      //create the format that dropwon wants with values and labels
      periferiakesEnotites = this.mapDropdownIdToValue(periferiakesEnotites);
      dimoi = this.mapDropdownIdToValue(dimoi);

      var periferia = {};
      periferia.value = input.value;
      periferia.label = input.label;
      periferia.layerId = "LOLA01";

      this.setState(
        {
          periferia: periferia,
          periferiakiEnotita: null,
          dimos: null,
          periferiakesEnotites,
          dimoi: dimoi,
          hideDropdownPeriferia: false
        }
        // ,
        // () => console.log(" Prwto select Periferia", this.state)
      );
    }

    if (e === "periferiakiEnotita") {
      //update the input tables of the other one  drop downs
      dimoi = this.state.electionLocations.filter(
        d => d.layerId === "LOLA03" && d.pId === input.value
      );
      dimoi = this.mapDropdownIdToValue(dimoi);

      var periferiakiEnotita = {};
      periferiakiEnotita.value = input.value;
      periferiakiEnotita.label = input.label;
      periferiakiEnotita.layerId = "LOLA02";

      //update the state, the value of the dropdown, make null the third dropdown and refresh the options of the 3 drop down
      this.setState({
        periferiakiEnotita: input,
        dimos: null,
        dimoi,
        hideDropdownDimos: false
      });
    }

    if (e === "dimos") {
      var dimos = {};
      dimos.value = input.value;
      dimos.label = input.label;
      dimos.layerId = "LOLA03";

      this.setState({ dimos: dimos });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();

    //to lock the button
    this.setState({ isLoading: true });

    ///Location
    const { periferia, periferiakiEnotita, dimos } = this.state;
    var data = { ...this.state.data };
    data.registeredVoters = parseInt(data.registeredVoters, 10);

    //console.log(data);
    data.location = [];

    if (periferia) {
      //change the value/lable  to Id and name
      var perNew = {};
      perNew.id = periferia.value;
      perNew.name = periferia.label;
      perNew.layerId = periferia.layerId;
      //is not null
      //data.location.push(this.mapDropdownValueToId(periferia));

      data.location.push(perNew);
    }

    if (periferiakiEnotita) {
      // if (periferiakiEnotita != null) {
      //change the value/lable  to Id and name
      var penNew = {};
      penNew.id = periferiakiEnotita.value;
      penNew.name = periferiakiEnotita.label;
      penNew.layerId = periferiakiEnotita.layerId;

      data.location.push(penNew);
    }

    if (dimos) {
      // if (dimos != null) {
      //change the value/lable  to Id and name
      var dimNew = {};
      dimNew.id = dimos.value;
      dimNew.name = dimos.label;
      dimNew.layerId = dimos.layerId;
      //check
      data.location.push(dimNew);
    }

    console.log("data before submit", data);
    try {
      if (this.props.production === true) await vstationsSave(data);
      if (this.props.production === false) fvstationsSave(data);

      // await vstationsSave(data);
      toast.success("Επιτυχία, Ενημέρωσα το Εκλογικό Τμήμα");

      this.props.history.push("/ekloges/vstations");
    } catch (ex) {
      toast.error("Αποτυχία, Δεν μπόρεσα να ενημερώσω το Εκλογικό Τμήμα");

      this.setState({ isLoading: false });
    }
  };

  handleCancel = () => {
    this.props.history.push("/ekloges/vstations");
  };

  render() {
    const { title } = this.state;
    // console.log("render", this.state.data);
    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col md="9" sm="9">
                  <h2 className="mb-0">{title}</h2>
                </Col>
                <Col md="3" sm="3" className="align-items-right">
                  <h5 className="mb-0">
                    Ενημερώθηκε
                    {" " +
                      moment
                        .unix(this.state.data.updatedOn)
                        .format("DD/MM/YY HH:mm:ss")}
                  </h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col md={1} xs={12}>
                    {this.renderInput("number", "Νούμερο")}
                  </Col>
                  <Col md={5} xs={12}>
                    {this.renderInput("name", "Όνομα")}
                  </Col>
                  <Col md={3} xs={12}>
                    {this.renderInput("area", "Εκλογικό Διαμέρισμα")}
                  </Col>
                  <Col md={2} xs={9}>
                    {this.renderInput(
                      "registeredVoters",
                      "Εγγεγραμμενοι",
                      "number"
                    )}
                  </Col>

                  {/* <Col md={3} xs={2}>
                      <p className="category">Default</p>
                      <Switch
                        checked={this.state.data.active}
                        //defaultValue={this.state.data.active}
                        onChange={this.handleSwitchChange("active")}
                        label="Active"
                      />
                      <Switch />
                    </Col> */}

                  <Col md={1} xs={3}>
                    {this.renderCheck(
                      "active",
                      "Ενεργό",
                      this.state.data.active,
                      "TypeActive"
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4} xs={12}>
                    {this.renderInput("startName", "Αρχικό Όνομα")}
                  </Col>
                  <Col md={4} xs={12}>
                    {this.renderInput("lastName", "Τελικό Όνομα")}
                  </Col>
                  <Col md={4} xs={12}>
                    {this.renderInput("address", "Διεύθυνση")}
                  </Col>
                  {/* <Col md={4} xs={12}>
                    {this.renderMoment(
                      "updatedOn",
                      "Ενημερώθηκε",
                      "Text",
                      true
                    )}
                  </Col> */}
                </Row>
                <Row>
                  <Col md={4} xs={12}>
                    <label htmlFor="periferia">Περιφέρεια</label>
                    <Select
                      name="periferia"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      isSearchable
                      placeholder="Επιλογή"
                      value={this.state.periferia}
                      label="Περιφερεια"
                      options={this.state.periferies}
                      onChange={this.handleChangeNewSelect3("periferia")}
                      isDisabled={this.state.hideDropdownPeriferia}
                    />
                  </Col>

                  <Col md={4} xs={12}>
                    <label htmlFor="periferiakiEnotita">
                      Περιφερειακή Ενότητα
                    </label>
                    <Select
                      name="periferiakiEnotita"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      isSearchable
                      placeholder="Επιλογή"
                      value={this.state.periferiakiEnotita}
                      label="Περιφερειακη Ενοτητα"
                      options={this.state.periferiakesEnotites}
                      onChange={this.handleChangeNewSelect3(
                        "periferiakiEnotita"
                      )}
                      isDisabled={this.state.hideDropdownPeriferiakiEnotita}
                    />
                  </Col>

                  <Col md={4} xs={12}>
                    <label htmlFor="Δήμος">Δήμος</label>
                    <Select
                      name="dimos"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      isSearchable
                      placeholder="Επιλογή"
                      value={this.state.dimos}
                      label="Δημος"
                      options={this.state.dimoi}
                      onChange={this.handleChangeNewSelect3("dimos")}
                      isDisabled={this.state.hideDropdownDimos}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12} xs={12}>
                    {this.renderTextArea(
                      "notes",
                      "Σημειώσεις",
                      "Text",
                      false,
                      "4",
                      "40"
                    )}
                  </Col>
                </Row>
                {/* {this.renderButton("Save")} {this.renderButton("Test")} */}
                <Row>
                  <Col md={6} xs={12}>
                    <Button
                      // leftLabel="now-ui-icons arrows-1_minimal-left"
                      // round
                      onClick={this.handleCancel}
                    >
                      Ακύρωση
                    </Button>
                    <span className="m-2"> </span>

                    {/* <Button
                        color="success"
                        round
                        leftLabel="now-ui-icons ui-1_check"
                        type="submit"
                        disabled={this.validateForm()}
                      >
                        Αποθήκευση
                      </Button> */}

                    <LoaderButton
                      color="success"
                      // round
                      // leftLabel="now-ui-icons ui-1_check"
                      onClick={this.handleSubmit}
                      disabled={this.validateForm()}
                      // type="submit"
                      isLoading={this.state.isLoading}
                      text="Αποθηκευση"
                      loadingText="Saving...."
                    />
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default VstationsForm;
