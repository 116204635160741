import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardImg,
  CardTitle,
  CardText,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class Main extends React.Component {
  render() {
    return (
      <>
        <SimpleHeader />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardImg
              alt="..."
              src={require("assets/img/WebsiteMainv2019_04_22v2.jpg")}
              top
            />
            <CardBody>
              <CardTitle className="h2 mb-0">Ιωάννης Μαλακάσης</CardTitle>
              <small className="text-muted">Με ήθος και αξιοπρέπεια</small>
              <CardText
                className="mt-4"
                style={{ textAlign: "justify", fontStyle: "italic" }}
              >
                {/* <h4
                  className={classes.description}
                  style={{ textAlign: "justify", fontStyle: "italic" }}
                > */}
                Θεωρώ ότι είναι χρέος όλων μας να προσφέρουμε, ότι μπορούμε
                ανιδιοτελώς, ιδιαίτερα σε αυτές τις δύσκολες στιγμές που
                βιώνουμε. Έτσι για άλλη μια φορά θέτω τις γνώσεις, το ήθος και
                την εμπειρία μου στην υπηρεσία του τόπου και των συμπολιτών μου
                ενώνοντας τις δυνάμεις μου με την υποψήφια περιφερειάρχη{" "}
                <a href="http://www.rodikratsa.gr" style={{ color: "#113a5f" }}>
                  <b>Ρόδη Κράτσα</b>
                </a>{" "}
                που έχει αξιόλογη επαγγελματική και πολιτική σταδιοδρομία.
                Πιστεύω ακράδαντα ότι με την πολυετή εμπειρία της ως
                ευρωβουλευτής θα μπορέσει να συνεισφέρει στον εκσυγχρονισμό και
                στην ανάπτυξη της περιφέρειας μας.
                <br />
                {/* </h4> */}
              </CardText>
            </CardBody>
          </Card>
          {/* <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">Εκλογες 2019</h3>
            </CardHeader>
            <CardBody>
              <h3> Καλως Ηλθες στην Πλατφορμα Εκλογων 2019 </h3>

              
            </CardBody>
          </Card> */}
        </Container>
      </>
    );
  }
}
export default Main;
