import React, { Component } from "react";
import Table from "../../../components/common/table";
import { Link } from "react-router-dom";

class ResultsTable extends Component {
  state = {
    data: {
      columns: [
        // {
        //   path: "idElection",
        //   label: "ID ΕΚ"
        // },
        // {
        //   path: "idVstation",
        //   label: "ID ΤΜ"
        // },

        {
          path: "nameVstation",
          label: "Τμήμα",
          content: result => (
            <Link
              // to="#"
              // onClick={() => this.props.onUpdate(result)}

              //to={this.props.onUpdate(result)}
              // onClick={() => this.props.onUpdate(result)}

              to={`/ekloges/results/${result.idElection}/${result.idVstation}`}
            >
              {result.nameVstation}
            </Link>
          )
        },
        // {
        //   path: "nameVstation",
        //   label: "Εκλογικό τμήμα",
        //   content: result => (
        //     <Link
        //       to="#"
        //       onClick={() => this.props.onUpdate(result)} // for setting the local storage variables !!!
        //       // to={`/ekloges/results/${result.idElection}/${result.idVstation}`}
        //     >
        //       {result.nameVstation}
        //     </Link>
        //   )
        // },
        // { path: "nameElection", label: "Εκλογική Διαδικασία" },
        // { path: "nameVstation", label: "Εκλογικό τμήμα" },
        // { path: "dateElection", label: "Ημερομηνία" },
        { path: "number", label: "Αριθμός" },

        { path: "electionLocation", label: "Περιοχή Εκλογών" },
        { path: "registeredVoters", label: "Eγγεγραμμένοι" },
        { path: "blankVotes", label: "Λευκά" },
        { path: "completionRate", label: "Ενσωμάτωση", type: "Percentage" },
        { path: "invalidVotes", label: "Άκυρα" },
        { path: "turnout", label: "Προσέλευση" },
        { path: "voted", label: "Ψήφισαν" },
        { path: "vStatus.name", label: "Status" },
        { path: "updatedOn", label: "Τελευταια Ενημερωση", type: "Date" },
        {
          key: "edit",
          content: result => (
            <button
              onClick={() => this.props.onUpdate(result)}
              className="btn btn-info btn-sm"
              disabled={this.props.authLevel >= 2 ? false : true}
            >
              Edit
            </button>
          )
        },
        {
          key: "delete",
          content: result => (
            <button
              onClick={() => this.props.onDelete(result)}
              className="btn btn-danger btn-sm"
              disabled={this.props.authLevel >= 4 ? false : true}
            >
              Delete
            </button>
          )
        }
      ]
    }
  };

  componentDidMount() {
    //console.log("props on results table", this.props.authLevel);
    const data = { ...this.state.data };

    var position = 3;

    ///i will check the first one for the parties ....
    // console.log("results", this.props.results[0].votesParties);
    var nc = {};
    for (var i = 0; i < this.props.results[0].votesParties.length; i++) {
      nc = {
        path: this.props.results[0].votesParties[i].name,
        label: this.props.results[0].votesParties[i].name
      };
      data.columns.splice(position, 0, nc);
      position++;
      // console.log("NC", nc);
    }

    var results = this.props.results;
    //bring the results one level up

    for (var r = 0; r < results.length; r++) {
      // console.log(results[r].votesParties);
      for (var vp = 0; vp < results[r].votesParties.length; vp++) {
        // console.log(results[r].votesParties[vp].name);

        results[r][results[r].votesParties[vp].name] =
          results[r].votesParties[vp].votes;
      }
      // console.log("!!!", this.props.results.length);
    }

    this.setState({ columns: data });
  }

  render() {
    const { results, onSort, sortColumn } = this.props;

    return (
      <Table
        uniqueKey1="idElection"
        uniqueKey2="idVstation"
        columns={this.state.data.columns}
        data={results}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ResultsTable;
