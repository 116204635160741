import _ from "lodash";

export function paginate(items, pageNumber, pageSize) {
  //To handle the case that you delete the last row in a page and then it will not go to the previous page
  if (pageNumber > 1 && items.length === pageSize) {
    pageNumber--;
  }

  const startIndex = (pageNumber - 1) * pageSize;
  const data = _(items)
    .slice(startIndex)
    .take(pageSize)
    .value();

  // console.log("start index", startIndex, "page Num", pageNumber);
  // console.log(
  //   "Paginate Tricks",
  //   _(items)
  //     .slice(startIndex)
  //     .take(pageSize)
  //     .value()
  // );

  return data;
}
