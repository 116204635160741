import React from "react";
import Joi from "joi-browser";
import Form from "../../../components/common/form";
import moment from "moment";
import { toast } from "react-toastify";
import LoaderButton from "../../../components/common/loaderButton";
import {
  felectionsSave,
  felectionsGetOne
  // electionsAreasDelete,
  // electionsAreasCreate,
  // electionsPartyCreate,
  // electionsPartyDelete,
  // electionsVstationCreate,
  // electionsVstationDelete
} from "../../../services/fakeElectionsService";

import {
  electionsSave,
  electionsGetOne
} from "../../../services/electionsService";
import { resultsSave, resultsDelete } from "../../../services/resultsService";

import {
  fresultsSave,
  fresultsDelete
} from "../../../services/fakeResultsService";

// import { vstationsGetAll } from "../services/fakeVstationsService";
// import { partiesGetAll } from "../services/fakePartiesService";

import { vstationsGetAll } from "../../../services/vstationsService";
import { partiesGetAll } from "../../../services/partiesService";
import { fvstationsGetAll } from "../../../services/fakeVstationsService";
import { fpartiesGetAll } from "../../../services/fakePartiesService";

import { electionTypesGetAll } from "../../../services/listOfValues";
import ElectionsAreasForm from "./electionsAreasForm";
import ElectionsPartiesForm from "./electionsPartiesForm";
import ElectionsVstationsForm from "./electionsVstationsForm";
import swal from "sweetalert";
//import LoaderButton from "./loaderButton";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

import { Button } from "../../../components/Ncomponents";
import Datetime from "react-datetime";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import NewSelect4 from "../../../components/common/newSelect4";

class ElectionsForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: "",
        name: "",
        date: "",
        active: true,
        // electionTypeId: "",
        electionType: { id: "", name: "" },
        electionLocations: [],
        electionVstations: [],
        electionParties: [],
        updatedOn: moment().unix()
      },
      testDate: "",

      hTabs: "ht1",
      hideDropDownElectionType: false, // this one should be deactivated as the user add information
      // newElectionArea: [],
      electionTypes: [],
      title: "",
      errors: {},
      isLoading: false,
      action: "Create"
    };
  }

  schema = {
    id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Όνομα"),
    date: Joi.string()
      .required()
      .label("Ημερομηνία"),
    active: Joi.boolean()
    // electionTypeId: Joi.string().required()
  };

  async componentDidMount() {
    var hideDropDownElectionType = false;

    const electionTypes = electionTypesGetAll();
    this.setState({ electionTypes });

    const electionId = this.props.match.params.id;
    if (electionId === "new") {
      const title = "Δημιουργία καινούριας Εκλογικής Αναμέτρησης";
      this.setState({ title: title });
      return;
    }

    console.log(electionId, this.props);
    try {
      //Load the election
      var election;
      if (this.props.production === true)
        election = await electionsGetOne(electionId);
      if (this.props.production === false)
        election = felectionsGetOne(electionId);

      if (election.electionLocations.length > 0) {
        hideDropDownElectionType = true;
      }

      const title = "Ενημέρωση, Εκλογικής Αναμέτρησηs : " + election.name;
      this.setState(
        {
          data: this.mapToViewModel(election),
          hideDropDownElectionType,
          title: title
        },
        () =>
          console.log(
            "callback cdm on election form, state is",
            this.state.data
          )
      );
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  //this is happenning if in the future will add more thing to the API to have a view mdoel
  mapToViewModel(election) {
    return {
      id: election.id,
      name: election.name,
      date: election.date,
      active: election.active,
      updatedOn: election.updatedOn,
      electionType: election.electionType,
      // electionTypeId: election.electionType.id,
      electionLocations: election.electionLocations || "",
      electionVstations: election.electionVstations || "",
      electionParties: election.electionParties || ""
    };
  }

  // mapToSaveModel(
  //   election // REFACTOR i need to change the electionTypeId to {Id,name} object
  // ) {
  //   const electionType = this.state.electionTypes.find(
  //     e => e.id === election.electionTypeId
  //   );

  //   delete election.electionTypeId;
  //   election["electionType"] = electionType;

  //   return election;
  // }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ isLoading: true });

    // const saveData =    this.mapToSaveModel(this.state.data);

    try {
      if (this.props.production === true) await electionsSave(this.state.data);
      if (this.props.production === false) felectionsSave(this.state.data);

      // await electionsSave(this.state.data);

      toast.success(
        "Επιτυχία, Ενημέρωσα την Εκλογική Αναμέτρηση" + this.state.data.name
      );
      //console.log(this.state.data);
      this.props.history.push("/ekloges/elections");
    } catch (ex) {
      toast.error(
        "Αποτυχία, Δεν μπόρεσα να ενημερώσω την Εκλογική Αναμέτρηση" +
          this.state.data.name
      );
      console.log(ex);
      this.setState({ isLoading: false });
    }
  };

  handleCancel = () => {
    this.props.history.push("/ekloges/elections");
  };

  validateForm = () => {
    if (
      typeof this.state.data.electionType.id === "undefined" ||
      this.state.data.electionType.id === "" ||
      Object.keys(this.state.errors).length !== 0 ||
      Object.keys(this.state.data.name).length === 0 ||
      Object.keys(this.state.data.date).length === 0
    ) {
      return true;
    } else return false;
  };

  handleelectionLocationsSave = async electionArea => {
    // var election = electionsAreasCreate(this.state.data.id, electionArea);

    const { data } = { ...this.state };
    const previousState = data;

    //find if the record exist
    const index = data.electionLocations.findIndex(
      e => e.id === electionArea.id
    );
    //New Entry
    if (index === -1) {
      // New record
      data.electionLocations.push(electionArea);
    } else {
      data.electionLocations[index] = electionArea;
    }
    ///////////////////BECAREFUL to call the map to view model !!!!! on the state
    this.setState(
      {
        showElectionsAreasCreateForm: false,
        data: data, //this.mapToViewModel(election),
        showElectionsAreaDataForm: true,
        hideDropDownElectionType: true
      },
      () => console.log("Refresh Data on Save", this.state.data)
    );

    try {
      if (this.props.production === true) await electionsSave(data);
      if (this.props.production === false) felectionsSave(this.state.data);
      // await electionsSave(data);

      toast.success(
        "Επιτυχία, Ενημέρωσα την εκλογική αναμέτρηση " + this.state.data.name
      );
    } catch (ex) {
      toast.error(
        "Αποτυχία, Δεν μπόρεσα να ενημερώσω τα στοιχεία της εκλογικής αναμέτρησης " +
          this.state.data.name
      );
      console.log(ex);
      this.setState({ isLoading: false, data: previousState });
    }
  };

  handleelectionLocationsDelete = async electionLocation => {
    // const data = electionsAreasDelete(this.state.data.id, electionLocations.id);

    const { data } = { ...this.state };
    const previousState = data;
    //find if the record exist
    const index = data.electionLocations.findIndex(
      e => e.id === electionLocation.id
    );

    data.electionLocations.splice(index, 1);
    this.setState({ data: data });

    try {
      if (this.props.production === true) await electionsSave(data);
      if (this.props.production === false) felectionsSave(this.state.data);
      // await electionsSave(data);
    } catch (ex) {
      console.log(ex);
      this.setState({ data: previousState });
    }
  };

  handleElectionPartySave = async electionParty => {
    //var election = electionsPartyCreate(this.state.data.id, electionParty);

    const { data } = { ...this.state };
    const previousState = data;

    //find if the record exist
    const index = data.electionParties.findIndex(
      e => e.id === electionParty.id
    );
    if (index === -1) {
      // New record
      data.electionParties.push(electionParty);
    } else {
      data.electionParties[index] = electionParty;
    }
    ///////////////////BECAREFUL to call the map to view model !!!!! on the state
    this.setState(
      {
        data: data //this.mapToViewModel(election),
      },
      () => console.log("Refresh Data on Save", this.state.data)
    );

    try {
      if (this.props.production === true) await electionsSave(data);
      if (this.props.production === false) felectionsSave(this.state.data);
      //await electionsSave(data);
      toast.success(
        "Επιτυχία, Ενημέρωσα την εκλογική αναμέτρηση " + this.state.data.name
      );
    } catch (ex) {
      toast.error(
        "Αποτυχία, Δεν μπόρεσα να ενημερώσω τα στοιχεία της εκλογικής αναμέτρησης " +
          this.state.data.name
      );
      console.log(ex);
      this.setState({ data: previousState });
    }
  };

  handleElectionPartyDelete = async electionParty => {
    //const data = electionsPartyDelete(this.state.data.id, electionParty.id);

    const { data } = { ...this.state };
    const previousState = data;
    //find if the record exist
    const index = data.electionParties.findIndex(
      e => e.id === electionParty.id
    );

    data.electionParties.splice(index, 1);
    this.setState({ data: data });

    try {
      if (this.props.production === true) await electionsSave(data);
      if (this.props.production === false) felectionsSave(this.state.data);
      //await electionsSave(data);
    } catch (ex) {
      console.log(ex);
      this.setState({ data: previousState });
    }
  };

  handleElectionVstationSave = async electionVstation => {
    // var election = electionsVstationCreate(
    //   this.state.data.id,
    //   electionVstation
    // );

    const { data } = { ...this.state };
    const previousState = data;

    //find if the record exist
    const index = data.electionVstations.findIndex(
      e => e.id === electionVstation.id
    );
    if (index === -1) {
      // New record
      data.electionVstations.push(electionVstation);
    } else {
      data.electionVstations[index] = electionVstation;
    }
    ///////////////////BECAREFUL to call the map to view model !!!!! on the state
    this.setState(
      {
        data: data //this.mapToViewModel(election),
      },
      () => console.log("Refresh Data on Save", this.state.data)
    );

    try {
      if (this.props.production === true) await electionsSave(data);
      if (this.props.production === false) felectionsSave(this.state.data);
      //await electionsSave(data);
      toast.success(
        "Επιτυχία, Ενημέρωσα την εκλογική αναμέτρηση " + this.state.data.name
      );
    } catch (ex) {
      toast.error(
        "Αποτυχία, Δεν μπόρεσα να ενημερώσω τα στοιχεία της εκλογικής αναμέτρησης " +
          this.state.data.name
      );
      console.log(ex);
      this.setState({ data: previousState });
    }
  };

  handleElectionVstationDelete = async electionVstation => {
    // const data = electionsVstationDelete(
    //   this.state.data.id,
    //   electionVstation.id
    // );
    const { data } = { ...this.state };
    const previousState = data;
    //find if the record exist
    const index = data.electionVstations.findIndex(
      e => e.id === electionVstation.id
    );

    data.electionVstations.splice(index, 1);
    this.setState({ data: data });

    try {
      if (this.props.production === true) await electionsSave(data);
      if (this.props.production === false) felectionsSave(this.state.data);
      //await electionsSave(data);
    } catch (ex) {
      console.log(ex);
      this.setState({ data: previousState });
    }

    try {
      //////////////////////////////////////
      //I will delete also now the result in my table Results if any
      await resultsDelete(this.state.data.id, electionVstation.id);
    } catch (ex) {
      console.log(ex);
      toast.error(
        "Πρόβλημα, με το να διαγράψω το εκλογικό τμήμα απο τα Αποτελέσματα"
      );
    }
  };

  handleDateChange = input => {
    const data = { ...this.state.data };
    data.date = moment(input._d).format("DD/MM/YYYY");

    // console.log(input._d);
    // console.log(moment(input._d).format("DD/MM/YYYY"));

    this.setState({ data: data });
  };

  handleChangeNewSelect3 = e => input => {
    if (e === "electionTypeId") {
      console.log(input.value, input.label);

      const { data } = { ...this.state };
      data.electionType.id = input.value;
      data.electionType.name = input.label;

      this.setState({ data: data });
    }
  };

  handleCreationResults = async () => {
    swal({
      title: "ΠΡΟΣΟΧΗ !!!!",
      text:
        "Εισαι σιγουρος  προκειται να ξανα γραψεις ολα τα αποτελέσματα για την εκλογικη" +
        this.state.data.name,
      icon: "warning",
      buttons: true
    }).then(async willCreate => {
      if (willCreate) {
        var vstationsAll;
        var partiesAll;

        if (this.props.production === true) {
          vstationsAll = await vstationsGetAll(); // FUTURE-REFACTOR bring only the vstations that we want
          partiesAll = await partiesGetAll(); // FUTURE-REFACTOR bring only the partys that we want
        }
        if (this.props.production === false) {
          vstationsAll = fvstationsGetAll(); // FUTURE-REFACTOR bring only the vstations that we want
          partiesAll = fpartiesGetAll(); // FUTURE-REFACTOR bring only the partys that we want
        }

        var results = []; // will hold all the data
        var all = []; // will hold all the reuslts
        const totalRecords = this.state.data.electionVstations.length;

        const totalParties = this.state.data.electionParties.length;
        const partiesSorted = this.state.data.electionParties.sort((a, b) =>
          a.order > b.order ? 1 : -1
        );

        var elpa = {}; // a party object from the election table
        var canList = [];
        var can = {}; // a candidate object from the election table that will create the record for the voteCandidates
        var vs = {}; // the votting station with all the details from the DB

        var locationArray = []; //only location Ids of vstation

        for (var i = 0; i < totalRecords; i++) {
          //this is the vstation full details
          vs = vstationsAll.find(
            f => f.id === this.state.data.electionVstations[i].id
          );

          results["idElection"] = this.state.data.id;
          results["idVstation"] = this.state.data.electionVstations[i].id;
          // results["id"] = Date.now().toString(); //just to have a unique id for the table rendering
          results["registeredVoters"] = vs.registeredVoters; //this.state.data.electionVstations[i].
          results["dateElection"] = this.state.data.date;
          results["nameElection"] = this.state.data.name;
          results["nameVstation"] = vs.name;
          results["number"] = vs.number;
          results["electionLocationId"] = this.state.data.electionVstations[
            i
          ].electionLocationId; /// the name of the location periferi
          results["electionLocation"] = this.state.data.electionVstations[
            i
          ].electionLocation; /// the name of the location periferi
          results["vstationLocation"] = vs.location;
          results["voted"] = 0; //number integer of ψήφισαν
          results["blankVotes"] = 0; //number integer of  Λευκα
          results["invalidVotes"] = 0; //number integer of άκυρα
          results["updatedOn"] = moment().unix(); //number
          results["vStatus"] = { id: "VOST01", name: "Δεν έχει ξεκινήσει" }; //εγγεγραμμένοι : {id: id from process status , name: the status of process info from an API }
          results["turnout"] = 0; //number % will be automatically calculated
          results["completionRate"] = 0; //number that shows  the percentage of votes counted for the parties out of the number of votes ones check also vs empty and invalid

          results["votesParties"] = [];
          /////should bring all the parties that are registered under the election parties

          // create a table with the locations onlye
          locationArray[0] = vs.location[0].id;
          locationArray[1] = vs.location[1].id;
          locationArray[2] = vs.location[2].id;
          results["votesCandidates"] = []; //[{ id: candidate iD, name : name of candidate , order : number from parties API , votes: number, eVotes: estimated votes }]

          for (var p = 0; p < totalParties; p++) {
            elpa = partiesSorted[p]; //     this.state.data.electionParties[p]; // it will take the name, id and order but i want also the votes and eVotes

            elpa.votes = 0;
            elpa.eVotes = 0;
            results["votesParties"].push(elpa);

            //console.log(elpa);

            //i will find the candidates of the given party but first i need some more details of that oarty
            var partyInDb;
            partyInDb = partiesAll.find(f => f.id === elpa.id);

            //this will find the candidate from the party table with the details that we want order, name and id
            canList = partyInDb.partiesCandidates;
            //sort then with the order number
            canList = canList.sort((a, b) => (a.order > b.order ? 1 : -1));

            //for each candidate that exist in the party
            for (var c = 0; c < canList.length; c++) {
              // check if the candidates location is on vs station location array if yes then add him in the results table
              // thus we will not have any candidate that donw belong to this votting station/ area => this is majorly done
              //for the periferiakes as we will have candidates under the party for diffenetr periferiakes enotites but under the same
              // party. those candidates shall be inlcuded only on their areas votting stations
              if (locationArray.indexOf(canList[c].locationId) !== -1) {
                can.id = canList[c].id;
                can.name = canList[c].name;
                can.order = canList[c].order;
                can.votes = 0;
                canList.eVotes = 0;

                results["votesCandidates"].push(can);
                can = {};
              }
            }

            //clear the elpa object
            elpa = {};
            //as we parse the parties i will find the candidates also
          }

          all.push(results);
          results = [];
        }

        //Delete first the data of that Election
        // await resultsDelete(
        //   this.state.data.id,
        //   "e52dc670-74f6-11e9-aad5-d50169aa37f0"
        // );

        // await resultsDeleteAllFromElection(this.state.data.id);
        ///////////////////////////Chnage the 1 to all.length !!!!!!!!
        //all.length
        for (var s = 0; s < all.length; s++) {
          //fire the API for createing the Items list in Dynamo DB

          if (this.props.production === true) await resultsSave(all[s]);
          if (this.props.production === false) fresultsSave(all[s]);
          //await resultsSave(all[s]);
          console.log(s, all[s]);
          //console.log(s, result);
        }
      }
    });
  };

  render() {
    const { title } = this.state;

    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            {/* <CardHeader>
              <h3 className="mb-0">{this.state.title}</h3>
            </CardHeader> */}

            <CardHeader>
              <Row>
                <Col md="9" sm="9">
                  <h2 className="mb-0">{title}</h2>
                </Col>
                <Col md="3" sm="3" className="align-items-right">
                  <h5 className="mb-0">
                    Ενημερώθηκε :
                    {" " +
                      moment
                        .unix(this.state.data.updatedOn)
                        .format("DD/MM/YY HH:mm:ss")}
                  </h5>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <Nav pills className="nav-pills-primary">
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht1" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht1" })}
                  >
                    Βασικές Πληροφορίες
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht2" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht2" })}
                  >
                    Εκλογικές Περιοχές
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht3" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht3" })}
                  >
                    Κόμματα
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht4" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht4" })}
                  >
                    Εκλογικά Κέντρα
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht5" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht5" })}
                  >
                    Δημιουργία αποτελεσμάτων
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.hTabs} className="tab-space">
                <TabPane tabId="ht1">
                  <Row>
                    <h5> </h5>
                  </Row>

                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col md={4} xs={6}>
                        {this.renderInput("name", "Ονομα")}
                      </Col>
                      <Col md={4} xs={6}>
                        {/* {this.renderNewSelect(
                              "electionTypeId",
                              "Τύπος",
                              this.state.electionTypes,
                              this.state.hideDropDownElectionType
                            )} */}

                        <NewSelect4
                          name="electionTypeId"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          isSearchable
                          placeholder="Επιλογή"
                          value={this.state.data.electionType.id}
                          label="Τύπος"
                          options={this.state.electionTypes}
                          onChange={this.handleChangeNewSelect3(
                            "electionTypeId"
                          )}
                          isDisabled={this.state.hideDropDownElectionType}
                        />
                      </Col>
                      <Col md={3} xs={6}>
                        <div className="form-group">
                          <label htmlFor="DateTimePicker">Ημερομηνία</label>
                          <Datetime
                            name="DateTimePicker"
                            id="DateTimePicker"
                            dateFormat="DD/MM/YYYY"
                            timeformat="false"
                            // defaultValue={new Date()}
                            // dateFormat={true}
                            // format="dd mm yyyy"
                            // format={{ raw: "dd/mm/yyyy" }}
                            value={this.state.data.date}
                            // onChange={value => this.setState({ value })}
                            //value={this.state.data.date}
                            onChange={this.handleDateChange}
                            // timeFormat={false}
                            inputProps={{
                              placeholder: "Επιλογη"
                            }}
                          />
                        </div>

                        {/* {this.renderDate("date", "Ημερομηνία")} */}
                        {/* {this.renderInput("date", "Ημερομηνία")} */}
                      </Col>

                      <Col md={1} xs={2}>
                        {this.renderCheck(
                          "active",
                          "Ενεργο",
                          this.state.data.active,
                          "TypeActive"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <h5> </h5>
                    </Row>
                    <Row>
                      <Col md={3} xs={6}>
                        {this.renderMoment(
                          "updatedOn",
                          "Ενημερώθηκε",
                          "Text",
                          true
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <h5> </h5>
                    </Row>
                    <Row>
                      <Col md={6} xs={6}>
                        <div className="btns-mr-5">
                          <Button
                            // leftLabel="now-ui-icons arrows-1_minimal-left"
                            // round
                            onClick={this.handleCancel}
                          >
                            Ακύρωση
                          </Button>
                          <span className="m-2"> </span>

                          {/* <Button
                              color="success"
                              round
                              leftLabel="now-ui-icons ui-1_check"
                              type="submit"
                              disabled={this.validateForm()}
                            >
                              Αποθήκευση
                            </Button> */}
                          <LoaderButton
                            color="success"
                            // round
                            // leftLabel="now-ui-icons ui-1_check"
                            onClick={this.handleSubmit}
                            disabled={this.validateForm()}
                            // type="submit"
                            isLoading={this.state.isLoading}
                            text="Αποθηκευση"
                            loadingText="Saving...."
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </TabPane>
                <TabPane tabId="ht2">
                  <ElectionsAreasForm
                    elections={this.state.data}
                    onDelete={this.handleelectionLocationsDelete}
                    onSave={this.handleelectionLocationsSave}
                    production={this.props.production}
                  />
                </TabPane>
                <TabPane tabId="ht3">
                  <ElectionsPartiesForm
                    elections={this.state.data}
                    onSave={this.handleElectionPartySave}
                    onDelete={this.handleElectionPartyDelete}
                    production={this.props.production}
                  />
                </TabPane>
                <TabPane tabId="ht4">
                  <ElectionsVstationsForm
                    elections={this.state.data}
                    onSave={this.handleElectionVstationSave}
                    onDelete={this.handleElectionVstationDelete}
                    production={this.props.production}
                  />
                </TabPane>
                <TabPane tabId="ht5">
                  <p>
                    Πατησε το παρακατω κουμπι οταν εισαι ετοιμος για δημιουργια
                    εγγραφων αποτελεσματων
                  </p>
                  <Button
                    color="success"
                    round
                    leftLabel="now-ui-icons ui-1_check"
                    onClick={this.handleCreationResults}
                  >
                    Δημιουργια
                  </Button>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default ElectionsForm;
