import React, { Component } from "react";
import Table from "../../../components/common/table";
import { Link } from "react-router-dom";

class ElectionsTable extends Component {
  columns = [
    // { path: "id", label: "ID" },

    // {
    //   path: "name",
    //   label: "Όνομα",
    //   content: election => (
    //     <Link
    //       to="#"
    //       onClick={() => this.props.onUpdate(election)} // for setting the local storage variables !!!
    //       // to={`/ekloges/results/${result.idElection}/${result.idVstation}`}
    //     >
    //       {election.name}
    //     </Link>
    //   )
    // },

    {
      path: "name",
      label: "Όνομα",
      content: election => (
        <Link to={`/ekloges/elections/${election.id}`}>{election.name}</Link>
      )
    },

    // { path: "name", label: "Ονομα" },
    { path: "active", label: "Ενεργο", type: "Boolean" },
    { path: "electionType.name", label: "Tύπος Εκλογών" },
    { path: "date", label: "Ημερομηνία" },
    { path: "updatedOn", label: "Ενημερώθηκε", type: "Date" },
    {
      key: "edit",
      content: election => (
        <button
          onClick={() => this.props.onUpdate(election)}
          className="btn btn-info btn-sm"
        >
          Edit
        </button>
      )
    },
    {
      key: "delete",
      content: election => (
        <button
          onClick={() => this.props.onDelete(election)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      )
    }
  ];

  render() {
    const { elections, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={elections}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ElectionsTable;
