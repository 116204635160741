// import { API } from "./node_modules/aws-amplify";
import { API } from "aws-amplify";
// const apiEndpoint = apiUrl + "/movies";

export function candidatesSave(candidate) {
  if (candidate.id) {
    const body = { ...candidate };
    delete body.id;
    return API.put("candidates", `/candidates/${candidate.id}`, {
      body: body
    });
  }

  return API.post("candidates", "/candidates", {
    body: candidate
  });
}

export function candidatesGetOne(candidateId) {
  return API.get("candidates", `/candidates/${candidateId}`);
}
export function candidatesGetAll() {
  return API.get("candidates", "/candidates");
}

export function candidatesDelete(candidate) {
  return API.del("candidates", `/candidates/${candidate.id}`);
}
