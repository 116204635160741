import React, { Component } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  fresultsGetAll
  //resultsGetElectionAndArea
} from "../../../services/fakeResultsService";

import {
  resultsGetAll,
  resultsDelete
  // resultsGetElectionAndArea
} from "../../../services/resultsService";

import { felectionsGetAll } from "../../../services/fakeElectionsService";
import { electionsGetAll } from "../../../services/electionsService";

import Pagination from "../../../components/common/pagination";
import { paginate } from "../../../components/utils/paginate";
import ResultsTable from "./resultsTable";
import SearchBox from "../../../components/common/searchBox";
import NewSelect4 from "../../../components/common/newSelect4";
import _ from "lodash";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { electionLocations } from "../../../services/listOfValues";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class Results extends Component {
  state = {
    results: [],
    resultsAll: [],
    //for the pagination
    pageSize: 20,
    currentPage: 1,
    searchQuery: "",
    sortColumn: { path: "nameVstation", order: "asc" },
    isDeleting: false,

    //drodowns
    electionId: "",
    elections: [],
    electionsLocationId: "",
    electionsLocations: [],
    electionsLocationsFiltered: [],
    hideDropdownElectionsLocations: true,
    hideDropdownElections: false,

    //reults table
    hideResultsTable: true,
    hTabs: "ht1"
  };

  async componentDidMount() {
    //Chec the storage

    const elid = localStorage.getItem("electionId");
    const loid = localStorage.getItem("electionsLocationId");

    //console.log("local storage retrieve", elid, loid);

    var results;
    var elections;
    if (this.props.production === true) {
      results = await resultsGetAll();
      elections = await electionsGetAll();
    }
    if (this.props.production === false) {
      results = fresultsGetAll();
      elections = felectionsGetAll();
    }

    //Keep only the active elections
    elections = elections.filter(f => f.active === true);

    this.setState(
      {
        resultsAll: results,
        results: results,
        elections: elections
      },
      () => console.log("Call Back on the cdm", this.state)
    );

    if (elid) {
      //If i have data from the local storage
      const election = elections.find(f => f.id === elid);

      //if found an election proceed to load data
      //i you swap fron prod to dev it retrives the dev values an crashes thats why i added that
      if (election) {
        const electionsLocations = election.electionLocations;

        results = results.filter(
          c => (c.idElection === elid) & (c.electionLocationId === loid)
        );

        this.setState({
          electionId: elid,
          electionsLocationId: loid,
          hideDropdownElectionsLocations: false,
          electionsLocationsFiltered: electionsLocations,
          hideResultsTable: false,
          results: results
        });
      }
    }
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    // console.log("query", query);

    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      results: allResults
    } = this.state;

    // console.log("on paged data", this.state);

    let filtered = this.state.results;
    //console.log("filtered", filtered);

    if (searchQuery)
      filtered = allResults.filter(v =>
        v.nameVstation.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const results = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: results };
  };

  handleUpdate = result => {
    //Save the data so when back to load the drodpw

    // console.log("local", this.state.electionId, this.state.electionsLocationId);
    localStorage.setItem("electionId", this.state.electionId);
    localStorage.setItem("electionsLocationId", this.state.electionsLocationId);

    this.props.history.push(
      `/ekloges/results/${result.idElection}/${result.idVstation}`
    );
  };

  handleDelete = async result => {
    swal({
      title: "Είσαι σίγουρος  οτι θέλεις να διαγράψεις το Αποτέλεσμα",
      text: result.nameElection + " " + result.nameVstation,
      icon: "warning",
      buttons: true
    }).then(async willDelete => {
      if (willDelete) {
        //Optimistic delete keep the previous state
        const originalResults = this.state.results;
        // console.log("originalResults", originalResults);

        // Results will be the one that will not have a key combonatioon of the wo keys
        const results = originalResults.filter(
          c =>
            !(
              (c.idElection === result.idElection) &
              (c.idVstation === result.idVstation)
            )
        );

        // update the UI
        this.setState({ results });
        var pagePrevNum = this.state.currentPage;

        //Fix the pagination issue
        if (
          this.state.currentPage > 1 &&
          this.state.currentPage - 1 === results.length / this.state.pageSize //e,g, page 3 , 8 length page size 4=>  (3-1=2) =  ( 8/4= 2) so reduce
        ) {
          const newPage = this.state.currentPage - 1;

          this.setState({ currentPage: newPage });
        }

        try {
          if (this.props.production === true) await resultsDelete(result.id);
          //in case of Dev we have already deleted that in the UI Before
          // if (this.props.production === false) fresultsDelete(result.id);
        } catch (ex) {
          //If goes wrong the revert !!!
          if (ex.response && ex.response.status === 404) console.log("x");
          toast.error("Tο αποτέλεσμα  φαίνεται οτι έχει ηδη διαγραφτεί");

          this.setState({
            results: originalResults,
            currentPage: pagePrevNum
          });
        }
      }
    });
  };

  handleChangeNewSelect3 = e => input => {
    if (e === "elections") {
      // console.log(input);

      // this.setState({
      //   testelectionValue: input.value,
      //   testelectionLocationValue: null,
      //   hideDropdownElectionsLocations: false
      // });

      const electionId = input.value;
      const election = this.state.elections.find(f => f.id === electionId);
      const electionsLocationsFiltered = election.electionLocations;

      localStorage.setItem("electionId", electionId);

      this.setState(
        {
          electionId: electionId,
          electionsLocationId: null,
          electionsLocationsFiltered: electionsLocationsFiltered,
          hideDropdownElectionsLocations: false,
          hideResultsTable: true
        },
        () => console.log(this.state)
      );
    }
    if (e === "electionsLocations") {
      const locationId = input.value;
      const results = this.state.resultsAll.filter(
        f =>
          f.idElection === this.state.electionId &&
          f.electionLocationId === locationId
      );

      localStorage.setItem("electionsLocationId", locationId);
      //console.log(results, this.state.electionId, locationId);

      this.setState({
        electionsLocationId: locationId,
        results: results,
        hideResultsTable: false
      });
    }
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { totalCount, data: results } = this.getPagedData();

    // results = results.sort((a, b) => (a.name < b.name ? 1 : -1));

    //console.log("sorted", results);

    const { length: count } = this.state.results; // Object destructing of the lentgh of candidates to count

    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col xs="12">
                  <h2 className="mb-0">
                    Διάλεξε την Εκλογική Αναμέτρηση και την περιοχή
                  </h2>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <Row>
                <Col md="6" sm="6">
                  <NewSelect4
                    name="elections"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Επιλογή"
                    value={this.state.electionId} // it wants only the ID wich will automatically convert that to value
                    label="Εκλογική  Αναμέτρηση"
                    options={this.state.elections}
                    onChange={this.handleChangeNewSelect3("elections")}
                    isDisabled={this.state.hideDropdownElections}
                  />
                </Col>

                <Col md="6" sm="6">
                  <NewSelect4
                    name="electionsLocations"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Επιλογή"
                    value={this.state.electionsLocationId} // it wants only the ID wich will automatically convert that to value
                    label="Περιοχή"
                    options={this.state.electionsLocationsFiltered}
                    onChange={this.handleChangeNewSelect3("electionsLocations")}
                    isDisabled={this.state.hideDropdownElectionsLocations}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/* <Row className="justify-content-center">
              <Col lg={12} md={12} xs={12}> */}

          {this.state.electionsLocationId && (
            <Card className="mb-4">
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h2 className="mb-0">
                      <Badge color="info">{totalCount}</Badge>
                      <span className="m-1" />
                      Αποτελέσματα Εκλογών
                    </h2>
                  </Col>

                  {this.props.authLevel >= 4 && (
                    <Col className="text-right" xs="6">
                      <Link
                        to="/ekloges/elections/new"
                        className="btn btn-success"
                        style={{ marginBottom: 20 }}
                      >
                        Προσθήκη
                      </Link>
                    </Col>
                  )}
                </Row>
              </CardHeader>

              <CardBody>
                {this.state.hideResultsTable === true ? (
                  <h6>Διαλεξε πρωτα εκλογικη Διαδικασία και περιοχη</h6>
                ) : this.state.results.length === 0 ? (
                  <h4>
                    {" "}
                    Δυστυχως δεν υπαρχουνε διαθεσιμα Αποτελέσματα για την
                    περιοχή που ψαχνεις
                  </h4>
                ) : (
                  <React.Fragment>
                    <SearchBox
                      value={searchQuery}
                      onChange={this.handleSearch}
                    />
                    <ResultsTable
                      results={results}
                      sortColumn={sortColumn}
                      onDelete={this.handleDelete}
                      onUpdate={this.handleUpdate}
                      onSort={this.handleSort}
                      authLevel={this.props.authLevel}
                    />
                    <Pagination
                      itemsCount={totalCount}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={this.handlePageChange}
                    />
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default Results;
