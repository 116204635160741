import React, { Component } from "react";
import Table from "../../../components/common/table";
import { Link } from "react-router-dom";

class VstationsTable extends Component {
  columns = [
    // {
    //   path: "id",
    //   label: "ID"
    // },

    {
      path: "name",
      label: "Τμημα",
      content: vstation => (
        <Link to={`/ekloges/vstations/${vstation.id}`}>{vstation.name}</Link>
      )
    },
    { path: "number", label: "Αριθμος" },

    { path: "address", label: "Διευθυνση" },
    { path: "registeredVoters", label: "Eγγεγραμμενοι" },
    { path: "startName", label: "Αρχικο Ονομα" },
    { path: "lastName", label: "Τελικο Ονομα" },
    { path: "active", label: "Ενεργο", type: "Boolean" },
    { path: "updatedOn", label: "Τελευταια Ενημερωση", type: "Date" },
    {
      key: "edit",
      content: vstation => (
        <button
          onClick={() => this.props.onUpdate(vstation)}
          className="btn btn-info btn-sm"
        >
          Edit
        </button>
      )
    },
    {
      key: "delete",
      content: vstation => (
        <button
          onClick={() => this.props.onDelete(vstation)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      )
    }
  ];

  render() {
    const { vstations, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={vstations}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default VstationsTable;
