import React, { Component } from "react";
import Table from "../../../components/common/table";

class PartiesCandidatesTable extends Component {
  columns = [
    // { path: "id", label: "ID" },
    { path: "name", label: "Ονομα" },
    { path: "order", label: "Σειρά" },
    // { path: "locationId", label: "ID Περιοχης" },
    { path: "locationName", label: "Περιοχή" },

    {
      key: "edit",
      content: candidate => (
        <button
          onClick={() => this.props.onUpdate(candidate)}
          className="btn btn-info btn-sm"
        >
          Edit
        </button>
      )
    },
    {
      key: "delete",
      content: candidate => (
        <button
          onClick={() => this.props.onDelete(candidate)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      )
    }
  ];

  render() {
    const { candidates, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={candidates}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default PartiesCandidatesTable;
