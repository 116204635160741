import React from "react";
import Select from "react-select";

const NewSelect = ({
  name,
  label,
  options,
  placeholder,
  value,
  error,
  ...rest
}) => {
  options = options.map(p => ({ value: p.id, label: p.name }));
  // console.log("mesa options", options);
  // console.log("mesa options VAlue prin", value);
  value = options.find(o => o.value === value);
  // console.log("mesa options Value meta", value);

  // if (value === undefined) {
  //   value = "";
  // }
  // { value: "PT1", label: "Ευρωβουλευτικές" };

  // console.log("mesa", value, options);
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Select
        name={name}
        id={name}
        {...rest}
        // className="form-control"
        className="react-select primary"
        classNamePrefix="react-select"
        placeholder={placeholder}
        value={value}
        options={options}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default NewSelect;
