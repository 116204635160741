import { API } from "aws-amplify";

export function partiesSave(party) {
  if (party.id) {
    const body = { ...party };
    delete body.id;
    return API.put("parties", `/parties/${party.id}`, {
      body: body
    });
  }
  return API.post("parties", "/parties", {
    body: party
  });
}
export function partiesGetOne(partyId) {
  return API.get("parties", `/parties/${partyId}`);
}

export function partiesGetAll() {
  return API.get("parties", "/parties");
}

export function partiesDelete(partyId) {
  return API.del("parties", `/parties/${partyId}`);
}

// export function partiesCreateCandidates(party, candidate) {
//   // //We need to check if the attribute partiesCandidates[]  is missing totaly as nobody has created that before e.g. on a new Party
//   // if (_.has(party, "partiesCandidates") === false) {
//   //   const partiesCandidates = [];
//   //   partiesCandidates[0] = candidate;

//   //   party["partiesCandidates"] = partiesCandidates;

//   //   return party;
//   // }
//   // console.log("lodash", _.has(partiesInDb, "partiesCandidates"));
//   // let indexFirst = partiesInDb.findIndex(e => e === "leader");
//   //else check to see if that exist as elemnt under partiesCandidates so to post or update
//   const index = party.partiesCandidates.findIndex(e => e.id === candidate.id);
//   console.log(index);
//   if (index === -1) {
//     // New record
//     party.partiesCandidates.push(candidate);
//     console.log("i can push");
//     return party.partiesCandidates;
//   }

//   //else update
//   party.partiesCandidates[index] = candidate;
//   return party.partiesCandidates;
// }
