export default {
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://1lzsn868c9.execute-api.eu-west-2.amazonaws.com/prod"
  },
  apiGatewayDublin: {
    REGION: "eu-west-1",
    URL: "https://dkoux2t5q9.execute-api.eu-west-1.amazonaws.com/dev"
  },

  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_V3ug3oHyL",
    APP_CLIENT_ID: "fna71g1921oorn5s5pcc6nevf",
    IDENTITY_POOL_ID: "eu-west-2:cddcc79a-3f9f-47ec-a9e6-5d3b34040dc2"
  }
};
