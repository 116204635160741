// const electionsSchema = {
//   id: "", // Unique ID from the UUID
//   name: "", //String
//   date: "", // The date of the elections
//   active: true, //Bollean type for active/ Non active
//   electionVstations: [
//     {
//       id: "", //String of the UUID of the i
//       name: "", //string The name of the Voting station
//       order: "" //number integer
//     }
//   ],
//   electionParties: [
//     {
//       id: "", //
//       name: "", //
//       order: ""
//     }
//   ],

//   updatedOn: "" //Date that has been updated
// };
import moment from "moment";
import * as listOfValues from "./listOfValues";
import _ from "lodash";

const elections = [
  {
    id: "EL1",
    name: "Περιφερειακές Εκλογές 2019 Α",
    active: true,
    date: "26/05/2019",
    electionType: { id: "ELTY003", name: "Περιφερειακές" },
    electionLocations: [
      {
        id: "PEEN31",
        name: "ΛΕΥΚΑΔΑΣ",
        order: 1,
        active: true,
        layerId: "LOLA02",
        locationId: "PEEN31"
      },
      {
        id: "PEEN29",
        name: "ΚΕΡΚΥΡΑΣ",
        order: 2,
        active: true,
        layerId: "LOLA02",
        locationId: "PEEN31"
      },
      {
        id: "PEEN28",
        name: "ΙΘΑΚΗΣ",
        order: 3,
        active: true,
        layerId: "LOLA02",
        locationId: "PEEN31"
      }
    ],
    electionVstations: [
      {
        id: "VS1",
        name: "Λευκαδα 1 Δημοτικό",
        order: 6,
        electionLocation: "ΛΕΥΚΑΔΑΣ",
        electionLocationId: "PEEN31"
      },
      {
        id: "VS2",
        name: "Λευκαδα 2 Δημοτικό",
        order: 8,
        electionLocation: "ΛΕΥΚΑΔΑΣ",
        electionLocationId: "PEEN31"
      },
      {
        id: "VS3",
        name: "Λευκάδα 3 Δημοτικό",
        order: 7,
        electionLocation: "ΛΕΥΚΑΔΑΣ",
        electionLocationId: "PEEN31"
      },
      {
        id: "VS9",
        name: "Ιθακη",
        order: 1,
        electionLocation: "ΙΘΑΚΗΣ",
        electionLocationId: "PEEN28"
      }
    ],
    electionParties: [
      { id: "P1", name: "Ιόνιος Δυνατά, κάθε νησι ψηλά", order: 1 },
      { id: "P2", name: "Ιόνιος ΣΥΡΙΖΑ", order: 2 }
    ],

    updatedOn: 1557265802
  },

  {
    id: "EL2",
    name: "Δημοτικές Εκλογές 2019 Α",
    active: true,
    date: "26/05/2019",
    electionType: { id: "ELTY004", name: "Δημοτικές" },
    electionLocations: [
      {
        id: "DIM001",
        name: "ΔΟΞΑΤΟΥ",
        order: 1,
        active: true,
        layerId: "LOLA03",
        locationId: "DIM001"
      }
    ],
    electionVstations: [
      {
        id: "VS6",
        name: "Doksatou 1 Δημοτικό",
        order: 3,
        electionLocation: "ΔΟΞΑΤΟΥ",
        electionLocationId: "DIM001"
      },
      {
        id: "VS7",
        name: "Doksatou 2 Δημοτικό",
        order: 1,
        electionLocation: "ΔΟΞΑΤΟΥ",
        electionLocationId: "DIM001"
      }
    ],
    electionParties: [
      { id: "P7", name: "Drama 1", order: 1 }
      // ,
      // { id: "P3", name: "ΝΔ  Σολδατος", order: 2 }
    ],
    updatedOn: 1557265802
  },
  {
    id: "EL3",
    name: "Περιφερειακές Εκλογές 2019 Β",
    active: false,
    date: "02/06/2019",
    electionType: { id: "ELTY003", name: "Περιφερειακές" },
    electionLocations: [],
    electionVstations: [
      {
        id: "VS1",
        name: "Λευκαδα 1 Δημοτικό",
        number: "01",
        order: 1,
        electionLocation: "ΛΕΥΚΑΔΑΣ",
        electionLocationId: "PEEN31"
      },
      {
        id: "VS2",
        name: "Λευκαδα 2 Δημοτικό",
        number: "02",
        order: 1,
        electionLocation: "ΛΕΥΚΑΔΑΣ",
        electionLocationId: "PEEN31"
      },
      { id: "VS3", name: "Λευκάδα 3 Δημοτικό", number: "04", order: 1 }
    ],
    electionParties: [
      { id: "P1", name: "Ιόνιος Δυνατά, κάθε νησι ψηλά", order: 1 },
      { id: "P2", name: "Ιόνιος ΣΥΡΙΖΑ", order: 2 },
      { id: "P5", name: "Spirou", order: 3 }
    ],

    updatedOn: 1557265802
  },
  {
    id: "EL4",
    name: "Δημοτικές Εκλογές 2019 Β",
    active: false,
    date: "02/06/2019",
    electionType: { id: "ELTY004", name: "Δημοτικές" },
    electionLocations: [],
    electionVstations: [
      {
        id: "VS1",
        name: "Λευκαδα 1 Δημοτικό",
        number: "01",
        order: 1,
        electionLocation: "ΛΕΥΚΑΔΑΣ",
        electionLocationId: "PEEN31"
      },
      {
        id: "VS2",
        name: "Λευκαδα 2 Δημοτικό",
        number: "02",
        order: 1,
        electionLocation: "ΛΕΥΚΑΔΑΣ",
        electionLocationId: "PEEN31"
      },
      {
        id: "VS3",
        name: "Λευκάδα 3 Δημοτικό",
        number: "03",
        order: 2,
        electionLocation: "ΛΕΥΚΑΔΑΣ",
        electionLocationId: "PEEN31"
      }
    ],
    electionParties: [
      { id: "P4", name: "Φλουδας", order: 1 },
      { id: "P3", name: "ΝΔ  Σολδατος", order: 2 }
    ],
    updatedOn: 1557265802
  }
];

export function felectionsGetAll() {
  return elections;
}

export function felectionsGetOne(id) {
  return elections.find(p => p.id === id);
}

export function felectionsDelete(id) {
  let electionsInDb = elections.find(v => v.id === id);
  elections.splice(elections.indexOf(electionsInDb), 1);
  return elections;
}

export function felectionsSave(election) {
  let electionsInDb = elections.find(v => v.id === election.id) || {};
  electionsInDb.name = election.name;
  electionsInDb.date = election.date;
  electionsInDb.active = election.active;
  electionsInDb.electionType = election.electionType;
  // electionsInDb.electionType = listOfValues.electionTypes.find(
  //   el => el.id === election.electionTypeId
  // );
  // // Becareful with ==== vs =

  // console.log("on save the election Type", electionsInDb.electionType);
  electionsInDb.electionLocations = election.electionLocations;
  electionsInDb.electionVstations = election.electionVstations;
  electionsInDb.electionParties = election.electionParties;

  electionsInDb.updatedOn = moment().unix();

  // console.log("PartyIndDB", electionsInDb.electionType);
  //if the ID is null that means is a new one
  if (!electionsInDb.id) {
    electionsInDb.id = Date.now().toString();
    elections.push(electionsInDb);
  }
  console.log("from save !!!", electionsInDb);
  return electionsInDb;
}

export function felectionVstationsDelete() {
  return null;
}

export function felectionsAreasDelete(electionId, electionAreaId) {
  //find the election that i want
  let electionsInDb = elections.find(p => p.id === electionId);

  let electionsAreasInDb = electionsInDb.electionLocations.find(
    c => c.id === electionAreaId
  );

  console.log(electionsAreasInDb);

  electionsInDb.electionLocations.splice(
    electionsInDb.electionLocations.indexOf(electionsAreasInDb),
    1
  );
  console.log(electionsInDb);

  return electionsInDb;
}

export function felectionsAreasCreate(electionId, electionArea) {
  //find the election that i want
  let electionsInDb = elections.find(p => p.id === electionId);

  //if the attribute is missing
  if (_.has(electionsInDb, "electionLocations") === false) {
    const electionLocations = [];
    electionLocations[0] = electionArea;

    electionsInDb["electionLocations"] = electionLocations;

    return electionsInDb;
  }

  //else check to see if that exist as elemnt under partiesCandidates so to post or update
  const index = electionsInDb.electionLocations.findIndex(
    e => e.id === electionArea.id
  );
  console.log(index);
  if (index === -1) {
    // New record
    electionsInDb.electionLocations.push(electionArea);
    console.log("i can push");
    return electionsInDb;
  }

  //else update
  electionsInDb.electionLocations[index] = electionArea;
  return electionsInDb;
}

export function felectionsPartyCreate(electionId, electionParty) {
  //find the election that i want
  let electionsInDb = elections.find(p => p.id === electionId);

  //if the attribute is missing
  if (_.has(electionsInDb, "electionParties") === false) {
    const electionParties = [];
    electionParties[0] = electionParty;

    electionsInDb["electionLocations"] = electionParties;

    return electionsInDb;
  }

  //else check to see if that exist as elemnt under partiesCandidates so to post or update
  const index = electionsInDb.electionParties.findIndex(
    e => e.id === electionParty.id
  );
  console.log(index);
  if (index === -1) {
    // New record
    electionsInDb.electionParties.push(electionParty);
    console.log("i can push");
    return electionsInDb;
  }

  //else update
  electionsInDb.electionParties[index] = electionParty;
  return electionsInDb;
}

export function felectionsPartyDelete(electionId, electionPartyId) {
  //find the election that i want
  let electionsInDb = elections.find(p => p.id === electionId);

  let electionsAreasInDb = electionsInDb.electionParties.find(
    c => c.id === electionPartyId
  );

  console.log(electionsAreasInDb);

  electionsInDb.electionParties.splice(
    electionsInDb.electionParties.indexOf(electionsAreasInDb),
    1
  );
  console.log(electionsInDb);

  return electionsInDb;
}

export function felectionsVstationCreate(electionId, electionVstation) {
  //find the election that i want
  let electionsInDb = elections.find(p => p.id === electionId);

  //if the attribute is missing
  if (_.has(electionsInDb, "electionVstations") === false) {
    const electionVstations = [];
    electionVstations[0] = electionVstation;

    electionsInDb["electionVstations"] = electionVstations;

    return electionsInDb;
  }

  //else check to see if that exist as elemnt under partiesCandidates so to post or update
  const index = electionsInDb.electionVstations.findIndex(
    e => e.id === electionVstation.id
  );

  if (index === -1) {
    // New record
    electionsInDb.electionVstations.push(electionVstation);

    return electionsInDb;
  }

  //else update
  electionsInDb.electionVstations[index] = electionVstation;
  return electionsInDb;
}

export function felectionsVstationDelete(electionId, electionVstationId) {
  //find the election that i want
  let electionsInDb = elections.find(p => p.id === electionId);

  let electionVstationsInDb = electionsInDb.electionVstations.find(
    c => c.id === electionVstationId
  );

  console.log(electionVstationsInDb);

  electionsInDb.electionVstations.splice(
    electionsInDb.electionVstations.indexOf(electionVstationsInDb),
    1
  );
  console.log(electionsInDb);

  return electionsInDb;
}
