import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import RoutesLayout from "./routesLayout";
import { ToastContainer } from "react-toastify";

class App extends Component {
  //by default the user is not authenticated, when he clicks on the login then it will update the this state to true
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false, // this is to ensure that i am authenticated and keep my statet
      isAuthenticating: true, //this goes to wait till API Gateway check the state before i render anything as it keeps a session open
      //so if i leave the page an come back i will be logged in :-)

      //There are 4 Levele of authority
      // L1 => 1 loimited acccess can read
      // L2 => 2 Contibutor can edit results only !!! but not delete or create anything else
      // L3 => 3 Candidate
      // L4 => 4 Admin can do everything
      authLevel: "",
      email: "",

      ///to change between fake services and production
      production: true
    };
  }

  async componentDidMount() {
    //console.log("Mpika sto App Mount");

    if (this.state.production === true) {
      try {
        const session = await Auth.currentSession(); //checks the state and brings the result
        let role = session.accessToken.payload["cognito:groups"];

        const promise = await Auth.currentAuthenticatedUser();

        // console.log("On Session Retieval", promise.attributes, session);
        // console.log("role", role);
        // this.addUserRole(role);
        // this.userHasAuthenticated(true, role);
        // console.log("role", role);
        this.userHasAuthenticated(true, role, promise.attributes.email); // if it  find yuou wth an open session makes the flag authenticated so to see the pages
        //this.setState({})
      } catch (e) {
        if (e !== "No current user") {
          alert(e);
        }
      }
    } else {
      this.setState({
        isAuthenticated: true,
        role: "L4",
        email: "malakasis@gmail.com",
        authLevel: 4
      }); // For the desiging mode
    }
    //finally temrinates the ins authenticating porocess
    this.setState({ isAuthenticating: false });
  }

  //this is the service that will be called from the child login
  userHasAuthenticated = (authenticated, role, email) => {
    var authLevel = "";
    if (role.indexOf("L1") > -1) authLevel = 1;
    if (role.indexOf("L2") > -1) authLevel = 2;
    if (role.indexOf("L3") > -1) authLevel = 3;
    if (role.indexOf("L4") > -1) authLevel = 4;

    //for not having to asisgn L1 to all of my users !!!
    if (authenticated === true && authLevel === "") authLevel = 1;

    this.setState({
      isAuthenticated: authenticated,
      authLevel: authLevel,
      email: email
    });
    // console.log("update of the authentication", this.state);
  };

  //this is the service that will be called from the child login
  changeMode = mode => {
    this.setState({ production: mode });
  };

  handleLogout = async event => {
    await Auth.signOut(); // clear the cache

    //console.log("Vgaine");

    this.userHasAuthenticated(false, "", "");
    this.props.history.push("/login"); //this is to send us in the login opage when we logout
  };

  render() {
    //we passs the properties to the childre  through the route componenet down so to uopdate the then about the authetnications
    const childProps = {
      authLevel: this.state.authLevel,
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      onLogout: this.handleLogout,
      production: this.state.production,
      email: this.state.email
      // changeMode: this.changeMode not yet live
      //}
    };

    return (
      !this.state.isAuthenticating && (
        <React.Fragment>
          <ToastContainer />
          <RoutesLayout childProps={childProps} />
        </React.Fragment>
      )
    );
  }
}

export default withRouter(App); // for the logout component to get the history props
