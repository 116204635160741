import React from "react";
import Select from "react-select";

const NewSelect = ({ name, label, options, value, error, ...rest }) => {
  options = options.map(p => ({ value: p.id, label: p.name }));

  value = options.find(o => o.value === value);

  // { value: "PT1", label: "Ευρωβουλευτικές" };

  // console.log("mesa", value, Akis);
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Select
        name={name}
        id={name}
        {...rest}
        // className="form-control"
        className="react-select primary"
        classNamePrefix="react-select"
        placeholder="Επέλεγξε"
        value={value}
        options={options}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default NewSelect;
