import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Container
} from "reactstrap";
import VstationsTable from "./vstationsTable";

import { fvstationsGetAll } from "../../../services/fakeVstationsService";
import {
  vstationsGetAll,
  vstationsDelete
} from "../../../services/vstationsService";
import SearchBox from "../../../components/common/searchBox";

import Pagination from "../../../components/common/pagination";
import { paginate } from "../../../components/utils/paginate";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class Vstations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vstations: [],
      pageSize: 100,
      currentPage: 1,
      searchQuery: "",
      sortColumn: { path: "name", order: "asc" },
      isDeleting: false
    };
  }

  async componentDidMount() {
    var vstations;
    if (this.props.production === true) vstations = await vstationsGetAll();
    if (this.props.production === false) vstations = fvstationsGetAll();

    this.setState({ vstations: vstations });
  }

  handleUpdate = vstation => {
    this.props.history.push(`/ekloges/vstations/${vstation.id}`);
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      vstations: allVstations
    } = this.state;

    let filtered = allVstations;
    if (searchQuery)
      filtered = allVstations.filter(v =>
        v.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const vstations = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: vstations };
  };

  handleDelete = async vstation => {
    swal({
      title: "Εισαι σιγουρος  οτι θελεις να διαγραψεις το Τμήμα",
      text: vstation.name,
      icon: "warning",
      buttons: true
    }).then(async willDelete => {
      if (willDelete) {
        //Optimistic delete keep the previous state
        const originalVstations = this.state.vstations;
        // Filter OUT the one that i dont want
        const vstations = originalVstations.filter(c => c.id !== vstation.id);

        // update the UI
        this.setState({ vstations });

        var pagePrevNum = this.state.currentPage;
        //Fix the pagination issue
        if (
          this.state.currentPage > 1 &&
          this.state.currentPage - 1 === vstations.length / this.state.pageSize //e,g, page 3 , 8 length page size 4=>  (3-1=2) =  ( 8/4= 2) so reduce
        ) {
          const newPage = this.state.currentPage - 1;
          this.setState({ currentPage: newPage });
        }
        try {
          if (this.props.production === true)
            await vstationsDelete(vstation.id);

          //If dev environment has already deleted !!! beofre
          // if (this.props.production === false) fvstationsDelete(vstation.id);

          //await vstationsDelete(vstation.id);
        } catch (ex) {
          //If goes wrong the revert !!!
          if (ex.response && ex.response.status === 404) console.log("x");
          toast.error("Ο Υποψήφιος  φαίνεται οτι έχει ηδη διαγραφτεί");

          this.setState({
            vstations: originalVstations,
            currentPage: pagePrevNum
          });
        }
      }
    });
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { totalCount, data: vstations } = this.getPagedData();

    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h2 className="mb-0">
                    <Badge color="info">{totalCount}</Badge>
                    <span className="m-1" />
                    Eκλογικά τμήματα
                  </h2>
                </Col>
                <Col className="text-right" xs="6">
                  <Link
                    to="/ekloges/vstations/new"
                    className="btn btn-success"
                    style={{ marginBottom: 20 }}
                  >
                    Προσθήκη
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <VstationsTable
                vstations={vstations}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onUpdate={this.handleUpdate}
                onSort={this.handleSort}
              />
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default Vstations;
