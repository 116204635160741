// const vstationsSchema = {
//   id: "", // Unique ID from the UUID
//   name: "", //String
//   number: "", // String of the Number of the stations could be 23A, 40 B etc
//   active: true, //Bollean type for active/ Non active
//   address: "", //String the address
//   registeredVoters: "", // The number of registered voters
//   startName: "", //The start name of the elections deprtment
//   lastName: "", //The Last Name
//   updatedOn: "" //Date that has been updated
// };

import moment from "moment";
export const vstations = [
  {
    id: "VS1",
    name: "Λευκαδα 1 Δημοτικό",
    number: "01",
    active: true,
    address: "Alikes 2, 31100",
    registeredVoters: 230,
    startName: "Antonopoulos Marios",
    lastName: "Katopodis Tasos",
    updatedOn: 1557264050,
    location: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN31", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA02" },
      { id: "DIM121", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA03" }
    ]
  },
  {
    id: "VS2",
    name: "Λευκαδα 2 Δημοτικό",
    number: "02",
    active: true,
    address: "Alikes 16, 31100",
    registeredVoters: 146,
    startName: "Katopodis Vasillis",
    lastName: "Papadopoulos Kostas",
    updatedOn: 1557264050,
    location: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN31", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA02" },
      { id: "DIM121", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA03" }
    ]
  },
  {
    id: "VS3",
    name: "Λευκάδα 3 Δημοτικό",
    number: "03",
    active: true,
    address: "Πεφανερωμενης 23, 31100",
    registeredVoters: 180,
    startName: "Αλικη Βασιλοπουλου",
    lastName: "Μητσος Κωστοπουλος",
    updatedOn: 1557264050,
    location: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN31", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA02" },
      { id: "DIM121", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA03" }
    ]
  },
  {
    id: "VS4",
    name: "Λευκαδα 4 Δημοτικό",
    number: "04",
    active: true,
    address: "Μαλακαση 18, 31100",
    registeredVoters: 289,
    startName: "Δρακατος Αλεξανδρος",
    lastName: "Μαλακασης Γιαννης",
    updatedOn: 1557264050,
    location: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN31", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA02" },
      { id: "DIM121", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA03" }
    ]
  },
  {
    id: "VS5",
    name: "Lefkada 5 Δημοτικό",
    number: "5",
    active: true,
    address: "Φοντα 30, 31100",
    registeredVoters: 340,
    startName: "Σαλμα Αγγελικη",
    lastName: "Σολδατος Θοδωρης",
    updatedOn: 1557264050,
    location: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN31", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA02" },
      { id: "DIM121", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA03" }
    ]
  },

  {
    id: "VS6",
    name: "Doksatou 1 Δημοτικό",
    number: "5",
    active: true,
    address: "DDDD 30, 31100",
    registeredVoters: 287,
    startName: "Σωτηριου Αγγελικη",
    lastName: "Παπαδοπουλος Θοδωρης",
    updatedOn: 1557264050,
    location: [
      { id: "PEIA01", name: "ΑΝ. ΜΑΚΕΔΟΝΙΑΣ & ΘΡΑΚΗΣ", layerId: "LOLA01" },
      { id: "PEEN01", name: "ΔΡΑΜΑΣ", layerId: "LOLA02" },
      { id: "DIM001", name: "ΔΟΞΑΤΟΥ", layerId: "LOLA03" }
    ]
  },
  {
    id: "VS7",
    name: "Doksatou 2 Δημοτικό",
    number: "5",
    active: true,
    address: "PPPP 30, 31100",
    registeredVoters: 220,
    startName: "Αρετη Αγγελικη",
    lastName: "Χαροπουλους Θοδωρης",
    updatedOn: 1557264050,
    location: [
      { id: "PEIA01", name: "ΑΝ. ΜΑΚΕΔΟΝΙΑΣ & ΘΡΑΚΗΣ", layerId: "LOLA01" },
      { id: "PEEN01", name: "ΔΡΑΜΑΣ", layerId: "LOLA02" },
      { id: "DIM001", name: "ΔΟΞΑΤΟΥ", layerId: "LOLA03" }
    ]
  },
  {
    id: "VS8",
    name: "Doksatou 3 Δημοτικό",
    number: "5",
    active: true,
    address: "DD 30, 31100",
    registeredVoters: 220,
    startName: "Σαλμα Παπαας",
    lastName: "Μαλκασης Θοδωρης",
    updatedOn: 1557264050,
    location: [
      { id: "PEIA01", name: "ΑΝ. ΜΑΚΕΔΟΝΙΑΣ & ΘΡΑΚΗΣ", layerId: "LOLA01" },
      { id: "PEEN01", name: "ΔΡΑΜΑΣ", layerId: "LOLA02" },
      { id: "DIM001", name: "ΔΟΞΑΤΟΥ", layerId: "LOLA03" }
    ]
  },
  {
    id: "VS9",
    name: "Ιθακη",
    number: "12",
    active: true,
    address: "34 Ιθακης",
    registeredVoters: 239,
    startName: "Αναστασια Βουλγαρη",
    lastName: "Παναγιωτης Σκιαδαρεσης",
    updatedOn: 1557264050,
    location: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN28", name: "ΙΘΑΚΗΣ", layerId: "LOLA02" },
      { id: "DIM117", name: "ΙΘΑΚΗΣ", layerId: "LOLA03" }
    ]
  }
];

export function fvstationsGetAll() {
  return vstations;
}

export function fvstationsGetOne(id) {
  return vstations.find(v => v.id === id);
}

export function fvstationsSave(vstation) {
  let vstationInDb = vstations.find(v => v.id === vstation.id) || {};
  vstationInDb.name = vstation.name;
  vstationInDb.number = vstation.number;
  vstationInDb.active = vstation.active;
  vstationInDb.address = vstation.address;
  vstationInDb.registeredVoters = vstation.registeredVoters;
  vstationInDb.startName = vstation.startName;
  vstationInDb.lastName = vstation.lastName;
  vstationInDb.location = vstation.location;
  vstationInDb.notes = vstation.notes;
  vstationInDb.updatedOn = moment().unix();

  console.log("on save:", vstationInDb);
  //if the ID is null that means is a new one
  if (!vstationInDb.id) {
    vstationInDb.id = Date.now().toString();
    vstations.push(vstationInDb);
  }

  return vstationInDb;
}

export function fvstationsDelete(id) {
  let vstationInDb = vstations.find(v => v.id === id);
  vstations.splice(vstations.indexOf(vstationInDb), 1);
  return vstations;
}
