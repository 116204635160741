import React, { Component } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import { Link } from "react-router-dom";

class CustomFooter extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/axies"
                className={classes.block}
                style={{ fontSize: 11 }}
              >
                Αξιες
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/viografiko"
                className={classes.block}
                style={{ fontSize: 11 }}
              >
                Βιογραφικο
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/epikinonia"
                className={classes.block}
                style={{ fontSize: 11 }}
              >
                Επικοινωνια
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/login"
                className={classes.block}
                style={{ fontSize: 11 }}
              >
                Login
              </Link>
            </ListItem>
          </List>
        </div>
        <div className={classes.right} style={{ fontSize: 10 }}>
          &copy; {1900 + new Date().getYear()} , made by
          <a href="https://www.linkedin.com/in/malakasis/">
            Theodoros Malakasis
          </a>
        </div>
      </div>
    );
  }
}

export default CustomFooter;
