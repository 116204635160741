/* eslint-disable */
import React from "react";
// import "../../../assets/scssMaterial/material-kit-pro-react.scss?v=1.3.0";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import Tooltip from "@material-ui/core/Tooltip";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// // @material-ui/icons
// import Camera from "@material-ui/icons/Camera";
// import Palette from "@material-ui/icons/Palette";
// import People from "@material-ui/icons/People";
// import Add from "@material-ui/icons/Add";
// import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "../../../components/N1components/Header/Header.jsx";
import Footer from "../../../components/N1components/Footer/Footer.jsx";
import GridContainer from "../../../components/N1components/Grid/GridContainer.jsx";
import GridItem from "../../../components/N1components//Grid/GridItem.jsx";
import HeaderLinks from "../../../components/N1components/Header/HeaderLinks.jsx";
// import NavPills from "components/NavPills/NavPills.jsx";
// import Card from "components/Card/Card.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import Badge from "components/Badge/Badge.jsx";
// import Muted from "components/Typography/Muted.jsx";
import Parallax from "../../../components/N1components//Parallax/Parallax.jsx";
import Clearfix from "../../../components/N1components/Clearfix/Clearfix.jsx";
// import Button from "components/CustomButtons/Button.jsx";

import Giannis from "../../../assets/img/IoannisFacev2019_04_20.jpg";
// import christian from "assets/img/faces/christian.jpg";
// import oluEletu from "assets/img/examples/olu-eletu.jpg";
// import clemOnojeghuo from "assets/img/examples/clem-onojeghuo.jpg";
// import cynthiaDelRio from "assets/img/examples/cynthia-del-rio.jpg";
// import mariyaGeorgieva from "assets/img/examples/mariya-georgieva.jpg";
// import clemOnojegaw from "assets/img/examples/clem-onojegaw.jpg";
// import darrenColeshill from "assets/img/examples/darren-coleshill.jpg";
// import avatar from "assets/img/faces/avatar.jpg";
// import marc from "assets/img/faces/marc.jpg";
// import kendall from "assets/img/faces/kendall.jpg";
// import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";

import profilePageStyle from "../../../assets/jss/material-kit-pro-react/views/profilePageStyle.jsx";

import CustomFooter from "../../../components/N1components/CustomFooter";
import MainImage from "../../../assets/img/lefkadacity.jpg";

class ProfilePage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <div>
        <Header
          color="white"
          brand="Ιωάννης Μαλακάσης"
          links={
            <HeaderLinks
              dropdownHoverColor="info"
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              onLogout={this.props.onLogout}
            />
          }
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          image={"url(" + MainImage + ")"} //{require(   "assets/img/examples/lefkadacity.jpg")}
          //filter="dark"
          className={classes.parallax}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={Giannis} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h2>Ιωάννης Μαλακάσης</h2>
                    <h3>Υποψήφιος Περιφερειακός Σύμβουλος</h3>
                    <p />
                    <br />
                    {/* <Tooltip
                      id="tooltip-top"
                      title="Ακολουθησε με στο Facebook"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        justIcon
                        simple
                        color="facebook"
                        className={classes.margin5}
                        href="https://www.facebook.com/ioannis.malakasis"
                      >
                        <i className={classes.socials + " fab fa-facebook"} />
                      </Button>
                    </Tooltip> */}
                  </div>
                </div>
                {/* <div className={classes.follow}>
                  <Tooltip
                    id="tooltip-top"
                    title="Follow this user"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      justIcon
                      round
                      color="primary"
                      className={classes.followButton}
                    >
                      <Add className={classes.followIcon} />
                    </Button>
                  </Tooltip>
                </div> */}
              </GridItem>
            </GridContainer>

            <h4>
              Ονομάζομαι Ιωάννης Μαλακάσης, γεννήθηκα, μεγάλωσα και ζω στην
              Λευκάδα όπου παντρεύτηκα και απέκτησα δυο παιδιά. Ως πολιτικός
              μηχανικός ασχολήθηκα με ιδιωτικά και δημόσια έργα, επί σειρά ετών.
            </h4>
            <br />
            <h4>
              Η πολυετή τεχνική μου εμπειρία και ο ρόλος μου ως θεματικός
              Αντιπεριφερειάρχης, την περίοδο της τελευταίας μου πολιτικής
              ενασχόλησης, συνέβαλαν στην ένταξη και υπογραφή συμβάσεων
              σημαντικών έργων, όπως ο Δίαυλος της Λευκάδας και το τουριστικό
              αγκυροβόλιο της Βασιλικής συνολικού προϋπολογισμού άνω των 40
              εκατομμυρίων ευρώ.
            </h4>
            <br />
            <h4>
              Ασχολούμαι με τα κοινά πάνω από 30 χρόνια έχοντας υπηρετήσει
              διάφορες βαθμίδες της πολιτικής ιεραρχίας: Αντιδήμαρχος, Προέδρος
              Συμβουλίου Περιοχής, Προέδρος Λιμενικού Ταμείου, Προέδρος
              Νομαρχιακού Συμβουλίου, Περιφερειακός Σύμβουλος Π.Ι.Ν, Θεματικός
              Αντιπεριφερειάρχης, υπεύθυνος Αναπτυξιακού Προγραμματισμού,
              Περιβάλλοντος, Υποδομών της Περιφέρειας.
            </h4>
            <p />
            <br />
            <h4>
              Θεωρώ ότι είναι χρέος όλων μας να προσφέρουμε, ότι μπορούμε
              ανιδιοτελώς, ιδιαίτερα σε αυτές τις δύσκολες στιγμές που βιώνουμε.
              Έτσι για άλλη μια φορά θέτω τις γνώσεις, το ήθος και την εμπειρία
              μου στην υπηρεσία του τόπου και των συμπολιτών μου.
            </h4>
            <br />
            <p />
            <Clearfix />
          </div>
        </div>
        <Footer content={<CustomFooter classes={classes} />} />
      </div>
    );
  }
}

export default withStyles(profilePageStyle)(ProfilePage);
