import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../../components/common/table";

class CandidatesTable extends Component {
  columns = [
    {
      path: "lastName",
      label: "Επίθετο",
      content: candidate => (
        <Link to={`/ekloges/candidates/${candidate.id}`}>
          {candidate.lastName}
        </Link>
      )
    },
    { path: "firstName", label: "Όνομα" },
    { path: "email", label: "Email" },
    { path: "telephone", label: "Τηλέφωνο" },
    { path: "job", label: "Επάγγελμα" },
    { path: "updatedOn", label: "Ενημερώθηκε", type: "Date" },
    {
      key: "edit",
      content: candidate => (
        <button
          onClick={() => this.props.onUpdate(candidate)}
          className="btn btn-info btn-sm"
        >
          Edit
        </button>
      )
    },
    {
      key: "delete",
      content: candidate => (
        <button
          onClick={() => this.props.onDelete(candidate)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      )
    }
  ];

  render() {
    const { candidates, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={candidates}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default CandidatesTable;
