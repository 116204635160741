import Alternative from "views/pages/dashboards/Alternative.jsx";
import Buttons from "views/pages/components/Buttons.jsx";
import Calendar from "views/pages/Calendar.jsx";
import Cards from "views/pages/components/Cards.jsx";
import Charts from "views/pages/Charts.jsx";
import Components from "views/pages/forms/Components.jsx";
import Dashboard from "views/pages/dashboards/Dashboard.jsx";
import Elements from "views/pages/forms/Elements.jsx";
import Google from "views/pages/maps/Google.jsx";
import Grid from "views/pages/components/Grid.jsx";
import Icons from "views/pages/components/Icons.jsx";
import Lock from "views/pages/examples/Lock.jsx";
import Login from "views/pages/examples/Login.jsx";
import Notifications from "views/pages/components/Notifications.jsx";
import Pricing from "views/pages/examples/Pricing.jsx";
import Profile from "views/pages/examples/Profile.jsx";
import ReactBSTables from "views/pages/tables/ReactBSTables.jsx";
import Register from "views/pages/examples/Register.jsx";
import Sortable from "views/pages/tables/Sortable.jsx";
import Tables from "views/pages/tables/Tables.jsx";
import Timeline from "views/pages/examples/Timeline.jsx";
import Typography from "views/pages/components/Typography.jsx";
import Validation from "views/pages/forms/Validation.jsx";
import Vector from "views/pages/maps/Vector.jsx";
import Widgets from "views/pages/Widgets.jsx";

///EKL Pages
import Elections from "./views/pages/elections/elections";
import ElectionsForm from "./views/pages/elections/electionsForm";
import Candidates from "./views/pages/candidates/candidates";
import CandidatesForm from "./views/pages/candidates/candidatesForm";
import Parties from "./views/pages/parties/parties";
import PartiesForm from "./views/pages/parties/partiesForm";
import Results from "./views/pages/results/results";
import ResultsForm from "./views/pages/results/resultsForm";
import Vstations from "./views/pages/vstations/vstations";
import VstationsForm from "./views/pages/vstations/vstationsForm";
import Users from "./views/pages/users/users";
import Main from "./views/pages/main/main";
import ElectionDashboard from "./views/pages/electionDashboard/electionDashboard";
import Enotites from "./views/pages/enotites/enotites";
import MassiveUpload from "./views/pages/massiveUpload/massiveUpload";

const routes = [
  {
    path: "",
    name: "Main",
    component: Main,
    layout: "/ekloges",
    invisble: true,
    authLevel: 1
  },

  //The not visible routes
  {
    path: "/elections/:id",
    name: "ElectionsForm",
    component: ElectionsForm,
    layout: "/ekloges",
    invisble: true,
    authLevel: 3
  },
  {
    path: "/candidates/:id",
    name: "CandidatesForm",
    component: CandidatesForm,
    layout: "/ekloges",
    invisble: true,
    authLevel: 3
  },
  {
    path: "/results/:idElection/:idVstation",
    name: "ResultsForm",
    component: ResultsForm,
    layout: "/ekloges",
    invisble: true,
    authLevel: 2
  },
  {
    path: "/parties/:id",
    name: "PartiesForm",
    component: PartiesForm,
    layout: "/ekloges",
    invisble: true,
    authLevel: 3
  },
  {
    path: "/vstations/:id",
    name: "VstationsForm",
    component: VstationsForm,
    layout: "/ekloges",
    invisble: true,
    authLevel: 3
  },
  //From here the noraml route start
  {
    collapse: true,
    authLevel: 1,
    name: "Περιφερειακές",
    icon: "ni ni-atom text-info",
    state: "periferiakesCollapse",
    views: [
      {
        path: "/electionDashboard",
        name: "Συγκεντρωτικά",
        component: ElectionDashboard,
        layout: "/ekloges",
        authLevel: 1
      },

      {
        path: "/enotites",
        name: "Ενότητες",
        component: Enotites,
        layout: "/ekloges",
        authLevel: 1
      },
      {
        path: "/results",
        name: "Καταχώρηση",
        component: Results,
        layout: "/ekloges",
        authLevel: 2
      }
    ]
  },
  {
    collapse: true,
    authLevel: 3,
    name: "Διαχείριση",
    icon: "ni ni-archive-2 text-green",
    state: "diaxirisiCollapse",
    views: [
      {
        path: "/elections",
        name: "Εκλογές",
        component: Elections,
        layout: "/ekloges",
        authLevel: 3
      },

      {
        path: "/candidates",
        name: "Υποψήφιοι",
        component: Candidates,
        layout: "/ekloges",
        authLevel: 3
      },
      {
        path: "/parties",
        name: "Κόμματα",
        component: Parties,
        layout: "/ekloges",
        authLevel: 3
      },
      {
        path: "/vstations",
        name: "Εκλογικά Τμήματα",
        component: Vstations,
        layout: "/ekloges",
        authLevel: 3
      },
      {
        path: "/results",
        name: "Αποτελέσματα",
        component: Results,
        layout: "/ekloges",
        authLevel: 3
      }
    ]
  },
  {
    path: "/users",
    name: "Προφίλ",
    icon: "ni ni-badge text-info",
    component: Users,
    layout: "/ekloges",
    authLevel: 1
  },
  {
    authLevel: 4,
    collapse: true,
    name: "Admin",
    icon: "ni ni-calendar-grid-58 text-red",
    state: "adminCollapse",
    views: [
      {
        path: "/massiveUpload",
        name: "Μαζικη Ενημερωση",
        component: MassiveUpload,
        layout: "/ekloges",
        authLevel: 4
      }
    ]
  }
  // {
  //   collapse: true,
  //   name: "Dashboards",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   views: [
  //     {
  //       path: "/elections",
  //       name: "Εκλογές",
  //       component: Elections,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },

  //     {
  //       path: "/dashboard",
  //       name: "Dashboard",
  //       component: Dashboard,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/alternative-dashboard",
  //       name: "Alternative",
  //       component: Alternative,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Examples",
  //   icon: "ni ni-ungroup text-orange",
  //   state: "examplesCollapse",
  //   views: [
  //     {
  //       path: "/pricing",
  //       name: "Pricing",
  //       component: Pricing,
  //       layout: "/auth",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/login",
  //       name: "Login",
  //       component: Login,
  //       layout: "/auth",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/register",
  //       name: "Register",
  //       component: Register,
  //       layout: "/auth",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/lock",
  //       name: "Lock",
  //       component: Lock,
  //       layout: "/auth",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       component: Timeline,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       component: Profile,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     }
  //   ]
  // }
  // {
  //   collapse: true,
  //   name: "Components",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       component: Buttons,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/cards",
  //       name: "Cards",
  //       component: Cards,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/grid",
  //       name: "Grid",
  //       component: Grid,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       component: Notifications,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       component: Icons,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       component: Typography,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       collapse: true,
  //       name: "Multi Level",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "#pablo",
  //           name: "Third level menu",
  //           component: () => {},
  //           layout: "/",
  //           authLevel: 1
  //         },
  //         {
  //           path: "#pablo",
  //           name: "Just another link",
  //           component: () => {},
  //           layout: "/",
  //           authLevel: 1
  //         },
  //         {
  //           path: "#pablo",
  //           name: "One last link",
  //           component: () => {},
  //           layout: "/",
  //           authLevel: 1
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "ni ni-single-copy-04 text-pink",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/elements",
  //       name: "Elements",
  //       component: Elements,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/components",
  //       name: "Components",
  //       component: Components,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/validation",
  //       name: "Validation",
  //       component: Validation,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "ni ni-align-left-2 text-default",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/tables",
  //       name: "Tables",
  //       component: Tables,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/sortable",
  //       name: "Sortable",
  //       component: Sortable,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/react-bs-table",
  //       name: "React BS Tables",
  //       component: ReactBSTables,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     }
  //   ]
  // },
  // {

  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       component: Google,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       component: Vector,
  //       layout: "/ekloges",
  //       authLevel: 1
  //     }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/ekloges",
  //   authLevel: 1
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/ekloges",
  //   authLevel: 1
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: Calendar,
  //   layout: "/ekloges",
  //   authLevel: 1
  // }
];

export default routes;
