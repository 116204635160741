import React, { Component } from "react";
import Table from "../../../components/common/table";

class ElectionsPartiesTable extends Component {
  columns = [
    // { path: "id", label: "ID" },
    { path: "name", label: "Ονομα" },
    { path: "order", label: "Σειρά" },
    { path: "electionLocation", label: "Τοποθεσία" },
    {
      key: "edit",
      content: electionVstation => (
        <button
          onClick={() => this.props.onUpdate(electionVstation)}
          className="btn btn-info btn-sm"
        >
          Edit
        </button>
      )
    },
    {
      key: "delete",
      content: electionVstation => (
        <button
          onClick={() => this.props.onDelete(electionVstation)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      )
    }
  ];

  render() {
    const { electionVstations, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={electionVstations}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ElectionsPartiesTable;
