export const electionTypes = [
  { id: "ELTY001", name: "Ευρωεκλογές" },
  { id: "ELTY002", name: "Βουλευτικές" },
  { id: "ELTY003", name: "Περιφερειακές" },
  { id: "ELTY004", name: "Δημοτικές" }
];

export function electionTypesGetAll() {
  return electionTypes.filter(p => p);
}

export const locationLayers = [
  { id: "LOLA01", name: "ΠΕΡΙΦΕΡΕΙΑ", nameShort: "Περ.", level: 1 },
  {
    id: "LOLA02",
    name: "ΠΕΡΙΦΕΡΕΙΑΚΗ ΕΝΟΤΗΤΑ",
    nameShort: "Περ. Εν.",
    level: 2
  },
  { id: "LOLA03", name: "ΔΗΜΟΣ", nameShort: "Δημ.", level: 3 },
  { id: "LOLA04", name: "ΚΟΙΝΟΤΗΤΑ", nameShort: "Κιν.", level: 4 }
];

export function locationLayersGetAll() {
  return locationLayers.filter(p => p);
}

export const vStatus = [
  { id: "VOSTO01", name: "Δεν έχει ξεκινήσει" },
  { id: "VOSTO02", name: "Ξεκίνησε" },
  { id: "VOSTO03", name: "Κάλπες Έκλεισαν" },
  { id: "VOSTO04", name: "Μερικό Αποτέλεσμα" },
  { id: "VOSTO05", name: "Τελικό Αποτέλεσμα" }
];

export const reportTypes = [
  { id: "RETYO01", name: "Ψήφοι Κομμάτων" },
  { id: "RETYO02", name: "Σταυροί Υποψηφίων" }
];

export function reportTypesGetAll() {
  return reportTypes.filter(p => p);
}

export const resultType = [
  { id: "RETY001", name: "Τμήματος" },
  { id: "RETYO02", name: "Συνολικό" }
];

export function resultTypeGetAll() {
  return resultType.filter(p => p);
}

export function vStatusGetAll() {
  return vStatus.filter(p => p);
}

export function vStatusGetOne(vStatus) {
  return vStatus.find(p => p.id === vStatus.id);
}

export function electionsAreasGetAll() {
  return electionLocations.filter(p => p);
}

export function electionsAreasGetPerLayer(laeyrId) {
  return electionLocations.filter(p => p.layerId === laeyrId);
}

export const electionLocations = [
  { id: "PEIA01", name: "ΑΝ. ΜΑΚΕΔΟΝΙΑΣ & ΘΡΑΚΗΣ", pId: "", layerId: "LOLA01" },
  { id: "PEIA02", name: "ΚΕΝΤΡΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ", pId: "", layerId: "LOLA01" },
  { id: "PEIA03", name: "ΔΥΤΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ", pId: "", layerId: "LOLA01" },
  { id: "PEIA04", name: "ΗΠΕΙΡΟΥ", pId: "", layerId: "LOLA01" },
  { id: "PEIA05", name: "ΘΕΣΣΑΛΙΑΣ", pId: "", layerId: "LOLA01" },
  { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", pId: "", layerId: "LOLA01" },
  { id: "PEIA07", name: "ΔΥΤΙΚΗΣ ΕΛΛΑΔΑΣ", pId: "", layerId: "LOLA01" },
  { id: "PEIA08", name: "ΣΤΕΡΕΑΣ ΕΛΛΑΔΑΣ", pId: "", layerId: "LOLA01" },
  { id: "PEIA09", name: "ΑΤΤΙΚΗΣ", pId: "", layerId: "LOLA01" },
  { id: "PEIA10", name: "ΠΕΛΟΠΟΝΝΗΣΟΥ", pId: "", layerId: "LOLA01" },
  { id: "PEIA11", name: "ΒΟΡΕΙΟΥ ΑΙΓΑΙΟΥ", pId: "", layerId: "LOLA01" },
  { id: "PEIA12", name: "ΝΟΤΙΟΥ ΑΙΓΑΙΟΥ", pId: "", layerId: "LOLA01" },
  { id: "PEIA13", name: "ΚΡΗΤΗΣ", pId: "", layerId: "LOLA01" },
  { id: "PEEN01", name: "ΔΡΑΜΑΣ", pId: "PEIA01", layerId: "LOLA02" },
  { id: "PEEN02", name: "ΕΒΡΟΥ", pId: "PEIA01", layerId: "LOLA02" },
  { id: "PEEN03", name: "ΘΑΣΟΥ", pId: "PEIA01", layerId: "LOLA02" },
  {
    id: "PEEN04",
    name: "ΚΑΒΑΛΑΣ",
    pId: "PEIA01",
    layerId: "LOLA02"
  },
  {
    id: "PEEN05",
    name: "ΞΑΝΘΗΣ",
    pId: "PEIA01",
    layerId: "LOLA02"
  },
  {
    id: "PEEN06",
    name: "ΡΟΔΟΠΗΣ",
    pId: "PEIA01",
    layerId: "LOLA02"
  },
  {
    id: "PEEN07",
    name: "ΗΜΑΘΙΑΣ",
    pId: "PEIA02",
    layerId: "LOLA02"
  },
  {
    id: "PEEN08",
    name: "ΘΕΣΣΑΛΟΝΙΚΗΣ",
    pId: "PEIA02",
    layerId: "LOLA02"
  },
  {
    id: "PEEN09",
    name: "ΚΙΛΚΙΣ",
    pId: "PEIA02",
    layerId: "LOLA02"
  },
  {
    id: "PEEN10",
    name: "ΠΕΛΛΑΣ",
    pId: "PEIA02",
    layerId: "LOLA02"
  },
  {
    id: "PEEN11",
    name: "ΠΙΕΡΙΑΣ",
    pId: "PEIA02",
    layerId: "LOLA02"
  },
  {
    id: "PEEN12",
    name: "ΣΕΡΡΩΝ",
    pId: "PEIA02",
    layerId: "LOLA02"
  },
  {
    id: "PEEN13",
    name: "ΧΑΛΚΙΔΙΚΗΣ",
    pId: "PEIA02",
    layerId: "LOLA02"
  },
  {
    id: "PEEN14",
    name: "ΓΡΕΒΕΝΩΝ",
    pId: "PEIA03",
    layerId: "LOLA02"
  },
  {
    id: "PEEN15",
    name: "ΚΑΣΤΟΡΙΑΣ",
    pId: "PEIA03",
    layerId: "LOLA02"
  },
  {
    id: "PEEN16",
    name: "ΚΟΖΑΝΗΣ",
    pId: "PEIA03",
    layerId: "LOLA02"
  },
  {
    id: "PEEN17",
    name: "ΦΛΩΡΙΝΑΣ",
    pId: "PEIA03",
    layerId: "LOLA02"
  },
  {
    id: "PEEN18",
    name: "ΑΡΤΑΣ",
    pId: "PEIA04",
    layerId: "LOLA02"
  },
  {
    id: "PEEN19",
    name: "ΘΕΣΠΡΩΤΙΑΣ",
    pId: "PEIA04",
    layerId: "LOLA02"
  },
  {
    id: "PEEN20",
    name: "ΙΩΑΝΝΙΝΩΝ",
    pId: "PEIA04",
    layerId: "LOLA02"
  },
  {
    id: "PEEN21",
    name: "ΠΡΕΒΕΖΑΣ",
    pId: "PEIA04",
    layerId: "LOLA02"
  },
  {
    id: "PEEN22",
    name: "ΚΑΡΔΙΤΣΑΣ",
    pId: "PEIA05",
    layerId: "LOLA02"
  },
  {
    id: "PEEN23",
    name: "ΛΑΡΙΣΑΣ",
    pId: "PEIA05",
    layerId: "LOLA02"
  },
  {
    id: "PEEN24",
    name: "ΜΑΓΝΗΣΙΑΣ",
    pId: "PEIA05",
    layerId: "LOLA02"
  },
  {
    id: "PEEN25",
    name: "ΣΠΟΡΑΔΩΝ",
    pId: "PEIA05",
    layerId: "LOLA02"
  },
  {
    id: "PEEN26",
    name: "ΤΡΙΚΑΛΩΝ",
    pId: "PEIA05",
    layerId: "LOLA02"
  },
  {
    id: "PEEN27",
    name: "ΖΑΚΥΝΘΟΥ",
    pId: "PEIA06",
    layerId: "LOLA02"
  },
  {
    id: "PEEN28",
    name: "ΙΘΑΚΗΣ",
    pId: "PEIA06",
    layerId: "LOLA02"
  },
  {
    id: "PEEN29",
    name: "ΚΕΡΚΥΡΑΣ",
    pId: "PEIA06",
    layerId: "LOLA02"
  },
  {
    id: "PEEN30",
    name: "ΚΕΦΑΛΛΗΝΙΑΣ",
    pId: "PEIA06",
    layerId: "LOLA02"
  },
  {
    id: "PEEN31",
    name: "ΛΕΥΚΑΔΑΣ",
    pId: "PEIA06",
    layerId: "LOLA02"
  },
  {
    id: "PEEN32",
    name: "ΑΧΑΪΑΣ",
    pId: "PEIA07",
    layerId: "LOLA02"
  },
  {
    id: "PEEN33",
    name: "ΗΛΕΙΑΣ",
    pId: "PEIA07",
    layerId: "LOLA02"
  },
  {
    id: "PEEN34",
    name: "ΑΙΤΩΛΟΑΚΑΡΝΑΝΙΑΣ",
    pId: "PEIA07",
    layerId: "LOLA02"
  },
  {
    id: "PEEN35",
    name: "ΒΟΙΩΤΙΑΣ",
    pId: "PEIA08",
    layerId: "LOLA02"
  },
  {
    id: "PEEN36",
    name: "ΕΥΒΟΙΑΣ",
    pId: "PEIA08",
    layerId: "LOLA02"
  },
  {
    id: "PEEN37",
    name: "ΕΥΡΥΤΑΝΙΑΣ",
    pId: "PEIA08",
    layerId: "LOLA02"
  },
  {
    id: "PEEN38",
    name: "ΦΘΙΩΤΙΔΑΣ",
    pId: "PEIA08",
    layerId: "LOLA02"
  },
  {
    id: "PEEN39",
    name: "ΦΩΚΙΔΑΣ",
    pId: "PEIA08",
    layerId: "LOLA02"
  },
  {
    id: "PEEN40",
    name: "ΑΝΑΤΟΛΙΚΗΣ ΑΤΤΙΚΗΣ",
    pId: "PEIA09",
    layerId: "LOLA02"
  },
  {
    id: "PEEN41",
    name: "ΒΟΡΕΙΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ",
    pId: "PEIA09",
    layerId: "LOLA02"
  },
  {
    id: "PEEN42",
    name: "ΔΥΤΙΚΗΣ ΑΤΤΙΚΗΣ",
    pId: "PEIA09",
    layerId: "LOLA02"
  },
  {
    id: "PEEN43",
    name: "ΔΥΤΙΚΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ",
    pId: "PEIA09",
    layerId: "LOLA02"
  },
  {
    id: "PEEN44",
    name: "ΚΕΝΤΡΙΚΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ",
    pId: "PEIA09",
    layerId: "LOLA02"
  },
  {
    id: "PEEN45",
    name: "ΝΗΣΩΝ",
    pId: "PEIA09",
    layerId: "LOLA02"
  },
  {
    id: "PEEN46",
    name: "ΝΟΤΙΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ",
    pId: "PEIA09",
    layerId: "LOLA02"
  },
  {
    id: "PEEN47",
    name: "ΠΕΙΡΑΙΩΣ",
    pId: "PEIA09",
    layerId: "LOLA02"
  },
  {
    id: "PEEN48",
    name: "ΑΡΓΟΛΙΔΑΣ",
    pId: "PEIA10",
    layerId: "LOLA02"
  },
  {
    id: "PEEN49",
    name: "ΑΡΚΑΔΙΑΣ",
    pId: "PEIA10",
    layerId: "LOLA02"
  },
  {
    id: "PEEN50",
    name: "ΚΟΡΙΝΘΙΑΣ",
    pId: "PEIA10",
    layerId: "LOLA02"
  },
  {
    id: "PEEN51",
    name: "ΛΑΚΩΝΙΑΣ",
    pId: "PEIA10",
    layerId: "LOLA02"
  },
  {
    id: "PEEN52",
    name: "ΜΕΣΣΗΝΙΑΣ",
    pId: "PEIA10",
    layerId: "LOLA02"
  },
  {
    id: "PEEN53",
    name: "ΙΚΑΡΙΑΣ",
    pId: "PEIA11",
    layerId: "LOLA02"
  },
  {
    id: "PEEN54",
    name: "ΛΕΣΒΟΥ",
    pId: "PEIA11",
    layerId: "LOLA02"
  },
  {
    id: "PEEN55",
    name: "ΛΗΜΝΟΥ",
    pId: "PEIA11",
    layerId: "LOLA02"
  },
  {
    id: "PEEN56",
    name: "ΣΑΜΟΥ",
    pId: "PEIA11",
    layerId: "LOLA02"
  },
  {
    id: "PEEN57",
    name: "ΧΙΟΥ",
    pId: "PEIA11",
    layerId: "LOLA02"
  },
  {
    id: "PEEN58",
    name: "ΑΝΔΡΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN59",
    name: "ΘΗΡΑΣ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN60",
    name: "ΚΑΛΥΜΝΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN61",
    name: "ΚΑΡΠΑΘΟΥ-ΗΡΩΙΚΗΣ ΝΗΣΟΥ ΚΑΣΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN62",
    name: "ΚΕΑΣ-ΚΥΘΝΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN63",
    name: "ΚΩ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN64",
    name: "ΜΗΛΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN65",
    name: "ΜΥΚΟΝΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN66",
    name: "ΝΑΞΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN67",
    name: "ΠΑΡΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN68",
    name: "ΡΟΔΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN69",
    name: "ΣΥΡΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN70",
    name: "ΤΗΝΟΥ",
    pId: "PEIA12",
    layerId: "LOLA02"
  },
  {
    id: "PEEN71",
    name: "ΗΡΑΚΛΕΙΟΥ",
    pId: "PEIA13",
    layerId: "LOLA02"
  },
  {
    id: "PEEN72",
    name: "ΛΑΣΙΘΙΟΥ",
    pId: "PEIA13",
    layerId: "LOLA02"
  },
  {
    id: "PEEN73",
    name: "ΡΕΘΥΜΝΗΣ",
    pId: "PEIA13",
    layerId: "LOLA02"
  },
  {
    id: "PEEN74",
    name: "ΧΑΝΙΩΝ",
    pId: "PEIA13",
    layerId: "LOLA02"
  },
  {
    id: "DIM001",
    name: "ΔΟΞΑΤΟΥ",
    pId: "PEEN01",
    layerId: "LOLA03"
  },
  {
    id: "DIM002",
    name: "ΔΡΑΜΑΣ",
    pId: "PEEN01",
    layerId: "LOLA03"
  },
  {
    id: "DIM003",
    name: "ΚΑΤΩ ΝΕΥΡΟΚΟΠΙΟΥ",
    pId: "PEEN01",
    layerId: "LOLA03"
  },
  {
    id: "DIM004",
    name: "ΠΑΡΑΝΕΣΤΙΟΥ",
    pId: "PEEN01",
    layerId: "LOLA03"
  },
  {
    id: "DIM005",
    name: "ΠΡΟΣΟΤΣΑΝΗΣ",
    pId: "PEEN01",
    layerId: "LOLA03"
  },
  {
    id: "DIM006",
    name: "ΑΛΕΞΑΝΔΡΟΥΠΟΛΗΣ",
    pId: "PEEN02",
    layerId: "LOLA03"
  },
  {
    id: "DIM007",
    name: "ΔΙΔΥΜΟΤΕΙΧΟΥ",
    pId: "PEEN02",
    layerId: "LOLA03"
  },
  {
    id: "DIM008",
    name: "ΟΡΕΣΤΙΑΔΑΣ",
    pId: "PEEN02",
    layerId: "LOLA03"
  },
  {
    id: "DIM009",
    name: "ΣΑΜΟΘΡΑΚΗΣ",
    pId: "PEEN02",
    layerId: "LOLA03"
  },
  {
    id: "DIM010",
    name: "ΣΟΥΦΛΙΟΥ",
    pId: "PEEN02",
    layerId: "LOLA03"
  },
  {
    id: "DIM011",
    name: "ΘΑΣΟΥ",
    pId: "PEEN03",
    layerId: "LOLA03"
  },
  {
    id: "DIM012",
    name: "ΚΑΒΑΛΑΣ",
    pId: "PEEN04",
    layerId: "LOLA03"
  },
  {
    id: "DIM013",
    name: "ΝΕΣΤΟΥ",
    pId: "PEEN04",
    layerId: "LOLA03"
  },
  {
    id: "DIM014",
    name: "ΠΑΓΓΑΙΟΥ",
    pId: "PEEN04",
    layerId: "LOLA03"
  },
  {
    id: "DIM015",
    name: "ΑΒΔΗΡΩΝ",
    pId: "PEEN05",
    layerId: "LOLA03"
  },
  {
    id: "DIM016",
    name: "ΜΥΚΗΣ",
    pId: "PEEN05",
    layerId: "LOLA03"
  },
  {
    id: "DIM017",
    name: "ΞΑΝΘΗΣ",
    pId: "PEEN05",
    layerId: "LOLA03"
  },
  {
    id: "DIM018",
    name: "ΤΟΠΕΙΡΟΥ",
    pId: "PEEN05",
    layerId: "LOLA03"
  },
  {
    id: "DIM019",
    name: "ΑΡΡΙΑΝΩΝ",
    pId: "PEEN06",
    layerId: "LOLA03"
  },
  {
    id: "DIM020",
    name: "ΙΑΣΜΟΥ",
    pId: "PEEN06",
    layerId: "LOLA03"
  },
  {
    id: "DIM021",
    name: "ΚΟΜΟΤΗΝΗΣ",
    pId: "PEEN06",
    layerId: "LOLA03"
  },
  {
    id: "DIM022",
    name: "ΜΑΡΩΝΕΙΑΣ – ΣΑΠΩΝ",
    pId: "PEEN06",
    layerId: "LOLA03"
  },
  {
    id: "DIM023",
    name: "ΑΛΕΞΑΝΔΡΕΙΑΣ",
    pId: "PEEN07",
    layerId: "LOLA03"
  },
  {
    id: "DIM024",
    name: "ΒΕΡΟΙΑΣ",
    pId: "PEEN07",
    layerId: "LOLA03"
  },
  {
    id: "DIM025",
    name: "ΝΑΟΥΣΑΣ",
    pId: "PEEN07",
    layerId: "LOLA03"
  },
  {
    id: "DIM026",
    name: "ΑΜΠΕΛΟΚΗΠΩΝ – ΜΕΝΕΜΕΝΗΣ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM027",
    name: "ΒΟΛΒΗΣ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM028",
    name: "ΔΕΛΤΑ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM029",
    name: "ΘΕΡΜΑΪΚΟΥ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM030",
    name: "ΘΕΡΜΗΣ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM031",
    name: "ΘΕΣΣΑΛΟΝΙΚΗΣ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM032",
    name: "ΚΑΛΑΜΑΡΙΑΣ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM033",
    name: "ΚΟΡΔΕΛΙΟΥ – ΕΥΟΣΜΟΥ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM034",
    name: "ΛΑΓΚΑΔΑ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM035",
    name: "ΝΕΑΠΟΛΗΣ – ΣΥΚΕΩΝ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM036",
    name: "ΠΑΥΛΟΥ ΜΕΛΑ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM037",
    name: "ΠΥΛΑΙΑΣ – ΧΟΡΤΙΑΤΗ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM038",
    name: "ΧΑΛΚΗΔΟΝΟΣ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM039",
    name: "ΩΡΑΙΟΚΑΣΤΡΟΥ",
    pId: "PEEN08",
    layerId: "LOLA03"
  },
  {
    id: "DIM040",
    name: "ΚΙΛΚΙΣ",
    pId: "PEEN09",
    layerId: "LOLA03"
  },
  {
    id: "DIM041",
    name: "ΠΑΙΟΝΙΑΣ",
    pId: "PEEN09",
    layerId: "LOLA03"
  },
  {
    id: "DIM042",
    name: "ΑΛΜΩΠΙΑΣ",
    pId: "PEEN10",
    layerId: "LOLA03"
  },
  {
    id: "DIM043",
    name: "ΕΔΕΣΣΑΣ",
    pId: "PEEN10",
    layerId: "LOLA03"
  },
  {
    id: "DIM044",
    name: "ΠΕΛΛΑΣ",
    pId: "PEEN10",
    layerId: "LOLA03"
  },
  {
    id: "DIM045",
    name: "ΣΚΥΔΡΑΣ",
    pId: "PEEN10",
    layerId: "LOLA03"
  },
  {
    id: "DIM046",
    name: "ΔΙΟΥ – ΟΛΥΜΠΟΥ",
    pId: "PEEN11",
    layerId: "LOLA03"
  },
  {
    id: "DIM047",
    name: "ΚΑΤΕΡΙΝΗΣ",
    pId: "PEEN11",
    layerId: "LOLA03"
  },
  {
    id: "DIM048",
    name: "ΠΥΔΝΑΣ – ΚΟΛΙΝΔΡΟΥ",
    pId: "PEEN11",
    layerId: "LOLA03"
  },
  {
    id: "DIM049",
    name: "ΑΜΦΙΠΟΛΗΣ",
    pId: "PEEN12",
    layerId: "LOLA03"
  },
  {
    id: "DIM050",
    name: "ΒΙΣΑΛΤΙΑΣ",
    pId: "PEEN12",
    layerId: "LOLA03"
  },
  {
    id: "DIM051",
    name: "ΕΜΜΑΝΟΥΗΛ ΠΑΠΠΑ",
    pId: "PEEN12",
    layerId: "LOLA03"
  },
  {
    id: "DIM052",
    name: "ΗΡΑΚΛΕΙΑΣ",
    pId: "PEEN12",
    layerId: "LOLA03"
  },
  {
    id: "DIM053",
    name: "ΝΕΑΣ ΖΙΧΝΗΣ",
    pId: "PEEN12",
    layerId: "LOLA03"
  },
  {
    id: "DIM054",
    name: "ΣΕΡΡΩΝ",
    pId: "PEEN12",
    layerId: "LOLA03"
  },
  {
    id: "DIM055",
    name: "ΣΙΝΤΙΚΗΣ",
    pId: "PEEN12",
    layerId: "LOLA03"
  },
  {
    id: "DIM056",
    name: "ΑΡΙΣΤΟΤΕΛΗ",
    pId: "PEEN13",
    layerId: "LOLA03"
  },
  {
    id: "DIM057",
    name: "ΚΑΣΣΑΝΔΡΑΣ",
    pId: "PEEN13",
    layerId: "LOLA03"
  },
  {
    id: "DIM058",
    name: "ΝΕΑΣ ΠΡΟΠΟΝΤΙΔΑΣ",
    pId: "PEEN13",
    layerId: "LOLA03"
  },
  {
    id: "DIM059",
    name: "ΠΟΛΥΓΥΡΟΥ",
    pId: "PEEN13",
    layerId: "LOLA03"
  },
  {
    id: "DIM060",
    name: "ΣΙΘΩΝΙΑΣ",
    pId: "PEEN13",
    layerId: "LOLA03"
  },
  {
    id: "DIM061",
    name: "ΓΡΕΒΕΝΩΝ",
    pId: "PEEN14",
    layerId: "LOLA03"
  },
  {
    id: "DIM062",
    name: "ΔΕΣΚΑΤΗΣ",
    pId: "PEEN14",
    layerId: "LOLA03"
  },
  {
    id: "DIM063",
    name: "ΚΑΣΤΟΡΙΑΣ",
    pId: "PEEN15",
    layerId: "LOLA03"
  },
  {
    id: "DIM064",
    name: "ΝΕΣΤΟΡΙΟΥ",
    pId: "PEEN15",
    layerId: "LOLA03"
  },
  {
    id: "DIM065",
    name: "ΟΡΕΣΤΙΔΟΣ",
    pId: "PEEN15",
    layerId: "LOLA03"
  },
  {
    id: "DIM066",
    name: "ΒΟΪΟΥ",
    pId: "PEEN16",
    layerId: "LOLA03"
  },
  {
    id: "DIM067",
    name: "ΕΟΡΔΑΙΑΣ",
    pId: "PEEN16",
    layerId: "LOLA03"
  },
  {
    id: "DIM068",
    name: "ΚΟΖΑΝΗΣ",
    pId: "PEEN16",
    layerId: "LOLA03"
  },
  {
    id: "DIM069",
    name: "ΣΕΡΒΙΩΝ – ΒΕΛΒΕΝΤΟΥ",
    pId: "PEEN16",
    layerId: "LOLA03"
  },
  {
    id: "DIM070",
    name: "ΑΜΥΝΤΑΙΟΥ",
    pId: "PEEN17",
    layerId: "LOLA03"
  },
  {
    id: "DIM071",
    name: "ΠΡΕΣΠΩΝ",
    pId: "PEEN17",
    layerId: "LOLA03"
  },
  {
    id: "DIM072",
    name: "ΦΛΩΡΙΝΑΣ",
    pId: "PEEN17",
    layerId: "LOLA03"
  },
  {
    id: "DIM073",
    name: "ΑΡΤΑΙΩΝ",
    pId: "PEEN18",
    layerId: "LOLA03"
  },
  {
    id: "DIM074",
    name: "ΓΕΩΡΓΙΟΥ ΚΑΡΑΪΣΚΑΚΗ",
    pId: "PEEN18",
    layerId: "LOLA03"
  },
  {
    id: "DIM075",
    name: "ΚΕΝΤΡΙΚΩΝ ΤΖΟΥΜΕΡΚΩΝ",
    pId: "PEEN18",
    layerId: "LOLA03"
  },
  {
    id: "DIM076",
    name: "ΝΙΚΟΛΑΟΥ ΣΚΟΥΦΑ",
    pId: "PEEN18",
    layerId: "LOLA03"
  },
  {
    id: "DIM077",
    name: "ΗΓΟΥΜΕΝΙΤΣΑΣ",
    pId: "PEEN19",
    layerId: "LOLA03"
  },
  {
    id: "DIM078",
    name: "ΣΟΥΛΙΟΥ",
    pId: "PEEN19",
    layerId: "LOLA03"
  },
  {
    id: "DIM079",
    name: "ΦΙΛΙΑΤΩΝ",
    pId: "PEEN19",
    layerId: "LOLA03"
  },
  {
    id: "DIM080",
    name: "ΒΟΡΕΙΩΝ ΤΖΟΥΜΕΡΚΩΝ",
    pId: "PEEN20",
    layerId: "LOLA03"
  },
  {
    id: "DIM081",
    name: "ΔΩΔΩΝΗΣ",
    pId: "PEEN20",
    layerId: "LOLA03"
  },
  {
    id: "DIM082",
    name: "ΖΑΓΟΡΙΟΥ",
    pId: "PEEN20",
    layerId: "LOLA03"
  },
  {
    id: "DIM083",
    name: "ΖΙΤΣΑΣ",
    pId: "PEEN20",
    layerId: "LOLA03"
  },
  {
    id: "DIM084",
    name: "ΙΩΑΝΝΙΤΩΝ",
    pId: "PEEN20",
    layerId: "LOLA03"
  },
  {
    id: "DIM085",
    name: "ΚΟΝΙΤΣΑΣ",
    pId: "PEEN20",
    layerId: "LOLA03"
  },
  {
    id: "DIM086",
    name: "ΜΕΤΣΟΒΟΥ",
    pId: "PEEN20",
    layerId: "LOLA03"
  },
  {
    id: "DIM087",
    name: "ΠΩΓΩΝΙΟΥ",
    pId: "PEEN20",
    layerId: "LOLA03"
  },
  {
    id: "DIM088",
    name: "ΖΗΡΟΥ",
    pId: "PEEN21",
    layerId: "LOLA03"
  },
  {
    id: "DIM089",
    name: "ΠΑΡΓΑΣ",
    pId: "PEEN21",
    layerId: "LOLA03"
  },
  {
    id: "DIM090",
    name: "ΠΡΕΒΕΖΑΣ",
    pId: "PEEN21",
    layerId: "LOLA03"
  },
  {
    id: "DIM091",
    name: "ΑΡΓΙΘΕΑΣ",
    pId: "PEEN22",
    layerId: "LOLA03"
  },
  {
    id: "DIM092",
    name: "ΚΑΡΔΙΤΣΑΣ",
    pId: "PEEN22",
    layerId: "LOLA03"
  },
  {
    id: "DIM093",
    name: "ΛΙΜΝΗΣ ΠΛΑΣΤΗΡΑ",
    pId: "PEEN22",
    layerId: "LOLA03"
  },
  {
    id: "DIM094",
    name: "ΜΟΥΖΑΚΙΟΥ",
    pId: "PEEN22",
    layerId: "LOLA03"
  },
  {
    id: "DIM095",
    name: "ΠΑΛΑΜΑ",
    pId: "PEEN22",
    layerId: "LOLA03"
  },
  {
    id: "DIM096",
    name: "ΣΟΦΑΔΩΝ",
    pId: "PEEN22",
    layerId: "LOLA03"
  },
  {
    id: "DIM097",
    name: "ΑΓΙΑΣ",
    pId: "PEEN23",
    layerId: "LOLA03"
  },
  {
    id: "DIM098",
    name: "ΕΛΑΣΣΟΝΑΣ",
    pId: "PEEN23",
    layerId: "LOLA03"
  },
  {
    id: "DIM099",
    name: "ΚΙΛΕΛΕΡ",
    pId: "PEEN23",
    layerId: "LOLA03"
  },
  {
    id: "DIM100",
    name: "ΛΑΡΙΣΑΙΩΝ",
    pId: "PEEN23",
    layerId: "LOLA03"
  },
  {
    id: "DIM101",
    name: "ΤΕΜΠΩΝ",
    pId: "PEEN23",
    layerId: "LOLA03"
  },
  {
    id: "DIM102",
    name: "ΤΥΡΝΑΒΟΥ",
    pId: "PEEN23",
    layerId: "LOLA03"
  },
  {
    id: "DIM103",
    name: "ΦΑΡΣΑΛΩΝ",
    pId: "PEEN23",
    layerId: "LOLA03"
  },
  {
    id: "DIM104",
    name: "ΑΛΜΥΡΟΥ",
    pId: "PEEN24",
    layerId: "LOLA03"
  },
  {
    id: "DIM105",
    name: "ΒΟΛΟΥ",
    pId: "PEEN24",
    layerId: "LOLA03"
  },
  {
    id: "DIM106",
    name: "ΖΑΓΟΡΑΣ – ΜΟΥΡΕΣΙΟΥ",
    pId: "PEEN24",
    layerId: "LOLA03"
  },
  {
    id: "DIM107",
    name: "ΝΟΤΙΟΥ ΠΗΛΙΟΥ",
    pId: "PEEN24",
    layerId: "LOLA03"
  },
  {
    id: "DIM108",
    name: "ΡΗΓΑ ΦΕΡΑΙΟΥ",
    pId: "PEEN24",
    layerId: "LOLA03"
  },
  {
    id: "DIM109",
    name: "ΑΛΟΝΝΗΣΟΥ",
    pId: "PEEN25",
    layerId: "LOLA03"
  },
  {
    id: "DIM110",
    name: "ΣΚΙΑΘΟΥ",
    pId: "PEEN25",
    layerId: "LOLA03"
  },
  {
    id: "DIM111",
    name: "ΣΚΟΠΕΛΟΥ",
    pId: "PEEN25",
    layerId: "LOLA03"
  },
  {
    id: "DIM112",
    name: "ΚΑΛΑΜΠΑΚΑΣ",
    pId: "PEEN26",
    layerId: "LOLA03"
  },
  {
    id: "DIM113",
    name: "ΠΥΛΗΣ",
    pId: "PEEN26",
    layerId: "LOLA03"
  },
  {
    id: "DIM114",
    name: "ΤΡΙΚΚΑΙΩΝ",
    pId: "PEEN26",
    layerId: "LOLA03"
  },
  {
    id: "DIM115",
    name: "ΦΑΡΚΑΔΟΝΑΣ",
    pId: "PEEN26",
    layerId: "LOLA03"
  },
  {
    id: "DIM116",
    name: "ΖΑΚΥΝΘΟΥ",
    pId: "PEEN27",
    layerId: "LOLA03"
  },
  {
    id: "DIM117",
    name: "ΙΘΑΚΗΣ",
    pId: "PEEN28",
    layerId: "LOLA03"
  },
  {
    id: "DIM118",
    name: "ΚΕΡΚΥΡΑΣ",
    pId: "PEEN29",
    layerId: "LOLA03"
  },
  {
    id: "DIM119",
    name: "ΠΑΞΩΝ",
    pId: "PEEN29",
    layerId: "LOLA03"
  },
  {
    id: "DIM120",
    name: "ΚΕΦΑΛΟΝΙΑΣ",
    pId: "PEEN30",
    layerId: "LOLA03"
  },
  {
    id: "DIM121",
    name: "ΛΕΥΚΑΔΑΣ",
    pId: "PEEN31",
    layerId: "LOLA03"
  },
  {
    id: "DIM122",
    name: "ΜΕΓΑΝΗΣΙΟΥ",
    pId: "PEEN31",
    layerId: "LOLA03"
  },
  {
    id: "DIM123",
    name: "ΑΙΓΙΑΛΕΙΑΣ",
    pId: "PEEN32",
    layerId: "LOLA03"
  },
  {
    id: "DIM124",
    name: "ΔΥΤΙΚΗΣ ΑΧΑΪΑΣ",
    pId: "PEEN32",
    layerId: "LOLA03"
  },
  {
    id: "DIM125",
    name: "ΕΡΥΜΑΝΘΟΥ",
    pId: "PEEN32",
    layerId: "LOLA03"
  },
  {
    id: "DIM126",
    name: "ΚΑΛΑΒΡΥΤΩΝ",
    pId: "PEEN32",
    layerId: "LOLA03"
  },
  {
    id: "DIM127",
    name: "ΠΑΤΡΕΩΝ",
    pId: "PEEN32",
    layerId: "LOLA03"
  },
  {
    id: "DIM128",
    name: "ΑΝΔΡΑΒΙΔΑΣ – ΚΥΛΛΗΝΗΣ",
    pId: "PEEN33",
    layerId: "LOLA03"
  },
  {
    id: "DIM129",
    name: "ΑΝΔΡΙΤΣΑΙΝΑΣ – ΚΡΕΣΤΕΝΩΝ",
    pId: "PEEN33",
    layerId: "LOLA03"
  },
  {
    id: "DIM130",
    name: "ΑΡΧΑΙΑΣ ΟΛΥΜΠΙΑΣ",
    pId: "PEEN33",
    layerId: "LOLA03"
  },
  {
    id: "DIM131",
    name: "ΖΑΧΑΡΩΣ",
    pId: "PEEN33",
    layerId: "LOLA03"
  },
  {
    id: "DIM132",
    name: "ΗΛΙΔΑΣ",
    pId: "PEEN33",
    layerId: "LOLA03"
  },
  {
    id: "DIM133",
    name: "ΠΗΝΕΙΟΥ",
    pId: "PEEN33",
    layerId: "LOLA03"
  },
  {
    id: "DIM134",
    name: "ΠΥΡΓΟΥ",
    pId: "PEEN33",
    layerId: "LOLA03"
  },
  {
    id: "DIM135",
    name: "ΑΓΡΙΝΙΟΥ",
    pId: "PEEN34",
    layerId: "LOLA03"
  },
  {
    id: "DIM136",
    name: "ΑΚΤΙΟΥ – ΒΟΝΙΤΣΑΣ",
    pId: "PEEN34",
    layerId: "LOLA03"
  },
  {
    id: "DIM137",
    name: "ΑΜΦΙΛΟΧΙΑΣ",
    pId: "PEEN34",
    layerId: "LOLA03"
  },
  {
    id: "DIM138",
    name: "ΘΕΡΜΟΥ",
    pId: "PEEN34",
    layerId: "LOLA03"
  },
  {
    id: "DIM139",
    name: "ΙΕΡΑΣ ΠΟΛΗΣ ΜΕΣΟΛΟΓΓΙΟΥ",
    pId: "PEEN34",
    layerId: "LOLA03"
  },
  {
    id: "DIM140",
    name: "ΝΑΥΠΑΚΤΙΑΣ",
    pId: "PEEN34",
    layerId: "LOLA03"
  },
  {
    id: "DIM141",
    name: "ΞΗΡΟΜΕΡΟΥ",
    pId: "PEEN34",
    layerId: "LOLA03"
  },
  {
    id: "DIM142",
    name: "ΑΛΙΑΡΤΟΥ",
    pId: "PEEN35",
    layerId: "LOLA03"
  },
  {
    id: "DIM143",
    name: "ΔΙΣΤΟΜΟΥ – ΑΡΑΧΟΒΑΣ – ΑΝΤΙΚΥΡΑΣ",
    pId: "PEEN35",
    layerId: "LOLA03"
  },
  {
    id: "DIM144",
    name: "ΘΗΒΑΙΩΝ",
    pId: "PEEN35",
    layerId: "LOLA03"
  },
  {
    id: "DIM145",
    name: "ΛΕΒΑΔΕΩΝ",
    pId: "PEEN35",
    layerId: "LOLA03"
  },
  {
    id: "DIM146",
    name: "ΟΡΧΟΜΕΝΟΥ",
    pId: "PEEN35",
    layerId: "LOLA03"
  },
  {
    id: "DIM147",
    name: "ΤΑΝΑΓΡΑΣ",
    pId: "PEEN35",
    layerId: "LOLA03"
  },
  {
    id: "DIM148",
    name: "ΔΙΡΦΥΩΝ – ΜΕΣΣΑΠΙΩΝ",
    pId: "PEEN36",
    layerId: "LOLA03"
  },
  {
    id: "DIM149",
    name: "ΕΡΕΤΡΙΑΣ",
    pId: "PEEN36",
    layerId: "LOLA03"
  },
  {
    id: "DIM150",
    name: "ΙΣΤΙΑΙΑΣ – ΑΙΔΗΨΟΥ",
    pId: "PEEN36",
    layerId: "LOLA03"
  },
  {
    id: "DIM151",
    name: "ΚΑΡΥΣΤΟΥ",
    pId: "PEEN36",
    layerId: "LOLA03"
  },
  {
    id: "DIM152",
    name: "ΚΥΜΗΣ – ΑΛΙΒΕΡΙΟΥ",
    pId: "PEEN36",
    layerId: "LOLA03"
  },
  {
    id: "DIM153",
    name: "ΜΑΝΤΟΥΔΙΟΥ – ΛΙΜΝΗΣ – ΑΓΙΑΣ ΑΝΝΑΣ",
    pId: "PEEN36",
    layerId: "LOLA03"
  },
  {
    id: "DIM154",
    name: "ΣΚΥΡΟΥ",
    pId: "PEEN36",
    layerId: "LOLA03"
  },
  {
    id: "DIM155",
    name: "ΧΑΛΚΙΔΕΩΝ",
    pId: "PEEN36",
    layerId: "LOLA03"
  },
  {
    id: "DIM156",
    name: "ΑΓΡΑΦΩΝ",
    pId: "PEEN37",
    layerId: "LOLA03"
  },
  {
    id: "DIM157",
    name: "ΚΑΡΠΕΝΗΣΙΟΥ",
    pId: "PEEN37",
    layerId: "LOLA03"
  },
  {
    id: "DIM158",
    name: "ΑΜΦΙΚΛΕΙΑΣ – ΕΛΑΤΕΙΑΣ",
    pId: "PEEN38",
    layerId: "LOLA03"
  },
  {
    id: "DIM159",
    name: "ΔΟΜΟΚΟΥ",
    pId: "PEEN38",
    layerId: "LOLA03"
  },
  {
    id: "DIM160",
    name: "ΛΑΜΙΕΩΝ",
    pId: "PEEN38",
    layerId: "LOLA03"
  },
  {
    id: "DIM161",
    name: "ΛΟΚΡΩΝ",
    pId: "PEEN38",
    layerId: "LOLA03"
  },
  {
    id: "DIM162",
    name: "ΜΑΚΡΑΚΩΜΗΣ",
    pId: "PEEN38",
    layerId: "LOLA03"
  },
  {
    id: "DIM163",
    name: "ΜΩΛΟΥ – ΑΓΙΟΥ ΚΩΝΣΤΑΝΤΙΝΟΥ",
    pId: "PEEN38",
    layerId: "LOLA03"
  },
  {
    id: "DIM164",
    name: "ΣΤΥΛΙΔΟΣ",
    pId: "PEEN38",
    layerId: "LOLA03"
  },
  {
    id: "DIM165",
    name: "ΔΕΛΦΩΝ",
    pId: "PEEN39",
    layerId: "LOLA03"
  },
  {
    id: "DIM166",
    name: "ΔΩΡΙΔΟΣ",
    pId: "PEEN39",
    layerId: "LOLA03"
  },
  {
    id: "DIM167",
    name: "ΑΧΑΡΝΩΝ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM168",
    name: "ΒΑΡΗΣ – ΒΟΥΛΑΣ – ΒΟΥΛΙΑΓΜΕΝΗΣ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM169",
    name: "ΔΙΟΝΥΣΟΥ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM170",
    name: "ΚΡΩΠΙΑΣ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM171",
    name: "ΛΑΥΡΕΩΤΙΚΗΣ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM172",
    name: "ΜΑΡΑΘΩΝΟΣ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM173",
    name: "ΜΑΡΚΟΠΟΥΛΟΥ ΜΕΣΟΓΑΙΑΣ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM174",
    name: "ΠΑΙΑΝΙΑΣ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM175",
    name: "ΠΑΛΛΗΝΗΣ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM176",
    name: "ΡΑΦΗΝΑΣ – ΠΙΚΕΡΜΙΟΥ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM177",
    name: "ΣΑΡΩΝΙΚΟΥ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM178",
    name: "ΣΠΑΤΩΝ – ΑΡΤΕΜΙΔΟΣ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM179",
    name: "ΩΡΩΠΟΥ",
    pId: "PEEN40",
    layerId: "LOLA03"
  },
  {
    id: "DIM180",
    name: "ΑΓΙΑΣ ΠΑΡΑΣΚΕΥΗΣ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM181",
    name: "ΑΜΑΡΟΥΣΙΟΥ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM182",
    name: "ΒΡΙΛΗΣΣΙΩΝ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM183",
    name: "ΗΡΑΚΛΕΙΟΥ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM184",
    name: "ΚΗΦΙΣΙΑΣ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM185",
    name: "ΛΥΚΟΒΡΥΣΗΣ – ΠΕΥΚΗΣ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM186",
    name: "ΜΕΤΑΜΟΡΦΩΣΕΩΣ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM187",
    name: "ΝΕΑΣ ΙΩΝΙΑΣ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM188",
    name: "ΠΑΠΑΓΟΥ – ΧΟΛΑΡΓΟΥ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM189",
    name: "ΠΕΝΤΕΛΗΣ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM190",
    name: "ΦΙΛΟΘΕΗΣ – ΨΥΧΙΚΟΥ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM191",
    name: "ΧΑΛΑΝΔΡΙΟΥ",
    pId: "PEEN41",
    layerId: "LOLA03"
  },
  {
    id: "DIM192",
    name: "ΑΣΠΡΟΠΥΡΓΟΥ",
    pId: "PEEN42",
    layerId: "LOLA03"
  },
  {
    id: "DIM193",
    name: "ΕΛΕΥΣΙΝΑΣ",
    pId: "PEEN42",
    layerId: "LOLA03"
  },
  {
    id: "DIM194",
    name: "ΜΑΝΔΡΑΣ – ΕΙΔΥΛΛΙΑΣ",
    pId: "PEEN42",
    layerId: "LOLA03"
  },
  {
    id: "DIM195",
    name: "ΜΕΓΑΡΕΩΝ",
    pId: "PEEN42",
    layerId: "LOLA03"
  },
  {
    id: "DIM196",
    name: "ΦΥΛΗΣ",
    pId: "PEEN42",
    layerId: "LOLA03"
  },
  {
    id: "DIM197",
    name: "ΑΓΙΑΣ ΒΑΡΒΑΡΑΣ",
    pId: "PEEN43",
    layerId: "LOLA03"
  },
  {
    id: "DIM198",
    name: "ΑΓΙΩΝ ΑΝΑΡΓΥΡΩΝ – ΚΑΜΑΤΕΡΟΥ",
    pId: "PEEN43",
    layerId: "LOLA03"
  },
  {
    id: "DIM199",
    name: "ΑΙΓΑΛΕΩ",
    pId: "PEEN43",
    layerId: "LOLA03"
  },
  {
    id: "DIM200",
    name: "ΙΛΙΟΥ",
    pId: "PEEN43",
    layerId: "LOLA03"
  },
  {
    id: "DIM201",
    name: "ΠΕΡΙΣΤΕΡΙΟΥ",
    pId: "PEEN43",
    layerId: "LOLA03"
  },
  {
    id: "DIM202",
    name: "ΠΕΤΡΟΥΠΟΛΕΩΣ",
    pId: "PEEN43",
    layerId: "LOLA03"
  },
  {
    id: "DIM203",
    name: "ΧΑΪΔΑΡΙΟΥ",
    pId: "PEEN43",
    layerId: "LOLA03"
  },
  {
    id: "DIM204",
    name: "ΑΘΗΝΑΙΩΝ",
    pId: "PEEN44",
    layerId: "LOLA03"
  },
  {
    id: "DIM205",
    name: "ΒΥΡΩΝΟΣ",
    pId: "PEEN44",
    layerId: "LOLA03"
  },
  {
    id: "DIM206",
    name: "ΓΑΛΑΤΣΙΟΥ",
    pId: "PEEN44",
    layerId: "LOLA03"
  },
  {
    id: "DIM207",
    name: "ΔΑΦΝΗΣ – ΥΜΗΤΤΟΥ",
    pId: "PEEN44",
    layerId: "LOLA03"
  },
  {
    id: "DIM208",
    name: "ΖΩΓΡΑΦΟΥ",
    pId: "PEEN44",
    layerId: "LOLA03"
  },
  {
    id: "DIM209",
    name: "ΗΛΙΟΥΠΟΛΕΩΣ",
    pId: "PEEN44",
    layerId: "LOLA03"
  },
  {
    id: "DIM210",
    name: "ΚΑΙΣΑΡΙΑΝΗΣ",
    pId: "PEEN44",
    layerId: "LOLA03"
  },
  {
    id: "DIM211",
    name: "ΦΙΛΑΔΕΛΦΕΙΑΣ – ΧΑΛΚΗΔΟΝΟΣ",
    pId: "PEEN44",
    layerId: "LOLA03"
  },
  {
    id: "DIM212",
    name: "ΑΓΚΙΣΤΡΙΟΥ",
    pId: "PEEN45",
    layerId: "LOLA03"
  },
  {
    id: "DIM213",
    name: "ΑΙΓΙΝΑΣ",
    pId: "PEEN45",
    layerId: "LOLA03"
  },
  {
    id: "DIM214",
    name: "ΚΥΘΗΡΩΝ",
    pId: "PEEN45",
    layerId: "LOLA03"
  },
  {
    id: "DIM215",
    name: "ΠΟΡΟΥ",
    pId: "PEEN45",
    layerId: "LOLA03"
  },
  {
    id: "DIM216",
    name: "ΣΑΛΑΜΙΝΟΣ",
    pId: "PEEN45",
    layerId: "LOLA03"
  },
  {
    id: "DIM217",
    name: "ΣΠΕΤΣΩΝ",
    pId: "PEEN45",
    layerId: "LOLA03"
  },
  {
    id: "DIM218",
    name: "ΤΡΟΙΖΗΝΙΑΣ",
    pId: "PEEN45",
    layerId: "LOLA03"
  },
  {
    id: "DIM219",
    name: "ΥΔΡΑΣ",
    pId: "PEEN45",
    layerId: "LOLA03"
  },
  {
    id: "DIM220",
    name: "ΑΓΙΟΥ ΔΗΜΗΤΡΙΟΥ",
    pId: "PEEN46",
    layerId: "LOLA03"
  },
  {
    id: "DIM221",
    name: "ΑΛΙΜΟΥ",
    pId: "PEEN46",
    layerId: "LOLA03"
  },
  {
    id: "DIM222",
    name: "ΓΛΥΦΑΔΑΣ",
    pId: "PEEN46",
    layerId: "LOLA03"
  },
  {
    id: "DIM223",
    name: "ΕΛΛΗΝΙΚΟΥ – ΑΡΓΥΡΟΥΠΟΛΗΣ",
    pId: "PEEN46",
    layerId: "LOLA03"
  },
  {
    id: "DIM224",
    name: "ΚΑΛΛΙΘΕΑΣ",
    pId: "PEEN46",
    layerId: "LOLA03"
  },
  {
    id: "DIM225",
    name: "ΜΟΣΧΑΤΟΥ – ΤΑΥΡΟΥ",
    pId: "PEEN46",
    layerId: "LOLA03"
  },
  {
    id: "DIM226",
    name: "ΝΕΑΣ ΣΜΥΡΝΗΣ",
    pId: "PEEN46",
    layerId: "LOLA03"
  },
  {
    id: "DIM227",
    name: "ΠΑΛΑΙΟΥ ΦΑΛΗΡΟΥ",
    pId: "PEEN46",
    layerId: "LOLA03"
  },
  {
    id: "DIM228",
    name: "ΚΕΡΑΤΣΙΝΙΟΥ – ΔΡΑΠΕΤΣΩΝΑΣ",
    pId: "PEEN47",
    layerId: "LOLA03"
  },
  {
    id: "DIM229",
    name: "ΚΟΡΥΔΑΛΛΟΥ",
    pId: "PEEN47",
    layerId: "LOLA03"
  },
  {
    id: "DIM230",
    name: "ΝΙΚΑΙΑΣ – ΑΓΙΟΥ ΙΩΑΝΝΗ ΡΕΝΤΗ",
    pId: "PEEN47",
    layerId: "LOLA03"
  },
  {
    id: "DIM231",
    name: "ΠΕΙΡΑΙΩΣ",
    pId: "PEEN47",
    layerId: "LOLA03"
  },
  {
    id: "DIM232",
    name: "ΠΕΡΑΜΑΤΟΣ",
    pId: "PEEN47",
    layerId: "LOLA03"
  },
  {
    id: "DIM233",
    name: "ΑΡΓΟΥΣ – ΜΥΚΗΝΩΝ",
    pId: "PEEN48",
    layerId: "LOLA03"
  },
  {
    id: "DIM234",
    name: "ΕΠΙΔΑΥΡΟΥ",
    pId: "PEEN48",
    layerId: "LOLA03"
  },
  {
    id: "DIM235",
    name: "ΕΡΜΙΟΝΙΔΑΣ",
    pId: "PEEN48",
    layerId: "LOLA03"
  },
  {
    id: "DIM236",
    name: "ΝΑΥΠΛΙΕΩΝ",
    pId: "PEEN48",
    layerId: "LOLA03"
  },
  {
    id: "DIM237",
    name: "ΒΟΡΕΙΑΣ ΚΥΝΟΥΡΙΑΣ",
    pId: "PEEN49",
    layerId: "LOLA03"
  },
  {
    id: "DIM238",
    name: "ΓΟΡΤΥΝΙΑΣ",
    pId: "PEEN49",
    layerId: "LOLA03"
  },
  {
    id: "DIM239",
    name: "ΜΕΓΑΛΟΠΟΛΗΣ",
    pId: "PEEN49",
    layerId: "LOLA03"
  },
  {
    id: "DIM240",
    name: "ΝΟΤΙΑΣ ΚΥΝΟΥΡΙΑΣ",
    pId: "PEEN49",
    layerId: "LOLA03"
  },
  {
    id: "DIM241",
    name: "ΤΡΙΠΟΛΗΣ",
    pId: "PEEN49",
    layerId: "LOLA03"
  },
  {
    id: "DIM242",
    name: "ΒΕΛΟΥ – ΒΟΧΑΣ",
    pId: "PEEN50",
    layerId: "LOLA03"
  },
  {
    id: "DIM243",
    name: "ΚΟΡΙΝΘΙΩΝ",
    pId: "PEEN50",
    layerId: "LOLA03"
  },
  {
    id: "DIM244",
    name: "ΛΟΥΤΡΑΚΙΟΥ – ΑΓΙΩΝ ΘΕΟΔΩΡΩΝ",
    pId: "PEEN50",
    layerId: "LOLA03"
  },
  {
    id: "DIM245",
    name: "ΝΕΜΕΑΣ",
    pId: "PEEN50",
    layerId: "LOLA03"
  },
  {
    id: "DIM246",
    name: "ΞΥΛΟΚΑΣΤΡΟΥ – ΕΥΡΩΣΤΙΝΗΣ",
    pId: "PEEN50",
    layerId: "LOLA03"
  },
  {
    id: "DIM247",
    name: "ΣΙΚΥΩΝΙΩΝ",
    pId: "PEEN50",
    layerId: "LOLA03"
  },
  {
    id: "DIM248",
    name: "ΑΝΑΤΟΛΙΚΗΣ ΜΑΝΗΣ",
    pId: "PEEN51",
    layerId: "LOLA03"
  },
  {
    id: "DIM249",
    name: "ΕΛΑΦΟΝΗΣΟΥ",
    pId: "PEEN51",
    layerId: "LOLA03"
  },
  {
    id: "DIM250",
    name: "ΕΥΡΩΤΑ",
    pId: "PEEN51",
    layerId: "LOLA03"
  },
  {
    id: "DIM251",
    name: "ΜΟΝΕΜΒΑΣΙΑΣ",
    pId: "PEEN51",
    layerId: "LOLA03"
  },
  {
    id: "DIM252",
    name: "ΣΠΑΡΤΗΣ",
    pId: "PEEN51",
    layerId: "LOLA03"
  },
  {
    id: "DIM253",
    name: "ΔΥΤΙΚΗΣ ΜΑΝΗΣ",
    pId: "PEEN52",
    layerId: "LOLA03"
  },
  {
    id: "DIM254",
    name: "ΚΑΛΑΜΑΤΑΣ",
    pId: "PEEN52",
    layerId: "LOLA03"
  },
  {
    id: "DIM255",
    name: "ΜΕΣΣΗΝΗΣ",
    pId: "PEEN52",
    layerId: "LOLA03"
  },
  {
    id: "DIM256",
    name: "ΟΙΧΑΛΙΑΣ",
    pId: "PEEN52",
    layerId: "LOLA03"
  },
  {
    id: "DIM257",
    name: "ΠΥΛΟΥ – ΝΕΣΤΟΡΟΣ",
    pId: "PEEN52",
    layerId: "LOLA03"
  },
  {
    id: "DIM258",
    name: "ΤΡΙΦΥΛΙΑΣ",
    pId: "PEEN52",
    layerId: "LOLA03"
  },
  {
    id: "DIM259",
    name: "ΙΚΑΡΙΑΣ",
    pId: "PEEN53",
    layerId: "LOLA03"
  },
  {
    id: "DIM260",
    name: "ΦΟΥΡΝΩΝ ΚΟΡΣΕΩΝ",
    pId: "PEEN53",
    layerId: "LOLA03"
  },
  {
    id: "DIM261",
    name: "ΛΕΣΒΟΥ",
    pId: "PEEN54",
    layerId: "LOLA03"
  },
  {
    id: "DIM262",
    name: "ΑΓΙΟΥ ΕΥΣΤΡΑΤΙΟΥ",
    pId: "PEEN55",
    layerId: "LOLA03"
  },
  {
    id: "DIM263",
    name: "ΛΗΜΝΟΥ",
    pId: "PEEN55",
    layerId: "LOLA03"
  },
  {
    id: "DIM264",
    name: "ΣΑΜΟΥ",
    pId: "PEEN56",
    layerId: "LOLA03"
  },
  {
    id: "DIM265",
    name: "ΟΙΝΟΥΣΣΩΝ",
    pId: "PEEN57",
    layerId: "LOLA03"
  },
  {
    id: "DIM266",
    name: "ΧΙΟΥ",
    pId: "PEEN57",
    layerId: "LOLA03"
  },
  {
    id: "DIM267",
    name: "ΨΑΡΩΝ",
    pId: "PEEN57",
    layerId: "LOLA03"
  },
  {
    id: "DIM268",
    name: "ΑΝΔΡΟΥ",
    pId: "PEEN58",
    layerId: "LOLA03"
  },
  {
    id: "DIM269",
    name: "ΑΝΑΦΗΣ",
    pId: "PEEN59",
    layerId: "LOLA03"
  },
  {
    id: "DIM270",
    name: "ΘΗΡΑΣ",
    pId: "PEEN59",
    layerId: "LOLA03"
  },
  {
    id: "DIM271",
    name: "ΙΗΤΩΝ",
    pId: "PEEN59",
    layerId: "LOLA03"
  },
  {
    id: "DIM272",
    name: "ΣΙΚΙΝΟΥ",
    pId: "PEEN59",
    layerId: "LOLA03"
  },
  {
    id: "DIM273",
    name: "ΦΟΛΕΓΑΝΔΡΟΥ",
    pId: "PEEN59",
    layerId: "LOLA03"
  },
  {
    id: "DIM274",
    name: "ΑΓΑΘΟΝΗΣΙΟΥ",
    pId: "PEEN60",
    layerId: "LOLA03"
  },
  {
    id: "DIM275",
    name: "ΑΣΤΥΠΑΛΑΙΑΣ",
    pId: "PEEN60",
    layerId: "LOLA03"
  },
  {
    id: "DIM276",
    name: "ΚΑΛΥΜΝΙΩΝ",
    pId: "PEEN60",
    layerId: "LOLA03"
  },
  {
    id: "DIM277",
    name: "ΛΕΙΨΩΝ",
    pId: "PEEN60",
    layerId: "LOLA03"
  },
  {
    id: "DIM278",
    name: "ΛΕΡΟΥ",
    pId: "PEEN60",
    layerId: "LOLA03"
  },
  {
    id: "DIM279",
    name: "ΠΑΤΜΟΥ",
    pId: "PEEN60",
    layerId: "LOLA03"
  },
  {
    id: "DIM280",
    name: "ΚΑΡΠΑΘΟΥ",
    pId: "PEEN61",
    layerId: "LOLA03"
  },
  {
    id: "DIM281",
    name: "ΚΑΣΟΥ",
    pId: "PEEN61",
    layerId: "LOLA03"
  },
  {
    id: "DIM282",
    name: "ΚΕΑΣ",
    pId: "PEEN62",
    layerId: "LOLA03"
  },
  {
    id: "DIM283",
    name: "ΚΥΘΝΟΥ",
    pId: "PEEN62",
    layerId: "LOLA03"
  },
  {
    id: "DIM284",
    name: "ΚΩ",
    pId: "PEEN63",
    layerId: "LOLA03"
  },
  {
    id: "DIM285",
    name: "ΝΙΣΥΡΟΥ",
    pId: "PEEN63",
    layerId: "LOLA03"
  },
  {
    id: "DIM286",
    name: "ΚΙΜΩΛΟΥ",
    pId: "PEEN64",
    layerId: "LOLA03"
  },
  {
    id: "DIM287",
    name: "ΜΗΛΟΥ",
    pId: "PEEN64",
    layerId: "LOLA03"
  },
  {
    id: "DIM288",
    name: "ΣΕΡΙΦΟΥ",
    pId: "PEEN64",
    layerId: "LOLA03"
  },
  {
    id: "DIM289",
    name: "ΣΙΦΝΟΥ",
    pId: "PEEN64",
    layerId: "LOLA03"
  },
  {
    id: "DIM290",
    name: "ΜΥΚΟΝΟΥ",
    pId: "PEEN65",
    layerId: "LOLA03"
  },
  {
    id: "DIM291",
    name: "ΑΜΟΡΓΟΥ",
    pId: "PEEN66",
    layerId: "LOLA03"
  },
  {
    id: "DIM292",
    name: "ΝΑΞΟΥ ΚΑΙ ΜΙΚΡΩΝ ΚΥΚΛΑΔΩΝ",
    pId: "PEEN66",
    layerId: "LOLA03"
  },
  {
    id: "DIM293",
    name: "ΑΝΤΙΠΑΡΟΥ",
    pId: "PEEN67",
    layerId: "LOLA03"
  },
  {
    id: "DIM294",
    name: "ΠΑΡΟΥ",
    pId: "PEEN67",
    layerId: "LOLA03"
  },
  {
    id: "DIM295",
    name: "ΜΕΓΙΣΤΗΣ",
    pId: "PEEN68",
    layerId: "LOLA03"
  },
  {
    id: "DIM296",
    name: "ΡΟΔΟΥ",
    pId: "PEEN68",
    layerId: "LOLA03"
  },
  {
    id: "DIM297",
    name: "ΣΥΜΗΣ",
    pId: "PEEN68",
    layerId: "LOLA03"
  },
  {
    id: "DIM298",
    name: "ΤΗΛΟΥ",
    pId: "PEEN68",
    layerId: "LOLA03"
  },
  {
    id: "DIM299",
    name: "ΧΑΛΚΗΣ",
    pId: "PEEN68",
    layerId: "LOLA03"
  },
  {
    id: "DIM300",
    name: "ΣΥΡΟΥ – ΕΡΜΟΥΠΟΛΗΣ",
    pId: "PEEN69",
    layerId: "LOLA03"
  },
  {
    id: "DIM301",
    name: "ΤΗΝΟΥ",
    pId: "PEEN70",
    layerId: "LOLA03"
  },
  {
    id: "DIM302",
    name: "ΑΡΧΑΝΩΝ – ΑΣΤΕΡΟΥΣΙΩΝ",
    pId: "PEEN71",
    layerId: "LOLA03"
  },
  {
    id: "DIM303",
    name: "ΒΙΑΝΝΟΥ",
    pId: "PEEN71",
    layerId: "LOLA03"
  },
  {
    id: "DIM304",
    name: "ΓΟΡΤΥΝΑΣ",
    pId: "PEEN71",
    layerId: "LOLA03"
  },
  {
    id: "DIM305",
    name: "ΗΡΑΚΛΕΙΟΥ",
    pId: "PEEN71",
    layerId: "LOLA03"
  },
  {
    id: "DIM306",
    name: "ΜΑΛΕΒΙΖΙΟΥ",
    pId: "PEEN71",
    layerId: "LOLA03"
  },
  {
    id: "DIM307",
    name: "ΜΙΝΩΑ ΠΕΔΙΑΔΑΣ",
    pId: "PEEN71",
    layerId: "LOLA03"
  },
  {
    id: "DIM308",
    name: "ΦΑΙΣΤΟΥ",
    pId: "PEEN71",
    layerId: "LOLA03"
  },
  {
    id: "DIM309",
    name: "ΧΕΡΣΟΝΗΣΟΥ",
    pId: "PEEN71",
    layerId: "LOLA03"
  },
  {
    id: "DIM310",
    name: "ΑΓΙΟΥ ΝΙΚΟΛΑΟΥ",
    pId: "PEEN72",
    layerId: "LOLA03"
  },
  {
    id: "DIM311",
    name: "ΙΕΡΑΠΕΤΡΑΣ",
    pId: "PEEN72",
    layerId: "LOLA03"
  },
  {
    id: "DIM312",
    name: "ΟΡΟΠΕΔΙΟΥ ΛΑΣΙΘΙΟΥ",
    pId: "PEEN72",
    layerId: "LOLA03"
  },
  {
    id: "DIM313",
    name: "ΣΗΤΕΙΑΣ",
    pId: "PEEN72",
    layerId: "LOLA03"
  },
  {
    id: "DIM314",
    name: "ΑΓΙΟΥ ΒΑΣΙΛΕΙΟΥ",
    pId: "PEEN73",
    layerId: "LOLA03"
  },
  {
    id: "DIM315",
    name: "ΑΜΑΡΙΟΥ",
    pId: "PEEN73",
    layerId: "LOLA03"
  },
  {
    id: "DIM316",
    name: "ΑΝΩΓΕΙΩΝ",
    pId: "PEEN73",
    layerId: "LOLA03"
  },
  {
    id: "DIM317",
    name: "ΜΥΛΟΠΟΤΑΜΟΥ",
    pId: "PEEN73",
    layerId: "LOLA03"
  },
  {
    id: "DIM318",
    name: "ΡΕΘΥΜΝΗΣ",
    pId: "PEEN73",
    layerId: "LOLA03"
  },
  {
    id: "DIM319",
    name: "ΑΠΟΚΟΡΩΝΟΥ",
    pId: "PEEN74",
    layerId: "LOLA03"
  },
  {
    id: "DIM320",
    name: "ΓΑΥΔΟΥ",
    pId: "PEEN74",
    layerId: "LOLA03"
  },
  {
    id: "DIM321",
    name: "ΚΑΝΤΑΝΟΥ – ΣΕΛΙΝΟΥ",
    pId: "PEEN74",
    layerId: "LOLA03"
  },
  {
    id: "DIM322",
    name: "ΚΙΣΣΑΜΟΥ",
    pId: "PEEN74",
    layerId: "LOLA03"
  },
  {
    id: "DIM323",
    name: "ΠΛΑΤΑΝΙΑ",
    pId: "PEEN74",
    layerId: "LOLA03"
  },
  {
    id: "DIM324",
    name: "ΣΦΑΚΙΩΝ",
    pId: "PEEN74",
    layerId: "LOLA03"
  },
  {
    id: "DIM325",
    name: "ΧΑΝΙΩΝ",
    pId: "PEEN74",
    layerId: "LOLA03"
  }
];
