import React, { Component } from "react";
import Table from "../../../components/common/table";
import { Link } from "react-router-dom";
import { results } from "../../../services/fakeResultsService";

class ResultsTable extends Component {
  state = {
    data: {
      columns: [
        { path: "nameVstation", label: "Τμήμα" },
        { path: "number", label: "Αριθμός" },
        { path: "electionLocation", label: "Περιοχή Εκλογών" },
        { path: "registeredVoters", label: "Eγγεγραμμένοι" },
        { path: "blankVotes", label: "Λευκά" },
        { path: "completionRate", label: "Ενσωμάτωση" },
        { path: "invalidVotes", label: "Άκυρα" },
        { path: "turnout", label: "Προσέλευση" },
        { path: "voted", label: "Ψήφισαν" },
        { path: "vStatus.name", label: "Status" },
        { path: "updatedOn", label: "Τελευταια Ενημερωση", type: "Date" }
      ],
      results: []
    }
  };

  ///i will check the first one for the parties ....
  // console.log("results", this.props.results[0].votesParties);

  createColumns = () => {
    // const data = { ...this.state.data };

    const data = JSON.parse(JSON.stringify(this.state.data));

    var position = 3;

    // console.log("props on results !!!!!!!!!!!!", this.props.results);
    //Build Dynamically the columns for each party after the coloumn 3
    var nc = {};
    for (var i = 0; i < this.props.results[0].votesParties.length; i++) {
      nc = {
        path: this.props.results[0].votesParties[i].name,
        label: this.props.results[0].votesParties[i].name
      };
      data.columns.splice(position, 0, nc);
      position++;
      // console.log("NC", nc);
    }

    //build the results ( bring thme in the level1 1)
    var results = this.props.results;
    //bring the results one level up

    for (var r = 0; r < results.length; r++) {
      // console.log(results[r].votesParties);
      for (var vp = 0; vp < results[r].votesParties.length; vp++) {
        // console.log(results[r].votesParties[vp].name);

        results[r][results[r].votesParties[vp].name] =
          results[r].votesParties[vp].votes;
      }
      // console.log("!!!", this.props.results.length);
    }

    data.results = results;

    //console.log(data);
    return data;
  };

  componentDidMount() {
    // //console.log("props on results table", this.props.authLevel);
    // const columnsOfState = { ...this.state.columns };
    // var columns = [];
    // columns = columnsOfState;
    // var position = 3;
    // ///i will check the first one for the parties ....
    // // console.log("results", this.props.results[0].votesParties);
    // var nc = {};
    // for (var i = 0; i < this.props.results[0].votesParties.length; i++) {
    //   nc = {
    //     path: this.props.results[0].votesParties[i].name,
    //     label: this.props.results[0].votesParties[i].name
    //   };
    //   columns.splice(position, 0, nc);
    //   position++;
    //   // console.log("NC", nc);
    // }
    // const results = { ...this.state.results };
    // results = this.props.results;
    // //bring the results one level up
    // for (var r = 0; r < results.length; r++) {
    //   // console.log(results[r].votesParties);
    //   for (var vp = 0; vp < results[r].votesParties.length; vp++) {
    //     // console.log(results[r].votesParties[vp].name);
    //     results[r][results[r].votesParties[vp].name] =
    //       results[r].votesParties[vp].votes;
    //   }
    //   // console.log("!!!", this.props.results.length);
    // }
    // this.setState({ columns: columns, results: results });
  }

  render() {
    const data = this.createColumns();
    const { results, onSort, sortColumn } = this.props;

    return (
      <Table
        uniqueKey1="idElection"
        uniqueKey2="idVstation"
        columns={data.columns}
        data={data.results}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ResultsTable;
