import moment from "moment";

const users = [
  {
    id: "malakasis@gmail.com",
    firstName: "Test: Akis",
    lastName: "Test: Malakasis",
    email: "Test: malakasis@gmail.com",
    imageProfileId: "",
    mobile: "6944025025",
    notes: "Pame dinata",
    updatedOn: 1557529478
  }
];

export function fusersGetOne(usersId) {
  return users.find(f => f.id === usersId);
}
