import React, { Component } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import _ from "lodash";

import { partiesGetAll, partiesDelete } from "../../../services/partiesService";
import { fpartiesGetAll } from "../../../services/fakePartiesService";
import { electionTypesGetAll } from "../../../services/listOfValues";
import { paginate } from "../../../components/utils/paginate";
import PartiesTable from "./partiesTable";
import Pagination from "../../../components/common/pagination";
import SearchBox from "../../../components/common/searchBox";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class Parties extends Component {
  state = {
    parties: [],
    electionTypes: [],
    pageSize: 4,
    currentPage: 1,
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
    isDeleting: false
  };

  async componentDidMount() {
    //bring the elction types  from the file list of Values
    const electionTypes = electionTypesGetAll();

    var parties;
    if (this.props.production === true) parties = await partiesGetAll();
    if (this.props.production === false) parties = fpartiesGetAll();
    //Bring all the parties from the DB
    // const parties = await partiesGetAll();

    this.setState({
      parties: parties,
      electionTypes: electionTypes
    });
  }

  handleUpdate = party => {
    this.props.history.push(`/ekloges/parties/${party.id}`);
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      parties: allParties
    } = this.state;

    let filtered = allParties;
    if (searchQuery)
      filtered = allParties.filter(v =>
        v.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const parties = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: parties };
  };

  handleDelete = async party => {
    swal({
      title: "Εισαι σιγουρος  οτι θελεις να διαγραψεις το Κόμμα",
      text: party.name,
      icon: "warning",
      buttons: true
    }).then(async willDelete => {
      if (willDelete) {
        const originalParties = this.state.parties;
        // Filter OUT the one that i dont want
        const parties = originalParties.filter(c => c.id !== party.id);

        // update the UI
        this.setState({ parties });
        var pagePrevNum = this.state.currentPage;
        //Fix the pagination issue
        if (
          this.state.currentPage > 1 &&
          this.state.currentPage - 1 === parties.length / this.state.pageSize //e,g, page 3 , 8 length page size 4=>  (3-1=2) =  ( 8/4= 2) so reduce
        ) {
          const newPage = this.state.currentPage - 1;

          this.setState({ currentPage: newPage });
        }

        try {
          if (this.props.production === true) {
            await partiesDelete(party.id);
          }

          //else we have already deleted before for the Dev !!!

          // if (this.props.production === false)
          //   elections = felectionsDelete(election.id);
        } catch (ex) {
          //If goes wrong the revert !!!
          if (ex.response && ex.response.status === 404) console.log("x");
          toast.error("Το κόμμα  φαίνεται οτι έχει ηδη διαγραφτεί");

          this.setState({
            parties: originalParties,
            currentPage: pagePrevNum
          });
        }
      }
    });
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCount, data: parties } = this.getPagedData();

    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h2 className="mb-0">
                    <Badge color="info">{totalCount}</Badge>
                    <span className="m-1" />
                    Κόμματα
                  </h2>
                </Col>
                <Col className="text-right" xs="6">
                  <Link
                    to="/ekloges/parties/new"
                    className="btn btn-success"
                    style={{ marginBottom: 20 }}
                  >
                    Προσθήκη
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {/* <p>Συνολικά {totalCount} κόμματα έχουνε καταχωρηθεί.</p> */}
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <PartiesTable
                parties={parties}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onUpdate={this.handleUpdate}
                onSort={this.handleSort}
              />
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default Parties;
