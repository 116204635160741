import React, { Component, Fragment } from "react";
import {
  Card,
  Badge,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Container
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import _ from "lodash";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

import CandidatesTable from "./candidatesTable";
import Pagination from "../../../components/common/pagination";
import { paginate } from "../../../components/utils/paginate";

import {
  fcandidatesGetAll,
  fcandidatesDelete
} from "../../../services/fakeCandidatesService";

import {
  candidatesGetAll,
  candidatesDelete
} from "../../../services/candidatesService";
// import swal from "sweetalert2";

import SearchBox from "../../../components/common/searchBox";

/////////////////
class Candidates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      candidates: [],
      pageSize: 30,
      currentPage: 1,
      searchQuery: "",
      sortColumn: { path: "lastName", order: "asc" },
      isDeleting: false,
      isLoading: true
    };
  }

  handleDelete = async candidate => {
    swal({
      title: candidate.firstName + " " + candidate.lastName,
      text: "Εισαι σιγουρος  οτι θελεις να διαγραψεις τον υποψήφιο",
      icon: "warning",
      buttons: true
    }).then(async willDelete => {
      if (willDelete) {
        //Optimistic delete keep the previous state
        const originalCandidates = this.state.candidates;
        // Filter OUT the one that i dont want
        const candidates = originalCandidates.filter(
          c => c.id !== candidate.id
        );

        // update the UI
        this.setState({ candidates });
        var pagePrevNum = this.state.currentPage;
        //Fix the pagination issue
        if (
          this.state.currentPage > 1 &&
          this.state.currentPage - 1 === candidates.length / this.state.pageSize //e,g, page 3 , 8 length page size 4=>  (3-1=2) =  ( 8/4= 2) so reduce
        ) {
          const newPage = this.state.currentPage - 1;

          this.setState({ currentPage: newPage });
        }
        try {
          if (this.props.production === true) {
            console.log("candidatesDelete", candidate, candidate.id);
            await candidatesDelete(candidate);
          }

          //else if dev do nothing as we have alreayd deleted
        } catch (ex) {
          //If goes wrong the revert !!!
          if (ex.response && ex.response.status === 404) console.log("x");
          toast.error("Ο Υποψήφιος  φαίνεται οτι έχει ηδη διαγραφτεί");

          this.setState({
            candidates: originalCandidates,
            currentPage: pagePrevNum
          });
        }
      }
    });
  };

  handleUpdate = candidate => {
    this.props.history.push(`/ekloges/candidates/${candidate.id}`);
  };

  async componentDidMount(e) {
    try {
      // const candidates = await this.getAllcandidates();

      var candidates;
      if (this.props.production === true) candidates = await candidatesGetAll();
      if (this.props.production === false) candidates = fcandidatesGetAll();

      // const candidates = await candidatesGetAll();

      this.setState({ candidates, isLoading: false });

      //console.log(candidates);
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,

      searchQuery,
      candidates: allCandidates
    } = this.state;

    let filtered = allCandidates;
    if (searchQuery)
      filtered = allCandidates.filter(c =>
        c.lastName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const candidates = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: candidates };
  };

  render() {
    const { length: count } = this.state.candidates; // Object destructing of the lentgh of candidates to count
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      isLoading
    } = this.state; // object destructuring from state

    // if (isLoading) return null; // Is still loading data

    const { totalCount, data: candidates } = this.getPagedData();

    console.log(this.state.candidates);
    //else
    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h2 className="mb-0">
                    <Badge color="info">{count}</Badge>
                    <span className="m-1" />
                    Υποψήφιοι
                  </h2>
                  {/* <p>Έχουμε συνολικά {count} υποψηφίους καταχωρήσει</p> */}
                </Col>
                <Col className="text-right" xs="6">
                  <Link
                    to="/ekloges/candidates/new"
                    className="btn btn-success"
                    style={{ marginBottom: 20 }}
                  >
                    Προσθήκη
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {/* <p>Έχουμε συνολικά {count} υποψηφίους καταχωρήσει</p> */}

              {count === 0 ? (
                <p>Δεν εχει καταχωρηθει καποιος υποψηφιος ακομα</p>
              ) : (
                <React.Fragment>
                  <SearchBox value={searchQuery} onChange={this.handleSearch} />
                  <CandidatesTable
                    candidates={candidates}
                    sortColumn={sortColumn}
                    onDelete={this.handleDelete}
                    onUpdate={this.handleUpdate}
                    onSort={this.handleSort}
                  />
                  <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                  />
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default Candidates;
