import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// // @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "../../../components/N1components/Header/Header.jsx";
import Footer from "../../../components/N1components/Footer/Footer.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "../../../components/N1components//Header/HeaderLinks.jsx";
import Parallax from "../../../components/N1components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.jsx";

import CustomFooter from "../../../components/N1components/CustomFooter";

// import { homedir } from "os";
import desktopImage from "../../../assets/img/WebsiteMainv2019_04_22v2.jpg";
import mobileImage from "../../../assets/img/WebsiteMainMicrov2019_04_24.jpg";

class LandingPage extends React.Component {
  constructor() {
    super();
    this.state = { width: window.innerWidth };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  componentWillMount = () => {
    window.addEventListener("resize", this.handleWindowResize);
  };

  handleWindowResize = () => {
    this.setState({ width: window.innerWidth });
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleWindowResize);
  };

  render() {
    // const mainImage = "assets/img/WebsiteMainv2019_04_22v2.jpg";
    const { width } = this.state;
    console.log("props on landing", this.props);
    const MainImage = width >= 650 ? desktopImage : mobileImage;

    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="white"
          brand="Ιωάννης Μαλακάσης"
          links={
            <HeaderLinks
              dropdownHoverColor="info"
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              onLogout={this.props.onLogout}
            />
          }
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          image={"url(" + MainImage + ")"} //  "assets/img/WebsiteMainv2019_04_22v2.jpg")}
          //filter="dark"
        >
          <div className={classes.container}>
            {/* <GridContainer>
              <GridItem xs={12} sm={6} md={12} align="right">
                <h1 className={classes.title} style={{ color: "#113a5f" }}>
                  Ιωαννης Μαλακασης
                </h1>
                <h2>Υποψηφιος Περιφερειακος Συμβουλος</h2>
                <br />
              </GridItem>
            </GridContainer> */}
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionProduct />
            {/* <SectionTeam />
            <SectionWork /> */}
          </div>
        </div>

        <Footer content={<CustomFooter classes={classes} />} />
      </div>
    );
  }
}

// const useWindowWidth = () => {
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   const handleWindowResize = () => {
//     setWindowWidth(window.innerWidth);
//   };

//   useEffect(() => {
//     window.addEventListener("resize", handleWindowResize);
//     return () => window.removeEventListener("resize", handleWindowResize);
//   }, []);

//   return windowWidth;
// };

export default withStyles(landingPageStyle)(LandingPage);
