import React from "react";
import { Button } from "reactstrap";
// import { Button } from "../Ncomponents";
// import "../../assets/css/loaderButton.css";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) => (
  <Button disabled={disabled || isLoading} {...props}>
    {isLoading && <i className="fas fa-sync fa-spin" />}
    {!isLoading ? text : " " + loadingText}
  </Button>
);

{
  /* <button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...
</button> */
}
