import React, { Component } from "react";
import Table from "../../../components/common/table";
import { Button } from "../../../components/Ncomponents";
import { locationLayersGetAll } from "../../../services/listOfValues";
import { Link } from "react-router-dom";

class PartiesTable extends Component {
  state = {
    locationLayers: []
  };

  columns = [
    {
      path: "name",
      label: "Όνομα",
      content: party => (
        <Link to={`/ekloges/parties/${party.id}`}>{party.name}</Link>
      )
    },

    // { path: "name", label: "Ονομα" },
    { path: "active", label: "Ενεργο", type: "Boolean" },
    { path: "leader", label: "Aρχηγός" },
    { path: "electionType.name", label: "Tύπος Κόμματος" },

    {
      key: "location",
      label: "Περιοχή",
      content: party => this.findLocationName(party)
    },

    { path: "color", label: "Χρώμα", type: "Color" },
    { path: "updatedOn", label: "Ενημερώθηκε", type: "Date" },
    {
      key: "numberOfCandidates",
      label: "Υποψήφιοι",
      content: party => party.partiesCandidates.length
    },
    {
      key: "edit",
      content: party => (
        <button
          onClick={() => this.props.onUpdate(party)}
          className="btn btn-info btn-sm"
        >
          Edit
        </button>
      )
    },
    {
      key: "delete",
      content: party => (
        <div className="btns-mr-5">
          <Button
            color="danger"
            size="sm"
            onClick={() => this.props.onDelete(party)}
          >
            Delete
          </Button>
        </div>
      )
    }
  ];

  findLocationName(party) {
    // console.log(" psaxnw", party, this.state.locationLayers);

    var location = party.location[party.location.length - 1];

    //console.log("Localtion is !!!", location);

    if (!location) return null; //if the location is empty

    var layer = this.state.locationLayers.find(f => f.id === location.layerId);

    //console.log("Localtion is ", location, this.state.locationLayers);

    var locationName = layer.nameShort + " " + location.name;

    // console.log("location", locationName);
    return locationName;
  }

  componentDidMount() {
    const locationLayers = locationLayersGetAll();

    this.setState({ locationLayers });
  }

  render() {
    const { parties, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={parties}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default PartiesTable;
