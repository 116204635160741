import { API } from "aws-amplify";

export function resultsSave(results) {
  if (results.idElection && results.idVstation) {
    const body = { ...results };
    delete body.idElection;
    delete body.idVstation;

    return API.put(
      "results",
      `/results/${results.idElection}/${results.idVstation}`,
      {
        body: body
      }
    );
  }
  return API.post("results", "/results", {
    body: results
  });
}
export function resultsGetOne(idElection, idVstation) {
  return API.get("results", `/results/${idElection}/${idVstation}`);
}

export function resultsGetAll() {
  return API.get("results", "/results");
}

export function resultsGetAllByElection(idElection) {
  return API.get("results", `/results/${idElection}`);
}

export function resultsDelete(idElection, idVstation) {
  return API.del("results", `/results/${idElection}/${idVstation}`);
}

// export function resultsDeleteAllFromElection(idElection) {
//   return API.del("results", `/results/${idElection}`);
// }
