import React from "react";

import Joi from "joi-browser";
import { Col, Row } from "reactstrap";
import { fcandidatesGetAll } from "../../../services/fakeCandidatesService";
import { candidatesGetAll } from "../../../services/candidatesService";
// import { partiesCreateCandidates } from "../services/fakePartiesService";
// import { partiesCreateCandidates } from "../services/partiesService";
import { electionsAreasGetPerLayer } from "../../../services/listOfValues";
import { Button } from "../../../components/Ncomponents";
import LoaderButton from "../../../components/common/loaderButton";
import NewSelect4 from "../../../components/common/newSelect4";
import Form from "../../../components/common/form";

class PartiesCandidateForm extends Form {
  constructor(props) {
    super();
    this.state = {
      data: {
        id: "",
        name: "",
        order: "",
        locationId: "",
        locationName: ""
      },
      errors: {},
      title: "",
      candidates: [],
      candidatesInUse: [],
      hideDropdownElectionArea: false,
      renderBody: false,
      //dropdowns
      hideDropdownCandidates: false,
      locations: []
    };
  }

  schema = {
    id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Όνομα"),
    order: Joi.number()
      .required()
      .label("Αριθμός")
      .min(0),
    area: Joi.string()
      .required()
      .label("Διεύθυνση")
  };

  componentWillReceiveProps(props) {
    this.setState({
      candidatesInUse: props.candidatesInUse,
      data: props.candidateToUpdate
    });
  }

  validateForm() {
    var disabled = true;

    //Check if you ebnered all mandatory data
    if (
      this.state.data.name.length > 0 &&
      this.state.data.order !== "" &&
      this.state.data.locationId
    ) {
      disabled = false;
    }

    //Last if check if there is any errof
    if (Object.entries(this.state.errors).length !== 0) {
      disabled = true;
    }

    return disabled;
  }

  handleSave = () => {
    const data = { ...this.state.data };
    data.order = parseInt(data.order, 10);

    const { partiesCandidates } = { ...this.props.party };
    //find if the record exist
    const index = partiesCandidates.findIndex(e => e.id === data.id);

    //New Entry
    if (index === -1) {
      // New record
      partiesCandidates.push(data);
    } else {
      partiesCandidates[index] = data;
    }

    // console.log("partiesCandidates", partiesCandidates);

    //handle to the parent the whole object of parties candidates so to save that
    this.props.onSave(partiesCandidates);
  };

  getNewCandidatesOnly = async () => {
    //Will bring the data from the sever
    var candidatesInDb = [];
    try {
      if (this.props.production === true)
        candidatesInDb = await candidatesGetAll();
      if (this.props.production === false) {
        candidatesInDb = fcandidatesGetAll();
      }

      // candidatesInDb = await candidatesGetAll();
    } catch (ex) {
      console.log(ex);
    }

    candidatesInDb = candidatesInDb.map(p => ({
      id: p.id,
      name: p.lastName + " " + p.firstName
    }));

    // will filter out the data in case of a total new party that will not have any candidate we need to catch that
    if (!this.props.candidatesInUse) {
      console.log("issue with filetring", this.state.candidatesInUse);
      return candidatesInDb;
    }
    console.log(this.state.candidatesInUse);
    const NewCandidates = candidatesInDb.filter(
      cdb => !this.props.candidatesInUse.find(cf => cdb.id === cf.id)
    );
    return NewCandidates;
  };

  handleChangeNewSelect3 = e => input => {
    if (e === "electionLocations") {
      const data = { ...this.state.data };

      data.locationId = input.value;
      data.locationName = input.label;

      this.setState({ data: data });
    }

    if (e === "candidates") {
      const data = { ...this.state.data };

      data.id = input.value;
      data.name = input.label;

      this.setState({ data: data });
    }
  };

  async componentDidMount() {
    //bring the areas of the party if it is election type Periferiakes i want all the periferiakes enotites else level 3

    const newCandidates = await this.getNewCandidatesOnly();

    //sort the list with ascending on the name
    newCandidates.sort((a, b) => (a.name > b.name ? 1 : -1));

    this.setState({ candidates: newCandidates, renderBody: true });

    //console.log("Location", this.props.party.location);

    var locations = [];
    if (this.props.party.electionType.id === "ELTY003") {
      const locationsAllLevel2 = electionsAreasGetPerLayer("LOLA02");
      // console.log("party details loc1", this.props.party.location[0].id);
      locations = locationsAllLevel2.filter(
        f => f.pId === this.props.party.location[0].id
      );
      // console.log("party details loc", locations);
    } else {
      locations.push(this.props.party.location[2]); // the laste location of Dimos !!!
    }

    if (this.props.action === "Create") {
      // const newCandidates = this.getNewCandidatesOnly();
      const { length: count } = newCandidates || [];

      //console.log("newCandidates", newCandidates);
      if (count === 0) {
        alert(
          "Δεν υπαρχουν διαθεσιμοι Υποψήφιοι, Παρακαλω πηγαινετε στη καρτελα υποψηφίων για να προσθεσετε"
        );
        this.props.onCancel();
      }

      this.setState(
        {
          action: "Create",
          locations: locations,
          title: "Δημιουργία Υποψηφίου",
          // data: {}, //Just in case that the parent component sends a candidate. I need empty on the save fucntion
          candidates: newCandidates
        },
        () => console.log("Create ", this.state)
      );
    }

    if (this.props.action === "Update") {
      const candidate = this.props.candidateToUpdate;

      this.setState(
        {
          action: "Update",
          title: "Ενημέρωση Υποψηφίου",
          data: candidate,
          locations: locations
        },
        () => console.log("Update", this.state)
      );
    }
  }

  // handleCancel = () => {
  //   this.setState({
  //     showBodyForm: true,
  //     showCreateForm: false,
  //     data: null
  //   });
  // };

  render() {
    // console.log(this.state);

    return (
      <React.Fragment>
        {this.state.renderBody && (
          <React.Fragment>
            <Row>
              {" "}
              <p> </p>
            </Row>
            <Row>
              <Col md={4} xs={6}>
                <h4>{this.state.title} </h4>
              </Col>
            </Row>
            <form>
              <Row>
                {this.state.action === "Create" ? (
                  <Col md={4} xs={6}>
                    <NewSelect4
                      name="candidates"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      isSearchable
                      placeholder="Επιλογή"
                      value={this.state.data.id}
                      label="Υποψήφιος"
                      options={this.state.candidates}
                      onChange={this.handleChangeNewSelect3("candidates")}
                      isDisabled={this.state.hideDropdownCandidates}
                    />
                  </Col>
                ) : (
                  <Col md={4} xs={6}>
                    {this.renderInput("name", "Ονομα", "Text", true)}
                  </Col>
                )}
                <Col md={3} xs={6}>
                  {this.renderInput("order", "Σειρά", "Number")}
                </Col>

                <Col md={4} xs={6}>
                  <NewSelect4
                    name="electionLocations"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Επιλογή"
                    value={this.state.data.locationId}
                    label="Εκλογική Περιοχή"
                    options={this.state.locations}
                    onChange={this.handleChangeNewSelect3("electionLocations")}
                    isDisabled={this.state.hideDropdownElectionArea}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8} xs={12}>
                  <Button
                    // leftLabel="now-ui-icons arrows-1_minimal-left"
                    // round
                    onClick={this.props.onCancel}
                  >
                    Ακύρωση
                  </Button>
                  <span className="m-2"> </span>
                  <LoaderButton
                    color="success"
                    // round
                    // leftLabel="now-ui-icons ui-1_check"
                    onClick={this.handleSave}
                    disabled={this.validateForm()}
                    isLoading={this.state.isLoading}
                    text="Αποθηκευση"
                    loadingText="Saving...."
                  />
                </Col>
              </Row>
            </form>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default PartiesCandidateForm;
