import React, { Component } from "react";
import Table from "../../../components/common/table";
import { Link } from "react-router-dom";

class ResultsCandidatesTable extends Component {
  state = {
    data: {
      results: [],
      columns: [
        // {
        //   path: "idElection",
        //   label: "ID ΕΚ"
        // },
        // {
        //   path: "idVstation",
        //   label: "ID ΤΜ"
        // },

        // {
        //   path: "name",
        //   label: "Όνομα",
        //   content: result => (
        //     <Link
        //       to={this.props.myroute}
        //       onClick={() => this.props.onUpdate(result)}
        //       // to={`/ekloges/results/${result.idElection}/${result.idVstation}`}
        //     >
        //       {result.nameVstation}
        //     </Link>
        //   )
        // },
        // // {
        //   path: "nameVstation",
        //   label: "Εκλογικό τμήμα",
        //   content: result => (
        //     <Link
        //       to="#"
        //       onClick={() => this.props.onUpdate(result)} // for setting the local storage variables !!!
        //       // to={`/ekloges/results/${result.idElection}/${result.idVstation}`}
        //     >
        //       {result.nameVstation}
        //     </Link>
        //   )
        // },
        // { path: "nameElection", label: "Εκλογική Διαδικασία" },
        // { path: "nameVstation", label: "Εκλογικό τμήμα" },
        // { path: "dateElection", label: "Ημερομηνία" },
        { path: "nameVstation", label: "Τμήμα" },
        // { path: "number", label: "Αριθμός" },

        // { path: "electionLocation", label: "Περιοχή Εκλογών" },
        // { path: "registeredVoters", label: "Eγγεγραμμένοι" },
        // { path: "blankVotes", label: "Λευκά" },
        // { path: "completionRate", label: "Ενσωμάτωση" },
        // { path: "invalidVotes", label: "Άκυρα" },
        // { path: "turnout", label: "Προσέλευση" },
        // { path: "voted", label: "Ψήφισαν" },
        { path: "vStatus.name", label: "Status" },
        { path: "updatedOn", label: "Τελευταια Ενημερωση", type: "Date" }
        // {
        //   key: "edit",
        //   content: result => (
        //     <button
        //       onClick={() => this.props.onUpdate(result)}
        //       className="btn btn-info btn-sm"
        //       disabled={this.props.authLevel >= 2 ? false : true}
        //     >
        //       Edit
        //     </button>
        //   )
        // },
        // {
        //   key: "delete",
        //   content: result => (
        //     <button
        //       onClick={() => this.props.onDelete(result)}
        //       className="btn btn-danger btn-sm"
        //       disabled={this.props.authLevel >= 4 ? false : true}
        //     >
        //       Delete
        //     </button>
        //   )
        // }
      ]
    }
  };

  createColumns = () => {
    // const data = { ...this.state.data };

    const data = JSON.parse(JSON.stringify(this.state.data));

    var position = 1;

    // console.log("props on results !!!!!!!!!!!!", this.props.results);
    //Build Dynamically the coloumns for each candidate after the coloumn 3
    var nc = {};
    for (var i = 0; i < this.props.results[0].votesCandidates.length; i++) {
      nc = {
        path: this.props.results[0].votesCandidates[i].name,
        label: this.props.results[0].votesCandidates[i].name
      };
      data.columns.splice(position, 0, nc);
      position++;
      // console.log("NC", nc);
    }

    //build the results ( bring thme in the level1 1)
    var results = this.props.results;
    //bring the results one level up

    for (var r = 0; r < results.length; r++) {
      // console.log(results[r].votesParties);
      for (var vp = 0; vp < results[r].votesCandidates.length; vp++) {
        // console.log(results[r].votesParties[vp].name);

        results[r][results[r].votesCandidates[vp].name] =
          results[r].votesCandidates[vp].votes;
      }
      // console.log("!!!", this.props.results.length);
    }

    data.results = results;

    //console.log(data);
    return data;
  };

  componentDidMount() {
    // console.log("props on results table", this.props.authLevel);
    // const data = { ...this.state.data };

    // var position = 3;

    // console.log("props on results !!!!!!!!!!!!", this.props.results);
    // //Build Dynamically the coloumns for each candidate after the coloumn 3
    // var nc = {};
    // for (var i = 0; i < this.props.results[0].votesCandidates.length; i++) {
    //   nc = {
    //     path: this.props.results[0].votesCandidates[i].name,
    //     label: this.props.results[0].votesCandidates[i].name
    //   };
    //   data.columns.splice(position, 0, nc);
    //   position++;
    //   // console.log("NC", nc);
    // }

    // //build the results ( bring thme in the level1 1)
    // var results = this.props.results;
    // //bring the results one level up

    // for (var r = 0; r < results.length; r++) {
    //   // console.log(results[r].votesParties);
    //   for (var vp = 0; vp < results[r].votesCandidates.length; vp++) {
    //     // console.log(results[r].votesParties[vp].name);

    //     results[r][results[r].votesCandidates[vp].name] =
    //       results[r].votesCandidates[vp].votes;
    //   }
    //   // console.log("!!!", this.props.results.length);
    // }
    const data = this.createColumns();

    // this.setState({ data: data });
  }

  render() {
    const data = this.createColumns();
    const { results, onSort, sortColumn } = this.props;

    //console.log("props on results !!!!!!!!!!!!", this.props.results);

    return (
      <Table
        uniqueKey1="idElection"
        uniqueKey2="idVstation"
        columns={data.columns}
        data={data.results}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ResultsCandidatesTable;
