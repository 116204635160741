import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import Star from "@material-ui/icons/Star";
import WorkOutline from "@material-ui/icons/WorkOutline";
import School from "@material-ui/icons/SchoolOutlined";
import ArrowForward from "@material-ui/icons/ArrowForward";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "../../../../components/N1components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/N1components/Grid/GridItem.jsx";
import InfoArea from "../../../../components/N1components/InfoArea/InfoArea.jsx";

//import ImgStrategy from "../Sections/strategy.svg";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx";
// import { Favorite } from "@material-ui/icons/Favorite";
// import { blue } from "@material-ui/core/colors";

class SectionProduct extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Ιωάννης Μαλακάσης</h2>
            <h4
              className={classes.description}
              style={{ textAlign: "justify", fontStyle: "italic" }}
            >
              Θεωρώ ότι είναι χρέος όλων μας να προσφέρουμε, ότι μπορούμε
              ανιδιοτελώς, ιδιαίτερα σε αυτές τις δύσκολες στιγμές που βιώνουμε.
              Έτσι για άλλη μια φορά θέτω τις γνώσεις, το ήθος και την εμπειρία
              μου στην υπηρεσία του τόπου και των συμπολιτών μου ενώνοντας τις
              δυνάμεις μου με την υποψήφια περιφερειάρχη{" "}
              <a href="http://www.rodikratsa.gr" style={{ color: "#113a5f" }}>
                <b>Ρόδη Κράτσα</b>
              </a>{" "}
              που έχει αξιόλογη επαγγελματική και πολιτική σταδιοδρομία. Πιστεύω
              ακράδαντα ότι με την πολυετή εμπειρία της ως ευρωβουλευτής θα
              μπορέσει να συνεισφέρει στον εκσυγχρονισμό και στην ανάπτυξη της
              περιφέρειας μας.
              <br />
            </h4>
            <h4 className={classes.description} style={{ textAlign: "center" }}>
              {" "}
              <b>«ΙΟΝΙΟ ΔΥΝΑΤΑ, κάθε νησί ψηλά»​ </b>
            </h4>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Ήθος &  Ανιδιοτέλεια"
                description="Είναι γνωστό σε όλους τους συμπατριώτες μου ότι το ήθος και η ανιδιοτέλεια είναι από τα βασικά χαρακτηριστικά μου. Κάθε φορά που οι Λευκαδίτες με εξέλεξαν να τους αντιπροσωπεύσω  ήμουν πάντα στην διάθεση ΟΛΩΝ των πολιτών για την επίλυση των υποθέσεων τους. Όλα τα χρόνια της ενεργούς προσφοράς μου στην Λευκάδα, ουδέποτε επωφελήθηκα σε προσωπικό επίπεδο πάρα είχα πάντα γνώμονα το καλό του τόπου και των συμπολιτών μας. Κατά την πολιτική μου σταδιοδρομία είχα θέσεις που πιθανόν θα μπορούσαν να βοηθήσουν την επαγγελματικη μου ιδιότητα αλλά ουδέποτε έκανα χρήση της εξουσίας αυτής. Δεν θα ήθελα να αναφερθώ αναλυτικά και με παραδείγματα στα συγκεκριμένα χαρακτηριστικά μου, το αφήνω στην κρίση σας...."
                icon={Star}
                //img={ImgStrategy}
                iconColor="gray"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Γνώση & Εμπειρία"
                description="Οι τεχνικές γνώσεις και η ενασχόληση με τα κοινά για πάνω από 30 χρόνια, μου δίνουν την δυνατότητα να βοηθήσω εμπράκτως στην αντιμετώπιση προβλημάτων καθώς και στην στρατηγική  ανάπτυξη του τόπου μας.Η επαγγελματική και πολιτική εμπειρία, μου έδωσαν την τεχνογνωσία νέων δομών τεχνολογίας, ανάπτυξης, ευρωπαϊκών προγραμμάτων υποδομής και την δυνατότητα να έχω ενεργό ρόλο για την υλοποίηση τους. Οι έννοιες επιχειρηματικότητα, τουρισμός, πράσινη ενέργεια, υγεία, καθαριότητα, αθλητισμός, μεταφορές, εκσυγχρονισμός κ.α., όχι μόνο μου είναι γνωστές αλλά μπορώ να τις εντάξω εμπράκτως και εποικοδομητικά στην καθημερινότητα μας."
                icon={School}
                iconColor="gray"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Ενδιαφέρον & Προσφορά"
                description="Πολιτικά ενεργός τα περισσότερα χρόνια της ζωής μου στην όμορφη Λευκάδα μας, γνωρίζω τα προβλήματα του τόπου και των πολιτών και έχω την δυνατότητα να προγραμματίσω  τα έργα για την επίλυση τους. Το ενδιαφέρον μου για προσφορά με ωθεί σε συνεχή ενημέρωση για την σωστή αξιοποίηση πόρων και ευρωπαϊκών προγραμμάτων  ώστε να έχουν την ταχύτερη και  καλύτερη δυνατή απορρόφηση. Έχω τον χρόνο και την διάθεση προσφοράς στα κοινά και ευελπιστώ να μου δοθεί η ευκαιρία να συνδράμω για μια ακόμα φορά στην ανάπτυξη του τόπου μου."
                icon={WorkOutline}
                iconColor="gray"
                vertical
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Πολιτική Σταδιοδρομία"
                description="Μόνιμος κάτοικος Λευκάδας από τότε που γεννήθηκα, δημιούργησα την οικογένεια μου και επένδυσα συναισθηματικά και επαγγελματικά στον τόπο μου. Ως απλός πολίτης συμμετείχα ενεργά σε διαφόρους συλλόγους, σωματεία και στο Πνευματικό Κέντρο με προτεραιότητά την πολιτιστική προβολή της Λευκάδας. Ως πολιτικός, για πάνω από 30 χρόνια, ασχολήθηκα με τα κοινά σε διάφορες θέσεις όπως Αντιδήμαρχος, Προέδρος Συμβουλίου Περιοχής, Προέδρος Λιμενικού Ταμείου, Προέδρος Νομαρχιακού Συμβουλίου, Περιφερειακός Σύμβουλος Π.Ι.Ν, Θεματικός Αντιπεριφερειάρχης, Yπεύθυνος Αναπτυξιακού Προγραμματισμού, Περιβάλλοντος, Υποδομών της Περιφέρειας κ.α. με σκοπό την ανάπτυξη της Λευκάδας."
                icon={FavoriteBorder}
                iconColor="gray"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Αποτελεσματικότητα "
                description="Τόσο κατά την θητεία μου ως Προέδρος Νομαρχιακού Συμβουλίου, όσο και ως Θεματικός Αντιπεριφερειάρχης συμμετείχα σε τρεις συνεδριάσεις του ΚΑΣ (Κεντρικού Αρχαιολογικού Συμβουλίου) για την υπόθεση του σχεδίου πόλης Λυγιάς – Καρυωτών και αυτό που πέτυχα ήταν να ανατρέψω την αρχική τους απόφαση, που ήταν η εξαίρεση 150 στρεμμάτων από το σχέδιο πόλης. Ως Θεματικός Αντιπεριφερειάρχης εισηγήθηκα την ένταξη του τουριστικού αγκυροβολίου της Βασιλικής σε Ευρωπαϊκό πρόγραμμα όπως και υλοποιείται τώρα. Επιπλέον είχα καθοριστική συμμετοχή στην άρση των προβλημάτων που είχε η ένταξη του έργου Δίαυλος Λευκάδας και με συνεχείς παρεμβάσεις κατάφερα το έργο να δημοπρατηθεί και να ολοκληρωθεί."
                icon={Chat}
                iconColor="gray"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Όραμα για το Μέλλον"
                description="Όλοι αγαπάμε τον τόπο μας και θελουμε το καλύτερο για αυτόν αλλά λίγοι έχουμε τις ΓΝΩΣΕΙΣ, την  ΕΜΠΕΙΡΙΑ, τη ΔΙΑΘΕΣΗ και το ΧΡΟΝΟ να ασχοληθούμε με τα κοινά. Δεν θεωρώ τον εαυτό μου πολιτικό με την κοινή έννοια του όρου αλλά ΕΝΕΡΓΟ ΠΟΛΙΤΗ που οραματίζεται μια καλύτερη Λευκάδα με ουσιαστική ανάπτυξη και μέριμνα για τον συμπολίτη μας. Το όραμα μου είναι να δοθεί πραγματική δυνατότητα παραμονής των νέων στον τόπο μας καθώς και επιστροφή όλων αυτών που για διαφόρους λόγους αναγκάστηκαν να τον εγκαταλείψουν. Είμαι σίγουρος ότι όλοι μαζί θα το πετύχουμε, «ΙΟΝΙΟ ΔΥΝΑΤΑ, κάθε νησί ψηλά»"
                icon={ArrowForward}
                iconColor="gray"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(SectionProduct);
