import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";

class TableBody extends Component {
  renderCell = (item, column) => {
    //If content like buttons retun the button
    if (column.content) return column.content(item);

    //if boolean for the time being retun active no active
    if (column.type === "Boolean") {
      // console.log(column);
      return _.get(item, column.path).toString() === "true"
        ? "Ενεργό"
        : "Ανενεργό";
    }
    if (column.type === "Percentage") {
      // console.log(_.get(item, column.path));
      // console.log(moment.unix(_.get(item, column.path)));
      return Number(_.get(item, column.path) * 100).toFixed(0) + "%";
    }

    if (column.type === "Date") {
      // console.log(_.get(item, column.path));
      // console.log(moment.unix(_.get(item, column.path)));
      return moment.unix(_.get(item, column.path)).format("DD/MM/YY HH:mm:ss");
    }

    //else return the value
    return _.get(item, column.path);
  };

  createKey = (item, column) => {
    const { uniqueKey1, uniqueKey2 } = this.props;

    // console.log(uniqueKey1, uniqueKey2);
    if (uniqueKey1 !== uniqueKey2) {
      return item[uniqueKey1] + item[uniqueKey2] + (column.path || column.key);
    } else {
      return item[uniqueKey1] + (column.path || column.key);
    }
  };

  render() {
    const { data, columns, uniqueKey1, uniqueKey2 } = this.props;

    return (
      <tbody>
        {data.map(item => (
          <tr
            key={
              item[uniqueKey1] +
              (uniqueKey1 !== uniqueKey2 ? item[uniqueKey2] : "")
            }
          >
            {columns.map(column => (
              <td key={this.createKey(item, column)}>
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
