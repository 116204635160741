import React from "react";
import Form from "../../../components/common/form";
import Joi from "joi-browser";
import { Badge, Col, Row } from "reactstrap";
import { Button } from "../../../components/Ncomponents";
import { electionsAreasGetAll } from "../../../services/listOfValues";
import ElectionsAreasTable from "./electionsAreasTable";
import Pagination from "../../../components/common/pagination";
import SearchBox from "../../../components/common/searchBox";
import NewSelect4 from "../../../components/common/newSelect4";
import LoaderButton from "../../../components/common/loaderButton";
import { paginate } from "../../../components/utils/paginate";
import swal from "sweetalert2";
import _ from "lodash";

class electionLocationsForm extends Form {
  constructor(props) {
    super();
    this.state = {
      data: {
        id: "",
        name: "",
        order: "",
        active: true,
        layerId: ""
      },

      errors: [],
      title: "",

      //clean data
      electionLocationsAllDb: [], //All election areas from the DB // this will be used on the edit to search for layer ID
      electionLocations: [], //the delta between DB and used
      periferies: [], //for Dropdown 1
      periferiakesEnotites: [], //for Dropdown 2
      dimoi: [], //for Dropdown 3

      //The values that will be kept for the drodowns as per SeleCT3 i need onlt to store the id NOT values and labels
      periferiaId: null,
      periferiakiEnotitaId: null,
      dimosId: null,

      //dopdowns
      hideDropdownPeriferia: false,
      hideDropdownPeriferiakiEnotita: true,
      hideDropdownDimos: true,
      showBodyForm: true,
      showCreateForm: false,

      //for the pagination
      pageSize: 5,
      currentPage: 1,
      searchQuery: "",
      sortColumn: { path: "order", order: "asc" },
      ///
      isLoading: false
    };
  }

  schema = {
    id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Όνομα"),
    order: Joi.number()
      .required()
      .label("Αριθμός"),

    active: Joi.boolean()
      .required()
      .label("Ενεργος")
  };

  handleSave = () => {
    this.setState({ isLoading: true });

    const { periferiakiEnotitaId, dimosId } = this.state;

    var data = { ...this.state.data };
    data.order = parseInt(data.order, 10); // to make sure that the order is saved as integer cuase it was saving that as string

    //If there is data on my state object periferia, in the future i need to use the push !!! so to grab all of them in a table
    //now i go only for the last level

    console.log("Periferiakes", this.state.periferiakesEnotites);

    if (this.props.elections.electionType.id === "ELTY003") {
      const area = this.state.periferiakesEnotites.find(
        f => f.id === periferiakiEnotitaId
      );

      data.id = area.id;
      data.name = area.name;
      data.layerId = area.layerId;
    }

    if (this.props.elections.electionType.id !== "ELTY003") {
      if (dimosId) {
        // finalLocations.push(dimos);// it will be used later on ...

        const area = this.state.dimoi.find(f => f.id === dimosId);
        data.id = area.id;
        data.name = area.name;
        data.layerId = area.layerId;
      }
    }

    //it will pass to the parent the obligation to save the object
    // console.log("data from the on save from eAF", data);
    this.props.onSave(data);

    //i will now empty my data so to be ready to create new object
    this.setState({
      showBodyForm: true,
      showCreateForm: false,
      isLoading: false
    });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  validateForm() {
    const { periferiaId, periferiakiEnotitaId, dimosId } = this.state;
    // const { name } = this.state.data;

    var disabled = true;

    //If periferiakes then it needs only one drop down
    if (this.props.elections.electionType.id === "ELTY003") {
      if (periferiaId && periferiakiEnotitaId) {
        disabled = false;
      }
    } //i need 3 layers of locations
    else {
      if (periferiaId && periferiakiEnotitaId && dimosId) {
        disabled = false;
      }
    }

    //Last i check the order
    if (this.state.data.order === "") {
      disabled = true;
    }

    console.log(this.state.errors);

    if (Object.entries(this.state.errors).length !== 0) {
      disabled = true;
    }

    return disabled;
  }

  getPagedDataelectionLocations = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { electionLocations: allelectionLocations } = this.props.elections;

    let filtered = allelectionLocations || []; // in case is undefined
    if (searchQuery)
      filtered = allelectionLocations.filter(c =>
        c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const areas = paginate(sorted, currentPage, pageSize);

    return { totalCountAreas: filtered.length, areas: areas };
  };

  handleChangeNewSelect3 = e => input => {
    if (e === "periferia") {
      //update the input tables of the other two drop downs
      var periferiakesEnotites = this.state.electionLocations.filter(
        pe => pe.layerId === "LOLA02" && pe.pId === input.value
      );
      var dimoi = this.state.electionLocations.filter(
        d => d.layerId === "LOLA03"
      );

      this.setState(
        {
          periferiaId: input.value,
          periferiakiEnotitaId: null,
          periferiakesEnotites,
          dimosId: null,
          dimoi,
          hideDropdownPeriferiakiEnotita: false
        },
        () => console.log(" Prwto select Periferia", this.state)
      );
    }

    if (e === "periferiakiEnotita") {
      if (this.props.elections.electionType.id === "ELTY003") {
        this.setState({
          periferiakiEnotitaId: input.value, //storing the value as id on my attibutes
          dimosId: null,
          hideDropdownDimos: true
        });
      } else {
        //update the input tables of the other one  drop downs
        dimoi = this.state.electionLocations.filter(
          d => d.layerId === "LOLA03" && d.pId === input.value
        );

        //update the state, the value of the dropdown, make null the third dropdown and refresh the options of the 3 drop down
        this.setState({
          periferiakiEnotitaId: input.value, //storing the value as id on my attibutes
          dimosId: null,
          dimoi,
          hideDropdownDimos: false
        });
      }
    }

    if (e === "dimos") {
      this.setState({ dimosId: input.value });
    }
  };

  handleDelete = electionLocation => {
    //console.log("On Delete within the Areas Forrm", this.props, electionLocations);

    swal({
      title: "Εισαι σιγουρος  οτι θελεις να διαγραψεις ",
      text: electionLocation.name,
      icon: "warning",
      buttons: true
    }).then(async willDelete => {
      if (willDelete) {
        if (
          this.state.currentPage > 1 &&
          this.state.currentPage - 1 ===
            (this.props.elections.electionLocations.length - 1) /
              this.state.pageSize //e,g, page 3 , 8 length page size 4=>  (3-1=2) =  ( 8/4= 2) so reduce
        ) {
          const newPage = this.state.currentPage - 1;
          this.setState({ currentPage: newPage });
        }

        //calll
        this.props.onDelete(electionLocation);
      }
    });
  };

  handleUpdate = electionLocations => {
    var level;
    //var locations = [];
    let area;
    let dimosId = "";
    let periferiaId = "";
    let periferiakiEnotitaId = "";

    if (electionLocations.layerId === "LOLA03") {
      level = 3;
    }
    if (electionLocations.layerId === "LOLA02") {
      level = 2;
    }
    if (electionLocations.layerId === "LOLA01") {
      level = 1;
    }

    //BE CAREFUL it want all the AREAS not the delta

    area = this.state.electionLocationsAllDb.find(
      el => el.id === electionLocations.id
    );
    //create a an array with the
    for (var i = 0; i < level; i++) {
      // console.log("Area", area);
      // locations.push(area);

      switch (area.layerId) {
        case "LOLA03":
          dimosId = area.id;

          break;
        case "LOLA02":
          periferiakiEnotitaId = area.id;

          break;
        case "LOLA01":
          periferiaId = area.id;

          break;
        default:
      }

      area = this.state.electionLocations.find(el => el.id === area.pId);
    }

    //this.getNewelectionLocations(); // to load the dropdwons

    //load my
    var periferies = this.state.electionLocationsAllDb.filter(
      p => p.layerId === "LOLA01"
    );
    var periferiakesEnotites = this.state.electionLocationsAllDb.filter(
      p => p.layerId === "LOLA02"
    );
    var dimoi = this.state.electionLocationsAllDb.filter(
      p => p.layerId === "LOLA03"
    );

    const title = "Ενημέρωση εκλογικής περιοχής";

    this.setState({
      data: electionLocations,
      periferiaId,
      periferiakiEnotitaId,
      dimosId,
      periferies,
      periferiakesEnotites,
      dimoi,
      showCreateForm: true,
      showBodyForm: false,
      hideDropdownPeriferia: false,
      hideDropdownPeriferiakiEnotita: true,
      hideDropdownDimos: true,
      title
    });
  };

  handleShowCreateForm = () => {
    const data = { ...this.state.data };
    data.id = "";
    data.order = "";
    data.name = "";
    data.layerId = "";

    this.getNewelectionLocations();

    // //Check if there is periferiakes Ekloges and then show the drop down
    // var hideDropdownDimos = false;

    // if (this.props.elections.electionType.id === "ELTY003") {
    //   hideDropdownDimos = true;
    // }
    const title = "Καταχώρηση νέας εκλογικής περιοχής";
    this.setState(
      {
        data,
        periferiaId: null,
        periferiakiEnotitaId: null,
        dimosId: null,
        hideDropdownPeriferia: false,
        hideDropdownPeriferiakiEnotita: true,
        hideDropdownDimos: true,
        showCreateForm: true,
        showBodyForm: false,
        title
      },
      () => console.log("create form: state", this.state)
    );
  };

  getNewelectionLocations = () => {
    var newAreasOnly = this.state.electionLocations;
    //console.log("getNewElectionsArea, On new Election Areas calls ",this.state.electionLocations);

    // will filter out the data in case of a total new party that will not have any candidate we need to catch that
    if (!this.props.elections.electionLocations) {
      // console.log("getNewElectionsArea ,The elction areas array is missing",this.props.elections.electionLocations);
    } else {
      newAreasOnly = newAreasOnly.filter(
        eldb =>
          !this.props.elections.electionLocations.find(el => eldb.id === el.id)
      );
    }

    var periferies = newAreasOnly.filter(p => p.layerId === "LOLA01");
    var periferiakesEnotites = newAreasOnly.filter(p => p.layerId === "LOLA02");
    var dimoi = newAreasOnly.filter(p => p.layerId === "LOLA03");

    // //map them to format that dropdown wants them
    // periferies = this.mapDropdownIdToValue(periferies);
    // periferiakesEnotites = this.mapDropdownIdToValue(periferiakesEnotites);
    // dimoi = this.mapDropdownIdToValue(dimoi);

    // console.log("Mapping ", periferies);

    this.setState(
      {
        electionLocations: newAreasOnly,
        periferies,
        periferiakesEnotites,
        dimoi
      },
      () => console.log("getNewElectionsArea Update of Areas", this.state)
    );
    //  return newAreasOnly;
  };

  handleCancel = () => {
    const data = { ...this.state.data };
    data.id = "";
    data.order = "";
    data.name = "";
    data.layerId = "";

    this.setState({
      data,
      periferiaId: null,
      periferiakiEnotitaId: null,
      dimosId: null,
      showBodyForm: true,
      showCreateForm: false
    });
  };

  componentDidMount() {
    var electionLocations = electionsAreasGetAll();

    //the first will have the areas that are new( not used ) while the second one should not be updated i need that later on edit to check the layerid
    this.setState({
      electionLocations,
      electionLocationsAllDb: electionLocations
    });
  }

  renderAreasBody() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCountAreas, areas } = this.getPagedDataelectionLocations();

    return (
      <React.Fragment>
        <br />
        <Row>
          <Col xs="6">
            <h3 className="mb-0">
              <Badge color="info">{totalCountAreas}</Badge>
              <span className="m-1" />
              Εκλογικές Περιοχές
            </h3>
          </Col>
          <Col className="text-right" xs="6">
            <button
              onClick={this.handleShowCreateForm}
              className="btn btn-success"
              style={{ marginBottom: 20 }}
              disabled={!this.props.elections.id} // if there is no record on the parent
            >
              Προσθήκη
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ElectionsAreasTable
              electionLocations={areas} ///   {this.props.elections.electionLocations}
              sortColumn={sortColumn}
              onDelete={this.handleDelete} //{this.props.onDelete}
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <Pagination
              itemsCount={totalCountAreas}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderAreasCreate() {
    return (
      <React.Fragment>
        <br />
        <form>
          <Row>
            <Col xs="12">
              <h3 className="mb-0">{this.state.title}</h3>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="4" xs="6">
              <NewSelect4
                name="periferia"
                className="react-select primary"
                classNamePrefix="react-select"
                isSearchable
                placeholder="Επιλογή"
                value={this.state.periferiaId}
                label="Περιφερεια"
                options={this.state.periferies}
                onChange={this.handleChangeNewSelect3("periferia")}
                isDisabled={this.state.hideDropdownPeriferia}
              />
            </Col>

            <Col md="4" xs="6">
              {/* <label htmlFor="Περιφερειακη Ενοτητα">Περιφερειακη Ενοτητα</label> */}
              <NewSelect4
                name="periferiakiEnotita"
                className="react-select primary"
                classNamePrefix="react-select"
                isSearchable
                placeholder="Επιλογή"
                value={this.state.periferiakiEnotitaId}
                label="Περιφερειακη Ενοτητα"
                options={this.state.periferiakesEnotites}
                onChange={this.handleChangeNewSelect3("periferiakiEnotita")}
                isDisabled={this.state.hideDropdownPeriferiakiEnotita}
              />
            </Col>

            <Col md="4" xs="6">
              {/* <label htmlFor="Δήμος">Δήμος</label> */}
              <NewSelect4
                name="dimos"
                className="react-select primary"
                classNamePrefix="react-select"
                isSearchable
                placeholder="Επιλογή"
                value={this.state.dimosId}
                label="Δημος"
                options={this.state.dimoi}
                onChange={this.handleChangeNewSelect3("dimos")}
                isDisabled={this.state.hideDropdownDimos}
              />
            </Col>
          </Row>
          <Row>
            <Col md="3" xs="6">
              {this.renderInput("order", "Σειρά")}
            </Col>

            <Col md="3" xs="2">
              {this.renderCheck(
                "active",
                "Ενεργο",
                this.state.data.active,
                "TypeActive"
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <Button
                // leftLabel="now-ui-icons arrows-1_minimal-left"
                // round
                onClick={this.handleCancel} //    {this.props.onCancel}
              >
                Ακύρωση
              </Button>
              <span className="m-2"> </span>

              <LoaderButton
                color="success"
                // round
                // leftLabel="now-ui-icons ui-1_check"
                onClick={this.handleSave}
                disabled={this.validateForm()}
                isLoading={this.state.isLoading}
                text="Αποθηκευση"
                loadingText="Saving...."
              />
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showBodyForm === true
          ? this.renderAreasBody()
          : this.renderAreasCreate()}
      </React.Fragment>
    );
  }
}

export default electionLocationsForm;
