import React, { Component } from "react";
import Excel from "exceljs/modern.browser";

import LoaderButton from "../../../components/common/loaderButton";
import { saveAs } from "file-saver";
import { fresultsGetAll } from "../../../services/fakeResultsService";
import { resultsGetAll } from "../../../services/resultsService";
import { felectionsGetAll } from "../../../services/fakeElectionsService";
import { electionsGetAll } from "../../../services/electionsService";
import { reportTypesGetAll } from "../../../services/listOfValues";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import NewSelect4 from "../../../components/common/newSelect4";
import moment from "moment";

import Dropzone from "dropzone";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// import { Stream } from "stream";
// import { hexToRgb } from "assets/jss/material-kit-pro-react.jsx";
// import shadows from "@material-ui/core/styles/shadows";

class MassiveUpload extends Component {
  state = {
    //elections: [],
    results: [],
    selectedFile: null,
    resultsToUpdate: [],

    //DropDowns
    reportTypeId: "",
    reportTypeName: "",
    reportTypes: [],
    hideDropdownreportType: false,

    //election
    electionId: "",
    electionName: "",
    elections: [],
    hideDropdownElection: false,

    //Location
    //location: { id: "", name: "" },
    locationId: "",
    locationName: "",
    locations: [],
    hideDropdownLocation: false
  };

  dropzoneData = () => {
    // this variable is to delete the previous image from the dropzone state
    // it is just to make the HTML DOM a bit better, and keep it light
    let currentSingleFile = undefined;
    // single dropzone file - accepts only images
    new Dropzone(document.getElementById("dropzone-single"), {
      url: "/",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-single"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
        .innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function() {
        this.on("addedfile", function(file) {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
        });
      }
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
    // this variable is to delete the previous image from the dropzone state
    // it is just to make the HTML DOM a bit better, and keep it light
    let currentMultipleFile = undefined;
    // multiple dropzone file - accepts any type of file
    new Dropzone(document.getElementById("dropzone-multiple"), {
      url: "https://",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-multiple"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-multiple")[0]
        .innerHTML,
      maxFiles: null,
      acceptedFiles: null,
      init: function() {
        this.on("addedfile", function(file) {
          if (currentMultipleFile) {
          }
          currentMultipleFile = file;
        });
      }
    });
    document.getElementsByClassName("dz-preview-multiple")[0].innerHTML = "";
  };

  async componentDidMount() {
    var results;
    var elections;
    // var partiesAll;

    //get the report Types from the list of value
    const reportTypes = reportTypesGetAll();

    if (this.props.production === true) {
      results = await resultsGetAll();
      elections = await electionsGetAll();
      // partiesAll = await partiesGetAll();
    }
    if (this.props.production === false) {
      results = fresultsGetAll();
      elections = felectionsGetAll();
      //partiesAll = fpartiesGetAll();
    }

    console.log(elections);

    // this.dropzoneData();
    //Order the parties Table
    results.sort((a, b) => (a.number > b.number ? 1 : -1));

    this.setState({ results, elections, reportTypes });
  }

  handleSubmit() {
    console.log("Handle Submit");
  }

  generateResultsInputExcel = () => {
    // const excel = require("exceljs");
    var lastRowNum = "";
    var lastColumnNum = "";

    var workbook = new Excel.Workbook();
    workbook.creator = "Theodoros Malakasis";
    workbook.created = new Date();
    workbook.modified = new Date();

    var sheetResults = workbook.addWorksheet("Results", {
      properties: { tabColor: { argb: "16365C" } }
    });

    //add a row
    var rowTitle = [];
    rowTitle[1] = this.state.electionName + " " + this.state.locationName; //"Περιφερειακες Εκλογες";

    sheetResults.addRow(rowTitle);

    //The static Columns
    // var columns = [
    //   { header: "ID ΕκΑν", key: "idElection", width: 6 },
    //   { header: "ID EκΚε", key: "idVstation", width: 6 },
    //   { header: "α/α", key: "number", width: 6 },
    //   { header: "Εκλογικό Κέντρο", key: "nameVstation", width: 30 },
    //   { header: "Εγγεγραμμένοι", key: "registeredVoters", width: 8 },
    //   { header: "Ψήφισαν", key: "voted", width: 9 },
    //   { header: "Άκυρα", key: "invalidVotes", width: 7 },
    //   { header: "Λευκά", key: "blankVotes", width: 7 }
    // ];

    var columns = [
      { key: "idElection", width: 6 },
      { key: "idVstation", width: 6 },
      { key: "number", width: 6 },
      { key: "nameVstation", width: 30 },
      { key: "registeredVoters", width: 8 },
      { key: "voted", width: 9 },
      { key: "invalidVotes", width: 7 },
      { key: "blankVotes", width: 7 }
    ];

    var columnsHeadings = [
      "ID ΕκΑν",
      "ID EκΚε",
      "α/α",
      "Εκλογικό Κέντρο",
      "Εγγεγραμμένοι",
      "Ψήφισαν",
      "Άκυρα",
      "Λευκά"
    ];

    //bring the data from the server

    //I will generate the extra columns for the Parties Votes

    ///DRAFTTTTTT this will be chnaged with the GUI to select the election

    console.log(this.state);
    // var electionId;
    // if (this.props.production === false) {
    //   electionId = "EL1";
    // } else electionId = "34995f70-74da-11e9-9b6f-dd59ad44297a";

    const election = this.state.elections.find(
      f => f.id === this.state.electionId
    );

    for (var i = 0; i < election.electionParties.length; i++) {
      const party = election.electionParties[i];
      // const column = { header: party.name, key: party.id, width: 17 };
      const column = { key: party.id, width: 17 };
      //console.log("column", column);
      columns.push(column);
      columnsHeadings.push(party.name);
      //console.log(election.electionParties[i]);
    }

    //
    lastColumnNum = columns.length;

    //Merge cells from column 1 to last column for row 1
    sheetResults.mergeCells(1, 1, 1, lastColumnNum);

    //The sheet now has the
    //sheetResults.addRow(rowTitle);
    sheetResults.getRow(2).values = columnsHeadings;
    sheetResults.columns = columns;

    // console.log("electionId", this.state.electionId);
    // const electionLocationId = this.state.locationId;

    //Bring the rows now
    const resultsLocal = this.state.results.filter(
      f =>
        f.idElection === this.state.electionId &&
        f.electionLocationId === this.state.locationId
    );
    for (var x = 0; x < resultsLocal.length; x++) {
      const row = {};
      row.number = resultsLocal[x].number;
      row.idElection = resultsLocal[x].idElection;
      row.idVstation = resultsLocal[x].idVstation;
      row.nameVstation = resultsLocal[x].nameVstation;
      row.registeredVoters = resultsLocal[x].registeredVoters;
      row.voted = resultsLocal[x].voted;
      row.invalidVotes = resultsLocal[x].invalidVotes;
      row.blankVotes = resultsLocal[x].blankVotes;

      const votesParties = resultsLocal[x].votesParties;

      //And the parties votes
      for (var z = 0; z < votesParties.length; z++) {
        const id = votesParties[z].id;

        row[id] = votesParties[z].votes;
      }

      sheetResults.addRow(row);

      console.log(row);
      console.log(resultsLocal[x]);
    }

    lastRowNum = x + 2;
    console.log("lastRowNum", lastRowNum);

    //add autofilter
    sheetResults.autoFilter = {
      from: { row: 2, column: 1 },
      to: { row: lastRowNum, column: lastColumnNum }
    };

    //add frezze panes xSplkit i sthe columns to freeze and ySplit the rows
    sheetResults.views = [{ state: "frozen", xSplit: 0, ySplit: 2 }];

    //create the pattern for the resluts number till 200 more
    var empty = [""];
    for (var d = 1; d < lastRowNum + 200; d++) {
      if (d > lastRowNum) {
        sheetResults.addRow(empty);
      }

      var rowpattern = sheetResults.getRow(d);
      rowpattern.fill = {
        type: "pattern",
        pattern: "lightUp",
        fgColor: { argb: "000000" }
        // bgColor: { argb: "FF00CED1" } //{ argb: "FFFFFF00" }
      };
    }

    //Beautification and validation
    sheetResults.eachRow(function(row, rowNumber) {
      //Title Row

      if (rowNumber === 1) {
        row.height = 40;

        sheetResults.getCell("A1").alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true
        };
        sheetResults.getCell("A1").font = {
          name: "Comic Sans Ms",
          family: 4,
          size: 16,
          color: { argb: "FFFFFFFF" },
          bold: true
        };

        //console.log(hexToRgb("#92D050"));

        sheetResults.getCell("A1").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "16365C" }
          //fgColor: { argb:  hexToRgb("#92D050")  } // "FF191970" }
          // bgColor: { argb: "FF00CED1" } //{ argb: "FFFFFF00" }
        };

        // row.eachCell({ includeEmpty: true }, function(cell, colnumber) {
        //   cell.fill = {
        //     type: "pattern",
        //     pattern: "solid",
        //     fgColor: { argb: "FF00BFFF" }
        //     // bgColor: { argb: "FF00CED1" } //{ argb: "FFFFFF00" }
        //   };

        //   cell.alignment = {
        //     vertical: "middle",
        //     horizontal: "middle",
        //     indent: 1,
        //     wrapText: true
        //   };
        // });
      }
      //Column Rows
      if (rowNumber === 2) {
        row.height = 40;
        row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B8CCE4" }
            // bgColor: { argb: "FF00CED1" } //{ argb: "FFFFFF00" }
          };

          cell.alignment = {
            vertical: "middle",
            horizontal: "left",
            indent: 1,
            wrapText: true
          };
          cell.font = {
            name: "Comic Sans Ms",
            family: 4,
            size: 12,
            color: { argb: "000000" },
            bold: true
          };
        });
      }

      //Body Rows
      if (rowNumber > 2) {
        row.height = 30;
        row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
          cell.alignment = {
            vertical: "middle",
            horizontal: "left",
            indent: 1,
            wrapText: true
          };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF" }
            // bgColor: { argb: "FF00CED1" } //{ argb: "FFFFFF00" }
          };
          cell.font = {
            name: "Comic Sans Ms",
            family: 4,
            size: 10,
            color: { argb: "000000" }
          };

          //Validation for Whole Numbers
          if (colNumber > 4) {
            cell.dataValidation = {
              type: "whole",
              operator: "greaterThan",
              showErrorMessage: true,
              formulae: [-1],
              errorStyle: "error",
              errorTitle: "Ακέραιος Αριθμός",
              error: "Παρακαλώ εκχώρησε ακέραια θετική τιμή"
            };
            console.log(cell.value);
          }
        });
      }
    });

    //Create the file Name
    const fileName =
      this.state.electionName +
      "-" +
      this.state.locationName +
      "-" +
      this.state.reportTypeName +
      " v" +
      moment().format("YYYY_MM_DD HH:mm:ss") +
      ".xlsx";

    console.log(
      "Name of File",
      fileName
      //moment().format("YYYY_MM_DD HH:mm:ss")
    );

    //create the file
    workbook.xlsx.writeBuffer().then(function(data) {
      var blob = new Blob(
        [data]
        // , {
        // type:
        //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        // }
      );
      saveAs(blob, fileName); //"Results.xlsx");
    });
  };

  validateTemplateCreationButton() {
    var disabled = true;

    if (
      this.state.reportTypeId &&
      this.state.electionId &&
      this.state.locationId
    ) {
      disabled = false;
    }

    return disabled;
  }

  handleFile = () => {
    console.log("File uplaod");
  };

  onChangeHandler = event => {
    console.log("File uplaod josh");
    console.log(event.target.files[0].name);
    var file = event.target.files[0];

    var fileReader = new FileReader();
    var arrayBuffer;
    var results = [];
    var parties = [];
    //fileReader.readAsBinaryString(file);
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = function() {
      arrayBuffer = fileReader.result;
      console.log("arrayBuffer", arrayBuffer);
      //   var bytes = new Uint8Array(arrayBuffer);
      //   console.log(bytes);

      var workbook = new Excel.Workbook();
      workbook.xlsx.load(arrayBuffer).then(function() {
        // use workbook

        //Get sheet by Name
        var worksheet = workbook.getWorksheet("Results");

        worksheet.eachRow(function(row, rowNumber) {
          if (rowNumber === 1) {
            row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
              console.log("Cell " + colNumber + " = " + cell.value);
              if (colNumber > 8) {
                // console.log(cell.value);
                parties.push(cell.value);
              }
            });
          }

          //console.log(parties);
          if (rowNumber > 1) {
            // create the onject Result
            var result = {};
            result.idElection = row.values[1];
            result.idVstation = row.values[2];
            result.number = row.values[3];

            result.nameVstation = row.values[4];
            result.registeredVoters = row.values[5];
            result.voted = row.values[6];
            result.invalidVotes = row.values[7];
            result.blankVotes = row.values[8];

            for (var i = 0; i < parties.length; i++) {
              // console.log(parties.length);
              result[parties[i]] = parseInt(row.values[9 + i]);
            }

            results.push(result);

            //console.log(row.values);
            //console.log(row.values[1]);

            // console.log(
            //   "Row " + rowNumber + " = " + JSON.stringify(row.values)
            // );
          }
        });

        console.log(results);
      });
    };

    this.setState({
      selectedFile: event.target.files[0],
      resultsToUpdate: results
    });
  };

  readFile = () => {
    console.log("Reading:", this.state.selectedFile);
    var workbook = new Excel.Workbook();
    //workbook = this.state.selectedFile;

    // var data = new Blob(this.state.selectedFile);

    // workbook.xlsx.readFile("../massiveUpload/Results.xlsx").then(function() {
    //   console.log("Test");
    // });

    // var worksheet = workbook.getWorksheet("Results");
    // //Get Lastrow
    // var row = worksheet.lastRow;

    // console.log(row);

    // stream.pipe(workbook.xlsx.createInputStream(this.state.selectedFile));
  };

  handleChangeNewSelect3 = e => input => {
    console.log("change ");

    if (e === "reportType") {
      const reportTypeId = input.value;
      const reportTypeName = input.label;

      this.setState({
        reportTypeId: reportTypeId,
        reportTypeName: reportTypeName
      });
    }

    if (e === "elections") {
      const electionId = input.value;
      const electionName = input.label;

      const election = this.state.elections.find(f => f.id === electionId);
      const locations = election.electionLocations;
      console.log(locations);

      //   let { location } = { ...this.state };
      //   location.id = null;

      this.setState({
        electionId: electionId,
        electionName: electionName,
        locations: locations,
        // location: location,
        locationId: null
      });
    }

    if (e === "locations") {
      //   const { location } = { ...this.state };
      //   location.id = input.value;
      //   location.name = input.label;
      const locationId = input.value;
      const locationName = input.label;
      //   console.log(location);
      this.setState({ locationId: locationId, locationName: locationName });
      //   this.setState({ location: location });
    }
  };

  render() {
    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="4" xs="12">
              <Card className="mb-4">
                <CardHeader>
                  {/* <h6 className="surtitle">Διαλεξε τον τυπο</h6> */}
                  <h5 className="h3 mb-0">
                    <Badge color="info">1</Badge>
                    <span className="m-1" />
                    Διαλεξε Τυπο
                  </h5>
                </CardHeader>
                <CardBody>
                  {/* <Col md="4" xs="6"> */}
                  {/* <label htmlFor="Περιφερειακη Ενοτητα">Περιφερειακη Ενοτητα</label> */}
                  <NewSelect4
                    name="reportType"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Επιλογή"
                    value={this.state.reportTypeId}
                    label="Τυπος Αναφορας"
                    options={this.state.reportTypes}
                    onChange={this.handleChangeNewSelect3("reportType")}
                    isDisabled={this.state.hideDropdownreportType}
                  />
                  {/* </Col> */}
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" xs="12">
              <Card className="mb-4">
                <CardHeader>
                  {/* <h6 className="surtitle">Διαλεξε τον τυπο</h6> */}
                  <h5 className="h3 mb-0">
                    <Badge color="info">2</Badge>
                    <span className="m-1" />
                    Διάλεξε Αναμετρηση
                  </h5>
                </CardHeader>
                <CardBody>
                  <NewSelect4
                    name="elections"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Επιλογή"
                    value={this.state.electionId}
                    label="Εκλογική αναμέτρηση"
                    options={this.state.elections}
                    onChange={this.handleChangeNewSelect3("elections")}
                    isDisabled={this.state.hideDropdownElection}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" xs="12">
              <Card className="mb-4">
                <CardHeader>
                  {/* <h6 className="surtitle">Διαλεξε τον τυπο</h6> */}
                  <h5 className="h3 mb-0">
                    <Badge color="info">3</Badge>
                    <span className="m-1" />
                    Διάλεξε Περιοχή
                  </h5>
                </CardHeader>

                <CardBody>
                  <NewSelect4
                    name="locations"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Επιλογή"
                    value={this.state.locationId}
                    label="Εκλογική αναμέτρηση"
                    options={this.state.locations}
                    onChange={this.handleChangeNewSelect3("locations")}
                    isDisabled={this.state.hideDropdownLocation}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="4" xs="12">
              <Card className="mb-4">
                <CardHeader>
                  {/* <h6 className="surtitle">Διαλεξε τον τυπο</h6> */}
                  <h5 className="h3 mb-0">
                    <Badge color="info">4</Badge>
                    <span className="m-1" />
                    Δημιουργία Template
                  </h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4" sm="12">
                      <LoaderButton
                        color="success"
                        onClick={this.generateResultsInputExcel}
                        disabled={this.validateTemplateCreationButton()}
                        //isLoading={this.state.isLoading}
                        text="Δημιουργία"
                        loadingText="Saving...."
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" xs="12">
              <Card className="mb-4">
                <CardHeader>
                  {/* <h6 className="surtitle">Διαλεξε τον τυπο</h6> */}
                  <h5 className="h3 mb-0">
                    <Badge color="info">5</Badge>
                    <span className="m-1" />
                    Upload Template
                  </h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4" sm="12">
                      <input
                        type="file"
                        name="file"
                        onChange={this.onChangeHandler}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" xs="12">
              <Card className="mb-4">
                <CardHeader>
                  {/* <h6 className="surtitle">Διαλεξε τον τυπο</h6> */}
                  <h5 className="h3 mb-0">
                    <Badge color="info">6</Badge>
                    <span className="m-1" />
                    Submit Data
                  </h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4" sm="12">
                      <LoaderButton
                        color="success"
                        onClick={this.handleSubmit}
                        //disabled={this.validateForm()}
                        //isLoading={this.state.isLoading}
                        text="Submit"
                        loadingText="Saving...."
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} xs={12}>
              <LoaderButton
                color="success"
                onClick={this.readFile}
                //disabled={this.validateForm()}
                //isLoading={this.state.isLoading}
                text="Diavse"
                loadingText="Saving...."
              />
            </Col>
          </Row>
          <Row>
            <Card>
              <CardHeader>
                <h3 className="mb-0">Dropzone</h3>
              </CardHeader>
              <CardBody>
                <div
                  className="dropzone dropzone-single mb-3"
                  id="dropzone-single"
                >
                  <div className="fallback">
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        id="projectCoverUploads"
                        type="file"
                        //onChange={this.handleFile}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="projectCoverUploads"
                      >
                        DIALEKSE
                      </label>
                    </div>
                  </div>
                  <div className="dz-preview dz-preview-single">
                    <div className="dz-preview-cover">
                      <img
                        alt="..."
                        className="dz-preview-img"
                        data-dz-thumbnail=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="dropzone dropzone-multiple"
                  id="dropzone-multiple"
                >
                  <div className="fallback">
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        id="customFileUploadMultiple"
                        multiple="multiple"
                        type="file"
                        onChange={this.handleFile}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFileUploadMultiple"
                      >
                        Choose file
                      </label>
                    </div>
                  </div>
                  <ListGroup
                    className=" dz-preview dz-preview-multiple list-group-lg"
                    flush
                  >
                    <ListGroupItem className=" px-0">
                      <Row className=" align-items-center">
                        <Col className=" col-auto">
                          <div className=" avatar">
                            <img
                              alt="..."
                              className=" avatar-img rounded"
                              data-dz-thumbnail
                              src="..."
                            />
                          </div>
                        </Col>
                        <div className=" col ml--3">
                          <h4 className=" mb-1" data-dz-name>
                            ...
                          </h4>
                          <p className=" small text-muted mb-0" data-dz-size>
                            ...
                          </p>
                        </div>
                        <Col className=" col-auto">
                          <Button size="sm" color="danger" data-dz-remove>
                            <i className="fas fa-trash" />
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </CardBody>
            </Card>
          </Row>*/}
        </Container>
      </React.Fragment>
    );
  }
}

export default MassiveUpload;
