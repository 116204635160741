import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "../../../components/N1components/Header/Header.jsx";
import HeaderLinks from "../../../components/N1components/Header/HeaderLinks.jsx";
import Footer from "../../../components/N1components/Footer/Footer.jsx";
import GridContainer from "../../../components/N1components/Grid/GridContainer.jsx";
import GridItem from "../../../components/N1components/Grid/GridItem.jsx";

import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.jsx";

import image from "../../../assets/img/clint-mckoy.jpg";

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [1]
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="Ιωάννης Μαλακάσης"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          {/* <div className={classes.container}> */}
          {/* <div className={classes.contentCenter}>
            <GridContainer>
              <GridItem md={12}>
                <h1>Ιωάννης Μαλακασης</h1>
                <h2 className={classes.subTitle}>Σύντομα κοντά σας</h2>
                <h4 className={classes.description}>Κάτι καλό έρχεται ....</h4>
              </GridItem>
            </GridContainer>
          </div> */}

          <div className={classes.contentCenter}>
            <GridContainer>
              <GridItem md={12}>
                <h1>Δυστυχώς δεν βρέθηκε η σελίδα</h1>
                <h2 className={classes.subTitle}>Παρακαλώ ελέγξετε το url</h2>
              </GridItem>
            </GridContainer>
          </div>
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default withStyles(errorPageStyle)(ErrorPage);
