import React, { Component } from "react";
import Table from "../../../components/common/table";

class CandidatesVotesAll extends Component {
  state = {
    data: {
      columns: [
        { path: "order", label: "Σειρά" },
        { path: "name", label: "Κόμμα" },
        { path: "votes", label: "Ψήφοι" }
      ]
    }
  };

  render() {
    const { candidatesVotesAll, onSort, sortColumn } = this.props;
    //console.log("table candidatesVotesAll", candidatesVotesAll);
    return (
      <Table
        columns={this.state.data.columns}
        data={candidatesVotesAll}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default CandidatesVotesAll;
