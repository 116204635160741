import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

const Check = ({ name, label, checked, type, error, ...rest }) => {
  if ((type = "TypeActive")) {
    label = checked ? "Ενεργό" : "Μη Eνεργό";
    //console.log("Dynamic", DynamicTitle);
  }

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Checkbox
        type="checkbox"
        checked={checked}
        {...rest}
        name={name}
        id={name}
        className="form-control"
        color="primary"
      />
    </div>
  );
};

export default Check;
