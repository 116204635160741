import { API } from "aws-amplify";

export function vstationsSave(vstation) {
  if (vstation.id) {
    const body = { ...vstation };
    delete body.id;
    return API.put("vstations", `/vstations/${vstation.id}`, {
      body: body
    });
  }
  return API.post("vstations", "/vstations", {
    body: vstation
  });
}
export function vstationsGetOne(vstationId) {
  return API.get("vstations", `/vstations/${vstationId}`);
}

export function vstationsGetAll() {
  return API.get("vstations", "/vstations");
}

export function vstationsDelete(vstationId) {
  return API.del("vstations", `/vstations/${vstationId}`);
}
