import React, { Component } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  fresultsGetAll
  //resultsGetElectionAndArea
} from "../../../services/fakeResultsService";

import {
  resultsGetAll,
  resultsGetAllByElection,
  resultsDelete
  // resultsGetElectionAndArea
} from "../../../services/resultsService";

import {
  felectionsGetAll,
  felectionsGetOne
} from "../../../services/fakeElectionsService";
import { electionsGetAll } from "../../../services/electionsService";

import Pagination from "../../../components/common/pagination";
import { paginate } from "../../../components/utils/paginate";

import SearchBox from "../../../components/common/searchBox";
import NewSelect4 from "../../../components/common/newSelect4";
import _ from "lodash";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { electionLocations } from "../../../services/listOfValues";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

import PartiesVotesAllTable from "../electionDashboard/partiesVotesAllTable";
import ResultsTableReadOnly from "../results/resultsTableReadOnly";
import ResultsCandidatesTable from "../results/resultsCandidatesTable";
import { fpartiesGetAll } from "../../../services/fakePartiesService";
import { partiesGetAll } from "../../../services/partiesService";
import EnotitesHeader from "components/Headers/enotitesHeader.jsx";
import moment from "moment";
import CandidatesVotesAllTable from "./candidatesVotesAllTable";

import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js";

import {
  chartOptions,
  parseOptions
  // chartExample2,
  // chartExample3,
  // chartExample4,
  // chartExample5,
  // chartExample6,
  // chartExample7
} from "variables/charts.jsx";

class Enotites extends Component {
  state = {
    results: [],
    resultsAll: [],

    //for the pagination
    pageSize: 10,
    currentPage: 1,
    searchQuery: "",
    sortColumn: { path: "nameVstation", order: "asc" },
    isDeleting: false,

    //Pagination of Candidates
    pageSizeCan: 10,
    currentPageCan: 1,
    sortColumnCan: { path: "nameVstation", order: "asc" },
    searchQueryCan: "",

    //drodowns
    electionId: "",
    election: [],
    elections: [],
    electionsLocationId: "",
    electionsLocationName: "",
    electionsLocations: [],
    electionsLocationsFiltered: [],
    hideDropdownElectionsLocations: true,
    hideDropdownElections: false,

    //Parties
    parties: [],
    partiesAll: [],
    //Party Votes
    partiesVotes: [],

    sortColumnPartiesVotes: { path: "order", order: "asc" },
    sortColumnCandidatesVotes: { path: "order", order: "asc" },

    //for the election header
    lastUpdateOn: "",
    numOfVstationsAll: "",
    numOfVstationsWithResults: "",

    //reults table
    hideResultsTable: true,
    hTabs: "ht1",
    chart: {
      data: {
        labels: [
          // "ND", "Pasok", "Siriza", "KKE", "Xrisi Augi"
        ],
        datasets: [
          {
            data: [], //[123, 25, 7, 8, 89],
            backgroundColor: [], //  ["#172b4d","#172b4d"],

            label: "Dataset 1"
          }
        ]
      },
      options: {
        responsive: true,

        plugins: {
          labels: {
            render: "percentage",
            fontColor: ["white"],
            precision: 2
          }
        },

        legend: {
          display: true,
          position: "bottom"
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var total = dataset.data.reduce(function(
                previousValue,
                currentValue,
                currentIndex,
                array
              ) {
                return previousValue + currentValue;
              });
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = Math.floor((currentValue / total) * 100 + 0.5);
              return percentage + "%";
            }
          }
        }
      }
    }
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  chartUpdate = (partiesVotes, parties) => {
    // console.log(partiesVotes);

    const { chart } = { ...this.state };
    var labels = [];
    var data = [];
    var backColor = [];

    for (var i = 0; i < partiesVotes.length; i++) {
      const index = parties.findIndex(e => e.id === partiesVotes[i].id);

      //console.log(parties[index].name, parties[index].color);

      partiesVotes[i]["color"] = parties[index].color;

      //Fix the chart

      labels.push(parties[index].name);
      data.push(partiesVotes[i].votes);
      backColor.push(parties[index].color);

      //this.state.chart.data.labels.push(partiesVotes[i].name);
    }

    chart.data.labels = labels;
    chart.data.datasets[0].data = data;
    chart.data.datasets[0].backgroundColor = backColor;

    // console.log(labels, data, backColor);
    // console.log(" chart.data.labels", chart.data.labels);
    //console.log("partiesTable on color", partiesVotes);
    // this.setState({ partiesVotes: partiesVotes });

    this.setState({ chart: chart });
    //console.log("this.state", this.state.chart);
  };

  async componentDidMount() {
    //Chec the storage

    const elid = localStorage.getItem("electionId");
    const loid = localStorage.getItem("electionsLocationId");

    // console.log("local storage retrieve", elid, loid);

    var results;
    var elections;
    var partiesAll;
    if (this.props.production === true) {
      results = await resultsGetAll();

      elections = await electionsGetAll();
      partiesAll = await partiesGetAll();
    }
    if (this.props.production === false) {
      results = fresultsGetAll();
      elections = felectionsGetAll();
      partiesAll = fpartiesGetAll();
    }

    console.log("results on CDM", results);

    //Keep only the active elections
    elections = elections.filter(f => f.active === true);

    //If there is only one record
    var electionId = "";
    if (elections.length === 1) {
      // console.log("elections.length === 1", elections);
      electionId = elections[0].id;

      const electionsLocationsFiltered = elections[0].electionLocations;
      const parties = elections[0].electionParties;
      // console.log("On Election Selection,election", election);
      this.setState({
        electionsLocationsFiltered: electionsLocationsFiltered,
        hideDropdownElectionsLocations: false,
        parties: parties
      });
    }

    // console.log("On mount elections", elections);
    this.setState(
      {
        resultsAll: results,
        results: results,
        elections: elections,
        partiesAll: partiesAll,
        electionId: electionId
      },
      () => console.log("Call Back on the cdm", this.state)
    );

    if (elid && loid) {
      //If i have data from the local storage
      const election = elections.find(f => f.id === elid);
      console.log("Mpika sto elid ,", elid, loid, election);
      //if found an election proceed to load data
      //i you swap fron prod to dev it retrives the dev values an crashes thats why i added that
      if (election) {
        console.log("Mpika sto elid");
        const electionsLocations = election.electionLocations;

        results = results.filter(
          c => (c.idElection === elid) & (c.electionLocationId === loid)
        );

        //find the last udpdated item
        const updatedOnList = results.map(d => moment.unix(d.updatedOn));
        let lastUpdateOn = moment.max(updatedOnList);

        lastUpdateOn = moment(lastUpdateOn).unix();
        //console.log("lastUpdate", lastUpdateOn);

        // console.log("election", election);
        const parties = election.electionParties;
        // console.log("parties", parties);
        //find the number of vstations
        const numOfVstationsAll = results.length;

        var obj = _.countBy(results, function(rec) {
          return rec.vStatus.id === "VOSTO04" || rec.vStatus.id === "VOSTO05";
        });

        const numOfVstationsWithResults = obj.true;

        //console.log("elid", results);

        this.setState(
          {
            electionId: elid,
            electionsLocationId: loid,
            hideDropdownElectionsLocations: false,
            electionsLocationsFiltered: electionsLocations,
            hideResultsTable: false,
            results: results,
            lastUpdateOn: lastUpdateOn,
            numOfVstationsAll: numOfVstationsAll,
            numOfVstationsWithResults: numOfVstationsWithResults,
            parties: parties
          }
          //,
          // () => console.log("Call Back on local storage", this.state.parties)
        );

        const partiesVotes = this.createVotePartiesTable(results, parties);

        const candidatesVotes = this.createVoteCandidatesTable(
          results,
          loid,
          parties,
          partiesAll
        );

        this.chartUpdate(partiesVotes, partiesAll);
      } else {
        // could not find the election so maybe it has kept a previous local storage of dev
        localStorage.clear();
      }
    }
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handlePageChangeCan = page => {
    this.setState({ currentPageCan: page });
  };

  handleSearchCan = query => {
    this.setState({ searchQueryCan: query, currentPageCan: 1 });
  };

  handleSortCan = sortColumnCan => {
    this.setState({ sortColumnCan });
  };

  handleSortCandidatesVotes = sortColumnCandidatesVotes => {
    this.setState({ sortColumnCandidatesVotes });
  };

  handleSortPartiesVotes = sortColumnPartiesVotes => {
    this.setState({ sortColumnPartiesVotes });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  getPagedDataCan = () => {
    const {
      pageSizeCan,
      currentPageCan,
      sortColumnCan,
      searchQueryCan,
      results: allResults
    } = this.state;

    // console.log("on paged data", this.state);

    let filtered = this.state.results;
    if (searchQueryCan)
      filtered = allResults.filter(v =>
        v.nameVstation.toLowerCase().startsWith(searchQueryCan.toLowerCase())
      );

    const sorted = _.orderBy(
      filtered,
      [sortColumnCan.path],
      [sortColumnCan.order]
    );

    const results = paginate(sorted, currentPageCan, pageSizeCan);

    return { totalCount: filtered.length, data: results };
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      results: allResults
    } = this.state;

    // console.log("on paged data", this.state);

    let filtered = this.state.results;
    //  console.log("filtered", filtered);

    if (searchQuery)
      filtered = allResults.filter(v =>
        v.nameVstation.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const results = paginate(sorted, currentPage, pageSize);

    console.log("results", results);
    return { totalCount: filtered.length, data: results };
  };

  getPagedDataPartiesVotes = () => {
    const { sortColumnPartiesVotes, partiesVotes } = this.state;

    const sorted = _.orderBy(
      partiesVotes,
      [sortColumnPartiesVotes.path],
      [sortColumnPartiesVotes.order]
    );

    // const results = paginate(sorted, currentPageResults, pageSizeResults);

    return { totalCount: sorted.length, data: sorted };
  };

  getPagedDataCandidatesVotes = () => {
    const { sortColumnCandidatesVotes, candidatesVotes } = this.state;

    const sorted = _.orderBy(
      candidatesVotes,
      [sortColumnCandidatesVotes.path],
      [sortColumnCandidatesVotes.order]
    );

    // const results = paginate(sorted, currentPageResults, pageSizeResults);

    return { totalCount: sorted.length, data: sorted };
  };

  createVotePartiesTable = (results, parties) => {
    //create the table for the adding the votes

    //console.log(" this.state.parties;", this.state.parties);
    let partiesTable = [];
    partiesTable.lentgh = 0;

    //partiesTable = [...this.state.parties];
    partiesTable = JSON.parse(JSON.stringify(parties));

    // console.log(" partiesTable;", partiesTable);
    //For all the records in the results
    for (var r = 0; r < results.length; r++) {
      //for all the votes of the parties
      for (var vp = 0; vp < results[r].votesParties.length; vp++) {
        //find the party ID

        const partyId = results[r].votesParties[vp].id;
        //Find which is the record in parties tavble that i will add the votes
        const index = partiesTable.findIndex(e => e.id === partyId);

        // console.log("partyId", partyId);

        //For that given index on the votes attributes add the previous one and the new one
        // the previous one could emoty on the first round thats whu the || 0

        // console.log(
        //   parseInt(partiesTable[index]["votes"], 10),
        //   results[r].votesParties[vp].name,
        //   parseInt(results[r].votesParties[vp].votes, 10)
        // );

        partiesTable[index]["votes"] =
          (parseInt(partiesTable[index]["votes"], 10) || 0) +
          parseInt(results[r].votesParties[vp].votes, 10);

        // results[r][results[r].votesParties[vp].name] =
        //   results[r].votesParties[vp].votes;
      }
      // console.log("!!!", this.props.results.length);
    }

    //Order the parties Table
    partiesTable.sort((a, b) => (a.votes < b.votes ? 1 : -1));

    //fix tye order of the data
    for (var i = 0; i < partiesTable.length; i++) {
      partiesTable[i].order = i + 1;
    }

    // return partiesTable;
    this.setState({ partiesVotes: partiesTable });

    return partiesTable;
  };

  handleChangeNewSelect3 = e => input => {
    if (e === "elections") {
      //console.log("On Election Selection");
      const electionId = input.value;

      const election = this.state.elections.find(f => f.id === electionId);
      const electionsLocationsFiltered = election.electionLocations;
      // console.log("On Election Selection,election", election);

      const parties = election.electionParties;

      // console.log("parties", parties);

      this.setState(
        {
          electionId: electionId,
          electionsLocationId: null,
          election: election,
          electionsLocationsFiltered: electionsLocationsFiltered,
          hideDropdownElectionsLocations: false,
          hideResultsTable: true,
          parties: parties
        },
        () => console.log(this.state)
      );
    }
    if (e === "electionsLocations") {
      // console.log("Location Selection");

      const locationId = input.value;
      const results = this.state.resultsAll.filter(
        f =>
          f.idElection === this.state.electionId &&
          f.electionLocationId === locationId
      );

      // console.log("??????????", results);

      var election = this.state.elections.find(
        f => f.id === this.state.electionId
      );
      const parties = election.electionParties;

      // const candidates = election.election;

      // console.log("parties on location select", parties, election);

      // console.log("on Lcoation drop down election is ,", this.state.election);

      //find the last udpdated item
      const updatedOnList = results.map(d => moment.unix(d.updatedOn));
      let lastUpdateOn = moment.max(updatedOnList);

      lastUpdateOn = moment(lastUpdateOn).unix();
      // console.log("lastUpdate", lastUpdateOn);

      //find the number of vstations
      const numOfVstationsAll = results.length;
      //console.log("numOfVstationsAll", numOfVstationsAll);

      if (numOfVstationsAll === 0) {
        toast.warning(
          "Δεν εχω περασει καποιες εγγραφες results για την  περιοχη" +
            " " +
            input.label
        );
        return;
      }

      var obj = _.countBy(results, function(rec) {
        return rec.vStatus.id === "VOSTO04" || rec.vStatus.id === "VOSTO05";
      });

      const numOfVstationsWithResults = obj.true; // The number of true objects that are either draft or final result

      //console.log("numOfVstationsWithResults", numOfVstationsWithResults);

      this.setState({
        electionsLocationId: locationId,
        electionsLocationName: input.label,
        results: results,
        hideResultsTable: false,
        partiesVotes: [],
        candidatesVotes: [],
        lastUpdateOn: lastUpdateOn,
        numOfVstationsAll: numOfVstationsAll,
        numOfVstationsWithResults: numOfVstationsWithResults
      });

      //   const electionFresh = felectionsGetOne(this.state.election.id);
      //   console.log("electionFresh", electionFresh);

      //this.createVotePartiesTable(results);

      const partiesVotes = this.createVotePartiesTable(
        results,
        this.state.parties
      );

      const candidatesVotes = this.createVoteCandidatesTable(
        results,
        locationId,
        this.state.parties,
        this.state.partiesAll
      );

      this.chartUpdate(partiesVotes, this.state.partiesAll);
    }
  };

  createVoteCandidatesTable = (results, locationId, parties, partiesAll) => {
    //i need to find the parties full details with all the candidates

    // console.log(
    //   "this.state.parties on CAN TABLE",
    //   this.state.parties,
    //   this.state.partiesAll
    // );
    var candidatesTable = [];

    for (var c = 0; c < parties.length; c++) {
      const partyFull = partiesAll.find(f => f.id === parties[c].id);

      //Let create a new table add also the patty id and name in the trecords next ot candidates
      for (var x = 0; x < partyFull.partiesCandidates.length; x++) {
        var candidate = partyFull.partiesCandidates[x];

        // console.log(candidate, this.state.electionsLocationId);
        //Only the candidates that
        if (candidate.locationId === locationId) {
          candidate["partyId"] = parties[c].id;
          candidate["partyName"] = parties[c].name;

          //and now i add that
          candidatesTable.push(candidate);
        }
      }

      // candidatesTable.push(partyFull.partiesCandidates);
    }

    //as it creates reference i need to get rid the reference  !!!!
    var candidatesTable2 = JSON.parse(JSON.stringify(candidatesTable));

    // console.log("CAN TABLE candidatesTable", candidatesTable);

    // let partiesTable = [];
    // partiesTable.lentgh = 0;

    //For all the records in the results
    for (var r = 0; r < results.length; r++) {
      //for all the votes of the parties
      for (var vp = 0; vp < results[r].votesCandidates.length; vp++) {
        //find the party ID

        const candidateId = results[r].votesCandidates[vp].id;
        //Find which is the record in parties tavble that i will add the votes
        const index = candidatesTable.findIndex(e => e.id === candidateId);

        // console.log("partyId", partyId);

        //For that given index on the votes attributes add the previous one and the new one
        // the previous one could emoty on the first round thats whu the || 0
        candidatesTable2[index]["votes"] =
          (parseInt(candidatesTable2[index]["votes"], 10) || 0) +
          parseInt(results[r].votesCandidates[vp].votes, 10);

        // results[r][results[r].votesParties[vp].name] =
        //   results[r].votesParties[vp].votes;
      }
      // console.log("!!!", this.props.results.length);
    }

    //Order the parties Table
    //candidatesTable2.sort((a, b) => (a.votes < b.votes ? 1 : -1));
    candidatesTable2.sort((a, b) =>
      a.votes < b.votes
        ? 1
        : a.votes === b.votes
        ? a.order > b.order
          ? 1
          : -1
        : -1
    );

    //fix tye order of the data
    for (var i = 0; i < candidatesTable2.length; i++) {
      // console.log("Results on after sort", candidatesTable[i].order);
      candidatesTable2[i].order = i + 1;
    }

    //console.log("CANNNN 2", candidatesTable2);

    this.setState({ candidatesVotes: candidatesTable2 });

    return candidatesTable2;
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const {
      pageSizeCan,
      currentPageCan,
      sortColumnCan,
      searchQueryCan
    } = this.state;

    const { sortColumnPartiesVotes, sortColumnCandidatesVotes } = this.state;

    const {
      totalCount: totalPartiesVotes,
      data: partiesVotes
    } = this.getPagedDataPartiesVotes();

    console.log("partiesVotes", partiesVotes);

    const {
      totalCount: totalCandidatesVotes,
      data: candidatesVotes
    } = this.getPagedDataCandidatesVotes();

    const { totalCount, data: results } = this.getPagedData();
    const {
      totalCount: totalCountCan,
      data: resultsCan
    } = this.getPagedDataCan();

    const { length: count } = this.state.results; // Object destructing of the lentgh of candidates to count

    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />

        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col xs="12">
                  <h2 className="mb-0">
                    Διάλεξε την Εκλογική Αναμέτρηση και την περιοχή
                  </h2>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <Row>
                <Col md="6" sm="6">
                  <NewSelect4
                    name="elections"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Επιλογή"
                    value={this.state.electionId} // it wants only the ID wich will automatically convert that to value
                    label="Εκλογική  Αναμέτρηση"
                    options={this.state.elections}
                    onChange={this.handleChangeNewSelect3("elections")}
                    isDisabled={this.state.hideDropdownElections}
                  />
                </Col>

                <Col md="6" sm="6">
                  <NewSelect4
                    name="electionsLocations"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Επιλογή"
                    value={this.state.electionsLocationId} // it wants only the ID wich will automatically convert that to value
                    label="Περιοχή"
                    options={this.state.electionsLocationsFiltered}
                    onChange={this.handleChangeNewSelect3("electionsLocations")}
                    isDisabled={this.state.hideDropdownElectionsLocations}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          {this.state.hideResultsTable === false &&
            this.state.results.length !== 0 && (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <EnotitesHeader
                      name="Default"
                      parentName="Dashboards"
                      lastUpdateOn={this.state.lastUpdateOn || 0}
                      numOfVstationsAll={this.state.numOfVstationsAll}
                      numOfVstationsWithResults={
                        this.state.numOfVstationsWithResults
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl="7">
                    <Card>
                      {/* <CardHeader className="border-0">
                  <h3 className="mb-0">Συγκεντρωτικά Περιφέρειας</h3> */}

                      <CardHeader>
                        <h6 className="surtitle">
                          {" "}
                          {this.state.electionsLocationName}
                        </h6>
                        <h5 className="h3 mb-0">Σειρά Κομμάτων</h5>
                      </CardHeader>

                      <PartiesVotesAllTable
                        partiesVotesAll={partiesVotes} //{this.state.partiesVotes}
                        sortColumn={sortColumnPartiesVotes}
                        // onDelete={this.handleDelete}
                        // onUpdate={this.handleUpdate}
                        onSort={this.handleSortPartiesVotes}
                      />
                    </Card>
                  </Col>
                  <Col xl="5">
                    <Card>
                      <CardHeader>
                        <h6 className="surtitle">
                          {" "}
                          {this.state.electionsLocationName}
                        </h6>
                        <h5 className="h3 mb-0">Ποσοστό</h5>
                      </CardHeader>
                      <CardBody>
                        <div className="chart">
                          <Doughnut
                            data={this.state.chart.data}
                            options={this.state.chart.options}
                            className="chart-canvas"
                            id="chart-doughnut"
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          {/* {this.state.electionsLocationId && (
            <Card className="mb-4">
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h2 className="mb-0">
                     
                      Συγκεντρωτικά Ενότητας: {this.state.electionsLocationName}
                    </h2>
                  </Col>
                </Row>
              </CardHeader>

              {this.state.hideResultsTable === true ? (
                <h6>Διαλεξε πρωτα εκλογικη Διαδικασία και περιοχη</h6>
              ) : this.state.results.length === 0 ? (
                <h4>
                  {" "}
                  Δυστυχως δεν υπαρχουνε διαθεσιμα Αποτελέσματα για την περιοχή
                  που ψαχνεις
                </h4>
              ) : (
                <React.Fragment>
                  <PartiesVotesAllTable
                    partiesVotesAll={this.state.partiesVotes}
                    sortColumn={sortColumn}
                    // onDelete={this.handleDelete}
                    // onUpdate={this.handleUpdate}
                    // onSort={this.handleSort}
                  />
                </React.Fragment>
              )}
            </Card>
          )} */}

          {this.state.hideResultsTable === false &&
            this.state.results.length !== 0 && (
              <React.Fragment>
                <Card className="mb-4">
                  <CardHeader>
                    <Row>
                      <Col xs="12">
                        <h2 className="mb-0">
                          <Badge color="info">{totalCount}</Badge>
                          <span className="m-1" />
                          Αναλυτικά Αποτελέσματα:{" "}
                          {this.state.electionsLocationName}
                        </h2>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <SearchBox
                      value={searchQuery}
                      onChange={this.handleSearch}
                    />
                    <ResultsTableReadOnly
                      results={results}
                      sortColumn={sortColumn}
                      // onDelete={this.handleDelete}
                      // onUpdate={this.handleUpdate}
                      onSort={this.handleSort}
                      authLevel={this.props.authLevel}
                    />
                    <Pagination
                      itemsCount={totalCount}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={this.handlePageChange}
                    />
                  </CardBody>
                </Card>
                <Row>
                  <Col xl="7">
                    <Card>
                      {/* <CardHeader className="border-0">
                  <h3 className="mb-0">Συγκεντρωτικά Περιφέρειας</h3> */}

                      <CardHeader>
                        <h6 className="surtitle">
                          {" "}
                          {this.state.electionsLocationName}
                        </h6>
                        <h5 className="h3 mb-0">Σειρά Υποψηφίων</h5>
                      </CardHeader>

                      <CandidatesVotesAllTable
                        candidatesVotesAll={candidatesVotes} //{this.state.candidatesVotes}
                        sortColumn={sortColumnCandidatesVotes}
                        // onDelete={this.handleDelete}
                        // onUpdate={this.handleUpdate}
                        onSort={this.handleSortCandidatesVotes}
                      />
                    </Card>
                  </Col>
                </Row>

                <Card className="mb-4">
                  <CardHeader>
                    <Row>
                      <Col xs="12">
                        <h2 className="mb-0">
                          <Badge color="info">{totalCountCan}</Badge>
                          <span className="m-1" />
                          Αποτελέσματα Υποψηφίων:{" "}
                          {this.state.electionsLocationName}
                        </h2>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <SearchBox
                      value={searchQueryCan}
                      onChange={this.handleSearchCan}
                    />
                    <ResultsCandidatesTable
                      results={resultsCan}
                      sortColumn={sortColumnCan}
                      // onDelete={this.handleDelete}
                      // onUpdate={this.handleUpdate}
                      onSort={this.handleSortCan}
                      authLevel={this.props.authLevel}
                    />
                    <Pagination
                      itemsCount={totalCountCan}
                      pageSize={pageSizeCan}
                      currentPage={currentPageCan}
                      onPageChange={this.handlePageChangeCan}
                    />
                  </CardBody>
                </Card>
              </React.Fragment>
            )}
        </Container>
      </React.Fragment>
    );
  }
}

export default Enotites;
