import moment from "moment";

const candidates = [
  {
    id: "C1",
    firstName: " Ιωάννης",
    lastName: "Μαλακασης",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C2",
    firstName: " Ευάγγελος",
    lastName: "Σκιαδαρεσης",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C8",
    firstName: "Ιωάννης ",
    lastName: "Μαμαλουκας",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C9",
    firstName: " Κωστας",
    lastName: "Πατατας",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C10",
    firstName: "Πανος ",
    lastName: "Αρβανιτης",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C11",
    firstName: "Σωτήρης",
    lastName: "Μαγκαφας",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C12",
    firstName: "Pame",
    lastName: "",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C13",
    firstName: "Δοξατου 1",
    lastName: "Δοξατου",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C14",
    firstName: "Δοξατου 2",
    lastName: "Δοξατου",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C15",
    firstName: "Δοξατου 3",
    lastName: "Δοξατου",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C16",
    firstName: "Δοξατου 4",
    lastName: "Δοξατου",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  },
  {
    id: "C17",
    firstName: "Σμαραγδα",
    lastName: "Καρυδη",
    job: "Δικηγόρος",
    updatedOn: 1557529478,
    telephone: "1234",
    notes: ""
  }
];

export function fcandidatesGetAll() {
  return candidates;
}

export function fcandidatesGetOne(candidateId) {
  return candidates.find(f => f.id === candidateId);
}

export function fcandidatesSave(candidate) {
  console.log("candidate", candidate);
  let candidatesInDb = candidates.find(f => f.id === candidate.id) || {};
  candidatesInDb.firstName = candidate.firstName;
  candidatesInDb.lastName = candidate.lastName;
  candidatesInDb.job = candidate.job;
  candidatesInDb.telephone = candidate.telephone;
  candidatesInDb.notes = candidate.notes;
  candidatesInDb.updatedOn = moment().unix();

  //if the ID is null that means is a new one
  if (!candidatesInDb.id) {
    candidatesInDb.id = Date.now().toString();
    candidates.push(candidatesInDb);
  }

  return candidatesInDb;
}
