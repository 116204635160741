import * as listOfValues from "./listOfValues";
import moment from "moment";

export const results = [
  {
    idElection: "EL1",
    idVstation: "VS1",
    nameElection: "Περιφεριεακες Εκλογες 2019 Α",
    nameVstation: "Λευκαδα 1 Δημοτικό",
    dateElection: "26/05/2019",
    number: "01",
    blankVotes: 0,
    completionRate: 0,
    invalidVotes: 0,
    electionLocationId: "PEEN31",
    electionLocation: "ΛΕΥΚΑΔΑΣ",
    vstationLocation: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN31", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA02" },
      { id: "DIM121", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA03" }
    ],
    registeredVoters: 230,
    turnout: 0,
    updatedOn: 1557265802,
    vStatus: { id: "VOST01", name: "Δεν έχει ξεκινήσει" },
    voted: 0,
    votesCandidates: [
      { id: "C1", name: "Μαλακασης Ιωάννης ", order: 1, votes: 0 },
      { id: "C2", name: "Σκιαδαρεσης Ευάγγελος", order: 2, votes: 0 },
      { id: "C8", name: "Μαμαλουκας Ιωάννης ", order: 3, votes: 0 },
      { id: "C10", name: "Αρβανιτης Πανος ", order: 5, votes: 0 },
      { id: "C11", name: "Μαγκαφας Σωτήρης", order: 6, votes: 0 },
      { id: "C9", name: "Πατατας Κωστας", order: 7, votes: 0 },
      { id: "C3", name: "Πάπας  Ιωάννης ", order: 1, votes: 0 },
      { id: "C4", name: "Βασιλακος Ευάγγελος", order: 2, votes: 0 }
    ],
    votesParties: [
      {
        id: "P1",
        name: "Ιόνιος Δυνατά, κάθε νησι ψηλά",
        order: 1,
        votes: 5,
        eVotes: 0
      },
      { id: "P2", name: "Ιόνιος ΣΥΡΙΖΑ", order: 2, votes: 8, eVotes: 0 }
    ]
  },

  {
    idElection: "EL1",
    idVstation: "VS2",
    nameElection: "Περιφεριεακες Εκλογες 2019 Α",
    nameVstation: "Λευκαδα 2 Δημοτικό",
    dateElection: "26/05/2019",
    number: "02",
    blankVotes: 0,
    completionRate: 0,
    invalidVotes: 0,
    electionLocationId: "PEEN31",
    electionLocation: "ΛΕΥΚΑΔΑΣ",
    vstationLocation: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN31", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA02" },
      { id: "DIM121", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA03" }
    ],
    registeredVoters: 230,
    turnout: 0,
    updatedOn: 1557265802,
    vStatus: { id: "VOST01", name: "Δεν έχει ξεκινήσει" },
    voted: 0,
    votesCandidates: [
      { id: "C1", name: "Μαλακασης Ιωάννης ", order: 1, votes: 15 },
      { id: "C2", name: "Σκιαδαρεσης Ευάγγελος", order: 2, votes: 20 },
      { id: "C8", name: "Μαμαλουκας Ιωάννης ", order: 3, votes: 5 },
      { id: "C10", name: "Αρβανιτης Πανος ", order: 5, votes: 0 },
      { id: "C11", name: "Μαγκαφας Σωτήρης", order: 6, votes: 0 },
      { id: "C9", name: "Πατατας Κωστας", order: 7, votes: 0 },
      { id: "C3", name: "Πάπας  Ιωάννης ", order: 1, votes: 0 },
      { id: "C4", name: "Βασιλακος Ευάγγελος", order: 2, votes: 0 }
    ],
    votesParties: [
      {
        id: "P1",
        name: "Ιόνιος Δυνατά, κάθε νησι ψηλά",
        order: 1,
        votes: 5,
        eVotes: 0
      },
      { id: "P2", name: "Ιόνιος ΣΥΡΙΖΑ", order: 2, votes: 8, eVotes: 0 }
    ]
  },
  {
    idElection: "EL1",
    idVstation: "VS3",
    nameElection: "Περιφεριεακες Εκλογες 2019 Α",
    nameVstation: "Λευκαδα 3 Δημοτικό",
    dateElection: "26/05/2019",
    number: "03",
    blankVotes: 0,
    completionRate: 0,
    invalidVotes: 0,
    electionLocationId: "PEEN31",
    electionLocation: "ΛΕΥΚΑΔΑΣ",
    vstationLocation: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN31", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA02" },
      { id: "DIM121", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA03" }
    ],
    registeredVoters: 230,
    turnout: 0,
    updatedOn: 1557265802,
    vStatus: { id: "VOST01", name: "Δεν έχει ξεκινήσει" },
    voted: 0,
    votesCandidates: [
      { id: "C1", name: "Μαλακασης Ιωάννης ", order: 1, votes: 50 },
      { id: "C2", name: "Σκιαδαρεσης Ευάγγελος", order: 2, votes: 20 },
      { id: "C8", name: "Μαμαλουκας Ιωάννης ", order: 3, votes: 3 },
      { id: "C10", name: "Αρβανιτης Πανος ", order: 5, votes: 10 },
      { id: "C11", name: "Μαγκαφας Σωτήρης", order: 6, votes: 20 },
      { id: "C9", name: "Πατατας Κωστας", order: 7, votes: 0 },
      { id: "C3", name: "Πάπας  Ιωάννης ", order: 1, votes: 0 },
      { id: "C4", name: "Βασιλακος Ευάγγελος", order: 2, votes: 0 }
    ],
    votesParties: [
      {
        id: "P1",
        name: "Ιόνιος Δυνατά, κάθε νησι ψηλά",
        order: 1,
        votes: 100,
        eVotes: 0
      },
      { id: "P2", name: "Ιόνιος ΣΥΡΙΖΑ", order: 2, votes: 20, eVotes: 0 }
    ]
  },
  {
    idElection: "EL1",
    idVstation: "VS4",
    nameElection: "Περιφεριεακες Εκλογες 2019 Α",
    nameVstation: "Λευκαδα 4 Δημοτικό",
    dateElection: "26/05/2019",
    number: "04",
    blankVotes: 0,
    completionRate: 0,
    invalidVotes: 0,
    electionLocationId: "PEEN31",
    electionLocation: "ΛΕΥΚΑΔΑΣ",
    vstationLocation: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN31", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA02" },
      { id: "DIM121", name: "ΛΕΥΚΑΔΑΣ", layerId: "LOLA03" }
    ],
    registeredVoters: 146,
    turnout: 0,
    updatedOn: 1557265802,
    vStatus: { id: "VOST01", name: "Δεν έχει ξεκινήσει" },
    voted: 0,
    votesCandidates: [
      { id: "C1", name: "Μαλακασης Ιωάννης ", order: 1, votes: 30 },
      { id: "C2", name: "Σκιαδαρεσης Ευάγγελος", order: 2, votes: 10 },
      { id: "C8", name: "Μαμαλουκας Ιωάννης ", order: 3, votes: 2 },
      { id: "C10", name: "Αρβανιτης Πανος ", order: 5, votes: 4 },
      { id: "C11", name: "Μαγκαφας Σωτήρης", order: 6, votes: 0 },
      { id: "C9", name: "Πατατας Κωστας", order: 7, votes: 0 },
      { id: "C3", name: "Πάπας  Ιωάννης ", order: 1, votes: 0 },
      { id: "C4", name: "Βασιλακος Ευάγγελος", order: 2, votes: 0 }
    ],
    votesParties: [
      {
        id: "P1",
        name: "Ιόνιος Δυνατά, κάθε νησι ψηλά",
        order: 1,
        votes: 15,
        eVotes: 0
      },
      { id: "P2", name: "Ιόνιος ΣΥΡΙΖΑ", order: 2, votes: 45, eVotes: 0 }
    ]
  },
  {
    idElection: "EL1",
    idVstation: "VS9",
    nameElection: "Περιφεριεακες Εκλογες 2019 Α",
    nameVstation: "Ιθακη",
    dateElection: "26/05/2019",
    number: "12",
    blankVotes: 0,
    completionRate: 0,
    invalidVotes: 0,
    electionLocationId: "PEEN28",
    electionLocation: "ΙΘΑΚΗΣ",
    vstationLocation: [
      { id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" },
      { id: "PEEN28", name: "ΙΘΑΚΗΣ", layerId: "LOLA02" },
      { id: "DIM117", name: "ΙΘΑΚΗΣ", layerId: "LOLA03" }
    ],
    registeredVoters: 239,
    turnout: 0,
    updatedOn: 1557265802,
    vStatus: { id: "VOSTO04", name: "Μερικο Αποτελεσμα" },
    voted: 0,
    votesCandidates: [{ id: "C17", name: "Σμαραγδα", order: 1, votes: 8 }],
    votesParties: [
      {
        id: "P1",
        name: "Ιόνιος Δυνατά, κάθε νησι ψηλά",
        order: 1,
        votes: 3,
        eVotes: 0
      },
      { id: "P2", name: "Ιόνιος ΣΥΡΙΖΑ", order: 2, votes: 50, eVotes: 0 }
    ]
  }
];

export function fresultsGetAll() {
  return results.filter(g => g);
}

// export function resultsGetOne(id) {
//   return results.find(r => r.id === id);
// }

export function fresultsGetOne(idElection, idVstation) {
  return results.find(
    r => (r.idElection === idElection) & (r.idVstation === idVstation)
  );
}

export function fresultsGetAllByElection(idElection) {
  return results.filter(r => r.idElection === idElection);
}

export function fresultsGetElectionAndArea(idElection, idLocation) {
  return results.filter(
    r => (r.idElection === idElection) & (r.electionLocationId === idLocation)
  );
}

export function fresultsSave(result) {
  let resultsInDb =
    results.find(
      r =>
        (r.idElection === result.idElection) &
        (r.idVstation === result.idVstation)
    ) || {};

  resultsInDb.idElection = result.idElection;
  resultsInDb.idVstation = result.idVstation;
  resultsInDb.nameElection = result.nameElection;
  resultsInDb.nameVstation = result.nameVstation;
  resultsInDb.dateElection = result.dateElection;
  resultsInDb.number = result.number;
  resultsInDb.locationLabel = result.locationLabel;
  resultsInDb.registeredVoters = result.registeredVoters;
  resultsInDb.blankVotes = result.blankVotes || "";
  resultsInDb.invalidVotes = result.invalidVotes || "";
  resultsInDb.voted = result.voted || "";

  //console.log(result, resultsInDb);

  resultsInDb.vStatus = result.vStatus;
  // resultsInDb.vStatus = listOfValues.vStatusGetOne(result.vStatusId);

  resultsInDb.votesParties = result.votesParties;
  resultsInDb.votesCandidates = result.votesCandidates;
  resultsInDb.turnout = result.turnout;
  resultsInDb.completionRate = result.completionRate;

  resultsInDb.updatedOn = moment().unix();

  //it needs to have

  // console.log("PartyIndDB", resultsInDb.resultType);
  // //if the ID is null that means is a new one
  // if (!resultsInDb.id) {
  //   resultsInDb.id = Date.now().toString();
  //   results.push(resultsInDb);
  // }

  return resultsInDb;
}
