import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "../../../components/N1components/Header/Header.jsx";
import HeaderLinks from "../../../components/N1components/Header/HeaderLinks.jsx";
import GridContainer from "../../../components/N1components/Grid/GridContainer.jsx";
import GridItem from "../../../components/N1components/Grid/GridItem.jsx";
import InfoArea from "../../../components/N1components/InfoArea/InfoArea.jsx";
import CustomInput from "../../../components/N1components/CustomInput/CustomInput.jsx";
import Button from "../../../components/N1components/CustomButtons/Button.jsx";
import Footer from "../../../components/N1components/Footer/Footer.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.jsx";

import CustomFooter from "../../../components/N1components/CustomFooter";
import { TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { API } from "aws-amplify";
import "react-toastify/dist/ReactToastify.css";
import { ConsoleLogger } from "@aws-amplify/core";
import { red } from "@material-ui/core/colors";
import swal from "sweetalert";

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: 38.828513, lng: 20.704535 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 38.828513, lng: 20.704535 }} />
    </GoogleMap>
  ))
);

class ContactUsPage extends Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    content: "",
    errors: {}
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleChange = event => {
    console.log(errors);
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(event.target.id);

    console.log(errorMessage, event.target.id);
    if (errorMessage) errors[event.target.id] = errorMessage;
    else delete errors[event.target.id];

    this.setState({
      [event.target.id]: event.target.value,
      errors
    });
    console.log(event.target.id, errors);
  };

  validateProperty = input => {
    if (input === "name") {
      if (this.state.name.trim() === "")
        return (this.state.errors.name = "Παρακαλώ συμπληρωσε το όνομα σου");
    }

    if (input === "email") {
      if (this.state.email.trim() === "")
        return (this.state.errors.email = "Παρακαλώ συμπληρωσε το email σου");
    }

    if (input === "mobile") {
      if (this.state.mobile.trim().length < 10)
        return (this.state.errors.mobile =
          "Παρακαλώ συμπληρωσε το κινητο σου με 10 νουμερα");
      // console.log(this.state.errors.mobile);
    }
    if (input === "content") {
      if (this.state.content.trim().length < 20)
        return (this.state.errors.content =
          "Παρακαλώ συμπληρωσε το κειμενο σου πανω απο 20 χαρακτηρες");
    }
  };

  validate = () => {
    const { name, email, mobile, content } = this.state;

    const errors = {};

    if (name.trim() === "") errors.name = "Παρακαλώ συμπληρωσε το όνομα σου";
    if (email.trim() === "") errors.email = "Παρακαλώ συμπληρωσε το email σου";
    if (content.trim().length < 20)
      errors.content =
        "Παρακαλώ συμπληρωσε το κειμενο σου πανω απο 20 χαρακτηρες";
    if (mobile.trim().length < 10)
      errors.mobile =
        "Παρακαλώ συμπλήρωσε το κινητο/Τηλεφωνο σου τουλαχιστον 10 αριθμοί";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { email, name, content, mobile } = this.state;

    console.log(this.state);
    const errors = this.validate();
    this.setState({ errors: errors || {} }); // when no errors to pass an empty object
    console.log(errors);
    if (errors) {
      swal({
        title: "Δυστυχώς το μήνυμα σας δεν στάλθηκε",
        text:
          "Παρακαλώ ελέγξτε την φόρμα επικοινωνίας για τυχόν παραλείψεις,αλλιώς στείλτε μου email στο ioannis@malakasis.gr",
        icon: "warning",
        timer: 6000,
        button: false
      });

      // toast.error(
      //   "Το μήνυμα δεν μπορει να σταλει γιατι υπαρχουν παραλειψεις στην φόρμα"
      // );
      // return;
    }

    try {
      const promise = await API.post("email", "/email/send", {
        body: { name: name, mobile: mobile, email: email, content: content }
      });
      console.log(promise);
      // alert("Success");
      //toast.success("Τέλεια, Το μήνυμα σας στάλθηκε με επιτυχία ");

      swal({
        title: "Το μήνυμα σας στάλθηκε με επιτυχία",
        text: "Σε ευχαριστώ για την επικοινωνία",
        icon: "success",
        timer: 2000,
        button: false
      });

      this.setState({ name: "", email: "", mobile: "", content: "" });
      // this.setState({ data: [] }); //empty the data
    } catch (ex) {
      swal({
        title: "Δυστυχώς το μήνυμα σας δεν στάλθηκε",
        text:
          "Παρακαλώ ελέγξτε την φόρμα επικοινωνίας για τυχόν παραλείψεις,αλλιώς στείλτε μου email στο ioannis@malakasis.gr",
        icon: "warning",
        timer: 6000,
        button: false
      });

      // toast.error(
      //   "Δυστυχως λογο τεχνικου προβληματος το μηνυμα δεν παραδόθηκε, παρακαλω επικοινωνηστε μαζι μου με το email ioannis@malakasis.gr "
      // );
      //alert("Something went wrong send your text please try later");
    }

    console.log(this.state);
  };

  render() {
    const { errors } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ToastContainer />
        <div>
          <Header
            color="white"
            brand="Ιωάννης Μαλακάσης"
            links={
              <HeaderLinks
                dropdownHoverColor="info"
                isAuthenticated={this.props.isAuthenticated}
                userHasAuthenticated={this.props.userHasAuthenticated}
                onLogout={this.props.onLogout}
              />
            }
            fixed
            changeColorOnScroll={{
              height: 300,
              color: "white"
            }}
          />
          <div className={classes.bigMap}>
            <CustomSkinMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAk-Qhmi55IbcNA7YFpp67XLvkiQJlTe9M"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={{
                    height: `100%`,
                    borderRadius: "6px",
                    overflow: "hidden"
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.contactContent}>
              <div className={classes.container}>
                <h2 className={classes.title}>Επικοινωνήστε μαζί μου</h2>
                <GridContainer>
                  <GridItem md={6} sm={6}>
                    <p>
                      Χρησιμοποιείστε την παρακάτω φόρμα για κάθε επικοινωνία
                      μαζι μου. Καθώς η επικοινωνία είναι βασική προϋπόθεση
                      συνεργασίας, θα προσπαθήσω να σας απαντήσω πολύ σύντομα.
                      <br />
                      <br />
                    </p>
                    <form className={classes.container}>
                      <TextField
                        id="name"
                        label="Ονοματεπώνυμο"
                        className={classes.TextField}
                        value={this.state.name}
                        onChange={this.handleChange}
                        helperText="Όνομα και επίθετο"
                        margin="normal"
                        fullWidth="true"
                      />
                      {errors.name && (
                        <div classΝame="alert alert-danger">{errors.name}</div>
                      )}
                      <TextField
                        id="email"
                        label="Email"
                        className={classes.TextField}
                        value={this.state.email}
                        onChange={this.handleChange}
                        margin="normal"
                        fullWidth="true"
                        helperText="Το email σου"
                      />
                      {errors.email && (
                        <div classΝame="alert alert-danger">{errors.email}</div>
                      )}
                      <TextField
                        id="mobile"
                        label="Κινητό Τηλέφωνο"
                        className={classes.TextField}
                        value={this.state.mobile}
                        onChange={this.handleChange}
                        margin="normal"
                        fullWidth="true"
                        helperText="Τηλέφωνο τουλάχιστον 10 αριθμών"
                      />
                      {errors.mobile && (
                        <div classΝame="alert alert-danger">
                          {errors.mobile}
                        </div>
                      )}
                      <TextField
                        id="content"
                        label="Μήνυμα"
                        className={classes.TextField}
                        value={this.state.content}
                        onChange={this.handleChange}
                        margin="normal"
                        fullWidth="true"
                        multiline="true"
                        helperText="Μήνυμα τουλάχιστον 20 χαρακτηρων"
                        rows="6"
                      />
                      {errors.content && (
                        <div classΝame="alert alert-danger">
                          {errors.content}
                        </div>
                      )}

                      {/* <CustomInput
                      labelText="Ονοματεπώνυμο"
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                    /> */}
                      {/* <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                    /> */}
                      {/* <CustomInput
                      labelText="Κινητό Τηλέφωνο"
                      id="mobile"
                      formControlProps={{
                        fullWidth: true
                      }}
                    /> */}
                      {/* <CustomInput
                      labelText="Μήνυμα "
                      id="content"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 6
                      }}
                    /> */}
                      <div className={classes.textCenter}>
                        <Button
                          color="info"
                          onClick={this.handleSubmit}
                          // disabled={() =>
                          //   (this.state.errors.length = 0 ? false : true)
                          // }
                          round
                        >
                          Αποστολη
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                  <GridItem md={4} sm={4} className={classes.mlAuto}>
                    <InfoArea
                      className={classes.info}
                      title="Εκλογικό Κέντρο"
                      description={
                        <p>
                          Πάροδος Καραβέλα <br /> Σύγκροτημα Ερατώ , <br />{" "}
                          Λευκάδα 31100
                        </p>
                      }
                      icon={PinDrop}
                      iconColor="info"
                    />
                    <InfoArea
                      className={classes.info}
                      title="Τηλέφωνα Επικοινωνίας"
                      description={
                        <p>
                          Ιωάννης Μαλακάσης <br /> 697 741 9062
                          <br /> 26450 23125
                        </p>
                      }
                      icon={Phone}
                      iconColor="info"
                    />
                    {/* <p>
                      <span className="mg-2"> Ιωαννης Μαλακασης</span> <br />
                      697 741 906 <br />
                      26450 23125
                    </p> */}
                    <InfoArea
                      className={classes.info}
                      title="Επιπλέον τρόποι επικοινωνίας"
                      description={
                        <p>
                          email : ioannis@malakasis.gr <br />
                          Facebook :{" "}
                          <a href="https://www.facebook.com/ioannis.malakasis">
                            Ioannis Malakasis
                          </a>
                        </p>
                      }
                      icon={BusinessCenter}
                      iconColor="info"
                    />
                    {/* <Tooltip
                      id="tooltip-top"
                      title="Ακολουθησε με στο Facebook"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        justIcon
                        simple
                        color="facebook"
                        className={classes.margin5}
                        href="https://www.facebook.com/ioannis.malakasis"
                      >
                        <i className={classes.socials + " fab fa-facebook"} />
                      </Button>
                    </Tooltip>{" "}
                    Ioannis Malakasis
                    <br />
                    <span> </span>email : ioannis@malakasis.gr */}
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          <Footer content={<CustomFooter classes={classes} />} />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(contactUsStyle)(ContactUsPage);
