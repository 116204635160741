import * as electionTypeAPI from "./listOfValues";
import _ from "lodash";
import moment from "moment";
import { ConfigService } from "aws-sdk/clients/all";

export const parties = [
  {
    id: "P1",
    name: "Ιόνιος Δυνατά, κάθε νησι ψηλά",
    active: true,
    leader: "Ροδη Κρατσα",
    electionType: { id: "ELTY003", name: "Περιφερειακές" },
    location: [{ id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" }],
    partiesCandidates: [
      {
        id: "C1",
        name: "Μαλακασης Ιωάννης ",
        order: 1,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      },
      {
        id: "C2",
        name: "Σκιαδαρεσης Ευάγγελος",
        order: 2,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      },
      {
        id: "C8",
        name: "Μαμαλουκας Ιωάννης ",
        order: 3,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      },
      {
        id: "C10",
        name: "Αρβανιτης Πανος ",
        order: 5,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      },
      {
        id: "C11",
        name: "Μαγκαφας Σωτήρης",
        order: 6,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      },
      {
        id: "C9",
        name: "Πατατας Κωστας",
        order: 7,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      },
      {
        id: "C17",
        name: "Σμαραγδα",
        order: 1,
        locationId: "PEEN28",
        locationName: "ΙΘΑΚΗΣ"
      }
    ],
    color: "#1568ed",
    updatedOn: "1557262895"
  },
  {
    id: "P2",
    name: "Ιόνιος ΣΥΡΙΖΑ",
    active: true,
    leader: "Συριζαίος",
    electionType: { id: "ELTY003", name: "Περιφερειακές" },
    location: [{ id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" }],
    partiesCandidates: [
      {
        id: "C3",
        name: "Πάπας  Ιωάννης ",
        order: 1,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      },
      {
        id: "C4",
        name: "Βασιλακος Ευάγγελος",
        order: 2,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      }
    ],
    color: "#ffa30f",
    updatedOn: "1557262895"
  },
  {
    id: "P3",
    name: "ΝΔ  Σολδατος",
    active: true,
    leader: "Σολδατος",
    electionType: { id: "ELTY004", name: "Δημοτικές" },

    location: [],
    partiesCandidates: [
      {
        id: "C5",
        name: "Βεργίνης   Ιωάννης ",
        order: 1,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      },
      {
        id: "c6",
        name: "Σαραντης Διονύσης ",
        order: 2,
        locationId: "PEEN31",
        locationName: "ΛΕΥΚΑΔΑΣ"
      }
    ],
    color: "#1568ed",
    updatedOn: "1557262895"
  },
  {
    id: "P5",
    name: "Σπυρου Ανεξαρτητος",
    active: true,
    leader: "Σπυρος Σπυρου",
    electionType: { id: "ELTY003", name: "Περιφερειακές" },
    location: [{ id: "PEIA06", name: "ΙΟΝΙΩΝ ΝΗΣΩΝ", layerId: "LOLA01" }],
    partiesCandidates: [],
    color: "#0013BF",
    updatedOn: "1557262895"
  },
  {
    id: "P6",
    name: "Φλουδας Κομμα  ",
    active: true,
    leader: "Φλουδας",
    electionType: { id: "ELTY004", name: "Δημοτικές" },

    location: [],
    partiesCandidates: [],
    color: "#1568ed",
    updatedOn: "1557262895"
  },
  {
    id: "P7",
    name: "Drama 1",
    active: true,
    leader: "Δραμα",
    electionType: { id: "ELTY004", name: "Δημοτικές" },
    location: [
      { id: "PEIA01", name: "ΑΝ. ΜΑΚΕΔΟΝΙΑΣ & ΘΡΑΚΗΣ", layerId: "LOLA01" },
      { id: "PEEN01", name: "ΔΡΑΜΑΣ", layerId: "LOLA02" },
      { id: "DIM001", name: "ΔΟΞΑΤΟΥ", layerId: "LOLA03" }
    ],
    partiesCandidates: [
      {
        id: "C13",
        name: "Δοξατου 1",
        order: 1,
        locationId: "DIM001",
        locationName: "ΔΟΞΑΤΟΥ"
      },
      {
        id: "C14",
        name: "Δοξατου 2",
        order: 2,
        locationId: "DIM001",
        locationName: "ΔΟΞΑΤΟΥ"
      },
      {
        id: "C15",
        name: "Δοξατου 3",
        order: 3,
        locationId: "DIM001",
        locationName: "ΔΟΞΑΤΟΥ"
      },
      {
        id: "C16",
        name: "Δοξατου 4",
        order: 4,
        locationId: "DIM001",
        locationName: "ΔΟΞΑΤΟΥ"
      }
    ],
    color: "#1568ed",
    updatedOn: "1557262895"
  },
  {
    id: "P8",
    name: "Drama 2",
    active: true,
    leader: "ffff",
    electionType: { id: "ELTY004", name: "Δημοτικές" },
    location: [
      { id: "PEIA01", name: "ΑΝ. ΜΑΚΕΔΟΝΙΑΣ & ΘΡΑΚΗΣ", layerId: "LOLA01" },
      { id: "PEEN01", name: "ΔΡΑΜΑΣ", layerId: "LOLA02" },
      { id: "DIM001", name: "ΔΟΞΑΤΟΥ", layerId: "LOLA03" }
    ],
    partiesCandidates: [],
    color: "#1568ed",
    updatedOn: "1557262895"
  },
  {
    id: "P9",
    name: "Drama 3",
    active: true,
    leader: "ffff",
    electionType: { id: "ELTY004", name: "Δημοτικές" },
    location: [
      { id: "PEIA01", name: "ΑΝ. ΜΑΚΕΔΟΝΙΑΣ & ΘΡΑΚΗΣ", layerId: "LOLA01" },
      { id: "PEEN01", name: "ΔΡΑΜΑΣ", layerId: "LOLA02" },
      { id: "DIM001", name: "ΔΟΞΑΤΟΥ", layerId: "LOLA03" }
    ],
    partiesCandidates: [],
    color: "#1568ed",
    updatedOn: "1557262895"
  }
];

export function fpartiesGetAll() {
  return parties;
}

export function fpartiesGetOne(id) {
  return parties.find(p => p.id === id);
}

export function fpartiesSave(party) {
  let partiesInDb = parties.find(v => v.id === party.id) || {};
  partiesInDb.name = party.name;
  partiesInDb.active = party.active;
  partiesInDb.color = party.color;
  partiesInDb.leader = party.leader;
  partiesInDb.area = party.area;
  partiesInDb.partiesCandidates = party.partiesCandidates;
  partiesInDb.location = party.location;
  partiesInDb.electionType = party.electionType;
  partiesInDb.notes = party.notes;
  partiesInDb.updatedOn = moment().unix();

  // partiesInDb.electionType = electionTypeAPI.electionTypes.find(
  //   p => p.id === party.electionTypeId
  // );
  // console.log("PartyIndDB", partiesInDb.electionType);
  //if the ID is null that means is a new one
  if (!partiesInDb.id) {
    partiesInDb.id = Date.now().toString();
    parties.push(partiesInDb);
  }
  //console.log("from save !!!", partiesInDb);
  return partiesInDb;
}

export function fpartiesDelete(id) {
  let partiesInDb = parties.find(v => v.id === id);
  parties.splice(parties.indexOf(partiesInDb), 1);
  return parties;
}

export function fpartiesDeleteCandidate(partyId, candidateId) {
  //find the Party that i want
  let partiesInDb = parties.find(p => p.id === partyId);

  let partiesCandidatesInDb = partiesInDb.partiesCandidates.find(
    c => c.id === candidateId
  );

  //console.log(partiesCandidatesInDb);

  partiesInDb.partiesCandidates.splice(
    partiesInDb.partiesCandidates.indexOf(partiesCandidatesInDb),
    1
  );
  //console.log(partiesInDb);

  return partiesInDb;
}

export function fpartiesCreateCandidates(partyId, candidate) {
  var partiesInDb = parties.find(p => p.id === partyId);

  //We need to check if the attribute partiesCandidates[]  is missing totaly as nobody has created that before e.g. on a new Party

  if (_.has(partiesInDb, "partiesCandidates") === false) {
    const partiesCandidates = [];
    partiesCandidates[0] = candidate;

    partiesInDb["partiesCandidates"] = partiesCandidates;

    return partiesInDb;
  }

  console.log("lodash", _.has(partiesInDb, "partiesCandidates"));
  // let indexFirst = partiesInDb.findIndex(e => e === "leader");

  //else check to see if that exist as elemnt under partiesCandidates so to post or update
  const index = partiesInDb.partiesCandidates.findIndex(
    e => e.id === candidate.id
  );
  console.log(index);
  if (index === -1) {
    // New record
    partiesInDb.partiesCandidates.push(candidate);
    console.log("i can push");
    return partiesInDb;
  }

  //else update
  partiesInDb.partiesCandidates[index] = candidate;
  return partiesInDb;
}
