import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import Favorite from "@material-ui/icons/Favorite";
// import Face from "@material-ui/icons/Face";
import Lock from "@material-ui/icons/Lock";
// core components
import Header from "../../../components/N1components/Header/Header.jsx";
import HeaderLinks from "../../../components/N1components/Header/HeaderLinks.jsx";
//import Footer from "../../../components/N1components/Footer/Footer.jsx";
import GridContainer from "../../../components/N1components/Grid/GridContainer.jsx";
import GridItem from "../../../components/N1components/Grid/GridItem.jsx";
import Button from "../../../components/N1components/CustomButtons/Button.jsx";
import Card from "../../../components/N1components/Card/Card.jsx";
import CardBody from "../../../components/N1components/Card/CardBody.jsx";
import CardHeader from "../../../components/N1components/Card/CardHeader.jsx";
import CustomInput from "../../../components/N1components/CustomInput/CustomInput.jsx";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import { Auth } from "aws-amplify";
// import image from "../../../assets/img/bg7.jpg";

import MainImage from "../../../assets/img/lefkadacity.jpg";
import { Row, Col } from "reactstrap";
class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
  }

  //if no email and password return false that will disable the submit button
  validateForm() {
    var disabled = true;

    if (this.state.email.length > 0 && this.state.password.length > 0)
      disabled = false;

    return disabled;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });

    // console.log("Ole", event.target.id, event.target.value, this.state);
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true }); //for the graphics

    try {
      const response = await Auth.signIn(this.state.email, this.state.password);
      // console.log("response from login ", response);

      // console.log(response.attributes.email);

      const session = await Auth.currentSession(); //checks the state and brings the result
      let role = session.accessToken.payload["cognito:groups"];
      //console.log("role", role);

      if (role === undefined) {
        role = "l1";
      }

      this.props.userHasAuthenticated(true, role, response.attributes.email);
      this.props.history.push("/ekloges/");

      // console.log(this.props);
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false }); //for the graphics
    }
  };

  componentDidMount() {
    //console.log(this.props);

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleOnSignup = () => {
    this.props.history.push("/signup");
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="Ιωάννης Μαλακάσης"
          links={
            <HeaderLinks
              dropdownHoverColor="info"
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              onLogout={this.props.onLogout}
            />
          }
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + MainImage + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader
                      color="info"
                      signup
                      className={classes.cardHeader}
                    >
                      <h3 className={classes.cardTitle}> Σύστημα Εκλογών</h3>
                    </CardHeader>

                    <CardBody signup>
                      <CustomInput
                        id="email"
                        value={this.state.email}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Email...",
                          type: "email",
                          onChange: this.handleChange,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        id="password"
                        value={this.state.password}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Password",
                          type: "password",
                          onChange: this.handleChange,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </CardBody>
                    <Row>
                      {" "}
                      <h1> </h1>{" "}
                    </Row>
                    <Row>
                      {" "}
                      <h1> </h1>{" "}
                    </Row>
                    <div className={classes.textCenter}>
                      {/* <Button
                        simple
                        color="info"
                        size="lg"
                        //disabled={!this.validateForm()}
                        onClick={this.handleSubmit}
                        // isLoading={this.state.isLoading}
                      >
                        Είσοδος
                      </Button> */}

                      {/* <Button disabled={disabled || isLoading} {...props}>
                        {isLoading && <i className="fas fa-sync fa-spin" />}
                        {!isLoading ? text : " " + loadingText}
                      </Button> */}

                      <Button
                        round
                        color="info"
                        onClick={this.handleSubmit}
                        disabled={this.validateForm() || this.state.isLoading}
                      >
                        {this.state.isLoading && (
                          <i className="fas fa-sync fa-spin" />
                        )}
                        {!this.state.isLoading ? "Εισοδος" : " " + "Loading..."}
                      </Button>
                      <Row>
                        {" "}
                        <h1> </h1>{" "}
                      </Row>
                    </div>
                  </form>
                </Card>
                <Row className="mt-3">
                  <Col xs="6">
                    {/* <a
                      className="text-light"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <small>Forgot password?</small>
                    </a> */}
                  </Col>
                  <Col className="text-right" xs="6">
                    <a
                      className="text-light"
                      href="#"
                      onClick={this.handleOnSignup}
                    >
                      <small>Δημιουργία Λογαριασμού</small>
                    </a>
                  </Col>
                </Row>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(LoginPage);
