import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import moment from "moment";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import ElectionsHeader from "components/Headers/electionsHeader.jsx";

import {
  fresultsGetAll,
  fresultsGetAllByElection
} from "../../../services/fakeResultsService";

import { felectionsGetOne } from "../../../services/fakeElectionsService";
import { electionsGetOne } from "../../../services/electionsService";

import { fpartiesGetAll } from "../../../services/fakePartiesService";
import { partiesGetAll } from "../../../services/partiesService";

import {
  resultsGetAll,
  resultsGetAllByElection
} from "../../../services/resultsService";

import { felectionsGetAll } from "../../../services/fakeElectionsService";
import { electionsGetAll } from "../../../services/electionsService";
import PartiesVotesAllTable from "./partiesVotesAllTable";
import Pagination from "../../../components/common/pagination";
import { paginate } from "../../../components/utils/paginate";
import _ from "lodash";
import { Doughnut } from "react-chartjs-2";
import ResultsTableReadOnly from "../results/resultsTableReadOnly";

import {
  chartOptions,
  parseOptions,
  chartExample2,
  chartExample3,
  chartExample4,
  chartExample5,
  chartExample6,
  chartExample7
} from "variables/charts.jsx";

import SearchBox from "../../../components/common/searchBox";

class ElectionDashboard extends React.Component {
  state = {
    partiesVotes: [],
    lastUpdateOn: "",

    parties: [],
    elections: [],
    results: [],
    isLoading: false,
    //// Table Results
    pageSizeResults: 10,
    currentPageResults: 1,
    searchQueryResults: "",
    sortColumnResults: { path: "nameVstation", order: "asc" },

    //Table PArties Votes
    sortColumnPartiesVotes: { path: "order", order: "asc" },

    activeNav: 1,
    chartExample1Data: "data1",
    chart: {
      data: {
        labels: [
          // "ND", "Pasok", "Siriza", "KKE", "Xrisi Augi"
        ],
        datasets: [
          {
            data: [], //[123, 25, 7, 8, 89],
            backgroundColor: [], //  ["#172b4d","#172b4d"],

            label: "Dataset 1"
          }
        ]
      },
      options: {
        responsive: true,

        plugins: {
          labels: {
            render: "percentage",
            fontColor: ["white"],
            precision: 2
          }
        },

        legend: {
          display: true,
          position: "bottom"
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var total = dataset.data.reduce(function(
                previousValue,
                currentValue,
                currentIndex,
                array
              ) {
                return previousValue + currentValue;
              });
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = Math.floor((currentValue / total) * 100 + 0.5);
              return percentage + "%";
            }
          }
        }
      }
    }
  };

  // toggleNavs = (e, index) => {
  //   e.preventDefault();
  //   this.setState({
  //     activeNav: index,
  //     chartExample1Data:
  //       this.state.chartExample1Data === "data1" ? "data2" : "data1"
  //   });
  // };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  async componentDidMount() {
    //Select the Election

    this.setState({ isLoading: true });

    var results;
    var idElection;
    var election;
    var parties;
    if (this.props.production === true) {
      idElection = "34995f70-74da-11e9-9b6f-dd59ad44297a";

      election = await electionsGetOne(idElection);
      results = await resultsGetAll(); //resultsGetAllByElection(idElection);
      parties = await partiesGetAll();
      // elections = await electionsGetAll();
    }
    if (this.props.production === false) {
      idElection = "EL1";
      election = felectionsGetOne(idElection);
      results = fresultsGetAllByElection(idElection);
      parties = fpartiesGetAll();
      // elections = felectionsGetAll();
    }

    //console.log("results......", results);

    //find the last udpdated item
    const updatedOnList = results.map(d => moment.unix(d.updatedOn));
    let lastUpdateOn = moment.max(updatedOnList);
    lastUpdateOn = moment(lastUpdateOn).unix();
    // moments = moments.map(d => moment(d));
    //console.log("lastUpdate", lastUpdateOn);

    //find the number of vstations
    const numOfVstationsAll = results.length;

    var obj = _.countBy(results, function(rec) {
      return rec.vStatus.id === "VOSTO04" || rec.vStatus.id === "VOSTO05";
    });
    const numOfVstationsWithResults = obj.true; // The number of true objects that are either draft or final result

    //Show only the results that hae value VOSTO04 or VOSTO05

    results = results.filter(
      f => f.vStatus.id === "VOSTO04" || f.vStatus.id === "VOSTO05"
    );

    // console.log(
    //   "Obj of count by,",
    //   numOfVstationsAll,
    //   obj,
    //   numOfVstationsWithResults
    // );

    this.setState({
      lastUpdateOn: lastUpdateOn,
      numOfVstationsAll: numOfVstationsAll,
      numOfVstationsWithResults: numOfVstationsWithResults,
      election: election,
      results: results,
      parties: parties,
      isLoading: false
    });

    // console.log(results, election);

    const partiesVotes = this.createVotePartiesTable(election, results);
    // console.log("partiesVotes", partiesVotes);
    this.chartUpdate(partiesVotes, parties);
  }

  chartUpdate = (partiesVotes, parties) => {
    //console.log(partiesVotes);

    const { chart } = { ...this.state };
    var labels = [];
    var data = [];
    var backColor = [];

    for (var i = 0; i < partiesVotes.length; i++) {
      const index = parties.findIndex(e => e.id === partiesVotes[i].id);

      //console.log(parties[index].name, parties[index].color);

      partiesVotes[i]["color"] = parties[index].color;

      //Fix the chart

      labels.push(parties[index].name);
      data.push(partiesVotes[i].votes);
      backColor.push(parties[index].color);

      //this.state.chart.data.labels.push(partiesVotes[i].name);
    }

    chart.data.labels = labels;
    chart.data.datasets[0].data = data;
    chart.data.datasets[0].backgroundColor = backColor;

    //  console.log(labels, data, backColor);
    // console.log(" chart.data.labels", chart.data.labels);
    //  console.log("partiesTable on color", partiesVotes);
    // this.setState({ partiesVotes: partiesVotes });

    this.setState({ chart: chart });
    //  console.log("this.state", this.state.chart);
  };

  createVotePartiesTable = (election, results) => {
    //Create a table with the parties
    var electionCopy = { ...election }; // election.electionParties;

    var partiesTable = [...electionCopy.electionParties];

    // console.log("partiesTable", partiesTable);

    //For all the records in the results
    for (var r = 0; r < results.length; r++) {
      //for all the votes of the parties
      for (var vp = 0; vp < results[r].votesParties.length; vp++) {
        //find the party ID
        const partyId = results[r].votesParties[vp].id;
        //Find which is the record in parties tavble that i will add the votes
        const index = partiesTable.findIndex(e => e.id === partyId);

        // console.log("partyId", partyId);

        //For that given index on the votes attributes add the previous one and the new one
        // the previous one could emoty on the first round thats whu the || 0
        partiesTable[index]["votes"] =
          (parseInt(partiesTable[index]["votes"], 10) || 0) +
          parseInt(results[r].votesParties[vp].votes, 10);

        // results[r][results[r].votesParties[vp].name] =
        //   results[r].votesParties[vp].votes;
      }
      // console.log("!!!", this.props.results.length);
    }

    //Order the parties Table
    partiesTable.sort((a, b) => (a.votes < b.votes ? 1 : -1));

    //fix tye order of the data
    for (var i = 0; i < partiesTable.length; i++) {
      //console.log("Results on after sort", partiesTable[i].order);
      partiesTable[i].order = i + 1;
    }

    // console.log("Results on after sort", partiesTable);

    this.setState({ partiesVotes: partiesTable });

    return partiesTable;
  };

  handlePageChange = page => {
    this.setState({ currentPageResults: page });
  };

  handleSearch = query => {
    // console.log("searchQueryResults.....", query);

    this.setState({ searchQueryResults: query, currentPageResults: 1 });
  };

  handleSort = sortColumnResults => {
    this.setState({ sortColumnResults });
  };

  handleSortPartiesVotes = sortColumnPartiesVotes => {
    this.setState({ sortColumnPartiesVotes });
  };

  getPagedData = () => {
    const {
      pageSizeResults,
      currentPageResults,
      sortColumnResults,
      searchQueryResults,
      results: AllResults
    } = this.state;

    let filtered = AllResults;
    if (searchQueryResults)
      filtered = AllResults.filter(c =>
        c.nameVstation
          .toLowerCase()
          .startsWith(searchQueryResults.toLowerCase())
      );

    const sorted = _.orderBy(
      filtered,
      [sortColumnResults.path],
      [sortColumnResults.order]
    );

    const results = paginate(sorted, currentPageResults, pageSizeResults);

    return { totalCount: filtered.length, data: results };
  };

  getPagedDataPartiesVotes = () => {
    const {
      // pageSizeResults,
      // currentPageResults,
      sortColumnPartiesVotes,
      // searchQueryResults,
      partiesVotes
    } = this.state;

    // let filtered = AllResults;
    // if (searchQueryResults)
    //   filtered = AllResults.filter(c =>
    //     c.nameVstation
    //       .toLowerCase()
    //       .startsWith(searchQueryResults.toLowerCase())
    //   );

    const sorted = _.orderBy(
      partiesVotes,
      [sortColumnPartiesVotes.path],
      [sortColumnPartiesVotes.order]
    );

    // const results = paginate(sorted, currentPageResults, pageSizeResults);

    return { totalCount: sorted.length, data: sorted };
  };

  render() {
    const {
      totalCount: totalCountResults,
      data: results
    } = this.getPagedData();

    const {
      totalCount: totalPartiesVotes,
      data: partiesVotes
    } = this.getPagedDataPartiesVotes();

    // console.log("partiesVotes", this.state.lastUpdateOn, partiesVotes);

    const {
      pageSizeResuslts,
      currentPageResults,
      sortColumnResults,
      pageSizeResults,
      searchQueryResults,
      sortColumnPartiesVotes
    } = this.state; // object destructuring from state

    // console.log("totalCountResults", totalCountResults);

    if (this.state.isLoading === true)
      return (
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h4 className="h3 mb-0">
                  Φορτώνω αποτελέσματα <i className="fas fa-sync fa-spin" />{" "}
                </h4>
                {/* <h6 className="surtitle"> Περιφέρεια</h6>
                <h5 className="h3 mb-0">Σειρά & Ψηφοι</h5> */}
              </CardHeader>
            </Card>
          </Col>
        </Row>
      );

    if (this.state.results.length === 0)
      return <h4> Δεν εχουμε ακομα καποιο Αποτελέσμα</h4>;

    return (
      <>
        <ElectionsHeader
          name="Default"
          parentName="Dashboards"
          lastUpdateOn={this.state.lastUpdateOn}
          numOfVstationsAll={this.state.numOfVstationsAll}
          numOfVstationsWithResults={this.state.numOfVstationsWithResults}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="8">
              <Card>
                {/* <CardHeader className="border-0">
                  <h3 className="mb-0">Συγκεντρωτικά Περιφέρειας</h3> */}

                <CardHeader>
                  <h6 className="surtitle"> Περιφέρεια</h6>
                  <h5 className="h3 mb-0">Σειρά & Ψηφοι</h5>
                </CardHeader>
                {totalCountResults >= 0 && (
                  <PartiesVotesAllTable
                    partiesVotesAll={partiesVotes} //{this.state.partiesVotes}
                    sortColumn={sortColumnPartiesVotes}
                    // onDelete={this.handleDelete}
                    // onUpdate={this.handleUpdate}
                    onSort={this.handleSortPartiesVotes}
                  />
                )}
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h6 className="surtitle"> Περιφέρεια</h6>
                  <h5 className="h3 mb-0">Ποσοστό</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={this.state.chart.data}
                      options={this.state.chart.options}
                      className="chart-canvas"
                      id="chart-doughnut"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <Col xs="12">
                    <h2 className="mb-0">
                      <Badge color="info">{totalCountResults}</Badge>
                      <span className="m-1" />
                      Αποτελέσματα Εκλογών
                    </h2>
                  </Col>
                </CardHeader>

                <CardBody>
                  <React.Fragment>
                    <SearchBox
                      value={searchQueryResults}
                      onChange={this.handleSearch}
                    />
                    <ResultsTableReadOnly
                      results={results}
                      sortColumn={sortColumnResults}
                      // onDelete={this.handleDelete}
                      // onUpdate={this.handleUpdate}
                      onSort={this.handleSort}
                      authLevel={this.props.authLevel}
                    />
                    <Pagination
                      itemsCount={totalCountResults}
                      pageSize={pageSizeResults}
                      currentPage={currentPageResults}
                      onPageChange={this.handlePageChange}
                    />
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ElectionDashboard;
