import React from "react";
import Joi from "joi-browser";
// import Select from "react-select";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  CardHeader
} from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import swal from "sweetalert";
import _ from "lodash";

import Form from "../../../components/common/form";
import NewSelect4 from "../../../components/common/newSelect4";
import LoaderButton from "../../../components/common/loaderButton";
import {
  fpartiesSave,
  fpartiesGetOne
} from "../../../services/fakePartiesService";
import { partiesSave, partiesGetOne } from "../../../services/partiesService";
import {
  electionsAreasGetAll,
  electionTypesGetAll
} from "../../../services/listOfValues";
import ColorPicker from "../../../components/common/colorPicker";
import PartiesCandidatesTable from "./partiesCandidatesTable";
import Pagination from "../../../components/common/pagination";
import SearchBox from "../../../components/common/searchBox";
import { paginate } from "../../../components/utils/paginate";
import PartiesCandidateForm from "./partiesCandidatesForm";
import { Button } from "../../../components/Ncomponents";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class PartiesForm extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: "",
        name: "",
        leader: "",
        active: true,
        electionType: { id: "", name: "" },
        // electionTypeId: "",
        color: "#0013BF", //"#0013BF",//initialize with a value and then will be brought
        partiesCandidates: [],
        location: [],
        notes: "",
        updatedOn: moment().unix()
      },
      //for the Candidates form
      pageSize: 20,
      currentPage: 1,
      searchQuery: "",
      sortColumn: { path: "order", order: "asc" },
      // T TColor: "",
      //Nav Pills data
      hTabs: "ht1",

      ////
      electionTypes: [],
      errors: {},
      partyAlreadyInDb: false, // to to switch off the card of Candidates
      hasCandidates: false,
      isLoading: null,
      showCreateForm: false,
      showCandidatesForm: true,

      //for the Location Dropdowns
      periferies: [], //for Dropdown 1
      periferiakesEnotites: [], //for Dropdown 2
      dimoi: [], //for Dropdown 3
      periferia: { id: "", name: "", layerId: "" },
      periferiakiEnotita: { id: "", name: "", layerId: "" },
      dimos: { id: "", name: "", layerId: "" },
      hideDropdownPeriferia: false,
      hideDropdownPeriferiakiEnotita: false,
      hideDropDownDimos: false,
      electionLocations: [],

      hideDropdownElectionTypes: false,
      title: "",
      newCandidate: [],
      candidateToUpdate: {},
      action: "Create"
    };
  }

  schema = {
    id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Όνομα"),
    leader: Joi.string()
      .required()
      .label("Αρχηγός"),
    active: Joi.boolean(),

    color: Joi.string()
      .min(7)
      .max(7)
      .label("Χρώμα"),
    updatedOn: Joi.string().label("Τελευταία Ενημέρωση"),
    notes: Joi.string()
      .label("Σημειωσεις")
      .allow("")
  };

  async componentDidMount() {
    const electionTypes = electionTypesGetAll();
    var electionLocations = electionsAreasGetAll();

    // this.setState({ electionTypes });

    //Bring the locations

    var periferies = electionLocations.filter(p => p.layerId === "LOLA01");
    var periferiakesEnotites = electionLocations.filter(
      p => p.layerId === "LOLA02"
    );
    var dimoi = electionLocations.filter(p => p.layerId === "LOLA03");

    //map them to format that dropdown wants them with value and label
    // periferies = this.mapDropdownIdToValue(periferies);
    // periferiakesEnotites = this.mapDropdownIdToValue(periferiakesEnotites);
    // dimoi = this.mapDropdownIdToValue(dimoi);

    this.setState(
      {
        electionLocations,
        periferies,
        periferiakesEnotites,
        dimoi,
        electionTypes
      }
      // ,
      // () => console.log("getNewElectionsArea Update of Areas", this.state)
    );
    // console.log(this.state);

    const partyId = this.props.match.params.id;
    if (partyId === "new") {
      this.setState({ title: "Δημιουργία καινούριου κόμματος" });
      return;
    }
    //Load the party

    try {
      var party;
      if (this.props.production === true) party = await partiesGetOne(partyId);
      if (this.props.production === false) party = fpartiesGetOne(partyId);
      // var party = await partiesGetOne(partyId);
      // if (!party) return this.props.history.replace("/not-found");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }

    //if party bring the value to the dropdowns of locations
    var periferia = "";
    var periferiakiEnotita = "";
    var dimos = "";

    for (var i = 0; i < party.location.length; i++) {
      // console.log(party.location);

      switch (party.location[i].layerId) {
        case "LOLA01":
          periferia = party.location[i];
          break;
        case "LOLA02":
          periferiakiEnotita = party.location[i];
          //i will filetr also out the pefiriakes enotites that are not on the perifeira
          periferiakesEnotites = periferiakesEnotites.filter(
            f => f.pId === periferia.id
          );
          break;
        case "LOLA03":
          dimos = party.location[i];
          //i will filetr also out the pefiriakes enotites that are not on the perifeira
          dimoi = dimoi.filter(f => f.pId === periferiakiEnotita.id);
          break;
        default:
      }
    }

    // console.log(periferia, periferiakiEnotita, dimos);
    //Check the periferiaki dropdown
    var hideDropDownDimos = false;
    var hideDropdownPeriferiakiEnotita = false;
    if (party.electionType.id === "ELTY003") {
      hideDropDownDimos = true;
      hideDropdownPeriferiakiEnotita = true;
    }

    const title = "Ενημέρωση κόμματος " + party.name;

    this.setState(
      {
        data: this.mapToViewModel(party),
        partyAlreadyInDb: true,
        periferia,
        periferiakiEnotita,
        dimos,
        hideDropDownDimos,
        hideDropdownPeriferiakiEnotita,
        periferiakesEnotites, // so to filter out the choices for the dropddowns and not show all available options
        dimoi,
        title
      },
      () =>
        console.log(
          "CallBack to refresh the state: ",
          // this.state.periferia.id,
          // this.state.periferiakiEnotita.id,
          // this.state.dimos.id,
          this.state
        )
    );

    // this.setState({ isInitializing: "false" });
  }

  //i addedd the "" cause the Dynamo DB saves nulls to the attributes that dint have a value on create and
  // then when i am trying to update that i got the warnings !! here so to make sure that it will not be the case
  //if null then "" to my non mandatory fields
  mapToViewModel(party) {
    return {
      id: party.id,
      name: party.name,
      leader: party.leader || "",
      active: party.active,
      electionType: party.electionType,
      color: party.color || "",
      partiesCandidates: party.partiesCandidates,
      location: party.location,
      notes: party.notes || "",
      updatedOn: party.updatedOn
    };
  }

  handleChangeNewSelect3 = e => input => {
    // console.log(e, input.value, input.label);
    if (e === "electionTypes") {
      console.log("change Type", input.value, input.label);
      const data = { ...this.state.data };
      data.electionType.id = input.value;
      data.electionType.name = input.label;

      var dimos = { ...this.state.dimos };
      var periferiakiEnotita = { ...this.state.periferiakiEnotita };
      var hideDropDownDimos = false;
      var hideDropdownPeriferiakiEnotita = false;

      if (data.electionType.id === "ELTY003") {
        dimos.id = null;
        periferiakiEnotita.id = null;
        hideDropDownDimos = true;
        hideDropdownPeriferiakiEnotita = true;
      }

      // console.log("change Type Data", data);
      this.setState({
        data: data,
        periferiakiEnotita: periferiakiEnotita,
        dimos: dimos,
        hideDropDownDimos: hideDropDownDimos,
        hideDropdownPeriferiakiEnotita: hideDropdownPeriferiakiEnotita
      });
    }

    if (e === "periferia") {
      //update the input tables of the other two drop downs
      var periferiakesEnotites = this.state.electionLocations.filter(
        pe => pe.layerId === "LOLA02" && pe.pId === input.value
      );
      var dimoi = this.state.electionLocations.filter(
        d => d.layerId === "LOLA03"
      );

      // //create the format that dropwon wants with values and labels
      // periferiakesEnotites = this.mapDropdownIdToValue(periferiakesEnotites);
      // dimoi = this.mapDropdownIdToValue(dimoi);

      var periferia = {};
      periferia.id = input.value;
      periferia.name = input.label;
      periferia.layerId = "LOLA01";

      let dimos = { ...this.state.dimos };
      dimos.id = null;

      let periferiakiEnotita = { ...this.state.periferiakiEnotita };
      periferiakiEnotita.id = null;

      this.setState(
        {
          periferia: periferia,
          periferiakiEnotita: periferiakiEnotita,
          dimos: dimos,
          periferiakesEnotites,
          dimoi,
          hideDropdownPeriferia: false
        }
        // ,
        // () => console.log(" Prwto select Periferia", this.state)
      );
    }

    if (e === "periferiakiEnotita") {
      var periferiakiEnotita = {};
      periferiakiEnotita.id = input.value;
      periferiakiEnotita.name = input.label;
      periferiakiEnotita.layerId = "LOLA02";

      var dimos = { ...this.state.dimos };
      dimos.id = null;

      if (this.state.data.electionType.id === "ELTY003") {
        // periferiakes Ekloges

        this.setState({
          periferiakiEnotita: periferiakiEnotita,
          dimos: dimos,
          hideDropdownDimos: true
        });
      } else {
        //update the input tables of the other one  drop downs
        dimoi = this.state.electionLocations.filter(
          d => d.layerId === "LOLA03" && d.pId === input.value
        );

        //update the state, the value of the dropdown, make null the third dropdown and refresh the options of the 3 drop down
        this.setState({
          periferiakiEnotita: periferiakiEnotita,
          dimos: dimos,
          dimoi,
          hideDropdownDimos: false
        });
      }
    }

    if (e === "dimos") {
      console.log("Test", e);
      var dimos = {};
      dimos.id = input.value;
      dimos.name = input.label;
      dimos.layerId = "LOLA03";

      //   console.log("Dimos", this.state.dimos, dimos);

      this.setState({ dimos: dimos });
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    //This is to lock the save button and start spining !!!
    this.setState({ isLoading: true });

    //Creat the location array for the Party structure based on the selection of election type
    const { periferia, periferiakiEnotita, dimos } = this.state;
    var data = { ...this.state.data };

    data.location = [];
    data.location.push(periferia);
    if (this.state.data.electionType.id !== "ELTY003") {
      if (periferiakiEnotita) {
        data.location.push(periferiakiEnotita);
      }
      if (dimos) {
        data.location.push(dimos);
      }
    }

    try {
      delete data.updatedOn;
      console.log("on save", data);

      if (this.props.production === true) await partiesSave(data);
      if (this.props.production === false) fpartiesSave(data);
      // await partiesSave(data);

      console.log("on save", this.state.data);
      toast.success("Επιτυχία, Ενημέρωσα το κόμμα " + this.state.data.name);
      this.props.history.push("/ekloges/parties");
    } catch (ex) {
      toast.error(
        "Αποτυχία, Δεν μπόρεσα να ενημερώσω τα στοιχεία του κόμματος " +
          this.state.data.name
      );
      console.log(ex);
      this.setState({ isLoading: false });
    }
  };

  validateForm() {
    var disabled = true;
    var issues = 0;

    //console.log(this.state.data.electionType.id);

    if (this.state.data.electionType.id === "ELTY003") {
      //
      if (this.state.periferia === "") issues = issues + 1;
    } else {
      // console.log("Dimotikes", issues);
      if (
        !this.state.periferia.id ||
        !this.state.periferiakiEnotita.id ||
        !this.state.dimos.id
      ) {
        // this.state.periferia.id === "" ||
        // this.state.periferiakiEnotita.id === "" ||
        // this.state.dimos.id === ""
        issues = issues + 1;
        // console.log(
        //   "Dimotikes 2",
        //   this.state.periferia,
        //   this.state.periferiakiEnotita,
        //   this.state.dimos,
        //   issues
        // );
      }
    }
    // console.log("Before", issues);
    // chek generic fo erros election Type
    if (
      typeof this.state.data.electionType.id === "undefined" ||
      this.state.data.electionType.id === "" ||
      Object.keys(this.state.errors).length !== 0
    ) {
      issues = issues + 1;
    }
    //console.log("Finale", issues);
    //check for issues
    if (issues === 0) {
      disabled = false;
    }

    return disabled;
  }

  handleCancel = () => {
    this.props.history.push("/ekloges/parties");
  };

  handleCandidateDelete = candidate => {
    console.log("handleCandidateDelete");

    swal({
      title: "Εισαι σιγουρος  οτι θελεις να διαγραψεις τον Υποψήφιο",
      text: candidate.name,
      icon: "warning",
      buttons: true
    }).then(async willDelete => {
      if (willDelete) {
        // const data = partiesDeleteCandidate(this.state.data.id, candidate.id);

        const { data } = { ...this.state };
        const previousState = data;
        //find if the record exist
        const index = data.partiesCandidates.findIndex(
          e => e.id === candidate.id
        );

        //console.log(data.partiesCandidates);
        data.partiesCandidates.splice(index, 1);
        this.setState({ data: data });
        // console.log(data.partiesCandidates);

        try {
          delete data.updatedOn;
          console.log("on save", data);

          if (this.props.production === true) await partiesSave(data);
          if (this.props.production === false) fpartiesSave(data);

          // await partiesSave(data);
        } catch (ex) {
          console.log(ex);
          this.setState({ data: previousState });
        }

        // swal("Μολις τον διέγραψα", { icon: "success" });
      }
    });
  };

  handleCandidateUpdate = candidate => {
    // console.log("Me kalese to koumpi", candidate);

    this.setState({
      candidateToUpdate: candidate,
      showCreateForm: true,
      showCandidatesForm: false,
      action: "Update"
    });
  };

  handleCandidateShowCreate = () => {
    this.setState({
      showCreateForm: true,
      action: "Create",
      showCandidatesForm: false
    });
  };

  handlecandidatesSave = async partiesCandidates => {
    console.log("ELA na mpoume", partiesCandidates);

    const { data } = { ...this.state };
    const previousState = data;

    data["partiesCandidates"] = partiesCandidates;
    console.log("Cureent", data.partiesCandidates);

    this.setState(
      { showCreateForm: false, data: data, showCandidatesForm: true },
      () => console.log("Kanw Refresh", this.state.data)
    );

    try {
      // delete data.updatedOn;
      // console.log("on save", data);

      if (this.props.production === true) await partiesSave(data);
      //If not i have already updated the state !! no need for a service
      // if (this.props.production === false)

      toast.success("Επιτυχία, Ενημέρωσα το κόμμα " + this.state.data.name);
    } catch (ex) {
      toast.error(
        "Αποτυχία, Δεν μπόρεσα να ενημερώσω τα στοιχεία του κόμματος " +
          this.state.data.name
      );
      console.log(ex);
      this.setState({ isLoading: false, data: previousState });
    }
  };

  handleCandidateHideCreate = () => {
    this.setState({ showCreateForm: false, showCandidatesForm: true });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleColorChange = color => {
    console.log(color);
    const data = { ...this.state.data };
    data.color = color;

    this.setState({ data });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { partiesCandidates: allCandidates } = this.state.data;

    //console.log(allCandidates);

    let filtered = allCandidates || []; // in case is undefined
    if (searchQuery)
      filtered = allCandidates.filter(c =>
        c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const candidates = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, candidates: candidates };
  };

  mapDropdownIdToValue(options) {
    options = options.map(p => ({
      value: p.id,
      label: p.name,
      layerId: p.layerId,
      pId: p.pId
    }));

    return options;
  }

  handleChangeNewSelect2 = e => input => {};

  render() {
    this.validateForm();

    // console.log(" this.validateForm();", this.validateForm());

    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      title
    } = this.state;

    //const { length: count } = this.state.data.partiesCandidates || []; // Object destructing of the lentgh of candidates to count

    const { totalCount, candidates } = this.getPagedData();

    return (
      <React.Fragment>
        <SimpleHeader name="Εκλογες 2019" parentName="Διαχείρηση" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <Row>
                <Col md="9" sm="9">
                  <h2 className="mb-0">{title}</h2>
                </Col>
                <Col md="3" sm="3" className="align-items-right">
                  <h5 className="mb-0">
                    Ενημερώθηκε
                    {" " +
                      moment
                        .unix(this.state.data.updatedOn)
                        .format("DD/MM/YY HH:mm:ss")}
                  </h5>
                </Col>
              </Row>
            </CardHeader>
            {/* <CardHeader>
                  <CardTitle tag="h4">
                    Navigation Pills -{" "}
                    <small className="description">Horizontal Tabs</small>
                  </CardTitle>
                </CardHeader> */}
            <CardBody>
              <Nav pills className="nav-pills-primary">
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht1" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht1" })}
                  >
                    Βασικές Πληροφορίες
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.hTabs === "ht2" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht2" })}
                  >
                    Υποψήφιοι
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.hTabs} className="tab-space">
                <TabPane tabId="ht1">
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col md="6" sm="6">
                        {this.renderInput("name", "Ονομα")}
                      </Col>

                      <Col md="1" sm="2">
                        <ColorPicker
                          onColorChange={this.handleColorChange}
                          colorHexValue={this.state.data.color}
                        />
                      </Col>

                      <Col md="2" sm="6">
                        {this.renderInput("color", "Χρώμα")}
                      </Col>

                      <Col md="2" sm="2">
                        {this.renderCheck(
                          "active",
                          "Ενεργο",
                          this.state.data.active,
                          "TypeActive"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" sm="6">
                        {this.renderInput("leader", "Αρχηγός")}
                      </Col>
                      <Col md="6" sm="6">
                        <NewSelect4
                          name="electionTypes"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          isSearchable
                          placeholder="Επιλογή"
                          value={this.state.data.electionType.id} // it wants only the ID wich will automatically convert that to value
                          label="Τύπος"
                          options={this.state.electionTypes}
                          onChange={this.handleChangeNewSelect3(
                            "electionTypes"
                          )}
                          isDisabled={this.state.hideDropdownElectionTypes}
                        />
                      </Col>

                      {/* <Col md="4" sm="6">
                        {this.renderMoment(
                          "updatedOn",
                          "Ενημερώθηκε",
                          "Text",
                          true
                        )}
                      </Col> */}
                    </Row>
                    <Row>
                      <Col md="4" sm="6">
                        <NewSelect4
                          name="periferia"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          isSearchable
                          placeholder="Επιλογή"
                          value={this.state.periferia.id}
                          label="Περιφερεια"
                          options={this.state.periferies}
                          onChange={this.handleChangeNewSelect3("periferia")}
                          isDisabled={this.state.hideDropdownPeriferia}
                        />
                      </Col>

                      <Col md="4" sm="6">
                        <NewSelect4
                          name="periferiakiEnotita"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          isSearchable
                          placeholder="Επιλογή"
                          value={this.state.periferiakiEnotita.id}
                          label="Περιφερειακη Ενοτητα"
                          options={this.state.periferiakesEnotites}
                          onChange={this.handleChangeNewSelect3(
                            "periferiakiEnotita"
                          )}
                          isDisabled={this.state.hideDropdownPeriferiakiEnotita}
                        />
                      </Col>

                      <Col md="4" sm="6">
                        <NewSelect4
                          name="dimos"
                          className="react-select primary"
                          classNamePrefix="react-select"
                          isSearchable
                          placeholder="Επιλογή"
                          value={this.state.dimos.id}
                          label="Δημος"
                          options={this.state.dimoi}
                          onChange={this.handleChangeNewSelect3("dimos")}
                          isDisabled={this.state.hideDropDownDimos}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" sm="12">
                        {this.renderTextArea(
                          "notes",
                          "Σημειωσεις",
                          "Text",
                          false,
                          "4",
                          "40"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8} xs={12}>
                        <div className="btns-mr-5">
                          <Button
                            // leftLabel="now-ui-icons arrows-1_minimal-left"
                            // round
                            onClick={this.handleCancel}
                          >
                            Ακύρωση
                          </Button>

                          <span className="m-2"> </span>

                          <LoaderButton
                            color="success"
                            // round
                            // leftLabel="now-ui-icons ui-1_check"
                            onClick={this.handleSubmit}
                            disabled={this.validateForm()}
                            isLoading={this.state.isLoading}
                            text="Αποθηκευση"
                            loadingText="Saving...."
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </TabPane>
                <TabPane tabId="ht2">
                  {this.state.showCreateForm === true && (
                    <PartiesCandidateForm
                      candidatesInUse={this.state.data.partiesCandidates}
                      onCancel={this.handleCandidateHideCreate}
                      onSave={this.handlecandidatesSave}
                      party={this.state.data}
                      candidateToUpdate={this.state.candidateToUpdate}
                      action={this.state.action}
                      production={this.props.production}
                    />
                  )}
                  {this.state.showCandidatesForm === true && (
                    <React.Fragment>
                      <Row>
                        <p> </p>{" "}
                      </Row>
                      <Row>
                        <Col md={6} xs={6}>
                          Οι Υποψήφιοι του <b>"{this.state.data.name}"</b> ειναι{" "}
                          {totalCount}
                        </Col>
                        {/* </Row>
                      <Row>
                        <p> </p>{" "}
                      </Row>
                      <Row> */}
                        <Col md="6" xs="6" className="text-right">
                          <button
                            onClick={this.handleCandidateShowCreate}
                            className="btn btn-success"
                            style={{ marginBottom: 20 }}
                            disabled={!this.state.partyAlreadyInDb}
                          >
                            Προσθήκη
                          </button>
                        </Col>
                      </Row>

                      <React.Fragment>
                        <Row>
                          <Col md={12} xs={12}>
                            <SearchBox
                              value={searchQuery}
                              onChange={this.handleSearch}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12}>
                            <PartiesCandidatesTable
                              candidates={candidates}
                              sortColumn={sortColumn}
                              onDelete={this.handleCandidateDelete}
                              onUpdate={this.handleCandidateUpdate}
                              onSort={this.handleSort}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12}>
                            <Pagination
                              itemsCount={totalCount}
                              pageSize={pageSize}
                              currentPage={currentPage}
                              onPageChange={this.handlePageChange}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    </React.Fragment>
                  )}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default PartiesForm;
