import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import Check from "./check";
import NewSelect from "./newSelect";
import DateTimePicker from "./dateTime";
import moment from "moment";
import TextArea from "./textarea";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  // validate = () => {
  //   const options = { abortEarly: false };
  //   const { error } = Joi.validate(this.state.data, this.schema, options);
  //   if (!error) return null;

  //   const errors = {};
  //   for (let item of error.details) errors[item.path[0]] = item.message;
  //   return errors;
  // };

  // validateProperty = ({ name, value }) => {
  //   const obj = { [name]: value };
  //   const schema = { [name]: this.schema[name] };
  //   const { error } = Joi.validate(obj, schema);
  //   return error ? error.details[0].message : null;
  // };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleChangeNewSelect2 = e => name => {};

  handleChangeNewSelect = e => name => {
    // const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];

    const data = { ...this.state.data };
    data[e] = name.value;

    this.setState({ data });
  };
  // handleChangeColorComplete = ({ currentTarget: input, color }) => {
  //   const data = { ...this.state.data };
  //   console.log(color.hex);

  //   // data[input.name] = color.hex;

  //   // this.setState({ data });
  // };

  // handleChangeColor(color, event) {
  //   const data = { ...this.state.data };
  //   console.log(color.hex);

  //   // data[input.name] = color.hex;

  //   // this.setState({ data });
  // }

  handleCheck = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.checked;
    this.setState({ data });
  };

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  // renderNewSelect(name, label, options) {
  //   const { data, errors } = this.state;

  //   console.log( "The values that gets is ",this.state.name, data[name], name,label);
  //   return (
  //     <NewSelect
  //       name={name}
  //       value={this.state[name]}
  //       label={label}
  //       options={options}
  //       onChange={this.handleChangeNewSelect(name)}
  //       error={errors[name]}
  //     />
  //   );
  // }

  renderNewSelect(name, label, options, disabled) {
    const { data, errors } = this.state;

    return (
      <NewSelect
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChangeNewSelect(name)}
        error={errors[name]}
        isDisabled={disabled}
      />
    );
  }

  // renderNewSelect2(name,label,value, options) {
  //   const { errors } = this.state;

  //   return (
  //     <NewSelect
  //       name={name}
  //       value={value}
  //       label={label}
  //       options={options}
  //       onChange={this.handleChangeNewSelect2()}
  //       error={errors[name]}
  //     />
  //   );
  // }

  renderInput(name, label, type = "text", disabled = false) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={disabled}
      />
    );
  }

  renderTextArea(name, label, type = "text", disabled = false, rows, cols) {
    const { data, errors } = this.state;

    return (
      <TextArea
        type={type}
        name={name}
        cols={cols}
        rows={rows}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={disabled}
      />
    );
  }

  renderMoment(name, label, type = "text", disabled = true) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={moment.unix(data[name]).format("DD/MM/YY HH:mm:ss")}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={disabled}
      />
    );
  }

  renderPercentage(name, label, type = "text", disabled = true) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={(data[name] * 100).toFixed(2)}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={disabled}
      />
    );
  }

  renderDate(name, label, type = "text", disabled = false) {
    const { data, errors } = this.state;

    return (
      <DateTimePicker
        type={type}
        name={name}
        placeholder="Επιλογή Ημερομηνίας"
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        disabled={disabled}
      />
    );
  }

  // renderDate(name, label, type = "text", disabled = true) {
  //   const { data } = this.state;

  //   return (
  //     <Input
  //       type={type}
  //       name={name}
  //       value={data[name]}
  //       label={label}
  //       disabled={disabled}
  //     />
  //   );
  // }

  // renderColor(name, label, type = "text", disabled = false) {
  //   const { data, errors } = this.state;

  //   return (
  //     <Input
  //       type={type}
  //       name={name}
  //       value={data[name]}
  //       label={label}
  //       onChange={this.handleChange}
  //       error={errors[name]}
  //       disabled={disabled}
  //     />
  //   );
  // }

  // renderColorInput(name, label) {
  //   return (
  //     <CPicker
  //       name={name}
  //       value={name}
  //       label={label}
  //       onChangeComplete={this.handleChangeComplete}
  //     />
  //   );
  // }

  renderCheck(name, label, checked, type = "TypeActive") {
    return (
      <Check
        checked={checked}
        name={name}
        value={name}
        label={label}
        onChange={this.handleCheck}
        type={type} //for automcatically saying energos anergos
      />
    );
  }

  // rendermCheck(name, label, checked) {
  //   const { data, errors } = this.state;

  //   //the Material UI
  //   return (
  //     <FormControlLabel
  //       control={
  //         <Checkbox
  //           checked={checked}
  //           onChange={this.handleMChange(name)}
  //           value={name}
  //           color="primary"
  //         />
  //       }
  //       label={checked ? "Ενεργός" : "Ανενεργός"}
  //     />
  //   );
  // }
}

export default Form;
