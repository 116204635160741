import React from "react";
import Form from "../../../components/common/form";
import Joi from "joi-browser";
import { Badge, Col, Row } from "reactstrap";
import { Button } from "../../../components/Ncomponents";

import { electionsAreasGetAll } from "../../../services/listOfValues";
// import { partiesGetAll } from "../services/fakePartiesService";
import { partiesGetAll } from "../../../services/partiesService";
import { fpartiesGetAll } from "../../../services/fakePartiesService";
import LoaderButton from "../../../components/common/loaderButton";
import Pagination from "../../../components/common/pagination";
import SearchBox from "../../../components/common/searchBox";
import { paginate } from "../../../components/utils/paginate";
import swal from "sweetalert2";
import _ from "lodash";
import ElectionsPartiesTable from "./electionsPartiesTable";
import NewSelect4 from "../../../components/common/newSelect4";

class ElectionsPartiesForm extends Form {
  constructor(props) {
    super();
    this.state = {
      data: {
        id: "",
        name: "",
        order: ""
      },

      electionParties: [],
      electionLocations: [],
      partiesAll: [],
      areasAll: [],
      //the delta between DB and used
      location: "", //{ value: "", label: "", layerId: "" },
      //   party: { value: "", label: "", layerId: "" },
      //   party2: {},
      //The values that will be kept for the drodowns
      errors: {},
      title: "",
      showBodyForm: true,
      showCreateForm: false,
      hideDropDownLocationParties: false,
      hideDropDownParties: false,

      //for the pagination
      pageSize: 10,
      currentPage: 1,
      searchQuery: "",
      sortColumn: { path: "order", order: "asc" },
      ///Save Loading Button !!
      isLoading: false
    };
  }

  schema = {
    id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Όνομα"),
    order: Joi.number()
      .required()
      .label("Αριθμός")
  };

  validateForm() {
    var disabled = true;

    if (this.state.data.name.length > 0 && this.state.data.order !== "") {
      disabled = false;
    }

    if (Object.entries(this.state.errors).length !== 0) {
      disabled = true;
    }

    return disabled;
  }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSearch = query => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  async componentDidMount() {
    const elections = { ...this.props.elections };

    //console.log("elections", elections);

    var parties;
    if (this.props.production === true) parties = await partiesGetAll();
    if (this.props.production === false) parties = fpartiesGetAll();
    // const parties = await partiesGetAll();
    console.log("parties", parties);
    //sort the parties by name
    parties.sort((a, b) => (a.name > b.name ? 1 : -1));

    const areas = electionsAreasGetAll();

    this.setState(
      {
        electionParties: elections.electionParties,
        electionLocations: elections.electionLocations,
        partiesAll: parties,
        areasAll: areas
      },
      () =>
        console.log(
          "call Back on cdm on election parties form",
          this.state.electionParties
        )
    );

    //the first will have the areas that are new( not used ) while the second one should not be updated i need that later on edit to check the layerid
    //this.setState({ electionLocations, electionLocationsAllDb: electionLocations });
  }

  handleSave = () => {
    //Save Button to lock that
    this.setState({ isLoading: true });

    const data = { ...this.state.data };
    data.order = parseInt(data.order, 10); // to make sure that the order is saved as integer cuase it was saving that as string

    console.log("save", this.state);
    this.props.onSave(data);

    this.setState({
      showBodyForm: true,
      showCreateForm: false,
      data: null,
      isLoading: false
    });
  };

  initializeStateData() {
    const data = { ...this.state.data };
    data.id = "";
    data.name = "";
    data.order = "";

    return data;
  }

  handleShowCreateForm = () => {
    //create the Title
    const title = "Προσθήκη κόμματος";

    //inititliaze as i save or cancel i make that null and the dropdown parties complains ...
    const data = this.initializeStateData();

    //Load only the new parties so to see the already used partis
    this.getNewParties();

    //Bring the locations from the electionLocations, those will be used to filter my parties also
    var areasUsed = this.props.elections.electionLocations;

    //if perfieriakes then i need to go on level up my areas so to show the perferies
    if (this.props.elections.electionType.id === "ELTY003") {
      var Periferies = [];
      var pen = {};
      var periferia = {};
      for (var i = 0; i < areasUsed.length; i++) {
        pen = this.state.areasAll.find(f => f.id === areasUsed[i].id);
        periferia = this.state.areasAll.find(f => f.id === pen.pId);

        // console.log(periferia, areasUsed[i]);
        if (Periferies.indexOf(periferia) === -1) {
          Periferies.push(periferia);
        }
      }

      //and finally i make the areasUsed to periferies
      areasUsed = Periferies;
    }

    this.setState(
      {
        data: data,
        title: title,
        showCreateForm: true,
        showBodyForm: false,
        electionLocations: areasUsed,
        location: "", //initialize the drop down of locations
        hideDropDownParties: true,
        hideDropDownLocationParties: false
      },
      () => console.log("create form: state", this.state)
    );
  };

  handleCancel = () => {
    this.setState({
      showBodyForm: true,
      showCreateForm: false,
      data: null
    });
  };

  getNewParties = () => {
    var freshParties = this.state.partiesAll;

    if (!this.props.elections.electionParties) {
      console.log("Props were not loaded");
    } else {
      freshParties = this.state.partiesAll.filter(
        cdb =>
          !this.props.elections.electionParties.find(cf => cdb.id === cf.id)
      );

      this.setState({ electionParties: freshParties }, () =>
        console.log("Refreshed the parties", this.state)
      );
    }
  };

  validateCreateButton() {
    if (this.props.elections.electionLocations.length > 0) {
      return false;
    }

    return true;
  }

  handleChangeNewSelect3 = e => input => {
    if (e === "electionLocations") {
      //find all parties that have in theri location the value of the drop down
      //it work either for periferiakes (level1) or all the others as the party has the value of level1 for periferiakes
      //or the party for dimotikes has all three leveles and it matches with the 3 one
      const parties = this.state.electionParties.filter(p =>
        p.location.find(f => f.id === input.value)
      );

      const data = { ...this.state.data };
      data.id = null; // that will make the value of the drop down to clear as per the new select 4

      this.setState({
        data: data,
        electionParties: parties,
        hideDropDownParties: false
      });
    }

    if (e === "Parties") {
      const data = { ...this.state.data };
      console.log("Select Parties", e, input);
      data.id = input.value; // will add the value to id
      data.name = input.label; // and the lable to name
      this.setState({ data });
    }
  };

  handleDelete = electionParty => {
    swal({
      title: electionParty.name,
      text: "Είσαι σίγουρος  οτι θέλεις να το διαγράψεις;",
      icon: "warning",
      buttons: true
    }).then(async willDelete => {
      if (willDelete) {
        if (
          this.state.currentPage > 1 &&
          this.state.currentPage - 1 ===
            (this.props.elections.electionParties.length - 1) /
              this.state.pageSize //e,g, page 3 , 8 length page size 4=>  (3-1=2) =  ( 8/4= 2) so reduce
        ) {
          const newPage = this.state.currentPage - 1;
          this.setState({ currentPage: newPage });
        }

        this.props.onDelete(electionParty);
      }
    });
  };

  getPagedDataElectionsParties = () => {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { electionParties: AllelectionParties } = this.props.elections;

    let filtered = AllelectionParties || []; // in case is undefined
    if (searchQuery)
      filtered = AllelectionParties.filter(c =>
        c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    // console.log("Input to paginate", sorted, currentPage, pageSize);

    const parties = paginate(sorted, currentPage, pageSize);

    return { totalCountParties: filtered.length, parties: parties };
  };

  renderAreasBody() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;

    const { totalCountParties, parties } = this.getPagedDataElectionsParties();

    return (
      <React.Fragment>
        <br />
        <Row>
          <Col xs="6">
            <h3 className="mb-0">
              <Badge color="info">{totalCountParties}</Badge>
              <span className="m-1" />
              Κόμματα συμμετέχουν
            </h3>
          </Col>
          <Col className="text-right" xs="6">
            <button
              onClick={this.handleShowCreateForm}
              className="btn btn-success"
              style={{ marginBottom: 20 }}
              disabled={this.validateCreateButton()} // if there is no record on the parent
            >
              Προσθήκη
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ElectionsPartiesTable
              electionParties={parties}
              sortColumn={sortColumn}
              onDelete={this.handleDelete} //{this.props.onDelete}
              onUpdate={this.handleUpdate}
              onSort={this.handleSort}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <Pagination
              itemsCount={totalCountParties}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  handleUpdate = electionParty => {
    //update the title of the form
    const title = "Ενημέρωση εγγραφής";

    //find the full details of the party as i want its locations
    const partyFullDetails = this.state.partiesAll.find(
      f => f.id === electionParty.id
    );

    console.log(this.state.partiesAll);
    //find the location of the patrty from the table parties All i can get all t
    var electionsAreas = [];
    var electionArea = {};
    if (this.props.elections.electionType.id === "ELTY003") {
      electionArea = partyFullDetails.location.find(
        p => p.layerId === "LOLA01"
      );
    } else {
      electionArea = partyFullDetails.location.find(
        p => p.layerId === "LOLA03"
      );

      console.log("Dimoitkes", electionArea, partyFullDetails);
    }
    electionsAreas.push(electionArea);

    //load for the parties dropdonw only the potion omly the one option
    const electionParties = [];
    electionParties.push(electionParty);

    //bring the data to thet state
    var data = { ...this.state.data };
    data = this.mapToViewModel(electionParty);
    // data.id = electionParty.id;
    // data.name = electionParty.name;
    // data.order = electionParty.order;

    //check the order if it is null or undefinde
    // if (data.order === undefined) {
    //   data.order = "";
    // }

    this.setState(
      {
        data: data, // the value that the drop down has is inside the id
        electionParties: electionParties, // for dropdonw of parties
        title: title,
        showCreateForm: true,
        showBodyForm: false,
        hideDropDownLocationParties: true,
        location: electionArea.id, // the value of the dropdonw of location
        electionLocations: electionsAreas // the options of the drop donw location
      },
      () => console.log(this.state)
    );
  };

  mapToViewModel(electionParty) {
    return {
      id: electionParty.id,
      name: electionParty.name,
      order: electionParty.order
    };
  }

  renderAreasCreate() {
    console.log("on create", this.state);

    return (
      <form>
        <br />
        <Row>
          <Col xs="12">
            <h3 className="mb-0">{this.state.title}</h3>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={4} xs={12}>
            <NewSelect4
              name="electionLocations"
              className="react-select primary"
              classNamePrefix="react-select"
              isSearchable
              placeholder="Επιλογή"
              value={this.state.location}
              label="Εκλογική Περιοχή"
              options={this.state.electionLocations}
              onChange={this.handleChangeNewSelect3("electionLocations")}
              isDisabled={this.state.hideDropDownLocationParties}
            />
          </Col>
          {/* <Row>
          <h4> </h4>
        </Row> */}
          <Col md={4} xs={12}>
            <NewSelect4
              name="Parties"
              className="react-select primary"
              classNamePrefix="react-select"
              isSearchable
              placeholder="Επιλογή"
              value={this.state.data.id} // it wants only the ID wich will automatically convert that to value
              label="Κόμμα"
              options={this.state.electionParties}
              onChange={this.handleChangeNewSelect3("Parties")}
              isDisabled={this.state.hideDropDownParties}
            />
          </Col>

          <Col md={3} xs={12}>
            {this.renderInput("order", "Σειρά Εμφάνισης")}
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <Button
              // leftLabel="now-ui-icons arrows-1_minimal-left"
              // round
              onClick={this.handleCancel} //    {this.props.onCancel}
            >
              Ακύρωση
            </Button>
            <span className="m-2"> </span>

            <LoaderButton
              color="success"
              // round
              // leftLabel="now-ui-icons ui-1_check"
              onClick={this.handleSave}
              disabled={this.validateForm()}
              isLoading={this.state.isLoading}
              text="Αποθηκευση"
              loadingText="Saving...."
            />
          </Col>
        </Row>
      </form>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showBodyForm === true
          ? this.renderAreasBody()
          : this.renderAreasCreate()}
      </React.Fragment>
    );
  }
}

export default ElectionsPartiesForm;
