import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import { moveSyntheticComments } from "typescript";
import moment from "moment";

class ElectionsHeader extends React.Component {
  state = {
    ensomatosi: ""
  };

  componentDidMount() {
    var ensomatosi;
    if (this.props.numOfVstationsWithResults) {
      ensomatosi = (
        (this.props.numOfVstationsWithResults / this.props.numOfVstationsAll) *
        100
      ).toFixed(2);

      //console.log("ensomatosi me noumero", ensomatosi);
    } else {
      ensomatosi = 0;
      //  console.log("ensomatosi me 0", ensomatosi);
    }

    // {" "}
    // {(
    //   this.props.numOfVstationsWithResults /
    //   this.props.numOfVstationsAll
    // ).toFixed(2) * 100}
    // %{" "}

    this.setState({ ensomatosi: ensomatosi });
  }

  render() {
    //console.log("this.props.lastUpdateOn", this.props.lastUpdateOn);
    return (
      <>
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              {/* <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "} */}
              {/* <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        {this.props.parentName}
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb> */}
              {/* </Col>
                <Col className="text-right" lg="6" xs="5">
                  <Button
                    className="btn-neutral"
                    color="default"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    size="sm"
                  >
                    New
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    size="sm"
                  >
                    Filters
                  </Button>
                </Col> */}
              {/* </Col>
              </Row> */}

              <Row>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            ΕΚΛΟΓΙΚΑ ΤΜΗΜΑΤΑ
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                            {this.props.numOfVstationsWithResults || 0} /
                            {this.props.numOfVstationsAll}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                            <i className="ni ni-chart-pie-35" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            ΕΝΣΩΜΑΤΩΣΗ
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                            {this.state.ensomatosi}
                            {""}%
                            {/* {" "}
                            {(
                              this.props.numOfVstationsWithResults /
                              this.props.numOfVstationsAll
                            ).toFixed(2) * 100}
                            %{" "} */}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="ni ni-chart-bar-32" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            ΕΝΗΜΕΡΩΘΗΚΕ
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                            {/* {moment().format("HH:mm:ss")} */}
                            {moment
                              .unix(this.props.lastUpdateOn)
                              .format("HH:mm:ss")}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="ni ni-watch-time" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

ElectionsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  lastUpdateOn: PropTypes.number,
  numOfVstationsAll: PropTypes.number,
  numOfVstationsWithResults: PropTypes.number
};

export default ElectionsHeader;
