import React from "react";

import AppliedRoute from "./components/common/AppliedRoute";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import IndexView from "views/Index.jsx";
import Viografiko from "./views/pages/viografiko/viografiko";
import Axies from "./views/pages/axies/axies";
import Epikinonia from "./views/pages/epikinonia/epikinonia";
import Login from "./views/pages/login/login";
import AuthenticatedRoute from "./components/common/AuthenticatedRoute";
import ErrorPage from "./views/pages/ErrorPage/ErrorPage";
import Signup from "./views/pages/signup/signup2";

export default ({ childProps }) => (
  <BrowserRouter>
    <Switch>
      <AuthenticatedRoute
        path="/ekloges"
        props={childProps}
        component={AdminLayout}
      />
      <Route path="/not-found" exact component={ErrorPage} />
      <AppliedRoute
        path="/signup"
        exact
        component={Signup}
        props={childProps}
      />
      <AppliedRoute path="/login" exact component={Login} props={childProps} />
      <AppliedRoute
        path="/viografiko"
        exact
        component={Viografiko}
        props={childProps}
      />
      <AppliedRoute
        path="/epikinonia"
        exact
        component={Epikinonia}
        props={childProps}
      />
      <AppliedRoute path="/axies" exact component={Axies} props={childProps} />
      <AppliedRoute path="/" exact component={Axies} props={childProps} />
      <AppliedRoute path="/auth" props={childProps} component={AuthLayout} />
      <Route component={ErrorPage} />
      {/* <AppliedRoute path="/" props={childProps} component={IndexView} /> */}
      {/* <Redirect from="*" to="/" /> */} */}
    </Switch>
  </BrowserRouter>
);
