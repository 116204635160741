import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import Check from "@material-ui/icons/Check";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "../../../components/N1components/Header/Header.jsx";
import HeaderLinks from "../../../components/N1components/Header/HeaderLinks.jsx";
import Footer from "../../../components/N1components/Footer/Footer.jsx";
import GridContainer from "../../../components/N1components/Grid/GridContainer.jsx";
import GridItem from "../../../components/N1components/Grid/GridItem.jsx";
import Button from "../../../components/N1components/CustomButtons/Button.jsx";
import Card from "../../../components/N1components/Card/Card.jsx";
import CardBody from "../../../components/N1components/Card/CardBody.jsx";
import CardHeader from "../../../components/N1components/Card/CardHeader.jsx";
import CustomInput from "../../../components/N1components/CustomInput/CustomInput.jsx";
import InfoArea from "../../../components/N1components/InfoArea/InfoArea.jsx";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";

import MainImage from "../../../assets/img/lefkadacity.jpg";
import { Row, Col } from "reactstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "../../../components/common/loaderButton";
import { toast } from "react-toastify";
import { usersCreate } from "../../../services/usersService";
import { emailSent } from "../../../services/emailService";

class Components extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, //for the button
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null
    };
  }

  validateForm() {
    var disabled = true;

    if (
      this.state.email.length > 5 &&
      this.state.password.length > 5 &&
      this.state.password === this.state.confirmPassword
    )
      disabled = false;

    //check the email
    // var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // console.log(this.state.password, emailRex.test(this.state.password));
    // if (emailRex.test(this.state.password)) {
    //   disabled = false;
    // } else {
    //   disabled = true;
    // }

    return disabled;
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password
      });
      this.setState({
        newUser: newUser,
        confirmationCode: ""
      });
      console.log("MPIKAAA", newUser);
      toast.success(
        "1) Πρωτο βημα ολοληρωθηκε, παρακαλω δες το email σου για κωδικο"
      );
    } catch (ex) {
      console.log(ex);
      if ((ex.code = "UsernameExistsException")) {
        toast.warning(
          "Προβλημα κατα την δημιουργια, ξανα στελνω κωδικο στο email " +
            this.state.email
        );

        const newUser = this.state.email;
        Auth.resendSignUp(newUser);

        this.setState({ newUser: newUser, confirmationCode: "" });

        console.log("state on wrong !!,", this.state);
      }
    }

    this.setState({ isLoading: false });
  };

  mapUser(user) {
    return {
      id: user.id,
      lastName: user.lastName || "",
      firstName: user.firstName || "",
      email: user.email || "",
      mobile: user.mobile || "",
      notes: user.notes || "",
      imageProfileId: user.imageProfileId || "",
      updatedOn: user.updatedOn || ""
    };
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    // console.log("on verification submit", this.state);

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      //console.log("confirmSignUp", this.state);

      const response = await Auth.signIn(this.state.email, this.state.password);
      //   console.log("response from signup ", response);
      //   const session = await Auth.currentSession(); //checks the state and brings the result
      //   let role = session.accessToken.payload["cognito:groups"];
      //   console.log("role", role);
      const role = "L1";

      var user = {};
      user.id = this.state.email;
      user.lastName = "";
      user.firstName = "";
      user.email = this.state.email;
      user.mobile = "";
      user.notes = "";
      user.imageProfileId = "";

      //I will create the user also oi my users list
      await usersCreate(user);

      //sen email to me
      await emailSent(
        this.state.email,
        this.state.email,
        this.state.email,
        "Μολις δημιουργησα Λογαριασμό για τον χρηστη " + this.state.email
      );

      this.props.userHasAuthenticated(true, role, response.attributes.email);
      this.props.history.push("/ekloges/");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });

    // console.log("Ole", event.target.id, event.target.value, this.state);
  };

  renderConfirmationForm() {
    const { classes, ...rest } = this.props;
    return (
      <React.Fragment>
        <CustomInput
          id="confirmationCode"
          value={this.state.confirmationCode}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            onChange: this.handleChange,
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornment}
              >
                <Lock className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            placeholder: "Pin..."
          }}
        />
        <LoaderButton
          color="success"
          onClick={this.handleConfirmationSubmit}
          disabled={!this.validateConfirmationForm()}
          isLoading={this.state.isLoading}
          text="Επιβεβαίωση"
          loadingText="Saving...."
        />
      </React.Fragment>
    );
  }

  renderForm() {
    const { classes, ...rest } = this.props;
    return (
      <form className={classes.form}>
        <CustomInput
          id="email"
          value={this.state.email}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            onChange: this.handleChange,
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornment}
              >
                <Email className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            placeholder: "Email..."
          }}
        />
        <CustomInput
          id="password"
          value={this.state.password}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            onChange: this.handleChange,
            type: "password",
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornment}
              >
                <Lock className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            placeholder: "Κωδικός..."
          }}
        />
        <CustomInput
          id="confirmPassword"
          value={this.state.password}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            onChange: this.handleChange,
            type: "password",
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornment}
              >
                <Lock className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            placeholder: "Επιβεβαίωση κωδικού..."
          }}
        />

        <div className={classes.textCenter}>
          <Button
            round
            color="info"
            onClick={this.handleSubmit}
            disabled={this.validateForm()}
          >
            Δημιουργια Λογιαριασμού
          </Button>
        </div>
      </form>
    );
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="white"
          brand="Ιωάννης Μαλακάσης"
          links={
            <HeaderLinks
              dropdownHoverColor="info"
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              onLogout={this.props.onLogout}
            />
          }
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
        />
        {/* <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + MainImage + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        /> */}
        <Row>
          <p />
        </Row>
        <p />
        <p />
        <p />
        <p />
        <Row />
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={10}>
            <Card className={classes.cardSignup}>
              <h1 className={classes.cardTitle}> Εγγραφή Χρήστη </h1>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={5} md={5}>
                    {this.state.newUser === null ? (
                      <InfoArea
                        className={classes.infoArea}
                        title="Συμπλήρωσε τα στοιχεία"
                        description="Στην καρτέλα δεξιά συμπλήρωσε με το email σου  το πεδίο όνομα χρήστη και χρησιμοποίησε έναν κωδικό (από 6 χαρακτήρες και πάνω) για την επιτυχή εγγραφή σου."
                        icon={Timeline}
                        iconColor="rose"
                      />
                    ) : (
                      <InfoArea
                        className={classes.infoArea}
                        title="Επιβεβαίωση Λογαριασμού"
                        description="Ένας 6 ψήφιος κωδικός έχει σταλεί στο email που έδωσες. Χρησιμοποίησε στο πεδίο  pin και με το κουμπί επιβεβαίωσης θα εισέλθεις στο site."
                        icon={Timeline}
                        iconColor="rose"
                      />
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    {this.state.newUser === null
                      ? this.renderForm()
                      : this.renderConfirmationForm()}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(signupPageStyle)(Components);
